"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSessionService = void 0;
const moment = require("moment");
let lastSuccessfulLogin = undefined;
exports.UserSessionService = {
    _lastLogin: () => {
        if (!lastSuccessfulLogin)
            throw Error("Never logged in");
        return lastSuccessfulLogin;
    },
    isLoggedIn: () => !!lastSuccessfulLogin,
    authTokenExpired: () => {
        if (!lastSuccessfulLogin)
            return true;
        const expiry = moment(lastSuccessfulLogin.loginResponse.expiry);
        const isExpired = moment().isAfter(expiry);
        return isExpired;
    },
    onLogout: () => {
        lastSuccessfulLogin = undefined;
    },
    getAuthToken: () => exports.UserSessionService._lastLogin().loginResponse.token,
    getUserId: () => {
        if (exports.UserSessionService.isLoggedIn())
            return exports.UserSessionService._lastLogin().loginResponse.userId;
        return undefined;
    },
    // TODO: This is only used to pass username to inspection sync worker.. need a better way!
    setUserName: (username, authToken) => {
        lastSuccessfulLogin = {
            username,
            password: "",
            state: "",
            loginResponse: {
                token: authToken,
                userId: -1,
                accountRoles: [],
                cviewRoles: [],
                cviewState: "",
                expiry: "",
            },
        };
    },
    getUsername: () => {
        if (exports.UserSessionService.isLoggedIn())
            return exports.UserSessionService._lastLogin().username;
        return "";
    },
    getPassword: () => exports.UserSessionService._lastLogin().password,
    getState: () => exports.UserSessionService._lastLogin().state,
    getRoles: () => {
        const username = exports.UserSessionService.getUsername();
        const password = exports.UserSessionService.getPassword();
        // MIGRATE_TODO
        // const lastLogin = LoginHistoryService.getLastLogin(username, password);
        // if (!lastLogin) return [];
        // return lastLogin.cviewRoles;
        return [];
    },
    isAdmin: () => exports.UserSessionService.getRoles().filter((x) => x === "inspectAdmin").length >
        0,
    isSupervisor: () => exports.UserSessionService.getRoles().filter((x) => x === "inspectSupervisor")
        .length > 0,
    onSuccessfulLogin: (username, password, state, loginResponse) => {
        lastSuccessfulLogin = {
            username,
            password,
            state,
            loginResponse,
        };
    },
};
