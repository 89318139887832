"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateOosInfo = exports.certifyText = exports.driverOosText = exports.vehicleOosText = void 0;
const InspectionUtil_1 = require("../inspection/InspectionUtil");
const config_1 = require("../../config");
const driverViolations = (inspection) => inspection.Violations.filter(InspectionUtil_1.isDriverViolation);
const vehicleViolations = (inspection) => inspection.Violations.filter(InspectionUtil_1.isVehicleViolation);
const numDriverOosViolations = (inspection) => driverViolations(inspection).filter(v => v.VioOOSFlag === "Y").length;
const numVehicleViolations = (inspection) => vehicleViolations(inspection).length;
const numVehicleOosViolations = (inspection) => vehicleViolations(inspection).filter(v => v.VioOOSFlag === "Y").length;
const numTotalViolations = (inspection) => vehicleViolations(inspection).length + driverViolations(inspection).length;
exports.vehicleOosText = "Pursuant to authority in 49 CFR 396.9 and KRS 281.600 and 601 KAR 1:005, this vehicle is OUT OF SERVICE.  This vehicle will not be operated until the Out of Service defects noted above are corrected";
exports.driverOosText = "Pursuant to authority in 49 CFR 396.9 and KRS 281.600 and 601 KAR 1:005, driver @DRIVER is OUT OF SERVICE.  No motor carrier shall permit nor require this driver to operate any commercial vehicle until: @TIME.";
exports.certifyText = "The undersigned certifies that necessary repairs were made to this vehicle to correct all noted defects.";
exports.generateOosInfo = (inspection) => {
    return {
        DriverOOSUntilText: numDriverOosViolations(inspection) > 0
            ? (inspection.OutOfServiceInfo || {}).DriverOOSUntilText
            : undefined,
        VehicleOOSText: numVehicleOosViolations(inspection) > 0 ? exports.vehicleOosText : undefined,
        DriverOOSText: numDriverOosViolations(inspection) > 0 ? exports.driverOosText : undefined,
        // Certify is done by Repairer
        CarrierCertifyOOSText: numVehicleViolations(inspection) > 0 ? exports.certifyText : undefined,
        // Verify is done by Motor carrier
        CarrierVerifyOOSText: numTotalViolations(inspection) > 0 ? config_1.verifyText : undefined,
    };
};
