"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const DateFormat_1 = require("../../../../services/DateFormat");
const Helper_1 = require("./Helper");
const SmartLogic_Cat4_Cat6_Hours_Logic_1 = require("./SmartLogic_Cat4_Cat6_Hours_Logic");
const subtractDays = (date, numDays) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - numDays);
    return newDate;
};
class SmartLogic_Cat4_Hours extends React.Component {
    constructor(props) {
        super(props);
        this.driverInViolationChanged = (e) => this.setState({ driverInViolation: e.currentTarget.value });
        this.confirmedViolationTypeChanged = (e) => this.setState({ confirmedViolationType: e.currentTarget.value });
        this.toggleDate = (date) => {
            let defaultDescription = this.props.triggeringViolation.VioDescText.substring(0, this.props.triggeringViolation.VioDescText.indexOf("(") - 1);
            if (defaultDescription.indexOf("60 hours") >= 0)
                defaultDescription = "Driver 60 hr";
            else if (defaultDescription.indexOf("70 hours") >= 0)
                defaultDescription = "Driver 70 hr";
            const dateKey = SmartLogic_Cat4_Cat6_Hours_Logic_1.dateToKey(date);
            const additionalDetails = Object.assign({}, this.state.additionalDetails);
            if (additionalDetails[dateKey])
                delete additionalDetails[dateKey];
            else
                additionalDetails[dateKey] = `${defaultDescription} on ${DateFormat_1.formatDate(date)}`;
            this.setState({ additionalDetails });
        };
        this.changeAdditionalDetail = (e, date) => {
            const additionalDetails = Object.assign({}, this.state.additionalDetails);
            additionalDetails[SmartLogic_Cat4_Cat6_Hours_Logic_1.dateToKey(date)] = e.currentTarget.value;
            this.setState({ additionalDetails });
        };
        // Coalesce violation messages
        this.getViolationMessage = () => this.state.errors.cantCiteError ||
            this.state.errors.violationTypeError ||
            this.state.errors.violationDateError ||
            this.state.errors.driverInViolationError;
        this.validate = () => {
            const result = SmartLogic_Cat4_Cat6_Hours_Logic_1.validate(this.props.inspection.InspMain.InspStartTime.asDate(), this.props.triggeringViolation, this.props.otherViolations, this.state.additionalDetails, this.state.confirmedViolationType, this.state.driverInViolation);
            this.setState(result);
            this.props.setError(result.errors.cantCiteError ||
                result.errors.violationTypeError ||
                result.errors.violationDateError ||
                result.errors.driverInViolationError);
            if (!result.errors.cantCiteError ||
                !result.errors.violationTypeError ||
                !result.errors.violationDateError ||
                !result.errors.driverInViolationError)
                this.props.updateViolations(SmartLogic_Cat4_Cat6_Hours_Logic_1.updateViolations(this.props.inspection.InspMain.InspStartTime.asDate(), this.props.triggeringViolation, this.props.inspection.Violations, this.state.additionalDetails, this.state.driverInViolation));
        };
        this.state = {
            showDateSelection: false,
            showDriverInViolationPrompt: false,
            showViolationsWillBeReplacedWarning: false,
            additionalDetails: {},
            errors: {},
        };
    }
    render() {
        if (this.state.errors.cantCiteError)
            return (React.createElement(core_1.Callout, { intent: core_1.Intent.DANGER }, this.state.errors.cantCiteError));
        return (React.createElement("div", null,
            React.createElement("div", null,
                React.createElement(core_1.Card, null,
                    "Is vehicle type passenger or property carrying?",
                    React.createElement(core_1.RadioGroup, { selectedValue: this.state.confirmedViolationType, onChange: this.confirmedViolationTypeChanged },
                        React.createElement(core_1.Radio, { value: "passenger" }, "Passenger"),
                        React.createElement(core_1.Radio, { value: "property" }, "Property")),
                    Helper_1.callout(core_1.Intent.DANGER, this.state.errors.violationTypeError))),
            Helper_1.callout(core_1.Intent.PRIMARY, this.state.evidenceMessage),
            this.state.showDateSelection && (React.createElement(core_1.Card, null,
                "On which dates did the violation occur?",
                [0, 1, 2, 3, 4, 5, 6, 7].map(numDays => {
                    const date = subtractDays(this.props.inspection.InspMain.InspStartTime.asDate(), numDays);
                    const additionalDetail = this.state.additionalDetails[SmartLogic_Cat4_Cat6_Hours_Logic_1.dateToKey(date)];
                    return (React.createElement("div", { key: numDays },
                        React.createElement(core_1.Checkbox, { checked: !!additionalDetail, label: DateFormat_1.formatDate(date), onChange: () => this.toggleDate(date), style: { display: "inline-block" } }),
                        " ",
                        additionalDetail && (React.createElement("input", { className: core_1.Classes.INPUT, type: "text", value: additionalDetail, onChange: e => this.changeAdditionalDetail(e, date), style: { width: 500 }, maxLength: 162 }))));
                }))),
            Helper_1.dangerCallout(this.state.errors.violationDateError),
            this.state.showDriverInViolationPrompt && (React.createElement(core_1.Card, null,
                "Is driver currently in violation?",
                React.createElement(core_1.RadioGroup, { selectedValue: this.state.driverInViolation, onChange: this.driverInViolationChanged },
                    React.createElement(core_1.Radio, { value: "yes" }, "Yes"),
                    React.createElement(core_1.Radio, { value: "no" }, "No")))),
            Helper_1.dangerCallout(this.state.errors.driverInViolationError),
            this.state.showViolationsWillBeReplacedWarning && (React.createElement(core_1.Callout, { intent: core_1.Intent.WARNING, icon: "warning-sign" },
                React.createElement("h5", { className: core_1.Classes.HEADING }, "Existing violations will be replaced!"),
                "Violation ",
                this.props.triggeringViolation.VioFederalRegCode,
                " has already been entered for this driver. By continuing these violations will be removed and be replaced with the new violations indicated."))));
    }
    componentDidMount() {
        this.validate();
    }
    componentDidUpdate(nextProps, nextState) {
        if (this.state.confirmedViolationType !== nextState.confirmedViolationType ||
            this.state.additionalDetails !== nextState.additionalDetails ||
            this.state.driverInViolation !== nextState.driverInViolation) {
            this.validate();
        }
        else
            this.props.setError(this.getViolationMessage());
    }
}
exports.default = SmartLogic_Cat4_Hours;
