"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getViolationForMcsipLevel = void 0;
exports.getViolationForMcsipLevel = (mcsipLevel, carrierCountryCode, carrierClassCode, isIep, hazmatStatus) => {
    const isHazmat = hazmatStatus && ["B", "C", "F", "S"].indexOf(hazmatStatus) >= 0;
    const isAnyClass = (classes) => classes.filter(x => (carrierClassCode || []).includes(x)).length >= 0;
    if (mcsipLevel === 54) {
        return isIep
            ? // OOS Order - IEP offering a CMV while an existing out-of-service order is in effect: Imminent Hazard - Intermodal Equipment Provider.
                "386.72B4-OOSOIEP"
            : // OOS Order - Motor carrier operating a CMV while an existing out-of-service order is in effect: Imminent Hazard - Motor Carrier.
                "386.72B4-OOSOMC";
    }
    if (mcsipLevel === 55) {
        isHazmat || isAnyClass(["24", "26"])
            ? // Operating a commercial motor vehicle after the effective date of an Unsatisfactory rating - HM and Passenger carriers
                "385.13A1"
            : // Operating a commercial motor vehicle after the effective date of an Unsatisfactory rating - all other carrier types
                "385.13A2";
    }
    if (mcsipLevel === 57) {
        if (isAnyClass(["01"]))
            // OOS Order - Motor carrier operating a CMV while an existing out-of-service order is in effect: Failure to pay fine - For-Hire Carrier.
            return "386.83A1-OOSOFHC";
        if (isAnyClass(["25"]))
            // OOS Order - Motor carrier operating a CMV while an existing out-of-service order is in effect: Failure to pay fine - Private Carrier.
            return "386.83A1-OOSOPC";
    }
    if (mcsipLevel === 58)
        // Operating in violation of FMCSA Operational Out of Service order for Failure to respond to Expedited Action Notification
        return "385.308D";
    if (mcsipLevel === 63)
        // Operating in violation of FMCSA Operational Out of Service order for Failure to permit a Safety Audit
        return "385.337B";
    if (mcsipLevel === 64)
        // Operating in interstate commerce on or after the Operational Out of Service order date for failure of a Safety Audit
        return "385.325C";
    return undefined;
};
