import {
  RemoteReportNumberService,
  CreateInspectionOptions,
} from "@rims/common";

const clientId = "web";

export const getCreateInspectionOptions = async (): Promise<
  CreateInspectionOptions
> => {
  const reportNumResponse = await RemoteReportNumberService.reserveNumbers(
    clientId,
    1,
    false,
  );
  let reportNumber: string | undefined;
  if (reportNumResponse.success)
    reportNumber = reportNumResponse.reportNumbers[0];

  if (reportNumber === undefined) throw Error("Can't get report number");

  return {
    rimsVersion: clientId,
    stateCode: "MS",
    timezone: "CT",
    inspectorName: "somebody",
    inspectorCode: "somebody",
    radioNumber: undefined,
    getNextNumber: () => {
      // Not sure why this is needed again
      if (reportNumber === undefined) throw Error("Can't get report number");

      return Promise.resolve({
        inspectionId: undefined,
        reportNumber: reportNumber,
        fromBack: false,
      });
    },
    optionalOptions: undefined,
  };
};
