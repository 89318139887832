"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldConsiderForViolation = exports.shouldConsiderUcrRecord = void 0;
// We consider UCR records for screening for past two years
exports.shouldConsiderUcrRecord = (regYear) => {
    const currentYear = new Date().getFullYear();
    return regYear === currentYear || regYear === currentYear - 1;
};
exports.shouldConsiderForViolation = (ucrResponse) => ucrResponse.registrations
    .filter(x => exports.shouldConsiderUcrRecord(x.year) && x.owesFees)
    .map(x => x.year);
