"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const getInspectorCode = (user) => (user.settings && user.settings.inspectorCode) || "";
const findMatchingUsers = (users, filter) => {
    filter = filter.toLocaleLowerCase();
    return filter.length === 0
        ? users
        : users.filter(x => x.name.toLocaleLowerCase().indexOf(filter) >= 0 ||
            getInspectorCode(x).toLocaleLowerCase() === filter);
};
class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.filterChanged = (e) => {
            const usernameFilter = e.currentTarget.value;
            this.setState({ userFilter: usernameFilter });
            const users = findMatchingUsers(this.props.users, usernameFilter);
            if (users.length > 0)
                this.props.onUserSelected(users[0]);
        };
        this.state = {
            userFilter: "",
        };
    }
    render() {
        const users = findMatchingUsers(this.props.users, this.state.userFilter);
        return (React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("input", { type: "text", className: core_1.Classes.INPUT, onChange: this.filterChanged, value: this.state.userFilter, placeholder: "Filter username" })),
            React.createElement(core_1.HTMLSelect, { onChange: e => this.props.onUserSelected(users.filter(x => x.id === parseInt(e.currentTarget.value))[0]), value: (this.props.selectedUser && this.props.selectedUser.id) || "" },
                this.state.userFilter === "" && React.createElement("option", { value: "" }),
                users
                    .sort((a, b) => (a.name < b.name ? -1 : 1))
                    .map((user, i) => (React.createElement("option", { value: user.id, key: i },
                    user.name,
                    " (",
                    getInspectorCode(user) || "n/a",
                    ")"))))));
    }
}
exports.default = UserList;
