"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const LocalFieldsService_1 = require("../../../services/LocalFieldsService");
const core_1 = require("@blueprintjs/core");
const getValue = (inspection, fieldId) => {
    const field = inspection.LocalFields.filter((x) => x.InspLocalFieldID === fieldId)[0];
    return field ? field.InspLocalFieldDataValue : "";
};
class LocalFieldsPage extends React.Component {
    constructor(props) {
        super(props);
        this.fieldChanged = (fieldConfig, value) => this.props.onLocalFieldsUpdated(LocalFieldsService_1.setFieldValue(this.props.inspection.LocalFields, fieldConfig, value));
        this.state = {
            // MIGRATE_TODO
            // groupConfigs: LocalSnetService.getLocalFields({ controlled: false }),
            groupConfigs: [],
        };
    }
    render() {
        const groupConfigs = this.state.groupConfigs.filter((gc) => gc.fields.length > 0);
        const defaultTabId = ((groupConfigs || [])[0] || {}).id;
        return (React.createElement("div", { style: { paddingTop: 10 } },
            React.createElement(core_1.Tabs, { id: "localFields", vertical: true, defaultSelectedTabId: defaultTabId, animate: false, className: "local-fields" }, groupConfigs.map((gc) => (React.createElement(core_1.Tab, { key: gc.id, id: gc.id, title: gc.name, panel: React.createElement("table", null,
                    React.createElement("tbody", null, gc.fields.map((field) => (React.createElement("tr", { key: field.id },
                        React.createElement("th", { style: { textAlign: "right" } },
                            field.uiName,
                            ":"),
                        React.createElement("td", null,
                            React.createElement("input", { id: `localfield_${field.id}`, spellCheck: false, className: core_1.Classes.INPUT, style: { width: 200 }, placeholder: field.xmlName, type: "text", dir: "auto", maxLength: field.maxLength, value: getValue(this.props.inspection, field.id), onChange: (e) => this.fieldChanged(field, e.currentTarget.value.toUpperCase()) }),
                            field.required && React.createElement("span", null, " (required)"))))))) }))))));
    }
}
exports.default = LocalFieldsPage;
