"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Labeled_1 = require("../../core/Labeled");
const core_1 = require("@blueprintjs/core");
const types = [
    { value: "", label: "None" },
    { value: "N", label: "Non-Bulk" },
    { value: "B", label: "Bulk" },
    { value: "C", label: "Both Bulk & Non-Bulk" },
];
// TODO: check these values
class default_1 extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.onChange = (e) => {
            const type = e.currentTarget.value;
            this.props.typeChanged(type === "" ? undefined : type);
        };
    }
    render() {
        return (React.createElement(Labeled_1.Labeled, { label: "HazMat Inspection Type" },
            React.createElement(core_1.HTMLSelect, { onChange: this.onChange, value: this.props.type }, types.map((type, i) => (React.createElement("option", { key: i, value: type.value }, type.label))))));
    }
}
exports.default = default_1;
