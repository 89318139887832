"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const CoreService_1 = require("./CoreService");
const NotFound_1 = require("./NotFound");
exports.default = {
    getVehicle: (vin, plate, state) => CoreService_1.CoreService.get(`safer/vehicle-detail/${vin}-${plate}-${state}`, undefined, undefined, 5000).then(response => {
        if (response === NotFound_1.NotFound)
            return response;
        else
            return response;
    }),
};
