"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCarrierScreenResult = void 0;
exports.getCarrierScreenResult = (t0031) => {
    const score = parseInt(t0031.ISS_SCORE);
    let screenResult = "pass";
    if (score >= 75 || t0031.MCMIS_STATUS !== "A")
        screenResult = "inspect";
    else if (score >= 50)
        screenResult = "warn";
    else
        screenResult = "pass";
    if (screenResult === "inspect")
        return {
            result: screenResult,
            icon: "error",
            label: "Inspect",
            primaryColor: "#FF0000",
        };
    else if (screenResult === "warn")
        return {
            result: screenResult,
            icon: "warning-sign",
            label: "Warn",
            primaryColor: "yellow",
        };
    else
        return {
            result: screenResult,
            icon: "tick",
            label: "Pass",
            primaryColor: "#00FF00",
        };
};
