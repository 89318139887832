"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.carrierIsOos = exports.resolveMcsipLevel = exports.mcsipLevelIsFoos = void 0;
exports.mcsipLevelIsFoos = (mcsipLevel) => 53 <= mcsipLevel && mcsipLevel <= 68;
exports.resolveMcsipLevel = (t0031, issResponse) => {
    if (issResponse)
        return issResponse.mcsipLevel;
    if (!t0031)
        return undefined;
    if (typeof t0031.MCSIP_LEVEL === "number")
        return t0031.MCSIP_LEVEL;
    const mcsipLevel = parseInt(t0031.MCSIP_LEVEL);
    if (isNaN(mcsipLevel))
        return undefined;
    return mcsipLevel;
};
exports.carrierIsOos = (t0031, issResponse) => {
    const mcsipLevel = exports.resolveMcsipLevel(t0031, issResponse);
    const foos = mcsipLevel === undefined ? false : exports.mcsipLevelIsFoos(mcsipLevel);
    return foos;
};
