"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.measurementIsWarning = exports.measurementIsOutOfRange = exports.outOfRange = exports.findChamberSubTypeMaxVal = exports.findChamberSubtype = exports.findChamberType = exports.ChamberTypes = void 0;
const Measurements_1 = require("./Measurements");
exports.ChamberTypes = [
    {
        name: "BOLT",
        subtypes: [
            { name: "A", outside: "6-15/16", max: "1-3/8", maxVal: 1.375 },
            { name: "B", outside: "9-3/16", max: "1-3/4", maxVal: 1.75 },
            { name: "C", outside: "8-1/16", max: "1-3/4", maxVal: 1.75 },
            { name: "D", outside: "5-1/4", max: "1-1/4", maxVal: 1.25 },
            { name: "E", outside: "6-3/16", max: "1-3/8", maxVal: 1.375 },
            { name: "F", outside: "11", max: "2-1/4", maxVal: 2.25 },
            { name: "G", outside: "9-7/8", max: "2", maxVal: 2 },
        ],
    },
    {
        name: "CLAMP",
        subtypes: [
            { name: "C-12", outside: "5-11/16", max: "1-3/8", maxVal: 1.375 },
            { name: "C-16", outside: "6-3/8", max: "1-3/4", maxVal: 1.75 },
            { name: "C-20", outside: "6-25/32", max: "1-3/4", maxVal: 1.75 },
            { name: "C-24", outside: "7-7/32", max: "1-3/4", maxVal: 1.75 },
            { name: "C-30", outside: "8-3/32", max: "2", maxVal: 2 },
            { name: "C-36", outside: "9", max: "2-1/4", maxVal: 2.25 },
            { name: "C-6", outside: "4-1/2", max: "1-1/4", maxVal: 1.25 },
            { name: "C-9", outside: "5-1/4", max: "1-3/8", maxVal: 1.375 },
        ],
    },
    {
        name: "DD3",
        subtypes: [{ name: "D-30", outside: "8-1/8", max: "2-1/4", maxVal: 2.25 }],
    },
    {
        name: "DISC",
        subtypes: [{ name: "DISC", outside: "NA", max: "NA", maxVal: 0 }],
    },
    {
        name: "ELECTRIC",
        subtypes: [{ name: "ELEC", outside: "NA", max: "NA", maxVal: 0 }],
    },
    {
        name: "HYDRAULIC",
        subtypes: [{ name: "HYDR", outside: "NA", max: "NA", maxVal: 0 }],
    },
    {
        name: "LONG STROKE CLAMP",
        subtypes: [
            { name: "L-12", outside: "5-11/16", max: "1-3/4", maxVal: 1.75 },
            { name: "L-16", outside: "6-3/8", max: "2", maxVal: 2 },
            { name: "L-20", outside: "6-25/32", max: "2", maxVal: 2 },
            { name: "L-20*", outside: "6-25/32", max: "2-1/2", maxVal: 2.5 },
            { name: "L-24", outside: "7-7/32", max: "2", maxVal: 2 },
            { name: "L-24*", outside: "7-7/32", max: "2-1/2", maxVal: 2.5 },
            { name: "L-30", outside: "8-3/32", max: "2-1/2", maxVal: 2.5 },
        ],
    },
    {
        name: "MISMATCH",
        subtypes: [{ name: "MM", outside: "8-1/8", max: "2-1/4", maxVal: 2.25 }],
    },
    {
        name: "NONE",
        subtypes: [{ name: "NONE", outside: "NA", max: "NA", maxVal: 0 }],
    },
    {
        name: "ROTO",
        subtypes: [
            { name: "R-12", outside: "4-13/16", max: "1-1/2", maxVal: 1.5 },
            { name: "R-16", outside: "5-13/32", max: "2", maxVal: 2 },
            { name: "R-20", outside: "5-15/16", max: "2", maxVal: 2 },
            { name: "R-24", outside: "6-13/32", max: "2", maxVal: 2 },
            { name: "R-30", outside: "7-1/16", max: "2-1/4", maxVal: 2.25 },
            { name: "R-36", outside: "7-5/8", max: "2-3/4", maxVal: 2.75 },
            { name: "R-50", outside: "8-7/8", max: "3", maxVal: 3 },
            { name: "R-9", outside: "4-9/32", max: "1-1/2", maxVal: 1.5 },
        ],
    },
    {
        name: "WEDGE",
        subtypes: [{ name: "WEDG", outside: "NA", max: "NA", maxVal: 0 }],
    },
];
function findChamberType(subType) {
    return exports.ChamberTypes.filter(chamberType => chamberType.subtypes.filter(st => st.name === subType).length > 0)[0];
}
exports.findChamberType = findChamberType;
function findChamberSubtype(subType) {
    const chamberType = findChamberType(subType);
    if (!chamberType)
        return undefined;
    const subtypes = chamberType.subtypes;
    return subtypes.filter(x => x.name === subType)[0];
}
exports.findChamberSubtype = findChamberSubtype;
function findChamberSubTypeMaxVal(subtype) {
    let max = 0;
    for (let i = 0; i < exports.ChamberTypes.length; ++i) {
        for (let j = 0; j < exports.ChamberTypes[i].subtypes.length; ++j) {
            const curSubtype = exports.ChamberTypes[i].subtypes[j];
            if (curSubtype.name === subtype)
                max = curSubtype.maxVal;
        }
    }
    return max;
}
exports.findChamberSubTypeMaxVal = findChamberSubTypeMaxVal;
exports.outOfRange = (subtype, measurement) => {
    if (subtype === undefined || measurement === undefined)
        return "good";
    if (measurement.label === "INOP")
        return "out-of-range";
    if (measurement === undefined || !measurement.measurement)
        return "good";
    if (subtype === undefined)
        return "good";
    if (measurement.measurement === 0 ||
        measurement.measurement > subtype.maxVal + 0.125)
        return "out-of-range";
    if (measurement.measurement > subtype.maxVal)
        return "warn";
    return "good";
};
exports.measurementIsOutOfRange = (chamberLabel, measurementLabel) => {
    const chamberSubtype = findChamberSubtype(chamberLabel);
    const measurement = Measurements_1.findMeasurement(measurementLabel);
    return exports.outOfRange(chamberSubtype, measurement) === "out-of-range";
};
exports.measurementIsWarning = (chamberLabel, measurementLabel) => {
    const chamberSubtype = findChamberSubtype(chamberLabel);
    const measurement = Measurements_1.findMeasurement(measurementLabel);
    return exports.outOfRange(chamberSubtype, measurement) === "warn";
};
