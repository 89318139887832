"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveVehicle = exports.UpdateVehicle = exports.AddVehicle = void 0;
const InspectionViolationService_1 = require("./InspectionViolationService");
const removeVehicleAndRenumberRemaining = (unitNumber, vehicles) => {
    // Remove vehicle
    let newVehicles = (vehicles || []).filter(v => v.VehicleUnitNum !== unitNumber);
    // Decrement higher-numbered vehicles
    for (let i = 0; i < newVehicles.length; ++i) {
        const unit = parseInt(newVehicles[i].VehicleUnitNum);
        if (unit > parseInt(unitNumber))
            newVehicles[i].VehicleUnitNum = (unit - 1).toString();
    }
    return newVehicles;
};
const removeViolationsAndRenumberRemaining = (unitNumber, violations) => {
    let newViolations = [...(violations || [])];
    // Decrement unit number for any violations with higher-numbered unit codes
    for (let i = 0; i < newViolations.length; ++i)
        if (newViolations[i].VehicleUnitCode === unitNumber)
            newViolations[i].VehicleUnitCode = (parseInt(newViolations[i].VehicleUnitCode) - 1).toString();
    // Remove violations that referred to unit
    for (var seqId of newViolations
        .filter(v => v.VehicleUnitCode === unitNumber)
        .map(v => v.VioSeqID)
        .sort()
        .reverse()) {
        newViolations = InspectionViolationService_1.RemoveViolation(seqId, newViolations);
    }
    return newViolations;
};
exports.AddVehicle = (inspection, newVehicle) => {
    const newUnitNumber = (inspection.Vehicles === undefined && "1") ||
        (inspection.Vehicles.length + 1).toString();
    return Object.assign(Object.assign({}, inspection), { Vehicles: [
            ...inspection.Vehicles,
            Object.assign(Object.assign({}, newVehicle), { VehicleUnitNum: newUnitNumber }),
        ] });
};
exports.UpdateVehicle = (inspection, unitNumber, vehicle) => {
    let newInspection = Object.assign({}, inspection);
    for (let i = 0; i < newInspection.Vehicles.length; ++i)
        if (newInspection.Vehicles[i].VehicleUnitNum === unitNumber)
            newInspection.Vehicles[i] = vehicle;
    return newInspection;
};
exports.RemoveVehicle = (inspection, unitNumber) => {
    // Remove vehicle and its violations, and decrement higher-numbered violations
    // NOTE: assumes unit number is numeric, and matches its index in the vehicles array
    const withoutVehicle = Object.assign(Object.assign({}, inspection), { Vehicles: removeVehicleAndRenumberRemaining(unitNumber, inspection.Vehicles), Violations: removeViolationsAndRenumberRemaining(unitNumber, inspection.Violations) });
    // Decrement violations referring to higher-numbered vehicles
    return withoutVehicle;
};
