"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoteReportNumberService = void 0;
const CoreService_1 = require("./CoreService");
const NotFound_1 = require("./NotFound");
exports.RemoteReportNumberService = {
    reserveNumbers: (clientId, count, fromBack) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.post("inspection-report-number", undefined, {
            clientId,
            count,
            fromBack,
        });
        if (response === NotFound_1.NotFound) {
            return {
                success: false,
                errorMessage: "Couldn't reserve any numbers, unknown error 001",
            };
        }
        else if (response.reportNumbers && response.reportNumbers.length > 0)
            return { success: true, reportNumbers: response.reportNumbers };
        else
            return {
                success: false,
                errorMessage: "Couldn't reserve report number, unknown error 002",
            };
    }),
};
