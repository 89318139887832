"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setStudy = void 0;
// seqNum is 1-offset index
exports.setStudy = (studies, seqNum, text) => {
    if (!(1 <= seqNum && seqNum <= 10))
        throw Error(`seqNum ${seqNum} out of range; must be between 1 and 10 inclusive`);
    const newStudies = [...studies];
    newStudies.length = 10;
    for (let i = 0; i < newStudies.length; ++i)
        if (!newStudies[i])
            newStudies[i] = { StudyText: "" };
    newStudies[seqNum - 1] = { StudyText: text };
    return newStudies;
};
