"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateNotesPdf = exports.formatDate = void 0;
const bwipjs = __importStar(require("bwip-js"));
const InspectionSerializer_1 = require("../inspection/InspectionSerializer");
const pdfmake_1 = require("pdfmake/build/pdfmake");
const PdfMakeVfsFonts_1 = require("./PdfMakeVfsFonts");
const formatInspectorAbbreviated = (name) => {
    const names = name.split(" ");
    const newNames = [names[0][0] + ".", ...names.slice(1)];
    return newNames.join(" ");
};
exports.formatDate = (d) => {
    const str = d.toISOString().substring(0, 10);
    const parts = str.split("-");
    return `${parts[2]}/${parts[1]}/${parts[0]}`;
};
const formatUsdot = (usdot) => {
    usdot = usdot || "";
    const pad = "00000000";
    return pad.substring(0, pad.length - usdot.length) + usdot;
};
const hr = () => {
    const margin = [0, 1, 0, 1];
    const lineWidth = 0.5;
    return {
        margin: margin,
        canvas: [
            {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 535,
                y2: 0,
                lineWidth,
            },
        ],
    };
};
const generateBarcode = (inspection) => {
    const canvas = document.createElement("canvas");
    return new Promise((resolve, reject) => {
        bwipjs.toCanvas(canvas, {
            bcid: "code128",
            text: inspection.InspMain.InspReportID,
        });
        resolve(canvas.toDataURL());
    });
};
const getDefinition = (report, notes) => __awaiter(void 0, void 0, void 0, function* () {
    const barcode = yield generateBarcode(report);
    return {
        pageMargins: [30, 30, 30, 120],
        pageSize: "letter",
        content: [
            {
                text: "INSPECTION NOTES",
                style: "title",
            },
            hr(),
            {
                alignment: "justify",
                margin: [0, 20, 0, 0],
                columns: [
                    [
                        {
                            text: "Report Number:",
                            style: "heading",
                        },
                        { text: report.InspMain.InspReportID },
                    ],
                    [
                        {
                            text: "Inspection Date",
                            style: "heading",
                        },
                        {
                            text: (report.InspMain.InspEndTime &&
                                exports.formatDate(report.InspMain.InspEndTime.asDate())) ||
                                exports.formatDate(report.InspMain.InspStartTime.asDate()),
                        },
                    ],
                    [
                        {
                            text: "Signature: _____________________",
                            style: "heading",
                        },
                        {
                            text: formatInspectorAbbreviated(report.InspMain.InspectorName),
                            margin: [60, 0, 0, 0],
                        },
                    ],
                ],
            },
            {
                text: notes,
                margin: [0, 20, 0, 0],
            },
        ],
        footer: (currentPage, pageCount) => ({
            alignment: "justify",
            columns: [
                [],
                [
                    {
                        text: `Page ${currentPage} of ${pageCount}`,
                    },
                ],
                [
                    {
                        image: barcode,
                        width: 150,
                        height: 20,
                        margin: [0, 10, 0, 0],
                    },
                    {
                        text: formatUsdot(report.InspMain.Carrier.USDOTNum) +
                            " " +
                            report.InspMain.InspLocation.InspStateCode +
                            " " +
                            report.InspMain.InspReportID,
                        alignment: "right",
                        margin: [0, 0, 60, 0],
                        fontSize: 10,
                    },
                ],
            ],
        }),
        styles: {
            title: {
                bold: true,
                fontSize: 14,
                alignment: "center",
                margin: [0, 0, 0, 2],
            },
            heading: { bold: true },
        },
        defaultStyle: {
            font: "Arial",
            fontSize: 12,
        },
    };
});
exports.generateNotesPdf = (inspection) => {
    return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
        const definition = yield getDefinition(inspection, InspectionSerializer_1.getFullNotes(inspection));
        var printer = pdfmake_1.createPdf(definition, {}, {
            Arial: {
                normal: "arial.ttf",
                bold: "arialbd.ttf",
            },
        }, PdfMakeVfsFonts_1.PdfMakeVfsFonts);
        printer.getBuffer((buffer) => resolve(buffer));
    }));
};
