"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const datetime_1 = require("@blueprintjs/datetime");
const DateFormat_1 = require("../../../../services/DateFormat");
const InspectionViolationService_1 = require("../../InspectionViolationService");
const DateUtil_1 = require("../../../../services/DateUtil");
const moment = require("moment");
const dateFormat = "MM/DD/YYYY";
const isInspectionStateAlaska = (inspection) => inspection.InspMain.InspLocation.InspStateCode == "AK";
const createAdditionalDetailsMessage = (date) => !date ? "" : `Violation occurred on ${DateFormat_1.formatDate(date)}`;
const displayErrors = (messages, step) => messages
    .filter(x => x.step === step)
    .map((error, i) => (React.createElement("div", { key: i },
    React.createElement(core_1.Callout, { key: i, intent: { error: core_1.Intent.DANGER, warning: core_1.Intent.WARNING }[error.level] }, error.msg))));
const anyErrorsForStep = (messages, step) => messages.filter(x => x.step <= step && x.level === "error").length > 0;
class SmartLogic_Cat5_Alaska extends React.Component {
    constructor(props) {
        super(props);
        this.updateViolations = () => {
            this.props.updateViolations(InspectionViolationService_1.UpdateViolation(this.props.violations, this.props.triggeringViolation.VioSeqID, Object.assign(Object.assign({}, this.props.triggeringViolation), { VioOOSFlag: this.state.driverInViolation === "yes" ? "Y" : "N", SuppVioDesc: this.state.additionalDetails, date: this.state.violationDate })));
        };
        // Coalesce violation messages
        this.getViolationErrorMessage = () => this.state.messages
            .filter(x => x.level === "error")
            .map(x => x.msg)
            .join(", ");
        this.driverInViolationChanged = (e) => this.setState({ driverInViolation: e.currentTarget.value });
        this.additionalDetailsChanged = (e) => this.setState({ additionalDetails: e.currentTarget.value });
        this.validate = () => {
            let messages = [];
            const { triggeringViolation, otherViolations } = this.props;
            let alreadyErroredRegardingState = false;
            if (!this.state.violationDate)
                messages.push({
                    msg: "Violation date required",
                    step: 1,
                    level: "error",
                });
            else {
                if (!isInspectionStateAlaska(this.props.inspection)) {
                    if (DateFormat_1.isSameDate(this.state.violationDate, this.props.inspection.InspMain.InspStartTime.asDate())) {
                        messages.push({
                            msg: "You cannot cite an Alaska violation on current date if the inspection is not in Alaska.",
                            step: 1,
                            level: "error",
                        });
                        alreadyErroredRegardingState = true;
                    }
                    else
                        messages.push({
                            msg: "Ensure you meant to select a violation of the Alaska HOS regulations.",
                            step: 1,
                            level: "warning",
                        });
                }
                // 395.1H1 / 395.1H2 conflict
                if ((triggeringViolation.VioFederalRegCode === "395.1H1" &&
                    otherViolations.filter(x => x.VioFederalRegCode === "395.1H2")
                        .length > 0) ||
                    (triggeringViolation.VioFederalRegCode === "395.1H2" &&
                        otherViolations.filter(x => x.VioFederalRegCode === "395.1H1")
                            .length > 0)) {
                    if (!alreadyErroredRegardingState)
                        messages.push({
                            msg: "You cannot cite 395.1(h)(1) and 395.1(2) on the same inspection",
                            step: 1,
                            level: "error",
                        });
                }
                if (otherViolations.filter(x => x.VioFederalRegCode &&
                    (x.VioFederalRegCode.startsWith("395.3") ||
                        x.VioFederalRegCode.startsWith("395.5"))).length > 0) {
                    if (DateFormat_1.isSameDate(this.state.violationDate, this.props.inspection.InspMain.InspStartTime.asDate())) {
                        messages.push({
                            msg: "You cannot cite 395.3 or 395.5 IN ALASKA on the current date",
                            step: 2,
                            level: "error",
                        });
                    }
                }
                if (!this.state.driverInViolation)
                    messages.push({
                        msg: "Please choose Yes or No",
                        step: 2,
                        level: "error",
                    });
            }
            this.setState({
                messages,
            });
            this.props.setError(this.getViolationErrorMessage());
            const errorMessages = messages.filter(x => x.level === "error");
            if (errorMessages.length === 0)
                this.updateViolations();
        };
        this.violationDateChanged = (violationDate) => this.setState({
            violationDate,
            additionalDetails: createAdditionalDetailsMessage(violationDate),
        });
        this.state = {
            messages: [],
            violationDate: props.inspection.InspMain.InspStartTime.asDate(),
            additionalDetails: createAdditionalDetailsMessage(props.inspection.InspMain.InspStartTime.asDate()),
        };
    }
    render() {
        const today = DateUtil_1.copyDate(this.props.inspection.InspMain.InspStartTime.asDate());
        const sevenDaysAgo = DateUtil_1.copyDate(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        return (React.createElement("div", null,
            React.createElement(core_1.Card, null,
                "When did the violation occur?",
                " ",
                React.createElement(datetime_1.DateInput, { formatDate: (date, locale) => moment(date).format(dateFormat), parseDate: (str, locale) => moment(str, dateFormat).toDate(), value: this.state.violationDate, onChange: this.violationDateChanged, minDate: sevenDaysAgo, maxDate: today, placeholder: dateFormat }),
                displayErrors(this.state.messages, 1)),
            !anyErrorsForStep(this.state.messages, 1) && (React.createElement(core_1.Card, null,
                "Is driver currently in violation?",
                React.createElement(core_1.RadioGroup, { selectedValue: this.state.driverInViolation, onChange: this.driverInViolationChanged },
                    React.createElement(core_1.Radio, { value: "yes" }, "Yes"),
                    React.createElement(core_1.Radio, { value: "no" }, "No")),
                displayErrors(this.state.messages, 2),
                "Additional Violation Details:",
                " ",
                React.createElement("input", { className: core_1.Classes.INPUT, maxLength: 162, style: { width: "90%" }, value: this.state.additionalDetails, onChange: this.additionalDetailsChanged })))));
    }
    componentDidMount() {
        this.validate();
    }
    componentDidUpdate(nextProps, nextState) {
        if (this.state.violationDate !== nextState.violationDate ||
            this.state.driverInViolation !== nextState.driverInViolation) {
            this.validate();
        }
        else
            this.props.setError(this.getViolationErrorMessage());
    }
}
exports.default = SmartLogic_Cat5_Alaska;
