"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
// MIGRATE_TODO
// import { remote, PrinterInfo } from "electron";
const core_1 = require("@blueprintjs/core");
// MIGRATE_TODO
// import {
//   displayPdf as displayInspectionPdf,
//   generatePdf as generateInspectionPdf,
// } from "../../services/inspection-rendering/InspectionPrintService";
// MIGRATE_TODO
// import { displayNotesPdf, writeNotesPdf } from "../../../../rims-electron/print-notes/NotesPdf";
// MIGRATE_TODO
// import * as child_process from "child_process";
const InspectionSerializer_1 = require("../../services/inspection/InspectionSerializer");
const RimsContext_1 = require("../../types/RimsContext");
const InspectionPrintService_1 = require("../../services/inspection-rendering/InspectionPrintService");
const NotesPdf_1 = require("../../services/inspection-rendering/NotesPdf");
const hasNotes = (inspection) => InspectionSerializer_1.getFullNotes(inspection).trim().length > 0;
const hasIep = (inspection) => inspection.Vehicles.filter((x) => x.VehicleUnitTypeCode === "IC").length > 0;
const printPdf = (pdfPath, numCopies, printerName) => {
    if (numCopies > 5) {
        // MIGRATE_TODO
        // remote.dialog.showMessageBox(remote.getCurrentWindow(), {
        //   type: "error",
        //   title: "Error",
        //   message: "Only support up to 5 copies at a time!",
        // });
        return;
    }
    const options = [pdfPath];
    if (printerName && printerName.length > 0)
        options.push(printerName);
    for (let i = 0; i < numCopies; ++i) {
        // MIGRATE_TODO
        // child_process.spawnSync("PDFtoPrinter.exe", options);
    }
};
let PrintDialog = /** @class */ (() => {
    class PrintDialog extends React.PureComponent {
        constructor(props) {
            super(props);
            this.previewInspection = () => __awaiter(this, void 0, void 0, function* () {
                // MIGRATE_TODO ??
                const buffer = yield InspectionPrintService_1.inspectionToPdf(this.props.inspection, {
                    mode: "full",
                });
                this.context.displayPdf("", buffer);
            });
            this.previewIep = () => __awaiter(this, void 0, void 0, function* () {
                // MIGRATE_TODO ??
                const buffer = yield InspectionPrintService_1.inspectionToPdf(this.props.inspection, {
                    mode: "iep",
                });
                this.context.displayPdf("", buffer);
            });
            this.previewNotes = () => __awaiter(this, void 0, void 0, function* () {
                // MIGRATE_TODO ??
                const buffer = yield NotesPdf_1.generateNotesPdf(this.props.inspection);
                this.context.displayPdf("", buffer);
            });
            this.numInspectionCopiesChanged = (e) => this.setState({ numInspectionCopies: e.currentTarget.value });
            this.numIepCopiesChanged = (e) => this.setState({ numIepCopies: e.currentTarget.value });
            this.printerChanged = (e) => this.setState({ selectedPrinter: e.currentTarget.value });
            this.numNotesCopiesChanged = (e) => this.setState({ numNotesCopies: e.currentTarget.value });
            this.printOnly = () => __awaiter(this, void 0, void 0, function* () {
                // await this.print();
                console.error("not implemented");
            });
            this.print = () => __awaiter(this, void 0, void 0, function* () {
                // MIGRATE_TODO
                /*    // Print inspection
                if (this.state.numInspectionCopies > 0) {
                  const inspectionPdfPath = await generateInspectionPdf(
                    this.props.inspection,
                    { mode: "full" },
                  );
                  printPdf(
                    inspectionPdfPath,
                    this.state.numInspectionCopies,
                    this.state.selectedPrinter,
                  );
                }
            
                // Print IEP inspection
                if (this.state.numIepCopies > 0) {
                  const iepPdfPath = await generateInspectionPdf(this.props.inspection, {
                    mode: "iep",
                  });
                  printPdf(iepPdfPath, this.state.numIepCopies, this.state.selectedPrinter);
                }
            
                // Print notes
                if (this.state.numNotesCopies > 0 && hasNotes(this.props.inspection)) {
                  const notePdfPath = await writeNotesPdf(this.props.inspection);
                  printPdf(
                    notePdfPath,
                    this.state.numNotesCopies,
                    this.state.selectedPrinter,
                  );
                }
              */
            });
            this.transmitOnly = () => __awaiter(this, void 0, void 0, function* () {
                // MIGRATE_TODO
                /*    console.log("transmitting...");
                 */
                yield this.props.onTransmit();
                this.props.onPrintFinished();
            });
            this.transmitAndPrint = () => __awaiter(this, void 0, void 0, function* () {
                yield this.props.onTransmit();
                this.props.onPrintFinished();
                // MIGRATE_TODO
                /*console.log("transmitting...");
                await this.props.onTransmit();
                console.log("printing");
                await this.print();
                console.log("finished..");
                this.props.onPrintFinished();
              */
            });
            this.state = {
                numInspectionCopies: 1,
                numIepCopies: 0,
                numNotesCopies: 0,
            };
        }
        render() {
            return (React.createElement("div", null,
                React.createElement(core_1.HTMLTable, null,
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, "Printer:"),
                            React.createElement("td", { colSpan: 3 },
                                React.createElement(core_1.HTMLSelect, { value: this.state.selectedPrinter, onChange: this.printerChanged }))),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Inspection"),
                            React.createElement("td", null,
                                React.createElement(core_1.HTMLSelect, { value: this.state.numInspectionCopies, onChange: this.numInspectionCopiesChanged },
                                    React.createElement("option", null, "0"),
                                    React.createElement("option", null, "1"),
                                    React.createElement("option", null, "2"),
                                    React.createElement("option", null, "3"),
                                    React.createElement("option", null, "4"),
                                    React.createElement("option", null, "5"))),
                            React.createElement("td", null, "copies"),
                            React.createElement("td", null,
                                React.createElement(core_1.Button, { text: "Preview", onClick: this.previewInspection }))),
                        React.createElement("tr", null,
                            React.createElement("td", null, "IEP Report"),
                            React.createElement("td", null,
                                React.createElement(core_1.HTMLSelect, { value: this.state.numIepCopies, onChange: this.numIepCopiesChanged, disabled: !hasIep(this.props.inspection) },
                                    React.createElement("option", null, "0"),
                                    React.createElement("option", null, "1"),
                                    React.createElement("option", null, "2"),
                                    React.createElement("option", null, "3"),
                                    React.createElement("option", null, "4"),
                                    React.createElement("option", null, "5"))),
                            React.createElement("td", null, "copies"),
                            React.createElement("td", null,
                                React.createElement(core_1.Button, { text: "Preview", onClick: this.previewIep, disabled: !hasIep(this.props.inspection) }))),
                        React.createElement("tr", null,
                            React.createElement("td", null, "Notes"),
                            React.createElement("td", null,
                                React.createElement(core_1.HTMLSelect, { value: this.state.numNotesCopies, onChange: this.numNotesCopiesChanged, disabled: !hasNotes(this.props.inspection) },
                                    React.createElement("option", null, "0"),
                                    React.createElement("option", null, "1"),
                                    React.createElement("option", null, "2"),
                                    React.createElement("option", null, "3"),
                                    React.createElement("option", null, "4"),
                                    React.createElement("option", null, "5"))),
                            React.createElement("td", null, "copies"),
                            React.createElement("td", null,
                                React.createElement(core_1.Button, { text: "Preview", onClick: this.previewNotes, disabled: !hasNotes(this.props.inspection) }))))),
                React.createElement("div", { className: core_1.Classes.DIALOG_FOOTER },
                    React.createElement("div", { className: core_1.Classes.DIALOG_FOOTER_ACTIONS },
                        React.createElement(core_1.Button, { text: "Cancel", onClick: this.props.onPrintCanceled }),
                        React.createElement(core_1.Button, { text: "Transmit ONLY", onClick: this.transmitOnly }),
                        React.createElement(core_1.Button, { text: "Print ONLY", onClick: this.printOnly }),
                        React.createElement(core_1.Button, { text: "Transmit and Print All", onClick: this.transmitAndPrint, intent: core_1.Intent.PRIMARY })))));
        }
        componentDidMount() {
            // MIGRATE_TODO
            // const window = new remote.BrowserWindow({ show: false });
            // const printers = window.webContents.getPrinters();
            // const defaultPrinter = printers.filter((x) => x.isDefault)[0];
            // this.setState({
            //   printers,
            //   selectedPrinter: (defaultPrinter || {}).name,
            //   numIepCopies: hasIep(this.props.inspection) ? 1 : 0,
            // });
            // window.close();
        }
    }
    PrintDialog.contextType = RimsContext_1.RimsContext;
    return PrintDialog;
})();
exports.default = PrintDialog;
