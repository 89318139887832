"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.mkInspectorLabel = void 0;
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
exports.mkInspectorLabel = (inspector) => `${inspector.name || "n/a"} (${inspector.code || "n/a"})`;
class CoInspectorSelector extends React.Component {
    constructor(props) {
        super(props);
        this.cancel = () => this.props.onCancel();
        this.saveAndClose = () => this.props.onCoInspectorsSaved(this.state.selectedCoInspectors);
        this.selectCoInspector = (inspector) => {
            const selected = Object.assign([], this.state.selectedCoInspectors);
            selected.push(inspector);
            this.setState({ selectedCoInspectors: selected });
        };
        this.removeCoInspector = (inspector) => {
            const selected = Object.assign([], this.state.selectedCoInspectors);
            selected.splice(selected.indexOf(inspector), 1);
            this.setState({ selectedCoInspectors: selected });
        };
        this.changeFilterText = (e) => this.setState({ filterText: e.currentTarget.value });
        this.state = {
            selectedCoInspectors: props.initialCoInspectors,
            filterText: "",
        };
    }
    render() {
        const filterText = this.state.filterText.toUpperCase();
        const selectedInspectorIds = this.state.selectedCoInspectors.map(s => s.userId);
        const availableInspectors = (this.props.allInspectors || [])
            .filter(i => i.userId !== this.props.myUserId)
            .filter(i => selectedInspectorIds.indexOf(i.userId) == -1)
            .filter(x => (x.code || "").toUpperCase().indexOf(filterText) >= 0 ||
            (x.name || "").toUpperCase().indexOf(filterText) >= 0)
            .sort((a, b) => {
            // Most common case -- both inspectors have name
            if (a.name && b.name) {
                return a.name < b.name ? -1 : 1;
            }
            if (!a.name && !b.name) {
                if (a.code && !b.code)
                    return 1;
                if (!a.code && b.code)
                    return -1;
            }
            return 0;
        });
        return (React.createElement("div", null,
            React.createElement("div", { style: { display: "flex" } },
                React.createElement("div", { style: { width: "50%" } },
                    React.createElement("h5", { style: { textAlign: "center" } }, "All Inspectors"),
                    React.createElement(core_1.InputGroup, { leftIcon: "search", type: "search", placeholder: "Filter...", dir: "auto", value: this.state.filterText, onChange: this.changeFilterText }),
                    availableInspectors.map((insp, i) => (React.createElement(core_1.Button, { className: core_1.Classes.FILL, key: i, text: exports.mkInspectorLabel(insp), onClick: () => this.selectCoInspector(insp) })))),
                React.createElement("div", { style: { width: "50%" } },
                    React.createElement("h5", { style: { textAlign: "center" } }, "Co-Inspectors"),
                    this.state.selectedCoInspectors.map((insp, i) => (React.createElement(core_1.Button, { className: core_1.Classes.FILL, key: i, text: exports.mkInspectorLabel(insp), onClick: () => this.removeCoInspector(insp) }))))),
            React.createElement("div", { className: core_1.Classes.DIALOG_FOOTER },
                React.createElement("div", { className: core_1.Classes.DIALOG_FOOTER_ACTIONS },
                    React.createElement(core_1.Button, { onClick: this.cancel, text: "Cancel" }),
                    React.createElement(core_1.Button, { intent: core_1.Intent.PRIMARY, onClick: this.saveAndClose, text: "Save" })))));
    }
}
exports.default = CoInspectorSelector;
