"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const datetime_1 = require("@blueprintjs/datetime");
const DateFormat_1 = require("../../../../services/DateFormat");
const Helper_1 = require("./Helper");
const moment = require("moment");
const SmartLogic_Cat2_FalseLogBook_Logic_1 = require("./SmartLogic_Cat2_FalseLogBook_Logic");
const dateFormat = "MM/DD/YYYY";
class SmartLogic_Cat2_FalseLogBook extends React.Component {
    constructor(props) {
        super(props);
        this.createAdditionalDetailsMessage = (violationDate) => `False log on ${DateFormat_1.formatDate(violationDate)}`;
        this.concealmentChanged = (e) => this.setState({ concealment: e.currentTarget.value });
        this.violationDateChanged = (violationDate) => this.setState({
            violationDate,
            additionalDetails: this.createAdditionalDetailsMessage(violationDate),
        });
        this.additionalDetailsChanged = (e) => this.setState({ additionalDetails: e.currentTarget.value });
        this.validate = () => {
            const result = SmartLogic_Cat2_FalseLogBook_Logic_1.validate(this.props.inspection.InspMain.InspStartTime.asDate(), this.state.violationDate, this.props.otherViolations, this.state.concealment);
            const { concealmentError, violationDateError, cantCiteError, } = result.errors;
            this.setState({
                errors: result.errors,
                evidenceMessage: result.evidenceMessage,
                prompts: result.prompts,
            });
            this.props.setError(concealmentError || violationDateError || cantCiteError);
            if (!concealmentError && !violationDateError && !cantCiteError)
                this.props.updateViolations(SmartLogic_Cat2_FalseLogBook_Logic_1.updateViolations(this.state.violationDate, this.props.triggeringViolation, this.props.violations, this.state.additionalDetails, this.state.concealment));
        };
        this.state = {
            violationDate: props.inspection.InspMain.InspStartTime.asDate(),
            additionalDetails: this.createAdditionalDetailsMessage(props.inspection.InspMain.InspStartTime.asDate()),
            errors: {},
            prompts: {
                additionalDetails: false,
                concealment: false,
            },
            evidenceMessage: undefined,
        };
    }
    render() {
        const inspectionDate = this.props.inspection.InspMain.InspStartTime.asDate();
        return (React.createElement("div", null,
            this.state.evidenceMessage &&
                Helper_1.callout(core_1.Intent.PRIMARY, this.state.evidenceMessage),
            React.createElement(core_1.Card, null,
                "When did the violation occur?",
                " ",
                React.createElement(datetime_1.DateInput, { formatDate: (date, locale) => moment(date).format(dateFormat), parseDate: (str, locale) => moment(str, dateFormat).toDate(), value: this.state.violationDate, onChange: this.violationDateChanged, maxDate: inspectionDate, placeholder: dateFormat })),
            Helper_1.callout(core_1.Intent.DANGER, this.state.errors.cantCiteError),
            Helper_1.callout(core_1.Intent.DANGER, this.state.errors.violationDateError),
            this.state.prompts.concealment && (React.createElement("div", null,
                React.createElement(core_1.Card, null,
                    "Does evidence exist to show the Driver was willfully attempting to conceal an HOS violation?",
                    React.createElement(core_1.RadioGroup, { selectedValue: this.state.concealment, onChange: this.concealmentChanged },
                        React.createElement(core_1.Radio, { value: "Y" }, "Yes"),
                        React.createElement(core_1.Radio, { value: "N" }, "No"))),
                Helper_1.callout(core_1.Intent.DANGER, this.state.errors.concealmentError))),
            this.state.prompts.additionalDetails && (React.createElement(core_1.Card, null,
                "Additional Violation Details:",
                " ",
                React.createElement("input", { className: core_1.Classes.INPUT, maxLength: 162, style: { width: "90%" }, value: this.state.additionalDetails, onChange: this.additionalDetailsChanged })))));
    }
    componentDidMount() {
        this.validate();
    }
    componentDidUpdate(nextProps, nextState) {
        if (this.state.concealment !== nextState.concealment ||
            this.state.violationDate !== nextState.violationDate ||
            this.state.additionalDetails !== nextState.additionalDetails) {
            this.validate();
        }
        else
            this.props.setError(this.state.errors.concealmentError ||
                this.state.errors.violationDateError ||
                this.state.errors.cantCiteError);
    }
}
exports.default = SmartLogic_Cat2_FalseLogBook;
