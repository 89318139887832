"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const datetime_1 = require("@blueprintjs/datetime");
const core_1 = require("@blueprintjs/core");
const moment = require("moment");
const dateFormat = "MM/DD/YYYY";
class DateTimeInput extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.onDateChanged = (date) => {
            const prevTime = this.props.value || new Date();
            const newDatetime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), prevTime.getHours(), prevTime.getMinutes(), prevTime.getSeconds(), prevTime.getMilliseconds());
            this.props.onChange(newDatetime);
        };
        this.onTimeBlur = (e) => {
            const [hours, minutes] = e.currentTarget.value
                .split(":")
                .map(x => parseInt(x));
            const prevDate = this.props.value || new Date();
            const newDatetime = new Date(prevDate.getFullYear(), prevDate.getMonth(), prevDate.getDate(), isNaN(hours) ? prevDate.getHours() : hours, isNaN(minutes) ? prevDate.getMinutes() : minutes, prevDate.getSeconds(), prevDate.getMilliseconds());
            this.props.onChange(newDatetime);
        };
    }
    render() {
        const timeString = moment(this.props.value).format("HH:mm");
        return (React.createElement("div", { style: { display: "flex" } },
            React.createElement(datetime_1.DateInput, { value: this.props.value, onChange: this.onDateChanged, minDate: this.props.minDate, maxDate: this.props.maxDate, formatDate: (date, locale) => moment(date).format(dateFormat), parseDate: (str, locale) => moment(str, dateFormat).toDate(), popoverProps: this.props.datePopoverProps || {
                    openOnTargetFocus: false,
                    isOpen: false,
                }, placeholder: dateFormat }),
            React.createElement("input", { className: core_1.Classes.INPUT, style: { marginLeft: 5, marginTop: 10 }, type: "time", defaultValue: timeString, required: this.props.required, onBlur: this.onTimeBlur })));
    }
}
exports.default = DateTimeInput;
