"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const ChamberTypes_1 = require("./ChamberTypes");
const core_1 = require("@blueprintjs/core");
const BrakeSelectionServices_1 = require("../../../services/BrakeSelectionServices");
class BrakeMeasurementBox extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.selectMeasurement = () => this.props.onMeasurementChanged(this.props.axleNum, BrakeSelectionServices_1.BrakeMeasurementSelectionService.getSelectedMeasurement());
    }
    render() {
        let mainButtonStyle = Object.assign({}, this.props.style);
        const oorStatus = ChamberTypes_1.outOfRange(this.props.chamberSubType, this.props.measurement);
        if (oorStatus === "out-of-range") {
            mainButtonStyle = Object.assign(Object.assign({}, mainButtonStyle), { backgroundColor: "maroon", color: "white", backgroundImage: "unset" });
        }
        else if (oorStatus === "warn") {
            mainButtonStyle = Object.assign(Object.assign({}, mainButtonStyle), { backgroundColor: "yellow", backgroundImage: "unset", color: "black" });
        }
        return (React.createElement("div", null,
            React.createElement(core_1.Button, { text: (this.props.measurement && this.props.measurement.label) || " ", style: mainButtonStyle, onClick: this.selectMeasurement })));
    }
}
exports.default = BrakeMeasurementBox;
