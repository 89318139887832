"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapToTab = void 0;
exports.mapToTab = (error) => {
    if (!error.elementPaths)
        return "finish";
    const imatches = (s) => error.elementPaths &&
        error.elementPaths
            .join(",")
            .toLowerCase()
            .indexOf(s) >= 0;
    const matches = (s) => error.elementPaths && error.elementPaths.join(",").indexOf(s) >= 0;
    if (matches("Violations") || matches("OutOfServiceInfo.DriverOOSUntilText"))
        return "violation";
    else if (matches("InspStartTime") && !matches("InspEndTime"))
        return "start";
    else if (matches("TripOrigin") ||
        matches("TripDestination") ||
        matches("CargoTypeText") ||
        matches("InspMain.Carrier") ||
        matches("InspMain.Cargo") ||
        matches("HazMats") ||
        matches("HazmatUnNaNumber"))
        return "carrier";
    else if (imatches("driver"))
        return "driver";
    else if (imatches("location"))
        return "location";
    else if (imatches("vehicle"))
        return "vehicle";
    else if (matches("InspEScreenFlag"))
        return "start";
    else if (matches("InspMain.InspDueToBasic"))
        return "start";
    else if (matches("InspMain.InspTypeLevelCode"))
        return "start";
    else if (matches("LocalFields"))
        return "state_fields";
    return "finish";
};
