import React from "react";
import { hot } from "react-hot-loader/root";
import "./App.css";
import {
  RimsApp,
  RimsAppConfig,
  RemoteInspectionService,
  RemoteSnetLocationService,
  Inspection,
} from "@rims/common";
import { RemoteInspectionManager } from "./services/RemoteInspectionManager";
import { getCounties } from "./services/LocalCountyService";
import { getStates } from "./services/LocalStateService";
import { getCities } from "./services/LocalCityService";
import { getFederalViolations } from "./services/LocalFederalViolationsService";
import { getVehicleMakes } from "./services/LocalVehicleMakesService";
import { getCreateInspectionOptions } from "./services/NewInspection";

export const clientId = "web";

let cachedLocations: any = undefined;

const bufferToBase64 = (buffer: Buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const config: RimsAppConfig = {
  offlineModeEnabled: false,
  inspectionManager: new RemoteInspectionManager(RemoteInspectionService),
  showDialogBox: (opts) => {
    alert(opts.title + "\n\n" + opts.message);
  },
  getLocations: async (filterText: string) => {
    if (!cachedLocations)
      try {
        cachedLocations = await RemoteSnetLocationService.getSnetLocations();
      } catch (error) {}
    return Promise.resolve(cachedLocations || []);
  },
  submitInspection: async (inspection: Inspection, version?: number) => {
    await RemoteInspectionService.saveInspection(
      inspection,
      "submitting",
      new Date(),
      inspection.coInspectors?.map((x) => x.userId),
      version,
    );
  },
  getStates: (longCountryCode?: string) => {
    const states = getStates(longCountryCode);
    return Promise.resolve(states);
  },
  getCounties: (shortCountryCode: string, state: string) => {
    const counties = getCounties(shortCountryCode, state);
    return Promise.resolve(counties);
  },
  getCities: getCities,
  getFederalViolations: getFederalViolations,
  getVehicleMakes: getVehicleMakes,
  displayPdf: (name: string, buffer: Buffer) => {
    const base64 = bufferToBase64(buffer);
    window.open("data:application/pdf;base64, " + base64);
  },
  getClientName: () => Promise.resolve("web"),
  openUrl: (url: string) => window.open(url),
  getCreateInspectionOptions: getCreateInspectionOptions,
};

function App() {
  return <RimsApp {...config} />;
}

export default process.env.NODE_ENV === "development" ? hot(App) : App;
