"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const calcTotal = (rows, colName) => {
    const total = rows
        .map(row => row[colName])
        .reduce((prev, cur) => prev + cur, 0);
    if (isNaN(total))
        return undefined;
    return total;
};
class ReportResultView extends React.Component {
    constructor(props) {
        super(props);
        this.showExportToCsvDialog = () => {
            // MIGRATE_TODO
            // const filename = remote.dialog.showSaveDialogSync(
            //   remote.getCurrentWindow(),
            //   {
            //     defaultPath: "report.csv",
            //     title: "Export Report to CSV",
            //     filters: [
            //       {
            //         name: "CSV",
            //         extensions: ["*.csv"],
            //       },
            //     ],
            //   },
            // );
            // if (filename) this.exportToCsv(filename);
        };
        this.exportToCsv = (filename) => {
            // MIGRATE_TODO
            // const content = generateCsvFromReportResult(this.props.result);
            // fs.writeFileSync(filename, content);
            // remote.dialog.showMessageBox(remote.getCurrentWindow(), {
            //   title: "File saved",
            //   message: "File saved!",
            // });
        };
        this.state = { isLoading: false };
    }
    render() {
        const { rows } = this.props.result;
        if (!rows || rows.length === 0)
            return React.createElement("div", null, "No results.");
        const colNames = Object.keys(rows[0]);
        return (React.createElement("div", null,
            React.createElement(core_1.HTMLTable, { striped: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        (this.props.preRowCells || []).map((_, i) => (React.createElement("th", { key: i }))),
                        colNames.map((colName, i) => (React.createElement("th", { key: i }, colName))))),
                React.createElement("tbody", null, rows.map((row, i) => (React.createElement("tr", { key: i },
                    (this.props.preRowCells || []).map((button, j) => (React.createElement("td", { key: j }, button.cellRenderer(this.props.result, i)))),
                    colNames.map((col, j) => (React.createElement("td", { key: j }, row[col]))))))),
                this.props.includeTotals && (React.createElement("tfoot", null,
                    React.createElement("tr", null,
                        React.createElement("td", null, "Totals"),
                        colNames.map(colName => (React.createElement("td", null, calcTotal(rows, colName)))))))),
            React.createElement(core_1.Button, { text: "Export to CSV", rightIcon: "export", onClick: this.showExportToCsvDialog })));
    }
}
exports.default = ReportResultView;
