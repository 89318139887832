"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const select_1 = require("@blueprintjs/select");
const core_1 = require("@blueprintjs/core");
const classNames = require("classnames");
const numVisibleListItems = 50;
const MakeSuggest = select_1.Suggest.ofType();
const renderMake = (item, { handleClick, modifiers, query }) => {
    const classes = classNames({
        [core_1.Classes.ACTIVE]: modifiers.active,
        [core_1.Classes.INTENT_PRIMARY]: modifiers.active,
    });
    return (React.createElement(core_1.MenuItem, { className: classes, key: item, onClick: handleClick, text: item }));
};
class VehicleMakeList extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            makes: this.props.makes.map((make) => `${make.name} (${make.ncicCode})`),
            filteredMakes: this.props.makes
                .map((make) => `${make.name} (${make.ncicCode})`)
                .slice(0, numVisibleListItems),
            // HACK: we track whether component is mounted to prevent auto-selcting the first item in the list
            didMount: false,
        };
        this.onSelected = (item, event) => this.updateMake(item || "");
        this.onChange = (query, e) => this.updateMake(query);
        this.updateMakeInSource = (make) => {
            const upperMake = make.toUpperCase();
            let makeName;
            let ncic;
            // NOTE: tried using Suggest.ofType<VehicleMake> but it didn't work out for some reason :/
            const lastParen = upperMake.lastIndexOf("(");
            if (lastParen >= 0) {
                const parts = [
                    upperMake.substring(0, lastParen),
                    upperMake.substring(lastParen),
                ];
                makeName = parts[0].trim();
                ncic = parts[1].replace("(", "").replace(")", "").trim();
            }
            else {
                makeName = upperMake;
                ncic = "";
            }
            return { upperMake, makeName, ncic };
        };
        this.updateMake = (make) => {
            const { upperMake, makeName, ncic } = this.updateMakeInSource(make);
            this.props.makeChanged(makeName, ncic);
            this.setState({
                filteredMakes: upperMake.length > 0
                    ? this.state.makes
                        .filter((x) => x.indexOf(upperMake) >= 0)
                        // Sort to the top those makes that match the beginning of string
                        .sort((a, b) => {
                        if (a.indexOf(upperMake) === 0 && b.indexOf(upperMake) !== 0)
                            return -1;
                        else if (a.indexOf(upperMake) !== 0 &&
                            b.indexOf(upperMake) === 0)
                            return 1;
                        return a < b ? -1 : 1;
                    })
                        .slice(0, numVisibleListItems)
                    : this.state.makes.slice(0, numVisibleListItems),
            });
        };
    }
    render() {
        return (React.createElement(MakeSuggest, { inputProps: {
                placeholder: "",
                style: { width: 200 },
                maxLength: 25,
            }, onQueryChange: this.onChange, selectedItem: this.props.name || "", itemRenderer: renderMake, inputValueRenderer: (val) => val, items: this.state.filteredMakes, onItemSelect: this.onSelected, openOnKeyDown: false, popoverProps: {
                popoverClassName: core_1.Classes.MINIMAL,
                position: core_1.PopoverPosition.RIGHT_BOTTOM,
            }, noResults: React.createElement(core_1.MenuItem, { disabled: true, text: "No results." }) }));
    }
    componentDidMount() {
        this.setState({ didMount: true });
    }
}
exports.default = VehicleMakeList;
