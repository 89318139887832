"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.keyToDate = exports.dateToKey = exports.updateViolations = exports.validate = void 0;
const InspectionViolationService_1 = require("../../InspectionViolationService");
const SmartLogicViolationCategoryService_1 = require("../../violation/SmartLogicViolationCategoryService");
const DateFormat_1 = require("../../../../services/DateFormat");
const hosGroups = [
    { vioCode: "395.3A3-PROP", hosType: "10/11 Hour" },
    { vioCode: "395.3A3-PROPN", hosType: "10/11 Hour" },
    { vioCode: "395.5A1-PASS", hosType: "10/11 Hour" },
    { vioCode: "395.5A1-PASSN", hosType: "10/11 Hour" },
    { vioCode: "395.5A2-PASS", hosType: "14/15 Hour" },
    { vioCode: "395.5A2-PASSN", hosType: "14/15 Hour" },
    { vioCode: "395.3A2-PROP", hosType: "14/15 Hour" },
    { vioCode: "395.3A2-PROPN", hosType: "14/15 Hour" },
];
const validateCat4 = (triggeredViolationCode, otherViolations, additionalDetails) => {
    let cantCiteError;
    let violationDateError;
    const ourHosType = getViolationHosType(triggeredViolationCode);
    if (!ourHosType)
        return {};
    // For each day that this violation is cited, ensure that no other
    // violations cited on the same day of the same HOS type
    for (const key of Object.keys(additionalDetails)) {
        const violationsOnSameDay = otherViolations.filter(x => x.SuppVioDesc && x.SuppVioDesc.indexOf(key) >= 0);
        const duplicates = violationsOnSameDay.filter(otherViolation => otherViolation.VioFederalRegCode &&
            getViolationHosType(otherViolation.VioFederalRegCode) === ourHosType);
        if (duplicates.length > 0)
            violationDateError = `${ourHosType} violation can be cited only once per driver per day.`;
    }
    return { cantCiteError, violationDateError };
};
const validateCat6 = (triggeredViolationCode, otherViolations, confirmedViolationType) => {
    let cantCiteError;
    let violationTypeError;
    if (!confirmedViolationType) {
        violationTypeError =
            "Please confirm if vehicle is passenger or property carrying";
    }
    else {
        // Mismatch between expected and confirmed type (passenger vs property)
        const expectedViolationType = getViolationType(triggeredViolationCode);
        if (expectedViolationType !== confirmedViolationType)
            violationTypeError = createAdditionalDetailsMessage(confirmedViolationType);
    }
    if (otherViolations.filter(x => x.VioFederalRegCode === triggeredViolationCode)
        .length > 0) {
        cantCiteError = `${triggeredViolationCode} can be cited only once per driver.`;
    }
    return { cantCiteError, violationTypeError };
};
const getViolationType = (violationCode) => {
    if (violationCode.startsWith("395.3"))
        return "property";
    else if (violationCode.startsWith("395.5"))
        return "passenger";
    return undefined;
};
const getViolationHosType = (violationCode) => {
    const item = hosGroups.filter(x => x.vioCode === violationCode)[0];
    if (item)
        return item.hosType;
    return undefined;
};
const violationsMatchingCodePrefix = (violationCode, violations) => violations.filter(x => x.VioFederalRegCode && x.VioFederalRegCode.startsWith(violationCode));
const cantCiteBoth3953_3955_message = "You cannot cite both a 395.3 and 395.5 violation on the same inspection";
const isTodayMarked = (inspectionStartTime, additionalDetails) => !!additionalDetails[exports.dateToKey(inspectionStartTime)];
exports.validate = (inspectionStartTime, triggeringViolation, otherViolations, additionalDetails, confirmedViolationType, driverInViolation) => {
    let violationTypeError;
    let cantCiteError;
    let violationDateError;
    let driverInViolationError;
    let evidenceMessage;
    const triggeredViolationCode = triggeringViolation.VioFederalRegCode;
    if (!triggeredViolationCode)
        return {
            errors: { violationTypeError: "No source violation code provided" },
            showDateSelection: false,
            showDriverInViolationPrompt: false,
        };
    if (!confirmedViolationType)
        return {
            errors: {
                violationTypeError: "Please select either passenger or property vehicle type.",
            },
            showDateSelection: false,
            showDriverInViolationPrompt: false,
        };
    if ((triggeringViolation.VioFederalRegCode || "").indexOf("PASS") >= 0 &&
        confirmedViolationType !== "passenger")
        return {
            errors: { violationTypeError: "Must select Passenger vehicle type." },
            showDateSelection: false,
            showDriverInViolationPrompt: false,
        };
    if ((triggeringViolation.VioFederalRegCode || "").indexOf("PROP") >= 0 &&
        confirmedViolationType !== "property")
        return {
            errors: { violationTypeError: "Must select Property vehicle type." },
            showDateSelection: false,
            showDriverInViolationPrompt: false,
        };
    if (!violationTypeError &&
        otherViolations.filter(x => x.VioFederalRegCode === "395.8E").length > 0)
        evidenceMessage =
            "A false log may not be used to document an HOS violation. Ensure that independent evidence exists to cite concurrent hours violation.";
    const category = SmartLogicViolationCategoryService_1.getCategory(triggeringViolation);
    if (category === "Cat4_Hours") {
        const result = validateCat4(triggeredViolationCode, otherViolations, additionalDetails);
        cantCiteError = result.cantCiteError;
        violationDateError = result.violationDateError;
    }
    else if (category === "Cat6_Hours") {
        const result = validateCat6(triggeredViolationCode, otherViolations, confirmedViolationType);
        cantCiteError = result.cantCiteError;
        violationTypeError = result.violationTypeError;
    }
    if (!violationDateError &&
        isTodayMarked(inspectionStartTime, additionalDetails) &&
        !driverInViolation) {
        driverInViolationError =
            "Please select whether or not driver is in violation.";
    }
    cantCiteError =
        cantCiteError ||
            validateGeneralConflict(triggeringViolation, otherViolations);
    // Date validation
    if (!violationTypeError && Object.keys(additionalDetails).length === 0) {
        violationDateError = "Please select at least one date.";
    }
    return {
        errors: {
            cantCiteError,
            violationTypeError,
            violationDateError,
            driverInViolationError,
        },
        showDateSelection: !violationTypeError,
        showDriverInViolationPrompt: isTodayMarked(inspectionStartTime, additionalDetails),
        evidenceMessage,
    };
};
const createAdditionalDetailsMessage = (confirmedViolationType) => `You cannot cite this violation to the driver of a ${confirmedViolationType}-carrying vehicle`;
const validateGeneralConflict = (triggeringViolation, otherViolations) => {
    const triggeredViolationCode = triggeringViolation.VioFederalRegCode || "";
    // Check for 395.3/395.5 conflict
    if (triggeredViolationCode.startsWith("395.3") &&
        otherViolations.filter(x => x.VioFederalRegCode && x.VioFederalRegCode.startsWith("395.5A")).length > 0) {
        return cantCiteBoth3953_3955_message;
    }
    else if (triggeredViolationCode.startsWith("395.5") &&
        otherViolations.filter(x => x.VioFederalRegCode && x.VioFederalRegCode.startsWith("395.3A")).length > 0) {
        return cantCiteBoth3953_3955_message;
    }
    // Violation type validation
    const message395_5 = cantCiteBoth3953_3955_message;
    const message395_8a = "You cannot cite a 395.3 or 395.5 violation on the same inspection with a 395.8(a) violation";
    // Check for conflicts between triggered violation and existing violations
    const conflictConfigs = [
        { prefix: "395.3B", conflicts: ["395.5B"] },
        { prefix: "395.3B1", conflicts: ["395.3B2"] },
        { prefix: "395.3B2", conflicts: ["395.3B1"] },
        { prefix: "395.5B1", conflicts: ["395.5B2"] },
        { prefix: "395.5B2", conflicts: ["395.5B1"] },
        { prefix: "395.5B", conflicts: ["395.3B"] },
        {
            prefix: "395.3",
            conflicts: ["395.5"],
            conflictMessage: message395_5,
        },
        {
            prefix: "395.5",
            conflicts: ["395.3"],
            conflictMessage: message395_5,
        },
        {
            prefix: "395.3",
            conflicts: ["395.8A"],
            conflictMessage: message395_8a,
        },
        {
            prefix: "395.5",
            conflicts: ["395.8A"],
            conflictMessage: message395_8a,
        },
    ];
    const errors = conflictConfigs
        .filter(x => triggeredViolationCode.startsWith(x.prefix))
        .map(config => config.conflicts
        .map(couldConflict => violationsMatchingCodePrefix(couldConflict, otherViolations))
        .filter(didConflict => didConflict.length > 0)
        .map(conflictList => conflictList.map(conflict => config.conflictMessage
        ? config.conflictMessage
        : `Cannot cite both a ${triggeringViolation.VioRegSectionCode} and ${conflict.VioRegSectionCode} on the same inspection.`)))
        .filter(conflicts => conflicts.length > 0)
        .flat()
        .flat();
    return errors[0];
};
const findExistingIdenticalViolations = (triggeringViolation, violations) => violations.filter(x => x.VioFederalRegCode === triggeringViolation.VioFederalRegCode);
exports.updateViolations = (inspectionStartTime, triggeringViolation, violations, additionalDetails, driverInViolation) => {
    let newViolations = JSON.parse(JSON.stringify(violations));
    // For each additional
    const violationsToRemove = findExistingIdenticalViolations(triggeringViolation, newViolations).sort((a, b) => (a.VioSeqID < b.VioSeqID ? -1 : 1));
    violationsToRemove.forEach(v => {
        newViolations = InspectionViolationService_1.RemoveViolation(v.VioSeqID, newViolations);
    });
    newViolations = InspectionViolationService_1.RemoveViolation(triggeringViolation.VioSeqID, newViolations);
    Object.keys(additionalDetails).forEach(x => {
        newViolations = InspectionViolationService_1.AddViolation(newViolations, "driver", "D", Object.assign(Object.assign({}, triggeringViolation), { VioOOSFlag: 
            // If driver in violation, mark today's violation as OOS. All others are not OOS.
            driverInViolation === "yes" && exports.dateToKey(inspectionStartTime) === x
                ? "Y"
                : "N", SuppVioDesc: additionalDetails[x], date: exports.keyToDate(x) }));
    });
    return newViolations;
};
exports.dateToKey = (date) => DateFormat_1.formatDate(date);
exports.keyToDate = (date) => {
    const [month, day, year] = date.split("/");
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};
