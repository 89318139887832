"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createBasicsTable = void 0;
const React = __importStar(require("react"));
const T0031V4_1 = require("../../services/api/T0031V4");
const IssScoreConfig_1 = require("./IssScoreConfig");
const core_1 = require("@blueprintjs/core");
const McsipLevels_1 = require("../../services/McsipLevels");
const McsipLevelService_1 = require("../../services/McsipLevelService");
const UcrService_1 = require("../../services/UcrService");
const lookupSafetyRating = (rating) => (rating &&
    { C: "Conditional", S: "Satisfactory", U: "Unsatisfactory" }[rating]) ||
    undefined;
const valueStyle = { fontWeight: "bold" };
const divideStrings = ($numerator, $denominator) => {
    const numerator = parseInt($numerator) || 0;
    const denominator = parseInt($denominator) || 0;
    if (denominator === 0)
        return 0;
    return numerator / denominator;
};
const divide = ($numerator, $denominator, places) => {
    let value;
    if (!$numerator || !$denominator)
        value = 0;
    else
        value = divideStrings($numerator, $denominator);
    return value.toFixed(places === undefined ? 2 : places);
};
const percentage = ($numerator, $denominator, places) => {
    let value;
    if (!$numerator || !$denominator)
        value = 0;
    else
        value = divideStrings($numerator, $denominator);
    return (value * 100).toFixed(places === undefined ? 2 : places) + "%";
};
const issScoreCard = (t0031) => {
    const config = IssScoreConfig_1.getCarrierScreenResult(t0031);
    let bgClass = "";
    if (config.result === "inspect")
        bgClass = "red-background";
    else if (config.result === "warn")
        bgClass = "yellow-background";
    else
        bgClass = "green-background";
    return (React.createElement(MyCard, { className: bgClass },
        React.createElement("h4", { className: core_1.Classes.HEADING }, "Inspection Value"),
        React.createElement("div", { style: { fontSize: 40 } },
            React.createElement(core_1.Icon, { icon: config.icon, iconSize: 32 }),
            " ",
            t0031.ISS_SCORE,
            " ",
            config.label)));
};
const foosCard = (t0031, issResponse) => {
    const mcsipLevel = McsipLevelService_1.resolveMcsipLevel(t0031, issResponse);
    const foos = mcsipLevel === undefined ? false : McsipLevelService_1.mcsipLevelIsFoos(mcsipLevel);
    let icon = "warning-sign";
    if (foos === true)
        icon = "error";
    else if (foos === false)
        icon = "tick";
    let yesNoLabel = "N/A";
    if (foos === true)
        yesNoLabel = "YES";
    else if (foos === false)
        yesNoLabel = "NO";
    return (React.createElement(MyCard, { className: foos ? "red-background" : "green-background" },
        React.createElement("h4", { className: core_1.Classes.HEADING },
            "Federal OOS",
            issResponse ? React.createElement("i", null, " (real-time)") : undefined),
        React.createElement("div", { style: { fontSize: 40 } },
            React.createElement(core_1.Icon, { icon: icon, iconSize: 32 }),
            " ",
            yesNoLabel),
        React.createElement("div", { style: { marginLeft: 50, fontSize: 20 } },
            mcsipLevel === undefined ? "n/a" : mcsipLevel,
            ":",
            " ",
            mcsipLevel === undefined ? "n/a" : McsipLevels_1.getDescription(mcsipLevel))));
};
class MyCard extends React.Component {
    render() {
        return (React.createElement("div", { style: { padding: 3 } },
            React.createElement(core_1.Card, Object.assign({}, this.props, { elevation: core_1.Elevation.ONE }))));
    }
}
exports.createBasicsTable = (basics) => {
    if (!basics || basics.length === 0)
        return React.createElement("div", null, "No BASICs data");
    const getCellStyle = (d) => (d.BASICS_DEFICIENCY_IND === "N" && {}) || {
        backgroundColor: "yellow",
        color: "black",
    };
    return (React.createElement("div", null, basics &&
        basics.map((basic, i) => (React.createElement(core_1.HTMLTable, { small: true, bordered: true, key: i, style: { captionSide: "bottom" } },
            React.createElement("caption", null,
                "SMS Date: ",
                React.createElement("span", { style: valueStyle }, basic.BASICS_DATE)),
            React.createElement("thead", null,
                React.createElement("tr", null,
                    React.createElement("th", null, "BASIC"),
                    React.createElement("th", null, "BASICs Status"),
                    React.createElement("th", null, "On-Road Performance"),
                    React.createElement("th", null, "Investigation"))),
            React.createElement("tbody", null, basic.CARRIER_BASICS_DETAIL &&
                basic.CARRIER_BASICS_DETAIL.map((detail, j) => (React.createElement("tr", { key: j, style: getCellStyle(detail) },
                    React.createElement("td", null, detail.BASICS_CODE),
                    React.createElement("td", null, detail.OVERALL_DISPLAY_TEXT),
                    React.createElement("td", { style: valueStyle }, (detail.ROAD_DISPLAY_TEXT === "Display Percentile" &&
                        detail.BASICS_PERCENTILE) ||
                        detail.ROAD_DISPLAY_TEXT),
                    React.createElement("td", null, detail.INVESTIGATION_DISPLAY_TEXT))))))))));
};
// Space-delimited date string, e.g., "10/27/2015 12:00:00 AM"
const asDate = (date) => date.split(" ")[0];
const carrierInfoCard = (t0031) => (React.createElement(MyCard, null,
    React.createElement("h4", { className: core_1.Classes.HEADING }, "Carrier"),
    React.createElement(core_1.HTMLTable, { small: true },
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("td", null, "Name:"),
                React.createElement("td", { colSpan: 3, style: valueStyle }, t0031.CARRIER_NAME),
                React.createElement("td", null, "USDOT:"),
                React.createElement("td", { style: valueStyle }, t0031.CARRIER_ID_NUMBER)),
            React.createElement("tr", null,
                React.createElement("td", null, "DBA Name:"),
                React.createElement("td", { colSpan: 5, style: valueStyle }, t0031.DBA_NAME)),
            React.createElement("tr", null,
                React.createElement("td", null, "Street:"),
                React.createElement("td", { colSpan: 5, style: valueStyle }, t0031.STREET)),
            React.createElement("tr", null,
                React.createElement("td", null, "City:"),
                React.createElement("td", { style: valueStyle }, t0031.CITY),
                React.createElement("td", null, "State:"),
                React.createElement("td", { style: valueStyle }, t0031.STATE),
                React.createElement("td", null, "Zip:"),
                React.createElement("td", { style: valueStyle }, t0031.ZIP_CODE)),
            React.createElement("tr", null,
                React.createElement("td", null, "Phone:"),
                React.createElement("td", { style: valueStyle }, t0031.TELEPHONE_NUMBER),
                React.createElement("td", null, "Country of Domicile:"),
                React.createElement("td", { style: valueStyle }, T0031V4_1.lookupCountry(t0031.COUNTRY).longName))))));
const carrierDetailCard = (t0031) => (React.createElement(MyCard, null,
    React.createElement("h4", { className: core_1.Classes.HEADING }, "Carrier Detail"),
    React.createElement(core_1.HTMLTable, { small: true },
        React.createElement("tbody", null,
            React.createElement("tr", null,
                React.createElement("td", null, "Vehicle Out-Of-Service Rate:"),
                React.createElement("td", { style: valueStyle }, percentage(t0031.OOS_VEHICLE_INSPECTIONS_LAST30, t0031.VEHICLE_INSPECTIONS_LAST30, 2)),
                React.createElement("td", null, "Total Number of Inspections:"),
                React.createElement("td", { style: valueStyle }, t0031.QUANTITY_INSPECTIONS_LAST30 || 0)),
            React.createElement("tr", null,
                React.createElement("td", null, "Driver Out-Of-Service Rate:"),
                React.createElement("td", { style: valueStyle }, percentage(t0031.OOS_DRIVER_INSPECTIONS_LAST30, t0031.QUANTITY_DRIVERS, 2)),
                React.createElement("td", null, "Inspections Per Power Unit:"),
                React.createElement("td", { style: valueStyle }, divide(t0031.QUANTITY_INSPECTIONS_LAST15, t0031.QUANTITY_POWER_UNITS, 2))),
            React.createElement("tr", null,
                React.createElement("td", null, "Inspections Involving HM:"),
                React.createElement("td", { style: valueStyle }, t0031.QUANTITY_HAZMAT_PRESENT_LAST30 || 0),
                React.createElement("td", null, "Inspections Per Driver:"),
                React.createElement("td", { style: valueStyle }, divide(t0031.QUANTITY_INSPECTIONS_LAST30, t0031.QUANTITY_DRIVERS, 2))),
            React.createElement("tr", null,
                React.createElement("td", null, "Carrier Operating Type:"),
                React.createElement("td", { style: valueStyle }, (t0031.CARRIER_INTERSTATE === "1" && "Interstate") || "Intrastate"),
                React.createElement("td", null, "Number of Power Units:"),
                React.createElement("td", { style: valueStyle }, t0031.QUANTITY_POWER_UNITS)),
            React.createElement("tr", null,
                React.createElement("td", null,
                    "Safety Rating as of:",
                    " ",
                    React.createElement("span", { style: valueStyle }, t0031.RATING_DATE || "n/a")),
                React.createElement("td", { style: valueStyle }, lookupSafetyRating(t0031.SAFETY_RATING) || "n/a"),
                React.createElement("td", null, "Number of Drivers:"),
                React.createElement("td", { style: valueStyle }, t0031.QUANTITY_DRIVERS || 0))))));
const createUcrTable = (t0034) => {
    if (t0034 === undefined || t0034.length === 0)
        return React.createElement("div", null, "No UCR data found");
    return (React.createElement(core_1.HTMLTable, { small: true, bordered: true },
        React.createElement("thead", null,
            React.createElement("tr", null,
                React.createElement("th", null, "State"),
                React.createElement("th", null, "Year"),
                React.createElement("th", null, "Record Date"),
                React.createElement("th", null, "Paid"),
                React.createElement("th", null, "Intrastate Vehicles"))),
        React.createElement("tbody", null, t0034
            .filter(x => UcrService_1.shouldConsiderUcrRecord(parseInt(x.REGISTRATION_YEAR)))
            .sort((a, b) => (a.REGISTRATION_YEAR < b.REGISTRATION_YEAR && 1) || -1)
            .map((row, i) => (React.createElement("tr", { key: i },
            React.createElement("td", null, row.BASE_STATE || "N/A"),
            React.createElement("td", null, row.REGISTRATION_YEAR),
            React.createElement("td", null, asDate(row.PAYMENT_UPDATE_DATE)),
            React.createElement("td", null, (row.FEE_PAYMENT_FLAG === "Y" && "YES") || "NO"),
            React.createElement("td", null, (row.INTRASTATE_FLAG === "Y" && "YES") || "NO")))))));
};
const undeliverableNotice = "The motor carrier has an UNDELIVERABLE address. You must attempt to obtain an updated address and contact information and you must add the new information in the inspection notes. Drivers must be instructed to inform the motor carrier of the UNDELIVERABLE address on file, and the motor carrier needs to update their address with FMCSA.";
class CarrierScreen extends React.Component {
    render() {
        const { t0031, t0034, issResponse } = this.props;
        return (React.createElement("div", null,
            t0031.MCMIS_STATUS !== "A" && (React.createElement(core_1.Callout, { intent: core_1.Intent.DANGER },
                React.createElement("h4", { className: core_1.Classes.HEADING }, "CARRIER NOT ACTIVE"),
                "MCMIS STATUS: ",
                React.createElement("b", null, t0031.MCMIS_STATUS),
                React.createElement("br", null),
                "MCMIS STATUS DATE: ",
                React.createElement("b", null, t0031.MCMIS_STATUS_DATE))),
            (t0031.PA_UNDELIVERABLE_CODE === "U" ||
                t0031.MA_UNDELIVERABLE_CODE === "U") && (React.createElement(core_1.Callout, { intent: core_1.Intent.DANGER },
                React.createElement("h4", { className: core_1.Classes.HEADING }, "UNDELIVERABLE Address"),
                undeliverableNotice)),
            foosCard(t0031, issResponse),
            carrierInfoCard(t0031),
            issScoreCard(t0031),
            carrierDetailCard(t0031),
            React.createElement(MyCard, null,
                React.createElement("h4", { className: core_1.Classes.HEADING }, "Carrier BASICs"),
                exports.createBasicsTable(t0031.CARRIER_BASICS)),
            React.createElement(MyCard, null,
                React.createElement("h4", { className: core_1.Classes.HEADING },
                    "UCR Information",
                    t0034 && t0034.liveResponse ? React.createElement("i", null, " (real-time)") : undefined),
                createUcrTable(t0034 && t0034.registrations)),
            React.createElement(MyCard, null,
                React.createElement("h4", { className: core_1.Classes.HEADING }, "Carrier Data Date"),
                "This data is current as of ",
                t0031.LAST_UPDATE_DATE)));
    }
}
exports.default = CarrierScreen;
