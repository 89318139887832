"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toT0033 = void 0;
const OosTextService_1 = require("./OosTextService");
const InspectionSerializer_1 = require("../inspection/InspectionSerializer");
// MIGRATE_TODO
//const { app } = require("electron").remote;
exports.toT0033 = (inspection) => {
    const driver = inspection.Drivers.filter(x => !x.IsCoDriver)[0] || {};
    const codriver = inspection.Drivers.filter(x => x.IsCoDriver)[0] || {};
    const oosInfo = OosTextService_1.generateOosInfo(inspection);
    const allViolations = inspection.Violations;
    const oosViolations = allViolations.filter(x => x.VioOOSFlag === "Y");
    const driverOosViolations = oosViolations.filter(x => ["D", "C"].indexOf(x.VehicleUnitCode) >= 0);
    const vehicleOosViolations = oosViolations.filter(x => [1, 2, 3, 4, 5, 6].indexOf(parseInt(x.VehicleUnitCode)) >= 0);
    return {
        INSPECTION_RPTNUM: inspection.InspMain.InspReportID,
        INSPECTION_NOTES: InspectionSerializer_1.getFullNotes(inspection),
        ALC_SUB_CHK: inspection.InspMain.InspAlcSubCheckFlag,
        TRAFFIC_ENF: inspection.InspMain.InspTrafficEnfFlag,
        ACCIDENT: inspection.InspMain.InspAccidentFlag,
        LOCAL_JURISD: inspection.InspMain.InspLocalJurisdictionFlag,
        PASACHECK: inspection.InspMain.InspPASACheckFlag,
        PBBTCHECK: inspection.InspMain.InspPBBTCheckFlag,
        PBBTAXLES: inspection.InspMain.PBBTAxles,
        PBBTBRAKEFORCE: inspection.InspMain.PBBTBrakeForce,
        PBBTMINBRAKEFORCE: inspection.InspMain.PBBTMinBrakeForce,
        SIZE_ENF: inspection.InspMain.InspSizeWeightEnfFlag,
        DRUG_SEARCH: inspection.InspMain.InspDrugSearchFlag,
        BORDERGRANT: inspection.InspMain.InspBorderGrant,
        ESCREEN: inspection.InspMain.InspEScreenFlag,
        OFFICE_NAME: inspection.InspectionOffice.InspOfficeName,
        OFFICE_ADDR_LINE1: inspection.InspectionOffice.InspOfficeAddress1Text,
        OFFICE_ADDR_LINE2: inspection.InspectionOffice.InspOfficeAddress2Text,
        OFFICE_ADDR_LINE3: inspection.InspectionOffice.InspOfficeAddress3Text,
        OFFICE_PHONE_NUMBER: inspection.InspectionOffice.InspOfficePhoneNum,
        INSPECTION_DATE_TIME: inspection.InspMain.InspStartTime.asDate(),
        LOCATION_TIME_ZONE: inspection.InspMain.InspLocation.TimeZoneCode,
        INSPECTION_END_DATE_TIME: (inspection.InspMain.InspEndTime &&
            inspection.InspMain.InspEndTime.asDate()) ||
            undefined,
        INSPECTION_LEVEL: inspection.InspMain.InspTypeLevelCode,
        HM_INSPECTION: inspection.InspMain.InspHazmatTypeCode,
        HM_CARGO_TANK: inspection.InspMain.CargoTankTypeCode,
        PLACARDS: inspection.InspMain.CargoHazmatPlacardFlag,
        CARRIER_ID_NUMBER: inspection.InspMain.Carrier.USDOTNum,
        CARRIER_NAME: inspection.InspMain.Carrier.CarrierName,
        ICC_NUMBER: inspection.InspMain.Carrier.CarrierICCNum,
        STATE_CARRIER_ID: inspection.InspMain.Carrier.CarrierStateCensusNum,
        CARRIER_PHONE: inspection.InspMain.Carrier.CarrierPhoneNum,
        CARRIER_FAX: inspection.InspMain.Carrier.CarrierFaxNum,
        CARRIER_STREET: inspection.InspMain.Carrier.CarrierAddress.AddressStreetText,
        CARRIER_CITY: inspection.InspMain.Carrier.CarrierAddress.AddressCityName,
        CARRIER_STATE: inspection.InspMain.Carrier.CarrierAddress.AddressStateCode,
        CARRIER_ZIP: inspection.InspMain.Carrier.CarrierAddress.AddressZipCode,
        // NOTE: Non-standard
        PA_UNDELIVERABLE_CODE: inspection.InspMain.Carrier.PAUndeliverableCode,
        // NOTE: Non-standard
        MA_UNDELIVERABLE_CODE: inspection.InspMain.Carrier.MAUndeliverableCode,
        INSPECTION_LOCATION_DESC: inspection.InspMain.InspLocation.InspLocationText,
        INSPECTION_STATE: inspection.InspMain.InspLocation.InspStateCode,
        INSPECTION_HIGHWAY_LOCATION: inspection.InspMain.InspLocation.HighwayDescText,
        COUNTY_NAME: inspection.InspMain.InspLocation.InspCountyName,
        HIGHWAY_MILEPOST: inspection.InspMain.InspLocation.HighwayMilepostNum,
        TRIP_ORIGIN: (inspection.InspMain.TripOrigin &&
            `${inspection.InspMain.TripOrigin.City}, ${inspection.InspMain.TripOrigin.State}`) ||
            "",
        TRIP_DESTINATION: (inspection.InspMain.TripDestination &&
            `${inspection.InspMain.TripDestination.City}, ${inspection.InspMain.TripDestination.State}`) ||
            "",
        SHIPPER_NAME: inspection.InspMain.ShipperName,
        SHIPPING_PAPER_NUMBER: inspection.InspMain.ShipmentNum,
        CARGO_TYPE: inspection.InspMain.CargoTypeText,
        DRIVER_FNAME: driver.DriverFirstName,
        DRIVER_MI: driver.DriverMiddleInitial,
        DRIVER_LNAME: driver.DriverLastName,
        INSPECTOR_NAME: inspection.InspMain.InspectorName,
        INSPECTOR_CODE: inspection.InspMain.InspectorCode,
        INSPECTOR_RADIO_NUM: inspection.InspMain.InspectorRadioNum,
        TOTAL_VEHICLES: (inspection.Vehicles || []).length,
        TOTAL_VIOLATION: allViolations.length,
        TOTAL_OOS_VIO: oosViolations.length,
        TOTAL_VEHICLE_OOS_VIO: vehicleOosViolations.length,
        TOTAL_DRIVER_OOS_VIO: driverOosViolations.length,
        VEHICLE_OOS: vehicleOosViolations.length > 0 ? "Y" : "N",
        DRIVER_OOS: driverOosViolations.length > 0 ? "Y" : "N",
        DRIVER_OOS_UNTIL: oosInfo.DriverOOSUntilText,
        DRIVER_LICENSE_NUMBER: driver.DriverLicenseID,
        DRIVER_LICENSE_STATE: driver.DriverLicenseStateCode,
        DRIVER_DOB: driver.DriverBirthDate,
        CODRIVER_FNAME: codriver.DriverFirstName,
        CODRIVER_MI: codriver.DriverMiddleInitial,
        CODRIVER_LNAME: codriver.DriverLastName,
        CODRIVER_LIC_NUMBER: codriver.DriverLicenseID,
        CODRIVER_LIC_STATE: codriver.DriverLicenseStateCode,
        CODRIVER_DOB: codriver.DriverBirthDate,
        IR_BRAKE: inspection.Brakes.map(brake => ({
            AXLE_NUMBER: brake.VehicleAxleNum,
            RIGHT_BRAKE: brake.RightBrakeMeasure,
            LEFT_BRAKE: brake.LeftBrakeMeasure,
            BRAKE_CHAMBER: brake.BrakeChamberSubtypeCode,
        })),
        IR_VIOLATION: inspection.Violations.map(violation => ({
            FED_VIOLATION_CODE: violation.VioFederalRegCode,
            FEDERAL_STATE_FLAG: violation.VioFederalStateFlag,
            VIOLATION_UNIT: violation.VehicleUnitCode,
            OOS: violation.VioOOSFlag,
            CITATION_NUMBER: violation.StateCitation && violation.StateCitation.CitationNum,
            DEFECT_ACTION: violation.VehicleDefectActionCode,
            POSTCRASH: violation.VioCrashRelatedFlag,
            VIOLATION_DESCRIPTION: violation.VioDescText,
            VIOLATION_SUPPLEMENTAL_DESCRIPTION: violation.SuppVioDesc,
        })),
        IR_HAZARDOUS_MATERIAL: inspection.HazMats.map(hazmat => ({
            HM_DESCRIPTION: hazmat.HMDescription,
        })),
        IR_OOS_TEXT: [
            {
                VEHICLE_OOS_TEXT: oosInfo.VehicleOOSText,
                DRIVER_OOS_TEXT: oosInfo.DriverOOSText,
                CERTIFY_OOS_TEXT: oosInfo.CarrierCertifyOOSText,
                VERIFY_OOS_TEXT: oosInfo.CarrierVerifyOOSText,
            },
        ],
        IR_VEHICLE: inspection.Vehicles.map(vehicle => ({
            VEHICLE_UNIT_NUMBER: vehicle.VehicleUnitNum,
            VEHICLE_TYPE: vehicle.VehicleUnitTypeCode,
            VEHICLE_MAKE: vehicle.VehicleMakeCode,
            VEHICLE_MODEL_YEAR: vehicle.VehicleYear,
            LICENSE_STATE: vehicle.VehicleLicenseStateCode,
            LICENSE_PLATE_NUMBER: vehicle.VehicleLicenseID,
            VEHICLE_COMPANY_NUMBER: vehicle.VehicleCompanyID,
            VIN: vehicle.VehicleID,
            UVWR: vehicle.VehicleGVWRNum,
            EXISTING_DECAL_NUMBER: vehicle.VehiclePreviousDecalID,
            REMOVED_CARGOSEALID: vehicle.VehicleRemovedCargoSealID,
            REPLACED_CARGOSEALID: vehicle.VehicleReplacedCargoSealID,
            DECAL_NUMBER: vehicle.VehicleDecalID,
            VEHICLE_OOS_NUMBER: vehicle.VehicleOOSStickerID,
            IEP_NAME: (vehicle.IntermodalEquipment || {})
                .IEPName,
            IEP_DOT_NUMBER: (vehicle.IntermodalEquipment || {})
                .IEPDOTNum,
            IEP_SOURCECODE: (vehicle.IntermodalEquipment || {})
                .IEPSourceCode,
        })),
        // NOTE: Non-standard
        LOCAL_FIELD: inspection.LocalFields.map(x => ({
            LABEL: x.InspLocalFieldLabelText,
            VALUE: x.InspLocalFieldDataValue,
        })),
    };
};
