"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const SmartLogic_Cat14_AllOtherDriver_Logic_1 = require("./SmartLogic_Cat14_AllOtherDriver_Logic");
class SmartLogic_Cat14_AllOtherDriver extends React.Component {
    constructor(props) {
        super(props);
        this.additionalDetailsChanged = (e) => this.setState({ additionalDetails: e.currentTarget.value });
        this.validate = () => {
            const cantCiteError = SmartLogic_Cat14_AllOtherDriver_Logic_1.validate(this.props.triggeringViolation, this.props.otherViolations);
            this.setState({ cantCiteError });
            this.props.setError(cantCiteError);
            if (!cantCiteError)
                this.props.updateViolations(SmartLogic_Cat14_AllOtherDriver_Logic_1.updateViolations(this.props.triggeringViolation, this.props.violations, this.state.additionalDetails));
        };
        this.state = {
            additionalDetails: "",
        };
    }
    render() {
        return (React.createElement("div", null,
            this.state.cantCiteError && (React.createElement(core_1.Callout, { intent: core_1.Intent.DANGER }, this.state.cantCiteError)),
            !this.state.cantCiteError && (React.createElement(core_1.Card, null,
                "Additional Violation Details:",
                " ",
                React.createElement("input", { className: core_1.Classes.INPUT, maxLength: 162, style: { width: "90%" }, value: this.state.additionalDetails, onChange: this.additionalDetailsChanged })))));
    }
    componentDidMount() {
        this.validate();
    }
    componentDidUpdate(nextProps, nextState) {
        if (this.state.additionalDetails !== nextState.additionalDetails) {
            this.validate();
        }
        else
            this.props.setError(this.state.cantCiteError);
    }
}
exports.default = SmartLogic_Cat14_AllOtherDriver;
