"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.outputPdf = void 0;
const bwipjs = __importStar(require("bwip-js"));
const pdfmake_1 = require("pdfmake/build/pdfmake");
const PdfMakeVfsFonts_1 = require("./PdfMakeVfsFonts");
const datefns = __importStar(require("date-fns"));
const formatDate = (date) => (date ? datefns.format(date, "MM/DD/YYYY") : "");
const formatTime = (date) => (date ? datefns.format(date, "hh:mm A") : "");
const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
};
const asInspectionLevel = (level) => {
    if (level === "1")
        return "I - Full";
    else if (level === "2")
        return "II - Walk-Around";
    else if (level === "3")
        return "III - Driver/Credentials";
    else if (level === "4")
        return "IV - Special Study";
    else if (level === "5")
        return "V - Terminal";
    else if (level === "6")
        return "VI - RAM";
    else if (level === "7")
        return "VII - Jur. Mandated";
};
const withHeader = (caption, value) => ({
    text: [
        {
            text: caption + ": ",
            style: "header",
        },
        value || "",
    ],
});
const asHmType = (type) => ({ B: "Bulk", N: "Non-Bulk", C: "Combined" }[type] || "None");
const hr = (options) => {
    const margin = (options && options.margin) || [0, 1, 0, 1];
    return {
        margin: margin,
        canvas: [
            {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 535,
                y2: 0,
                lineWidth: 0.5,
            },
        ],
    };
};
const iep = (inspection) => {
    const iepVehicles = (inspection.IR_VEHICLE || []).filter((v) => v.VEHICLE_TYPE === "IC");
    if (iepVehicles.length === 0)
        return undefined;
    return [
        {
            text: "INTERMODAL EQUIPMENT PROVIDER INFORMATION",
            style: "header",
        },
        withHeader("Name", iepVehicles[0].IEP_NAME),
        {
            text: [
                withHeader("USDOT #", iepVehicles[0].IEP_DOT_NUMBER),
                "    ",
                withHeader("Data Source", iepVehicles[0].IEP_SOURCECODE),
            ],
        },
    ];
};
const vehicleIdentification = (inspection) => {
    const head = (v) => ({ text: v, fontSize: 8, decoration: "underline" });
    const cell = (v) => ({
        text: v || "",
        fontSize: 8,
    });
    const body = [
        [
            head("Unit"),
            head("Type"),
            head("Make"),
            head("Year"),
            head("State"),
            head("Plate"),
            head("Equipment ID"),
            head("VIN"),
            head("GVWR"),
            head("CVSA #"),
            head("Issued #"),
            head("OOS Sticker"),
        ],
        ...(inspection.IR_VEHICLE || []).map((v) => [
            cell(v.VEHICLE_UNIT_NUMBER),
            cell(v.VEHICLE_TYPE),
            cell(v.VEHICLE_MAKE),
            cell(v.VEHICLE_MODEL_YEAR),
            cell(v.LICENSE_STATE),
            cell(v.LICENSE_PLATE_NUMBER),
            cell(v.VEHICLE_COMPANY_NUMBER),
            cell(v.VIN),
            cell(v.UVWR),
            cell(v.EXISTING_DECAL_NUMBER),
            cell(v.DECAL_NUMBER),
            cell(v.VEHICLE_OOS_NUMBER),
        ]),
    ];
    return [
        {
            text: "VEHICLE IDENTIFICATION",
            style: "header",
        },
        {
            table: {
                headerRows: 1,
                body: body,
            },
            layout: "noBorders",
        },
        ...vehicleCargoSealTable(inspection),
    ];
};
const vehicleCargoSealTable = (inspection) => {
    const vehiclesWithCargoData = (inspection.IR_VEHICLE || []).filter((v) => v.REMOVED_CARGOSEALID || v.REPLACED_CARGOSEALID);
    if (vehiclesWithCargoData.length === 0)
        return [];
    const head = (v) => ({ text: v, fontSize: 8, decoration: "underline" });
    const cell = (v) => ({
        text: v || "",
        fontSize: 8,
    });
    const body = [
        [head("Unit"), head("Cargo Seal Removed #"), head("Cargo Seal Replaced #")],
        ...vehiclesWithCargoData.map((v) => [
            cell(v.VEHICLE_UNIT_NUMBER),
            cell(v.REMOVED_CARGOSEALID),
            cell(v.REPLACED_CARGOSEALID),
        ]),
    ];
    return [
        {
            table: {
                headerRows: 1,
                body: body,
            },
            layout: "noBorders",
        },
    ];
};
const hazmat = (inspection) => {
    const placardLookup = {
        Y: "Yes",
        N: "No",
        U: "Unknown",
        "2": "2",
    };
    if ((inspection.IR_HAZARDOUS_MATERIAL || []).length === 0)
        return withHeader("HazMat", "No HM transported");
    return {
        columns: [
            withHeader("HazMat", inspection.IR_HAZARDOUS_MATERIAL.map((hm) => hm.HM_DESCRIPTION).join(", ")),
            withHeader("Placard", placardLookup[inspection.PLACARDS]),
            withHeader("Cargo Tank", inspection.HM_CARGO_TANK),
        ],
    };
};
// TODO: violations vs violations attributable to MOTOR CARRIER / IEP provider
const violations = (inspection) => {
    if ((inspection.IR_VIOLATION || []).length === 0)
        return withHeader("VIOLATIONS", "No violations were discovered");
    const head = (v) => ({ text: v, fontSize: 7, decoration: "underline" });
    const cell = (text, shouldBold) => ({
        text: text || "",
        fontSize: 7,
        bold: shouldBold || false,
    });
    const body = [
        [
            head("Section"),
            head("Type"),
            head("Unit"),
            head("OOS"),
            head("Citation #"),
            head("Verify"),
            head("Crash"),
            head("Violations Discovered"),
        ],
        ...(inspection.IR_VIOLATION || []).map((v) => [
            cell(v.FED_VIOLATION_CODE, v.OOS === "Y"),
            cell(v.FEDERAL_STATE_FLAG, v.OOS === "Y"),
            cell(v.VIOLATION_UNIT, v.OOS === "Y"),
            cell(v.OOS, v.OOS === "Y"),
            cell(v.CITATION_NUMBER, v.OOS === "Y"),
            cell(v.DEFECT_ACTION, v.OOS === "Y"),
            cell(v.POSTCRASH, v.OOS === "Y"),
            cell(v.VIOLATION_DESCRIPTION +
                (v.VIOLATION_SUPPLEMENTAL_DESCRIPTION &&
                    v.VIOLATION_SUPPLEMENTAL_DESCRIPTION.length > 0
                    ? `: ${v.VIOLATION_SUPPLEMENTAL_DESCRIPTION}`
                    : ""), v.OOS === "Y"),
        ]),
    ];
    return [
        {
            text: "VIOLATIONS",
            style: "header",
        },
        {
            table: {
                layout: "noBorders",
                headerRows: 1,
                body: body,
            },
            layout: "noBorders",
        },
    ];
};
const brakes = (inspection, options) => {
    options.brakeOos = options.brakeOos || (() => false);
    options.brakeWarning = options.brakeWarning || (() => false);
    if ((inspection.IR_BRAKE || []).length === 0 &&
        (inspection.INSPECTION_LEVEL === "2" || inspection.INSPECTION_LEVEL === "3")) {
        return withHeader("BRAKE ADJUSTMENTS", "No brake measurements required for level II or level III");
    }
    let head = (text) => ({ text: text, decoration: "underline" });
    let body = [
        [head("Axle #")],
        [head("Right")],
        [head("Left")],
        [head("Chamber")],
    ];
    // Highlight warning brakes
    const fmtBrake = (chamberType, measurement) => {
        const shouldHighlight = options.brakeOos(chamberType, measurement) ||
            options.brakeWarning(chamberType, measurement);
        if (!shouldHighlight)
            return measurement;
        return { style: "highlight", text: measurement };
    };
    (inspection.IR_BRAKE || []).forEach((brake) => {
        body[0].push(head(brake.AXLE_NUMBER));
        body[1].push(fmtBrake(brake.BRAKE_CHAMBER, brake.RIGHT_BRAKE));
        body[2].push(fmtBrake(brake.BRAKE_CHAMBER, brake.LEFT_BRAKE));
        body[3].push(brake.BRAKE_CHAMBER);
    });
    return [
        {
            text: "BRAKE ADJUSTMENTS",
            style: "header",
        },
        {
            table: {
                layout: "noBorders",
                headerRows: 1,
                body: body,
            },
            layout: "noBorders",
        },
    ];
};
// const pbbt = (inspection, options) => {
//   options.brakeOos = options.brakeOos || (() => false);
//   options.brakeWarning = options.brakeWarning || (() => false);
//   if (
//     (inspection.IR_BRAKE || []).length === 0 &&
//     (inspection.INSPECTION_LEVEL === "2" || inspection.INSPECTION_LEVEL === "3")
//   ) {
//     return withHeader(
//       "BRAKE ADJUSTMENTS",
//       "No brake measurements required for level II or level III",
//     );
//   }
//   let head = text => ({ text: text, decoration: "underline" });
//   let body = [
//     [head("Axle #")],
//     [head("Right")],
//     [head("Left")],
//     [head("Chamber")],
//   ];
//   // Highlight warning brakes
//   const fmtBrake = (chamberType, measurement) => {
//     const shouldHighlight =
//       options.brakeOos(chamberType, measurement) ||
//       options.brakeWarning(chamberType, measurement);
//     if (!shouldHighlight) return measurement;
//     return { style: "highlight", text: measurement };
//   };
//   (inspection.IR_BRAKE || []).forEach(brake => {
//     body[0].push(head(brake.AXLE_NUMBER));
//     body[1].push(fmtBrake(brake.BRAKE_CHAMBER, brake.RIGHT_BRAKE));
//     body[2].push(fmtBrake(brake.BRAKE_CHAMBER, brake.LEFT_BRAKE));
//     body[3].push(brake.BRAKE_CHAMBER);
//   });
//   return [
//     {
//       text:
//         "BRAKE PERFORMANCE ASSESSED USING PERFORMANCE-BASED BRAKE TESTER (PBBT)",
//       style: "header",
//     },
//     { text: `Minimum value required to pass for ${inspection.}` },
//     { text: "Braking Force as Percentage of GVW or GCW:" },
//     {
//       table: {
//         layout: "noBorders",
//         headerRows: 1,
//         body: body,
//       },
//       layout: "noBorders",
//     },
//   ];
// };
const specialChecks = (inspection) => {
    const checkRefs = {
        ALC_SUB_CHK: "Alcohol/Controlled Substance Check",
        TRAFFIC_ENF: "Traffic Enforcement",
        ACCIDENT: "Post Crash Inspection",
        LOCAL_JURISD: "Conducted by Local Jurisdiction",
        PASACHECK: "PASA Conducted Inspection",
        PBBTCHECK: "PBBT Inspection",
        SIZE_ENF: "Size and Weight Enforcement",
        DRUG_SEARCH: "Drug Interdiction Search",
        BORDERGRANT: "Border Enforcement Inspection",
        ESCREEN: "eScreen Inspection",
    };
    const blankMarker = "";
    const marker = (name) => (inspection[name] &&
        inspection[name] !== "N" && {
        text: "X",
        fontSize: 8,
    }) ||
        blankMarker;
    const getName = (field) => {
        const fieldHints = {
            BORDERGRANT: {
                D: "Domestic",
                F: "Foreign",
            },
        };
        let name = checkRefs[field];
        if (field in fieldHints) {
            const val = inspection[field];
            if (val in fieldHints[field])
                name = name + "-" + fieldHints[field][val];
        }
        return { text: name, fontSize: 8 };
    };
    const place = (field) => [marker(field), getName(field)];
    const content = Object.keys(checkRefs)
        .map((k) => marker(k))
        .filter((m) => m !== blankMarker).length === 0
        ? "No data for special checks"
        : {
            table: {
                body: [
                    [
                        ...place("ALC_SUB_CHK"),
                        ...place("TRAFFIC_ENF"),
                        ...place("ACCIDENT"),
                    ],
                    [
                        ...place("LOCAL_JURISD"),
                        ...place("PASACHECK"),
                        ...place("PBBTCHECK"),
                    ],
                    [...place("SIZE_ENF"), ...place("DRUG_SEARCH"), "", ""],
                    [...place("ESCREEN"), ...place("BORDERGRANT"), "", ""],
                ],
            },
            layout: "noBorders",
        };
    return [
        {
            columnGap: 10,
            columns: [
                {
                    text: "Special Checks:",
                    style: "header",
                    width: "auto",
                },
                content,
            ],
        },
    ];
};
const stateInformation = (inspection) => {
    const fields = (inspection.LOCAL_FIELD || []).filter((f) => f.VALUE !== "N");
    if (fields.length === 0)
        return [];
    return withHeader("State Information", fields.map((field) => `${field.LABEL}: ${field.VALUE}`).join("; "));
};
const notes = (inspection) => inspection.INSPECTION_NOTES &&
    withHeader("Notes", inspection.INSPECTION_NOTES);
const undeliverableAddress = (inspection) => inspection.PA_UNDELIVERABLE_CODE === "U" &&
    withHeader("Undeliverable Address", "The motor carrier has an UNDELIVERABLE address. You must attempt to obtain an updated address and contact information and you must add the new information in the inspection notes. Drivers must be instructed to inform the motor carrier of the UNDELIVERABLE address on file, and the motor carrier needs to update their address with FMCSA.");
const pageHeader = (inspection, options) => ({
    margin: [30, 20, 30, 0],
    layout: "noBorders",
    table: {
        body: [
            [
                {
                    columns: [
                        { width: 100, text: "" },
                        {
                            text: "DRIVER/VEHICLE EXAMINATION REPORT",
                            alignment: "center",
                            bold: true,
                        },
                        {
                            width: 100,
                            text: inspection.CLIENT_STRING || "",
                            alignment: "right",
                        },
                    ],
                },
            ],
            [
                hr({
                    margin: [0, -2],
                }),
            ],
            [
                {
                    columnGap: 10,
                    columns: [
                        ...(options.base64Logo
                            ? [
                                {
                                    image: options.base64Logo || "",
                                    width: 50,
                                },
                            ]
                            : []),
                        [
                            {
                                text: inspection.OFFICE_NAME || "",
                                style: "header",
                            },
                            {
                                text: inspection.OFFICE_ADDR_LINE1 || "",
                                style: "header",
                            },
                            {
                                text: inspection.OFFICE_ADDR_LINE2 || "",
                                style: "header",
                            },
                            {
                                text: inspection.OFFICE_ADDR_LINE3 || "",
                                style: "header",
                            },
                            {
                                text: inspection.OFFICE_PHONE_NUMBER || "",
                                style: "header",
                            },
                        ],
                        [
                            withHeader("Report Number", inspection.INSPECTION_RPTNUM || ""),
                            withHeader("Inspection Date", formatDate(inspection.INSPECTION_DATE_TIME)),
                            {
                                text: [
                                    withHeader("Start", formatTime(inspection.INSPECTION_DATE_TIME) +
                                        " " +
                                        inspection.LOCATION_TIME_ZONE || ""),
                                    " ",
                                    withHeader("End", formatTime(inspection.INSPECTION_END_DATE_TIME) +
                                        " " +
                                        inspection.LOCATION_TIME_ZONE || ""),
                                ],
                            },
                            withHeader("Inspection Level", asInspectionLevel(inspection.INSPECTION_LEVEL)),
                            withHeader("HM Inspection Type", asHmType(inspection.HM_INSPECTION)),
                        ],
                    ],
                },
            ],
            [
                hr({
                    margin: [0, -2],
                }),
            ],
        ],
    },
});
const formatName = (first, middle, last) => ((last && last + ", ") || "") +
    (first || "") +
    ((middle && " " + middle) || "");
const formatNameParagraph = (first, middle, last) => (first || "") + ((middle && " " + middle) || "") + " " + (last || "");
const demographics = (inspection, options) => [
    {
        columns: [
            [
                inspection.CARRIER_NAME,
                inspection.CARRIER_STREET,
                {
                    text: inspection.CARRIER_CITY +
                        ", " +
                        inspection.CARRIER_STATE +
                        ", " +
                        inspection.CARRIER_ZIP,
                },
                {
                    columns: [
                        [
                            withHeader("USDOT", inspection.CARRIER_ID_NUMBER),
                            withHeader("MC/MX#", inspection.ICC_NUMBER),
                            withHeader("State#", inspection.STATE_CARRIER_ID),
                        ],
                        [
                            withHeader("Phone#", formatPhoneNumber(inspection.CARRIER_PHONE)),
                            withHeader("Fax#", formatPhoneNumber(inspection.CARRIER_FAX)),
                        ],
                    ],
                },
            ],
            options.mode === "full"
                ? [
                    withHeader("Driver", formatName(inspection.DRIVER_FNAME, inspection.DRIVER_MI, inspection.DRIVER_LNAME)),
                    {
                        text: [
                            withHeader("License#", inspection.DRIVER_LICENSE_NUMBER),
                            "      ",
                            withHeader("State", inspection.DRIVER_LICENSE_STATE),
                        ],
                    },
                    withHeader("Date of Birth", formatDate(inspection.DRIVER_DOB)),
                    withHeader("CoDriver", formatName(inspection.CODRIVER_FNAME, inspection.CODRIVER_MI, inspection.CODRIVER_LNAME)),
                    {
                        text: [
                            withHeader("License#", inspection.CODRIVER_LIC_NUMBER),
                            "      ",
                            withHeader("State", inspection.CODRIVER_LIC_STATE),
                        ],
                    },
                    withHeader("Date of Birth", formatDate(inspection.CODRIVER_DOB)),
                ]
                : [],
        ],
    },
    {
        columnGap: 1,
        columns: [
            [
                withHeader("Location", inspection.INSPECTION_LOCATION_DESC),
                withHeader("Highway", inspection.INSPECTION_HIGHWAY_LOCATION),
                withHeader("County", inspection.COUNTY_NAME),
            ],
            [
                withHeader("Milepost", inspection.HIGHWAY_MILEPOST),
                withHeader("Origin", inspection.TRIP_ORIGIN),
                withHeader("Destination", inspection.TRIP_DESTINATION),
            ],
            [
                withHeader("Shipper", inspection.SHIPPER_NAME || "N/A"),
                withHeader("Bill of Lading", inspection.SHIPPING_PAPER_NUMBER),
                withHeader("Cargo", inspection.CARGO_TYPE),
            ],
        ],
    },
];
const oosSignatures = (inspection) => {
    // SAFER sends as IR_OOS_TEXT: [{ VEHICLE_OOS_TEXT: ..., etc }]
    const oosTexts = (inspection.IR_OOS_TEXT || [])[0] || {};
    const sigLookup = {
        VEHICLE_OOS_TEXT: null,
        DRIVER_OOS_TEXT: null,
        CERTIFY_OOS_TEXT: {
            signer: "Repairer",
            other: "Facility",
        },
        VERIFY_OOS_TEXT: {
            signer: "Motor Carrier",
            other: "Title",
        },
    };
    const createOosSection = (field) => {
        let text = oosTexts[field];
        if (!text)
            return null;
        text = text.replace("@DRIVER", formatNameParagraph(inspection.DRIVER_FNAME, inspection.DRIVER_MI, inspection.DRIVER_LNAME));
        text = text.replace("@TIME", inspection.DRIVER_OOS_UNTIL);
        const sig = sigLookup[field];
        if (sig)
            return [
                {
                    text: text,
                    fontSize: 8,
                },
                {
                    text: `Signature of ${sig["signer"]} X: ______________________ ${sig["other"]}: _____________ Date: ________`,
                    fontSize: 8,
                },
            ];
        else
            return [text];
    };
    const sections = [];
    if (inspection.DRIVER_OOS === "Y")
        sections.push(createOosSection("DRIVER_OOS_TEXT"));
    if (inspection.VEHICLE_OOS === "Y")
        sections.push(createOosSection("VEHICLE_OOS_TEXT"));
    // print repair text if any vehicle violations
    if ((inspection.IR_VIOLATION || []).filter((v) => [1, 2, 3, 4, 5, 6].indexOf(parseInt(v.VIOLATION_UNIT)) >= 0).length > 0)
        sections.push(createOosSection("CERTIFY_OOS_TEXT"));
    if (inspection.TOTAL_VIOLATION > 0)
        sections.push(createOosSection("VERIFY_OOS_TEXT"));
    if (inspection.TOTAL_VIOLATION === 0)
        sections.push({
            text: "Attention Motor Carrier: If the inspection indicates NO violations/defects on the driver and or vehicle no copy of the inspection needs to\nbe returned to the investigating agency. The carrier should maintain a copy of the inspection pursuant to retention guidelines.",
            fontSize: 9,
        });
    let joinedSections = [];
    sections.forEach((section) => {
        if (joinedSections.length > 0)
            joinedSections.push(hr());
        joinedSections.push(section);
    });
    return joinedSections;
};
const formatUsdot = (usdot) => {
    usdot = usdot || "";
    const pad = "00000000";
    return pad.substring(0, pad.length - usdot.length) + usdot;
};
// Replace first name with initial
const formatInspectorAbbreviated = (name) => {
    const names = name.split(" ");
    const newNames = [names[0][0] + ".", ...names.slice(1)];
    return newNames.join(" ");
};
const inspectorSignature = (inspection) => {
    const head = (text) => ({ text: text, decoration: "underline" });
    const sigLine = () => ({
        text: "X___________________________________________",
        margin: [0, 15, 0, 0],
    });
    return {
        columns: [
            [
                {
                    columns: [
                        [
                            head("Report Prepared By:"),
                            formatInspectorAbbreviated(inspection.INSPECTOR_NAME),
                        ],
                        [head("Badge #:"), inspection.INSPECTOR_CODE],
                        // Optionally include Radio #
                        ...((inspection.INSPECTOR_RADIO_NUM && [
                            [head("Radio #:"), inspection.INSPECTOR_RADIO_NUM],
                        ]) ||
                            []),
                    ],
                },
                sigLine(),
            ],
            [
                head("Copy Received By:"),
                formatNameParagraph(inspection.DRIVER_FNAME, inspection.DRIVER_MI, inspection.DRIVER_LNAME),
                sigLine(),
            ],
        ],
    };
};
var generateBarcode = (inspection) => {
    const canvas = document.createElement("canvas");
    return new Promise((resolve, reject) => {
        bwipjs.toCanvas(canvas, {
            bcid: "code128",
            text: inspection.INSPECTION_RPTNUM,
        });
        resolve(canvas.toDataURL());
    });
};
var createDefinition = (inspection, barcode, options) => {
    const section = (contentFn) => {
        const content = contentFn(inspection, options || {});
        if (content) {
            if (content.length === 0)
                return [];
            return [content, hr()];
        }
        return [];
    };
    return Promise.resolve({
        pageMargins: [30, 102, 30, 130],
        pageSize: "letter",
        content: [
            section(demographics),
            section(iep),
            section(vehicleIdentification),
            section(brakes),
            section(violations),
            // section(pbbt),
            section(hazmat),
            section(specialChecks),
            section(stateInformation),
            section(notes),
            section(undeliverableAddress),
            section(oosSignatures),
        ].filter((x) => x && x.length > 0),
        header: pageHeader(inspection, options),
        footer: (currentPage, pageCount) => {
            let content = [];
            if (currentPage === pageCount) {
                content.push({
                    columns: [
                        {
                            text: "",
                            width: 30,
                        },
                        inspectorSignature(inspection),
                    ],
                });
            }
            content.push({
                columns: [
                    "",
                    {
                        text: `Page ${currentPage} of ${pageCount}`,
                        margin: [60, 50, 0, 0],
                    },
                    [
                        {
                            image: barcode,
                            width: 150,
                            height: 20,
                            margin: [0, 10, 0, 0],
                        },
                        {
                            text: formatUsdot(inspection.CARRIER_ID_NUMBER) +
                                " " +
                                inspection.INSPECTION_STATE +
                                " " +
                                inspection.INSPECTION_RPTNUM,
                            alignment: "right",
                            margin: [0, 0, 60, 0],
                        },
                    ],
                ],
            });
            return content;
        },
        styles: {
            header: {
                bold: true,
            },
            tableHeader: {
                underline: true,
            },
            highlight: {
                background: "lightgray",
            },
        },
        defaultStyle: {
            font: "Arial",
            fontSize: 10,
        },
    });
};
// inspection: augmented T0033
// options:
//   mode = "full" | "iep"
//   brakeOos: (chamber type, measurement) => boolean
//   base64Logo = (base64 data url)
exports.outputPdf = (inspection, options) => {
    options = options || { mode: "full" };
    return new Promise((resolve, reject) => {
        // Not sure why we need to do this
        inspection = JSON.parse(JSON.stringify(inspection));
        generateBarcode(inspection)
            .then((barcode) => createDefinition(inspection, barcode, options))
            .then((definition) => {
            const pdf = pdfmake_1.createPdf(definition, {}, {
                Arial: {
                    normal: "arial.ttf",
                    bold: "arialbd.ttf",
                },
            }, PdfMakeVfsFonts_1.PdfMakeVfsFonts);
            pdf.getBuffer((buffer) => resolve(buffer));
        });
    });
};
