"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const uuid = __importStar(require("uuid"));
// import { StateCitation } from "../types/SaferInspection/Violation";
const DateTime_1 = require("../types/DateTime");
// import { LocalReportNumberReservationService } from "./LocalReportNumberReservationService";
// TODO: needs to come from server
const officeInfo = {
    DE: {
        InspOfficeName: "Delaware State Police",
        InspOfficeAddress1Text: "MCSAP Office",
        InspOfficeAddress2Text: "P. O. Box 430",
        InspOfficeAddress3Text: "Dover, DE  19903",
        InspOfficePhoneNum: "Phone: (302)378-5824   Fax: (302)378-5216",
    },
    KY: {
        InspOfficeName: "Kentucky State Police",
        InspOfficeAddress1Text: "Commercial Vehicle Enforcement Division",
        InspOfficeAddress2Text: "919 Versailles Road",
        InspOfficeAddress3Text: "Frankfort, Kentucky 40601",
        InspOfficePhoneNum: "cveinspections@ky.gov",
    },
    MS: {
        InspOfficeName: "Mississippi Department of Transportation",
        InspOfficeAddress1Text: "412 East Woodrow Wilson",
        InspOfficeAddress2Text: "P.O. Box 1850",
        InspOfficeAddress3Text: "Jackson, MS 39216",
        InspOfficePhoneNum: "Phone #: (601)359-1698    Fax #: (601)359-1781",
    },
    TN: {
        InspOfficeName: "Tennessee Department of Safety",
        InspOfficeAddress1Text: "Commercial Vehicle Division",
        InspOfficeAddress2Text: "1150 Foster Avenue",
        InspOfficeAddress3Text: "Nashville, TN  37243",
        InspOfficePhoneNum: "Phone: (615)743-4990   Fax: (615)253-2278",
    },
    VA: {
        InspOfficeName: "Virginia State Police Motor Carrier Safety",
        InspOfficeAddress1Text: "P.O. Box 27472",
        InspOfficeAddress2Text: "Richmond, VA  23261-7472",
        InspOfficeAddress3Text: "Phone: (703)803-2628   Fax: (804)-254-1635",
        InspOfficePhoneNum: "Email: mcsu2@vsp.virginia.gov",
    },
};
const getOfficeInfo = (state) => {
    const info = officeInfo[state];
    return (info || {
        InspOfficeName: "Placeholder Department of Transportation",
        InspOfficeAddress1Text: "123 Example Street",
        InspOfficeAddress2Text: "P.O. Box 1234",
        InspOfficeAddress3Text: "Seattle, WA 98101",
        InspOfficePhoneNum: "Phone #: (206)555-5555    Fax #: (206)555-6666",
    });
};
const CreateInspectionService = {
    createInspection: (createOptions) => __awaiter(void 0, void 0, void 0, function* () {
        const now = new Date();
        const inspectionId = uuid.v4();
        let reportNumber;
        let timezone;
        let inspectorName;
        let inspectorCode;
        let inspectorRadioNum;
        if (createOptions.optionalOptions) {
            reportNumber = createOptions.optionalOptions.reportNumber;
            timezone = createOptions.optionalOptions.timezone;
            inspectorName = createOptions.optionalOptions.inspectorName;
            inspectorCode = createOptions.optionalOptions.inspectorCode;
            inspectorRadioNum = createOptions.optionalOptions.inspectorRadioNum;
        }
        else {
            timezone = createOptions.timezone;
            inspectorName = createOptions.inspectorName || "";
            inspectorCode = createOptions.inspectorCode || "";
            inspectorRadioNum = createOptions.radioNumber || "";
            // MIGRATE_TODO prefix is passed here in electron version in order to delete any saved
            //   const prefix = userSettings
            //     ? (userSettings[reportNumberStateCode] || "") +
            //       (userSettings[reportNumberUniqueCode] || "")
            //     : "";
            const newReportNumber = yield createOptions.getNextNumber();
            // report numbers without the prefix. It
            //   const newReportNumber = await ReportNumberService.getInspectionNumber(
            //     prefix,
            //   );
            if (!newReportNumber)
                throw Error("Couldn't reserve report number!");
            reportNumber = newReportNumber;
        }
        // MIGRATE_TODO
        // let location = FavoriteLocationService.getFavoriteLocation();
        const defaultLocation = {
            TimeZoneCode: timezone,
            InspLocationText: "",
            HighwayDescText: "",
            InspStateCode: createOptions.stateCode,
            InspCountyName: "",
            FIPSCountyCode: "",
            CountryCode: "USA",
            StateCountyCode: "",
            HighwayMilepostNum: "",
            InspLocationCode: "",
        };
        // location = location || {
        //   TimeZoneCode: timezone,
        //   InspLocationText: "",
        //   HighwayDescText: "",
        //   InspStateCode: cviewState,
        //   InspCountyName: "",
        //   FIPSCountyCode: "",
        //   CountryCode: "USA",
        //   StateCountyCode: "",
        //   HighwayMilepostNum: "",
        //   InspLocationCode: "",
        // };
        const inspection = {
            id: inspectionId,
            reportNumberFromBack: reportNumber.fromBack,
            creationTime: new DateTime_1.DateTime(now),
            InspMain: {
                InspReportID: reportNumber.reportNumber,
                InspStartTime: new DateTime_1.DateTime(now),
                InspDuration: 0,
                InspTypeLevelCode: undefined,
                InspLocation: defaultLocation,
                TripOrigin: {
                    Country: "USA",
                },
                TripDestination: {
                    Country: "USA",
                },
                InspectorName: inspectorName,
                InspectorCode: inspectorCode,
                InspectorRadioNum: inspectorRadioNum,
                Carrier: {
                    CarrierStateCensusNum: "",
                    CarrierName: "",
                    CarrierAddress: {
                        AddressStreetText: "",
                        AddressCityName: "",
                        AddressCountryCode: "US",
                    },
                    CarrierInterstateFlag: "N",
                    CarrierAspenMatchFlag: "I",
                },
                // TODO: These will be overridden during XML serialization. Really don't belong in this type to begin with.
                InspTotalCounts: {
                    InspTotalHazmatNum: 0,
                    InspTotalVehicleNum: 0,
                    InspTotalVioNum: 0,
                    InspTotalOOSVioNum: 0,
                    InspTotalVehicleOOSVioNum: 0,
                    InspTotalDriverOOSVioNum: 0,
                },
                InspReportStatusCode: "C",
            },
            Drivers: [],
            Vehicles: [],
            Brakes: [],
            PBBTMeasurements: [],
            HazMats: [],
            Violations: [],
            Shippers: [],
            Studies: [],
            OutOfServiceInfo: {
                DriverOOSFlag: "N",
                VehicleOOSFlag: "N",
            },
            LocalFields: [],
            InspectionOffice: getOfficeInfo(createOptions.stateCode),
            AdminData: {
                InspJurisdictionCode: createOptions.stateCode,
                SourceSystemName: "RIMS",
                SystemVersionCode: createOptions.rimsVersion,
                UploadSaferRequiredFlag: "Y",
                UploadRequiredFlag: "Y",
                UpdateTime: now,
                AspenUploadTime: now,
            },
            coInspectors: [],
            attachments: [],
        };
        return inspection;
    }),
};
exports.default = CreateInspectionService;
