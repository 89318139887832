"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateViolations = exports.validate = exports.violationGroups = exports.violationKey = void 0;
const InspectionViolationService_1 = require("../../InspectionViolationService");
const DateFormat_1 = require("../../../../services/DateFormat");
const SmartLogicFunctions_1 = require("./SmartLogicFunctions");
exports.violationKey = (violation) => violation.VioFederalRegCode || violation.VioRegSectionCode || "n/a";
exports.violationGroups = [
    {
        group: [
            "395.8",
            // TODO: Should we remove 395.8A1? It appears to no longer exist,
            // replaced by 395.8A-ELD and 395.8A-NON_ELD
            "395.8A1",
            "395.8D09",
            "395.8F02",
            "395.8F03",
            "395.8F04",
            "395.8F05",
            "395.8F06",
            "395.8F07",
            "395.8F08I",
            "395.8F08III",
            "395.8F09",
            "395.8F10",
            "395.8F11",
            "395.8F12",
            "395.8H01",
            "395.8H02",
        ],
        exclude: ["395.8"],
    },
    {
        group: [
            "395.15I5",
            "395.15I5I",
            "395.15I5II",
            "395.15I5III",
            "395.15I5IV",
            "395.15I5V",
            "395.15I5VI",
        ],
        exclude: ["395.15I5"],
    },
];
exports.validate = (violationDate, triggeringViolation, otherViolations, selectedViolations) => {
    let citeError;
    let violationError;
    if (otherViolations.filter(x => SmartLogicFunctions_1.is395_8A(x)).length > 0)
        return {
            errors: {
                citeError: "A violation of §395.8 cannot be cited with a §395.8(a) violation.",
            },
        };
    if (otherViolations.filter(x => x.VehicleUnitCode === triggeringViolation.VehicleUnitCode &&
        x.VioFederalRegCode === triggeringViolation.VioFederalRegCode &&
        DateFormat_1.isSameDate(x.date, violationDate)).length > 0)
        citeError = `${triggeringViolation.VioFederalRegCode} can be cited only once per driver per day.`;
    if (Object.keys(selectedViolations).length === 0)
        violationError = "Please select at least one violation.";
    return { errors: { citeError, violationError } };
};
exports.updateViolations = (violationDate, triggeringViolation, violations, additionalDetails, additionalNotes) => {
    const newViolations = InspectionViolationService_1.UpdateViolation(violations, triggeringViolation.VioSeqID, Object.assign(Object.assign({}, triggeringViolation), { SuppVioDesc: additionalDetails, date: violationDate }));
    return {
        violations: newViolations,
        additionalNotes,
    };
};
