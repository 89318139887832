"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSmartLogicViolation = exports.getCategory = void 0;
const SmartLogicFunctions_1 = require("../pages/violation/SmartLogicFunctions");
// Violations which we explicitly do NOT want to show SmartLogic for
const blacklistedViolations = ["395.8A-ELD", "395.8A-NON-ELD"];
exports.getCategory = (fedViolation) => {
    const fedViolationCode = fedViolation.VioFederalRegCode;
    if (!fedViolationCode)
        return;
    if (blacklistedViolations.includes(fedViolationCode))
        return undefined;
    if (fedViolationCode === "395.8E")
        return "Cat2_FalseLogBook";
    else if (["395.8K2", "395.8F01"].includes(fedViolationCode))
        return "Cat3_LogNotCurrent";
    else if (SmartLogicFunctions_1.is395_8A(fedViolation))
        return "Cat3_NoLog";
    else if ([
        "395.3A2-PROP",
        "395.3A2-PROPN",
        "395.3A3-PROP",
        "395.3A3-PROPN",
        "395.5A1-PASS",
        "395.5A1-PASSN",
        "395.5A2-PASS",
        "395.5A2-PASSN",
    ].indexOf(fedViolationCode) >= 0)
        return "Cat4_Hours";
    else if (["395.1H1", "395.1H2"].indexOf(fedViolationCode) >= 0)
        return "Cat5_Alaska";
    else if ([
        "395.3B1-PROP",
        "395.3B1-PROPN",
        "395.3B2",
        "395.3B2-NOM",
        "395.5B1-PASS",
        "395.5B1-PASSN",
        "395.5B2-PASS",
        "395.5B2-PASSN",
    ].indexOf(fedViolationCode) >= 0)
        return "Cat6_Hours";
    else if ([
        "395.13D",
        "395.15G",
        "395.3A3II",
        "395.15F",
        "395.15B",
        "395.15B2",
        "395.15D2",
        "395.15G01",
        "395.15G02",
        "395.15I9",
        "398.6",
    ].indexOf(fedViolationCode) >= 0)
        return "Cat7_AllOtherHoursOfService";
    else if ([
        // Group 1
        "395.8",
        "395.8A1",
        "395.8D09",
        "395.8F02",
        "395.8F03",
        "395.8F04",
        "395.8F05",
        "395.8F06",
        "395.8F07",
        "395.8F08I",
        "395.8F08III",
        "395.8F09",
        "395.8F10",
        "395.8F11",
        "395.8F12",
        "395.8H01",
        "395.8H02",
        // Group 2
        "395.15I5",
        "395.15I5I",
        "395.15I5II",
        "395.15I5III",
        "395.15I5IV",
        "395.15I5V",
        "395.15I5VI",
    ].indexOf(fedViolationCode) >= 0)
        return "Cat50_LogBookAndManner";
    else if (["383.23A2"].indexOf(fedViolationCode) >= 0)
        // else if (["D", "C"].indexOf(fedViolation.VehicleUnitCode) >= 0)
        return "Cat14_AllOtherDriver";
    return undefined;
};
exports.isSmartLogicViolation = (violation) => !!exports.getCategory(violation);
