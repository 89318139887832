"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDescription = void 0;
const descriptions = {
    0: "Not In MCSIP",
    3: "Past Due CR",
    7: "Mandatory CR",
    22: "Compliance Review Complete",
    52: "Biennial Update not Completed",
    53: "Not Implemented -- Placeholder for future new OOSO",
    54: "OOSO -- Imminent Hazard",
    55: "OOSO -- Unsat/Unfit",
    56: "OOSO -- New Entrant Revoked for Expedited Actions and Intrastate Out-of-Service",
    57: "OOSO -- Failure to Pay Fine for Safety Violation",
    58: "OOSO -- New Entrant Revoked for Expedited Actions",
    59: "Intrastate Out-of-Service",
    60: "OOSO -- Imminent Hazard and Intrastate Out-of-Service",
    61: "OOSO -- Unsat/Unfit and Intrastate Out-of-Service",
    62: "OOSO -- Failure to Pay and Intrastate Out-of-Service",
    63: "OOSO -- New Entrant Revoked for Refusal of Audit/No Contact",
    64: "OOSO -- New Entrant Revoked for Failure of Safety Audit",
    65: "OOSO -- Operating without Authority",
    66: "OOSO -- Operating without Authority and Intrastate Out-of-Service",
    67: "OOSO -- New Entrant Revoked for Refusal of Audit/No Contact and Intrastate Out-of-Service",
    68: "OOSO -- New Entrant Revoked for Failure of Safety Audit and Intrastate Out-of-Service",
    99: "Carrier Out of Business",
};
exports.getDescription = (level) => descriptions[level] || "n/a";
