"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.populateLocalFieldsWithHM = exports.condSetField = exports.setFieldValue = exports.withoutField = exports.getBulkPackage = exports.supportsBulkPackage = exports.getBulkPackageField = exports.supportsCargoTankInspection = exports.getCargoTankInspection = exports.getCargoTankInspectionField = exports.getProperShippingName3 = exports.getProperShippingName2 = exports.getProperShippingName1 = exports.getProperShippingName3Field = exports.getProperShippingName2Field = exports.getProperShippingName1Field = exports.getUnNaNumber3 = exports.getUnNaNumber2 = exports.getUnNaNumber1 = exports.getUnNaNumber3Field = exports.getUnNaNumber2Field = exports.getUnNaNumber1Field = exports.getFieldValue = exports.getXmlField = exports.allFields = exports.getLocalFieldConfigs = void 0;
/****** BEGIN PARSING FUNCTIONS ******/
// TODO: Ideally no parsing would occur here, but on the .NET side
const parseLineAsLocalFieldConfig = (line) => ({
    id: parseInt(line.substring(0, 0 + 6)),
    groupId: parseInt(line.substring(133, 133 + 1)),
    xmlName: line.substring(6, 6 + 30).trim(),
    uiName: line.substring(36, 36 + 50).trim(),
    maxLength: parseInt(line.substring(100, 100 + 2)),
    required: line.substring(128, 129) === "1",
    yesNoRadio: false,
});
const MIN_LINE_LENGTH = 10;
exports.getLocalFieldConfigs = (config, localFieldMap, options) => {
    if (!config)
        return [];
    const { groups, data } = config;
    // Parse groups, including fields array which will be pushed to per-field
    const myGroups = groups
        .split("\n")
        .filter(line => line.length >= MIN_LINE_LENGTH)
        .map((line) => ({
        id: parseInt(line.substring(0, 4)),
        name: line.substring(4, 4 + 21).trim(),
        fields: [],
    }));
    // Parse fields
    const myFields = data
        .split("\n")
        .filter(line => line.length >= MIN_LINE_LENGTH)
        .map((line) => parseLineAsLocalFieldConfig(line));
    const controlledFields = localFieldMap
        ? Object.getOwnPropertyNames(localFieldMap).map(k => localFieldMap && localFieldMap[k])
        : [];
    // Add field to associated group
    for (const myField of myFields) {
        if (options) {
            // options say to include only controlled fields and field not controlled
            if (options.controlled === true &&
                !controlledFields.includes(myField.uiName))
                continue;
            // options say to exclude controlled fields and field is controlled
            if (options.controlled === false &&
                controlledFields.includes(myField.uiName))
                continue;
        }
        myGroups.filter(g => g.id === myField.groupId)[0].fields.push(myField);
    }
    return myGroups;
};
exports.allFields = (config) => config.map(x => x.fields).flat();
exports.getXmlField = (config, uiFieldName) => exports.allFields(config).filter(f => f.uiName === uiFieldName)[0];
/****** BEGIN PARSING FUNCTIONS ******/
exports.getFieldValue = (localFields, id) => {
    var _a;
    return id
        ? (_a = localFields.filter(x => x.InspLocalFieldID === id)[0]) === null || _a === void 0 ? void 0 : _a.InspLocalFieldDataValue : undefined;
};
// Hazmat: UN/NA numbers
exports.getUnNaNumber1Field = (config, localFieldMap) => localFieldMap.hm1UnNaNumber
    ? exports.getXmlField(config, localFieldMap.hm1UnNaNumber)
    : undefined;
exports.getUnNaNumber2Field = (config, localFieldMap) => localFieldMap.hm2UnNaNumber
    ? exports.getXmlField(config, localFieldMap.hm2UnNaNumber)
    : undefined;
exports.getUnNaNumber3Field = (config, localFieldMap) => localFieldMap.hm3UnNaNumber
    ? exports.getXmlField(config, localFieldMap.hm3UnNaNumber)
    : undefined;
exports.getUnNaNumber1 = (config, localFieldMap, localFields) => { var _a; return exports.getFieldValue(localFields, (_a = exports.getUnNaNumber1Field(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id); };
exports.getUnNaNumber2 = (config, localFieldMap, localFields) => { var _a; return exports.getFieldValue(localFields, (_a = exports.getUnNaNumber2Field(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id); };
exports.getUnNaNumber3 = (config, localFieldMap, localFields) => { var _a; return exports.getFieldValue(localFields, (_a = exports.getUnNaNumber3Field(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id); };
// Hazmat: Proper shipping name
exports.getProperShippingName1Field = (config, localFieldMap) => localFieldMap.hm1ProperShippingName
    ? exports.getXmlField(config, localFieldMap.hm1ProperShippingName)
    : undefined;
exports.getProperShippingName2Field = (config, localFieldMap) => localFieldMap.hm2ProperShippingName
    ? exports.getXmlField(config, localFieldMap.hm2ProperShippingName)
    : undefined;
exports.getProperShippingName3Field = (config, localFieldMap) => localFieldMap.hm3ProperShippingName
    ? exports.getXmlField(config, localFieldMap.hm3ProperShippingName)
    : undefined;
exports.getProperShippingName1 = (config, localFieldMap, localFields) => {
    var _a;
    return exports.getFieldValue(localFields, (_a = exports.getProperShippingName1Field(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id);
};
exports.getProperShippingName2 = (config, localFieldMap, localFields) => {
    var _a;
    return exports.getFieldValue(localFields, (_a = exports.getProperShippingName2Field(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id);
};
exports.getProperShippingName3 = (config, localFieldMap, localFields) => {
    var _a;
    return exports.getFieldValue(localFields, (_a = exports.getProperShippingName3Field(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id);
};
// Cargo tank
exports.getCargoTankInspectionField = (config, localFieldMap) => localFieldMap.cargoTankInspection
    ? exports.getXmlField(config, localFieldMap.cargoTankInspection)
    : undefined;
exports.getCargoTankInspection = (config, localFieldMap, localFields) => {
    var _a;
    return exports.getFieldValue(localFields, (_a = exports.getCargoTankInspectionField(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id);
};
exports.supportsCargoTankInspection = (config, localFieldMap) => !!exports.getCargoTankInspectionField(config, localFieldMap);
// Bulk package
exports.getBulkPackageField = (config, localFieldMap) => localFieldMap.bulkPackage
    ? exports.getXmlField(config, localFieldMap.bulkPackage)
    : undefined;
exports.supportsBulkPackage = (config, localFieldMap) => !!exports.getBulkPackageField(config, localFieldMap);
exports.getBulkPackage = (config, localFieldMap, localFields) => { var _a; return exports.getFieldValue(localFields, (_a = exports.getBulkPackageField(config, localFieldMap)) === null || _a === void 0 ? void 0 : _a.id); };
exports.withoutField = (sourceFields, targetField) => [...sourceFields].filter(x => x.InspLocalFieldID !== targetField.id);
exports.setFieldValue = (sourceFields, targetField, value) => {
    // Force "Y", "N", or "" for Yes/No fields
    if (targetField.uiName.indexOf("(Y or N)") >= 0 &&
        ["Y", "N"].indexOf(value) === -1 &&
        value !== "")
        return sourceFields;
    const fields = [...sourceFields];
    const field = fields.filter(x => x.InspLocalFieldID === targetField.id)[0];
    const newField = {
        InspLocalFieldID: targetField.id,
        InspLocalFieldLabelText: targetField.xmlName,
        InspLocalFieldDataValue: value,
        RecordPrintDesiredFlag: "N",
    };
    if (field) {
        // Copy new field properties to existing field
        Object.keys(field).forEach(key => (field[key] = newField[key]));
    }
    else {
        fields.push(newField);
    }
    return fields;
};
exports.condSetField = (fields, targetField, value) => (targetField ? exports.setFieldValue(fields, targetField, value) : fields);
exports.populateLocalFieldsWithHM = (config, localFieldMap, localFields, hazmats) => {
    for (const hm of hazmats) {
        switch (hm.HMSeqNum) {
            case "1":
                localFields = exports.condSetField(localFields, exports.getUnNaNumber1Field(config, localFieldMap), hm.unNaNumber);
                localFields = exports.condSetField(localFields, exports.getProperShippingName1Field(config, localFieldMap), hm.properShippingName);
                break;
            case "2":
                localFields = exports.condSetField(localFields, exports.getUnNaNumber2Field(config, localFieldMap), hm.unNaNumber);
                localFields = exports.condSetField(localFields, exports.getProperShippingName2Field(config, localFieldMap), hm.properShippingName);
                break;
            case "3":
                localFields = exports.condSetField(localFields, exports.getUnNaNumber3Field(config, localFieldMap), hm.unNaNumber);
                localFields = exports.condSetField(localFields, exports.getProperShippingName3Field(config, localFieldMap), hm.properShippingName);
                break;
        }
    }
    return localFields;
};
