"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unNumbers = void 0;
exports.unNumbers = [
    {
        number: "2908",
        name: "RADIOACTIVE MATERIAL, EXCEPTED PACKAGE - EMPTY PACKAGING",
    },
    {
        number: "2909",
        name: "RADIOACTIVE MATERIAL, EXCEPTED PACKAGE - ARTICLES MANUFACTURED FROM NATURAL URANIUM, DEPLETED URANIUM OR NATURAL THORIUM",
    },
    {
        number: "2910",
        name: "RADIOACTIVE MATERIAL, EXCEPTED PACKAGE - LIMITED QUANTITY OF MATERIAL",
    },
    {
        number: "2911",
        name: "RADIOACTIVE MATERIAL, EXCEPTED PACKAGE - INSTRUMENTS OR ARTICLES",
    },
    {
        number: "2912",
        name: "RADIOACTIVE MATERIAL, LOW SPECIFIC ACTIVITY (LSA-I) NON FISSILE OR FISSILE-EXCEPTED",
    },
    {
        number: "2913",
        name: "RADIOACTIVE MATERIAL, SURFACE CONTAMINATED OBJECTS (SCO-I OR SCO-II) NON FISSILE OR FISSILE-EXCEPTED",
    },
    {
        number: "2915",
        name: "RADIOACTIVE MATERIAL, TYPE A PACKAGE, NON-SPECIAL FORM, NON FISSILE OR FISSILE-EXCEPTED",
    },
    {
        number: "2916",
        name: "RADIOACTIVE MATERIAL, TYPE B(U) PACKAGE, NON FISSILE OR FISSILE-EXCEPTED",
    },
    {
        number: "2917",
        name: "RADIOACTIVE MATERIAL, TYPE B(M) PACKAGE, NON-FISSILE OR FISSILE-EXCEPTED",
    },
    {
        number: "2919",
        name: "RADIOACTIVE MATERIAL, TRANSPORTED UNDER SPECIAL ARRANGEMENT, NON FISSILE OR FISSILE EXCEPTED",
    },
    {
        number: "2977",
        name: "RADIOACTIVE MATERIAL, URANIUM HEXAFLUORIDE, FISSILE",
    },
    {
        number: "2978",
        name: "RADIOACTIVE MATERIAL, URANIUM HEXAFLUORIDE NON FISSILE OR FISSILE-EXCEPTED",
    },
    {
        number: "3321",
        name: "RADIOACTIVE MATERIAL, LOW SPECIFIC ACTIVITY (LSA-II) NON-FISSLE OR FISSILE-EXCEPTED",
    },
    {
        number: "3322",
        name: "RADIOACTIVE MATERIAL, LOW SPECIFIC ACTIVITY (LSA-III) NON FISSILE OR FISSILE EXCEPTED",
    },
    {
        number: "3327",
        name: "RADIOACTIVE MATERIAL, TYPE A PACKAGE, FISSILE NON-SPECIAL FORM",
    },
    { number: "3328", name: "RADIOACTIVE MATERIAL, TYPE B(U) PACKAGE, FISSLE" },
    { number: "3329", name: "RADIOACTIVE MATERIAL, TYPE B(M) PACKAGE, FISSLE" },
    {
        number: "3331",
        name: "RADIOACTIVE MATERIAL, TRANSPORTED UNDER SPECIAL ARRANGEMENT, FISSILE",
    },
    {
        number: "3332",
        name: "RADIOACTIVE MATERIAL, TYPE A PACKAGE, SPECIAL FORM NON FISSILE OR FISSILE-EXCEPTED",
    },
    {
        number: "3333",
        name: "RADIOACTIVE MATERIAL, TYPE A PACKAGE, SPECIAL FORM, FISSILE",
    },
];
