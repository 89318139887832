"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
class NewCarrier extends React.Component {
    constructor(props) {
        super(props);
        this.onConfirm = () => this.props.onConfirm(this.state.usdot);
        this.state = { usdot: "" };
    }
    render() {
        return (React.createElement(core_1.FormGroup, null,
            React.createElement("form", { onSubmit: e => {
                    e.preventDefault();
                    this.onConfirm();
                } },
                React.createElement(core_1.Card, { className: core_1.Classes.FORM_CONTENT },
                    React.createElement("label", { className: core_1.Classes.LABEL },
                        "USDOT",
                        React.createElement("input", { className: core_1.Classes.INPUT, style: { width: 200 }, type: "text", placeholder: "USDOT", dir: "auto", value: this.state.usdot, onChange: e => this.setState({
                                usdot: e.currentTarget.value,
                            }) })),
                    React.createElement(core_1.Button, { intent: core_1.Intent.PRIMARY, text: this.props.confirmButtonLabel, onClick: this.onConfirm })))));
    }
}
exports.default = NewCarrier;
