"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateViolations = exports.validate = exports.triggeredByDrivingAfterOos = void 0;
const DateFormat_1 = require("../../../../services/DateFormat");
const InspectionViolationService_1 = require("../../InspectionViolationService");
const SmartLogicFunctions_1 = require("./SmartLogicFunctions");
const drivingAfterOosCode = "395.13D";
exports.triggeredByDrivingAfterOos = (triggeringViolation) => triggeringViolation.VioFederalRegCode === drivingAfterOosCode;
exports.validate = (inspectionStartTime, triggeringViolation, otherViolations, violationDate, aobrdFunctioning, timeOff) => {
    const triggeringUnit = triggeringViolation.VehicleUnitCode;
    let timeOffError = (exports.triggeredByDrivingAfterOos(triggeringViolation) &&
        !timeOff &&
        "Time off flag required") ||
        undefined;
    const triggeringCode = triggeringViolation.VioFederalRegCode || "";
    let violationDateError = ([drivingAfterOosCode, "395.15G"].indexOf(triggeringCode) >= 0 &&
        !DateFormat_1.isSameDate(inspectionStartTime, violationDate) &&
        "Violation Date must be the Current Date") ||
        undefined;
    if (triggeringCode.startsWith("395.15G") &&
        otherViolations.filter(x => (x.VioFederalRegCode || "").startsWith("395.15G") &&
            DateFormat_1.isSameDate(x.date, violationDate) &&
            x.VehicleUnitCode === triggeringUnit).length > 0)
        violationDateError = `395.15G can be cited only once per driver per day.`;
    if (triggeringCode === "395.3A3II" &&
        otherViolations.filter(x => x.VioFederalRegCode === "395.3A3II" &&
            x.VehicleUnitCode === triggeringUnit &&
            DateFormat_1.isSameDate(x.date, violationDate)).length > 0)
        violationDateError = "395.3A3II can be cited only once per driver per day.";
    if (triggeringCode === "395.3A3II" &&
        otherViolations.filter(x => SmartLogicFunctions_1.is395_8A(x) && DateFormat_1.isSameDate(x.date, violationDate)).length > 0)
        violationDateError =
            "You cannot cite this violation with a §395.8(a) violation on the same date.";
    let aobrdFunctioningError;
    if (triggeringCode === "395.15F" && aobrdFunctioning !== "Y")
        aobrdFunctioningError =
            "If the AOBRD is not functioning, cite driver for the appropriate 395.8 violation.";
    return {
        errors: {
            timeOffError,
            violationDateError,
            aobrdFunctioningError,
        },
        prompts: {
            previousViolation: !violationDateError &&
                triggeringViolation.VioFederalRegCode === drivingAfterOosCode,
            aobrd: triggeringViolation.VioFederalRegCode === "395.15F",
            additionalDetails: !violationDateError,
            consider395_8e: triggeringViolation.VioFederalRegCode === "395.15B" &&
                otherViolations.filter(x => x.VioFederalRegCode === "395.8E").length ===
                    0,
        },
    };
};
exports.updateViolations = (triggeringViolation, violations, violationDate, additionalDetails, timeOff) => {
    let oosFlag = "N";
    if (exports.triggeredByDrivingAfterOos(triggeringViolation) && timeOff === "N")
        oosFlag = "Y";
    const thisViolation = Object.assign(Object.assign({}, triggeringViolation), { SuppVioDesc: additionalDetails, VioOOSFlag: oosFlag, date: violationDate });
    const updatedViolations = InspectionViolationService_1.UpdateViolation(violations, triggeringViolation.VioSeqID, thisViolation);
    return { thisViolation, updatedViolations };
};
