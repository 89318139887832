"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Labeled_1 = require("../core/Labeled");
const core_1 = require("@blueprintjs/core");
const RimsContext_1 = require("../../types/RimsContext");
let CountySelect = /** @class */ (() => {
    class CountySelect extends React.PureComponent {
        constructor(props) {
            super(props);
            this.onChange = (e) => {
                const county = this.state.counties.filter((x) => x.name === e.currentTarget.value)[0];
                this.props.onChange(county);
            };
            this.updateCountyList = () => __awaiter(this, void 0, void 0, function* () {
                const counties = yield this.context.getCounties(this.props.country, this.props.state);
                this.setState({
                    counties,
                });
            });
            this.state = {
                counties: [],
            };
        }
        render() {
            const dropdown = (React.createElement(core_1.HTMLSelect, { value: this.props.value, onChange: this.onChange },
                React.createElement("option", null),
                this.state.counties.map((county, i) => (React.createElement("option", { key: i, value: county.name }, county.name)))));
            return ((this.props.label && (React.createElement(Labeled_1.Labeled, { label: this.props.label }, dropdown))) ||
                dropdown);
        }
        componentDidMount() {
            this.updateCountyList();
        }
        componentDidUpdate(nextProps) {
            if (nextProps.country !== this.props.country ||
                nextProps.state !== this.props.state)
                this.updateCountyList();
        }
    }
    CountySelect.contextType = RimsContext_1.RimsContext;
    return CountySelect;
})();
exports.default = CountySelect;
