"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const core_2 = require("@blueprintjs/core");
class SpecialChecks extends React.Component {
    constructor() {
        super(...arguments);
        this.setEscreenFlag = (e) => this.props.setEscreenFlag(e.currentTarget.value);
        this.setBasicFlag = (e) => this.props.setBasicInspectionFlag(e.currentTarget.value);
        this.grantFundedChanged = (e) => {
            const { borderGrant, borderGrantChanged } = this.props;
            const newValue = ((!borderGrant || borderGrant === "N") && "D") || "N";
            borderGrantChanged(newValue);
        };
        this.grantTypeChanged = (e) => this.props.borderGrantChanged(e.currentTarget.value);
        this.setEnhancedScreeningFlag = (e) => this.props.enhancedScreeningChanged(e.currentTarget.value);
    }
    render() {
        return (React.createElement("div", null,
            React.createElement("label", { className: core_1.Classes.LABEL }, "Special Checks"),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "Size and Weight Enforcement", checked: this.props.sizeWeightEnforcement, onChange: this.props.toggleSizeWeightEnforcement })),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "Alcohol Substance Check", checked: this.props.alcoholSubstanceCheck, onChange: this.props.toggleAlcoholSubstanceCheck })),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "Traffic Enforcement", checked: this.props.trafficEnforcement, onChange: this.props.toggleTrafficEnforcement })),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "Local Enforcement", checked: this.props.localEnforcement, onChange: this.props.toggleLocalEnforcement })),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "Post Crash", checked: this.props.postCrash, onChange: this.props.togglePostCrash })),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "PBBT Inspection", checked: this.props.pbbtInspection, onChange: this.props.togglePbbtInspection, disabled: !this.props.shouldEnablePbbt })),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "PASA Inspection", checked: this.props.pasaInspection, onChange: this.props.togglePasaInspection })),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "Drug Interdiction Search", checked: this.props.drugInterdictionSearch, onChange: this.props.toggleDrugInterdictionSearch }),
                this.props.drugInterdictionSearch && (React.createElement("input", { style: { marginTop: "-0.3em", marginLeft: "0.5em" }, value: this.props.drugInterdictionArrests, onChange: e => this.props.onChangeDrugInterdictionArrests(e.currentTarget.value), placeholder: "# Arrests", size: 5 }))),
            React.createElement("div", null,
                React.createElement(core_1.Checkbox, { label: "Border Enforcement Grant Funded", checked: (this.props.borderGrant && this.props.borderGrant !== "N") ||
                        false, onChange: this.grantFundedChanged }),
                this.props.borderGrant && this.props.borderGrant !== "N" && (React.createElement("div", { style: { marginLeft: "0.5em" } },
                    React.createElement(core_1.RadioGroup, { selectedValue: this.props.borderGrant || "D", onChange: this.grantTypeChanged },
                        React.createElement(core_1.Radio, { value: "D", label: "Domestic Commerce" }),
                        React.createElement(core_1.Radio, { value: "F", label: "Foreign Commerce" }))))),
            React.createElement("div", { style: { marginLeft: 26 } },
                "eScreen Inspection",
                " ",
                React.createElement(core_2.Popover, { content: React.createElement(core_1.Card, null,
                        "Select \"Yes\" if:",
                        React.createElement("ul", null,
                            React.createElement("li", null, "Inspection is a result of mainline e-screening (Checkpoint, DriveWyze, Transponder, PrePass)"),
                            React.createElement("li", null, "Inspection is a result of ramp e-screening (LPR, USDOT-R, WIM)"),
                            React.createElement("li", null, "Inspection is a result of Virtual Weight Station (VWS) information")),
                        "Select \"No\" if:",
                        React.createElement("ul", null,
                            React.createElement("li", null, "Truck was not screened electronically at mainline or ramp (even if CVIEW check is conducted during an inspection)"),
                            React.createElement("li", null, "E-screening is turned off and all trucks are required to pull into the weigh station"),
                            React.createElement("li", null, "Truck was selected for inspection by an officer at roadside"))), target: React.createElement(core_1.Icon, { icon: "help" }), hoverOpenDelay: 0, hoverCloseDelay: 0, interactionKind: core_1.PopoverInteractionKind.HOVER }),
                React.createElement(core_1.RadioGroup, { inline: true, selectedValue: this.props.escreenInspection === undefined
                        ? ""
                        : (this.props.escreenInspection && "Y") || "N", onChange: this.setEscreenFlag },
                    React.createElement(core_1.Radio, { value: "N", label: "No" }),
                    React.createElement(core_1.Radio, { value: "Y", label: "Yes" }))),
            this.props.inspectionState === "KY" && (React.createElement("div", { style: { marginLeft: 26 } },
                "BASIC Inspection",
                " ",
                React.createElement(core_2.Popover, { content: React.createElement(core_1.Card, null, "Select \"Yes\" if inspection is due to high BASIC score"), target: React.createElement(core_1.Icon, { icon: "help" }), hoverOpenDelay: 0, hoverCloseDelay: 0, interactionKind: core_1.PopoverInteractionKind.HOVER }),
                React.createElement(core_1.RadioGroup, { inline: true, selectedValue: this.props.basicInspection === undefined
                        ? ""
                        : (this.props.basicInspection && "Y") || "N", onChange: this.setBasicFlag },
                    React.createElement(core_1.Radio, { value: "N", label: "No" }),
                    React.createElement(core_1.Radio, { value: "Y", label: "Yes" })))),
            this.props.inspectionState === "KY" && (React.createElement("div", { style: { marginLeft: 26 } },
                "Enhanced screening inspection",
                " ",
                React.createElement(core_2.Popover, { content: React.createElement(core_1.Card, null, "Select \"Yes\" if inspection was a result of the enhanced screening grant"), target: React.createElement(core_1.Icon, { icon: "help" }), hoverOpenDelay: 0, hoverCloseDelay: 0, interactionKind: core_1.PopoverInteractionKind.HOVER }),
                React.createElement(core_1.RadioGroup, { inline: true, selectedValue: this.props.enhancedScreening === undefined
                        ? ""
                        : (this.props.enhancedScreening && "Y") || "N", onChange: this.setEnhancedScreeningFlag },
                    React.createElement(core_1.Radio, { value: "N", label: "No" }),
                    React.createElement(core_1.Radio, { value: "Y", label: "Yes" }))))));
    }
}
exports.default = SpecialChecks;
