import { mapLongCountryToShort } from "@rims/common";

const states = [
  ["CA", "ALBERTA", "AB", "30"],
  ["CA", "BRITISH COLUMBIA", "BC", ""],
  ["CA", "MANITOBA", "MB", "38"],
  ["CA", "NEW BRUNSWICK", "NB", "23"],
  ["CA", "NEWFOUNDLAND", "NF", ""],
  ["CA", "NORTHWEST TERRITORIES", "NT", ""],
  ["CA", "NOVA SCOTIA", "NS", ""],
  ["CA", "NUNAVUT", "NU", ""],
  ["CA", "ONTARIO", "ON", ""],
  ["CA", "PRINCE EDWARD", "PE", ""],
  ["CA", "QUEBEC", "QC", ""],
  ["CA", "SASKATCHEWAN", "SK", "38"],
  ["CA", "YUKON TERRITORY", "YT", ""],
  ["MX", "AGUASCALIENTES", "AG", ""],
  ["MX", "BAJA CALIFORNIA NORTE", "BN", ""],
  ["MX", "BAJA CALIFORNIA SUR", "BS", ""],
  ["MX", "CAMPECHE", "CP", ""],
  ["MX", "CHIAPAS", "CS", ""],
  ["MX", "CHIHUAHUA", "CI", ""],
  ["MX", "COAHUILA", "CH", ""],
  ["MX", "COLIMA", "CL", ""],
  ["MX", "DISTRITO FEDERAL", "DF", ""],
  ["MX", "DURANGO", "DG", ""],
  ["MX", "GUANAJUATO", "GJ", ""],
  ["MX", "GUERRERO", "GE", ""],
  ["MX", "HIDALGO", "HD", ""],
  ["MX", "JALISCO", "JA", ""],
  ["MX", "MEXICO", "MX", ""],
  ["MX", "MICHOACAN", "MC", ""],
  ["MX", "MORELOS", "MR", ""],
  ["MX", "NAYARIT", "NA", ""],
  ["MX", "NUEVO LEON", "NL", ""],
  ["MX", "OAXACA", "OA", ""],
  ["MX", "PUEBLA", "PU", ""],
  ["MX", "QUERETARO", "QE", ""],
  ["MX", "QUINTANA ROO", "QI", ""],
  ["MX", "SAN LUIS POTOSI", "SL", ""],
  ["MX", "SINALOA", "SI", ""],
  ["MX", "SONORA", "SO", ""],
  ["MX", "TABASCO", "TB", ""],
  ["MX", "TAMAULIPAS", "TA", ""],
  ["MX", "TLAXCALA", "TL", ""],
  ["MX", "VERACRUZ", "VC", ""],
  ["MX", "YUCATAN", "YU", ""],
  ["MX", "ZACATECAS", "ZA", ""],
  ["O", "BELIZE", "BZ", ""],
  ["O", "CANAL ZONE", "CZ", ""],
  ["O", "COSTA RICA", "CR", ""],
  ["O", "EL SALVADOR", "ES", ""],
  ["O", "GUATEMALA", "GT", ""],
  ["O", "HONDURAS", "HO", ""],
  ["O", "NICARAGUA", "NI", ""],
  ["O", "OTHER", "OT", ""],
  ["O", "PANAMA", "PN", ""],
  ["O", "UNKNOWN", "UK", ""],
  ["US", "ALABAMA", "AL", "01"],
  ["US", "ALASKA", "AK", "02"],
  ["US", "AMERICAN SAMOA", "AS", "15"],
  ["US", "ARIZONA", "AZ", "04"],
  ["US", "ARKANSAS", "AR", "05"],
  ["US", "CALIFORNIA", "CA", "06"],
  ["US", "COLORADO", "CO", "08"],
  ["US", "CONNECTICUT", "CT", "09"],
  ["US", "DELAWARE", "DE", "10"],
  ["US", "DISTRICT OF COLUMBIA", "DC", "11"],
  ["US", "FLORIDA", "FL", "12"],
  ["US", "GEORGIA", "GA", "13"],
  ["US", "GUAM", "GU", "15"],
  ["US", "HAWAII", "HI", "15"],
  ["US", "IDAHO", "ID", "16"],
  ["US", "ILLINOIS", "IL", "17"],
  ["US", "INDIANA", "IN", "18"],
  ["US", "IOWA", "IA", "19"],
  ["US", "KANSAS", "KS", "20"],
  ["US", "KENTUCKY", "KY", "21"],
  ["US", "LOUISIANA", "LA", "22"],
  ["US", "MAINE", "ME", "23"],
  ["US", "MARYLAND", "MD", "24"],
  ["US", "MASSACHUSETTS", "MA", "25"],
  ["US", "MICHIGAN", "MI", "26"],
  ["US", "MINNESOTA", "MN", "27"],
  ["US", "MISSISSIPPI", "MS", "28"],
  ["US", "MISSOURI", "MO", "29"],
  ["US", "MONTANA", "MT", "30"],
  ["US", "NEBRASKA", "NE", "31"],
  ["US", "NEVADA", "NV", "32"],
  ["US", "NEW HAMPSHIRE", "NH", "33"],
  ["US", "NEW JERSEY", "NJ", "34"],
  ["US", "NEW MEXICO", "NM", "35"],
  ["US", "NEW YORK", "NY", "36"],
  ["US", "NORTH CAROLINA", "NC", "37"],
  ["US", "NORTH DAKOTA", "ND", "38"],
  ["US", "NORTHERN MARIANAS", "MP", "15"],
  ["US", "OHIO", "OH", "39"],
  ["US", "OKLAHOMA", "OK", "40"],
  ["US", "OREGON", "OR", "41"],
  ["US", "PENNSYLVANIA", "PA", "42"],
  ["US", "PUERTO RICO", "PR", "58"],
  ["US", "RHODE ISLAND", "RI", "44"],
  ["US", "SOUTH CAROLINA", "SC", "45"],
  ["US", "SOUTH DAKOTA", "SD", "46"],
  ["US", "TENNESSEE", "TN", "47"],
  ["US", "TEXAS", "TX", "48"],
  ["US", "UNITED STATES", "US", ""],
  ["US", "UTAH", "UT", "49"],
  ["US", "VERMONT", "VT", "50"],
  ["US", "VIRGIN ISLANDS", "VI", "58"],
  ["US", "VIRGINIA", "VA", "51"],
  ["US", "WASHINGTON", "WA", "53"],
  ["US", "WEST VIRGINIA", "WV", "54"],
  ["US", "WISCONSIN", "WI", "55"],
  ["US", "WYOMING", "WY", "56"],
];

export const getStates = (longCountryCode?: string) =>
  states
    .filter((x) => !longCountryCode || x[0] === mapLongCountryToShort(longCountryCode))
    .map((row) => ({
      country: row[0],
      name: row[1],
      abbreviation: row[2],
      oic: row[3],
    }));
