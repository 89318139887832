"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrakeMeasurementSelectionService = exports.ChamberTypeSelectionService = void 0;
// Probably be better to use react contexts, but I need to learn how that works :)
let selectedChamberType;
let selectedChamberSubtype;
let selectedMeasurement;
const ChamberTypeSelectionService = {
    selectChamberType: (chamberType) => {
        selectedChamberType = chamberType;
    },
    selectChamberSubtype: (chamberSubtype) => {
        selectedChamberSubtype = chamberSubtype;
    },
    getSelectedChamberType: () => selectedChamberType,
    getSelectedChamberSubtype: () => selectedChamberSubtype,
};
exports.ChamberTypeSelectionService = ChamberTypeSelectionService;
const BrakeMeasurementSelectionService = {
    selectMeasurement: (measurement) => {
        selectedMeasurement = measurement;
    },
    getSelectedMeasurement: () => selectedMeasurement,
};
exports.BrakeMeasurementSelectionService = BrakeMeasurementSelectionService;
