"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatCompanyId = exports.parseIepEquipmentId = void 0;
exports.parseIepEquipmentId = (companyId) => {
    if (!companyId)
        return { status: "success", equipmentIdNumber: "", equipmentIdPrefix: "" };
    // Prefix:
    //  - 3-4 characters
    //  - Alpha only
    //  - End with Z or C
    // Suffix:
    //  - Numeric only
    const match = companyId
        .toUpperCase()
        .replace(" ", "")
        .match(/^([A-Z]{3,4})([0-9]+)$/);
    if (!match)
        return {
            status: "error",
            reason: "Must begin with 3 or 4 alpha characters and end with number",
        };
    let [, prefix, id] = match;
    if (prefix.length !== 3 && prefix.length !== 4)
        return { status: "error", reason: "Must have prefix length of 3 or 4" };
    if (["Z", "C"].indexOf(prefix[prefix.length - 1]) === -1) {
        if (prefix.length === 3)
            prefix = prefix + "Z";
        else if (prefix.length === 4)
            prefix = prefix.substring(0, 3) + "Z";
    }
    id = id.substring(0, 11 - prefix.length - 1);
    return {
        status: "success",
        equipmentIdPrefix: prefix,
        equipmentIdNumber: id,
    };
};
// Return equipmentId as-is unless IC, in which case parse it out and return "XXX YYYY" format
exports.formatCompanyId = (unitTypeCode, equipmentId) => {
    if (unitTypeCode !== "IC")
        return equipmentId;
    const parsed = exports.parseIepEquipmentId(equipmentId);
    switch (parsed.status) {
        case "success":
            return `${parsed.equipmentIdPrefix} ${parsed.equipmentIdNumber}`;
        case "error":
            return equipmentId;
    }
};
