"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateViolations = exports.validate = void 0;
const InspectionViolationService_1 = require("../../InspectionViolationService");
exports.validate = (triggeringViolation, otherViolations) => {
    let cantCiteError;
    if (otherViolations.filter(x => x.VioFederalRegCode === triggeringViolation.VioFederalRegCode &&
        x.VehicleUnitCode === triggeringViolation.VehicleUnitCode).length > 0)
        cantCiteError = `${triggeringViolation.VioFederalRegCode} can be cited only once per driver.`;
    return cantCiteError;
};
exports.updateViolations = (triggeringViolation, violations, additionalDetails) => InspectionViolationService_1.UpdateViolation(violations, triggeringViolation.VioSeqID, Object.assign(Object.assign({}, triggeringViolation), { SuppVioDesc: additionalDetails, VioOOSFlag: triggeringViolation.VioFederalRegCode === "383.23A2" ? "Y" : "N" }));
