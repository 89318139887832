"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const allVehicleTypes = [
    ["BU", "BUS", "N"],
    ["CL", "CRIB-TYPE LOG TRAILER", "N"],
    ["DC", "DOLLY CONVERTER", "N"],
    ["FT", "FULL TRAILER", "Y"],
    ["IC", "INTERMODAL CHASSIS", "Y"],
    ["LM", "LIMOUSINE", "N"],
    ["MC", "MOTORCOACH", "N"],
    ["OT", "OTHER", "Y"],
    ["PT", "POLE TRAILER", "Y"],
    ["SB", "SCHOOL BUS", "N"],
    ["ST", "SEMI TRAILER", "Y"],
    ["TR", "STRAIGHT TRUCK", "Y"],
    ["TT", "TRUCK TRACTOR", "N"],
    ["VN", "PASSENGER VAN", "N"],
];
exports.default = {
    getVehicleUnitTypes: () => allVehicleTypes.map((row) => ({
        unitType: row[0],
        description: row[1],
        cargoSealAllowed: row[2] === "Y",
    })),
};
