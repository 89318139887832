"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifyText = exports.localFieldMap = exports.ucrUrl = exports.vehicleQueueEnabled = exports.allowTransmit = exports.testMode = exports.demoMode = exports.cviewState = exports.debugMode = exports.siteTag = exports.baseUrl = exports.cviewUrl = void 0;
const process_1 = require("process");
const stageUcrUrl = "https://admin.stage.ucr.gov";
const prodUcrUrl = "https://admin.ucr.gov";
const kyVerifyText = `Attention Motor Carrier: If the inspection indicates violations/defects on the driver and or vehicle a signed copy of the inspection shall be returned within 15
days to the address shown at the top left of the inspection or a pdf copy may be emailed to cveinspections@ky.gov.

An inspection absent any type of violation/defect does not need to be returned.

Motor carriers are required to keep a copy of this inspection on file for 12 months from the date of inspection {49 CFR 396.9(d)(3)(ii)}.

NOTE: Drivers or carriers may challenge the accuracy or validity of a commercial vehicle inspection, including the issuance of an Out of Service Order, by
contacting the Federal Motor carrier Safety Administration (FMCSA) at: https://dataqs.fmcsa.dot.gov

If your citation for a fatigue and or illness violation is dismissed by a prosecutor or judge for lack of probable cause, or you are acquitted of the charge, you
can make application through the DataQ system to have the related out of service order rescinded.

The undersigned certifies that all violations noted have been corrected and compliance achieved with the Federal Motor Carrier Safety and / or Federal
Hazardous Materials Regulations as applicable to motor carriers and drivers.`;
const defaultVerifyText = `Attention Motor Carrier: If the inspection indicates violations/defects on the driver and or vehicle a signed copy of the inspection shall be returned within 15
days to the address shown at the top left of the inspection.

An inspection absent any type of violation/defect does not need to be returned.

Motor carriers are required to keep a copy of this inspection on file for 12 months from the date of inspection {49 CFR 396.9(d)(3)(ii)}.

NOTE: Drivers or carriers may challenge the accuracy or validity of a commercial vehicle inspection, including the issuance of an Out of Service Order, by
contacting the Federal Motor carrier Safety Administration (FMCSA) at: https://dataqs.fmcsa.dot.gov

If your citation for a fatigue and or illness violation is dismissed by a prosecutor or judge for lack of probable cause, or you are acquitted of the charge, you
can make application through the DataQ system to have the related out of service order rescinded.

The undersigned certifies that all violations noted have been corrected and compliance achieved with the Federal Motor Carrier Safety and / or Federal
Hazardous Materials Regulations as applicable to motor carriers and drivers.`;
const configs = {
    localdev: {
        cviewUrl: "http://localhost:3001/ui/login",
        // baseUrl: "http://localhost:8080",
        baseUrl: "http://localhost:3000",
        siteTag: "mstest",
        // siteTag: "kytest",
        debugMode: true,
        demoMode: true,
        cviewState: "MS",
        // cviewState: "KY",
        testMode: true,
        allowTransmit: true,
        // allowTransmit: false,
        vehicleQueueEnabled: true,
        ucrUrl: stageUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
    // KY test
    kytest: {
        siteTag: "kytest",
        cviewState: "KY",
        cviewUrl: "https://cviewtest.kytc.ky.gov/ui/login",
        baseUrl: "https://cviewtest.kytc.ky.gov",
        debugMode: true,
        demoMode: true,
        testMode: true,
        allowTransmit: true,
        vehicleQueueEnabled: false,
        ucrUrl: stageUcrUrl,
        // TODO: Better for this to come from server
        localFieldMap: {
            hm1UnNaNumber: "UN/NA Number",
            hm2UnNaNumber: "Un/NA Number Two",
            hm3UnNaNumber: "UN/NA Number Three",
            hm1ProperShippingName: "Proper Shipping Name",
            hm2ProperShippingName: "Proper Shipping Number Two",
            hm3ProperShippingName: "Proper Shipping Number Three",
            cargoTankInspection: "Cargo Tank Inspection",
            bulkPackage: "Bulk Packaging",
        },
        verifyText: kyVerifyText,
    },
    // KY prod
    ky: {
        cviewUrl: "https://cview.kytc.ky.gov/ui/login",
        baseUrl: "https://cview.kytc.ky.gov",
        siteTag: "ky",
        debugMode: false,
        cviewState: "KY",
        demoMode: false,
        testMode: false,
        allowTransmit: true,
        vehicleQueueEnabled: false,
        ucrUrl: prodUcrUrl,
        // TODO: Better for this to come from server
        localFieldMap: {
            hm1UnNaNumber: "UN/NA Number",
            hm2UnNaNumber: "Un/NA Number Two",
            hm3UnNaNumber: "UN/NA Number Three",
            hm1ProperShippingName: "Proper Shipping Name",
            hm2ProperShippingName: "Proper Shipping Number Two",
            hm3ProperShippingName: "Proper Shipping Number Three",
            cargoTankInspection: "Cargo Tank Inspection",
            bulkPackage: "Bulk Packaging",
        },
        verifyText: kyVerifyText,
    },
    // MS test
    mstest: {
        cviewUrl: "https://ms.test.cviewlive.com",
        baseUrl: "https://api.test.cviewlive.com",
        siteTag: "mstest",
        cviewState: "MS",
        debugMode: false,
        // demoMode: true,
        demoMode: false,
        testMode: true,
        allowTransmit: true,
        vehicleQueueEnabled: true,
        ucrUrl: stageUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
    // MS prod
    ms: {
        cviewUrl: "https://ms.cviewlive.com",
        baseUrl: "https://api.cviewlive.com",
        siteTag: "ms",
        cviewState: "MS",
        debugMode: false,
        demoMode: false,
        testMode: false,
        allowTransmit: true,
        vehicleQueueEnabled: true,
        ucrUrl: prodUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
    // DE test
    detest: {
        cviewUrl: "https://demo.cviewlive.com",
        baseUrl: "https://api.test.cviewlive.com",
        siteTag: "detest",
        cviewState: "DE",
        debugMode: false,
        demoMode: false,
        // Off for certification
        testMode: false,
        allowTransmit: true,
        vehicleQueueEnabled: true,
        ucrUrl: stageUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
    // DE prod
    de: {
        cviewUrl: "https://de.cviewlive.com",
        baseUrl: "https://api.cviewlive.com",
        siteTag: "de",
        cviewState: "DE",
        debugMode: false,
        demoMode: false,
        testMode: false,
        allowTransmit: true,
        vehicleQueueEnabled: true,
        ucrUrl: prodUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
    // TN test
    tntest: {
        cviewUrl: "https://tn.test.cviewlive.com",
        baseUrl: "https://api.test.cviewlive.com",
        siteTag: "tntest",
        cviewState: "TN",
        debugMode: false,
        demoMode: false,
        testMode: false,
        allowTransmit: true,
        vehicleQueueEnabled: false,
        ucrUrl: stageUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
    // TN prod
    tn: {
        cviewUrl: "https://tn.cviewlive.com",
        baseUrl: "https://api.cviewlive.com",
        siteTag: "tn",
        cviewState: "TN",
        debugMode: false,
        demoMode: false,
        testMode: false,
        allowTransmit: true,
        vehicleQueueEnabled: false,
        ucrUrl: prodUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
    // VA test
    vatest: {
        cviewUrl: "https://va.test.cviewlive.com",
        baseUrl: "https://api.test.cviewlive.com",
        siteTag: "vatest",
        cviewState: "VA",
        debugMode: false,
        demoMode: true,
        testMode: true,
        allowTransmit: true,
        vehicleQueueEnabled: false,
        ucrUrl: stageUcrUrl,
        localFieldMap: {},
        verifyText: defaultVerifyText,
    },
};
// Choose which config to use
// Set RIMS_VERSION to kytest, ky, etc., or default to localdev
const config = (process_1.env.RIMS_VERSION && configs[process_1.env.RIMS_VERSION]) || configs.mstest;
exports.cviewUrl = config.cviewUrl, exports.baseUrl = config.baseUrl, exports.siteTag = config.siteTag, exports.debugMode = config.debugMode, exports.cviewState = config.cviewState, exports.demoMode = config.demoMode, exports.testMode = config.testMode, exports.allowTransmit = config.allowTransmit, exports.vehicleQueueEnabled = config.vehicleQueueEnabled, exports.ucrUrl = config.ucrUrl, exports.localFieldMap = config.localFieldMap, exports.verifyText = config.verifyText;
