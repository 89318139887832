"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Labeled_1 = require("../core/Labeled");
const select_1 = require("@blueprintjs/select");
const core_1 = require("@blueprintjs/core");
const classNames = require("classnames");
const CargoSuggest = select_1.Suggest.ofType();
const renderCargo = (item, { handleClick, modifiers, query }) => {
    const classes = classNames({
        [core_1.Classes.ACTIVE]: modifiers.active,
        [core_1.Classes.INTENT_PRIMARY]: modifiers.active,
    });
    return (React.createElement(core_1.MenuItem, { className: classes, key: item, onClick: handleClick, text: item }));
};
class CargoSelector extends React.Component {
    constructor(props) {
        super(props);
        this.updateCargo = (cargo) => {
            const upperCargo = cargo.toUpperCase();
            this.props.onChange(upperCargo);
            this.setState({
                filteredCargos: upperCargo.length > 0
                    ? this.state.cargos.filter((x) => x.indexOf(upperCargo) >= 0)
                    : this.state.cargos,
            });
        };
        this.onChange = (query, e) => this.updateCargo(query.toUpperCase());
        this.onSelected = (item, event) => this.updateCargo((item || "").toUpperCase());
        this.state = {
            cargos: [],
            filteredCargos: [],
        };
    }
    componentDidMount() {
        this.updateCargoList();
    }
    render() {
        const dropdown = (React.createElement(CargoSuggest, { inputProps: {
                placeholder: "",
                style: { width: 200 },
                maxLength: 25,
            }, onQueryChange: this.onChange, selectedItem: this.props.value || "", itemRenderer: renderCargo, inputValueRenderer: val => val, items: this.state.filteredCargos, onItemSelect: this.onSelected, openOnKeyDown: false, popoverProps: {
                popoverClassName: core_1.Classes.MINIMAL,
                position: core_1.PopoverPosition.RIGHT,
            }, noResults: React.createElement(core_1.MenuItem, { disabled: true, text: "No results." }) }));
        if (this.props.label)
            return (React.createElement(Labeled_1.Labeled, { label: this.props.label },
                React.createElement("div", null, dropdown)));
        else
            return dropdown;
    }
    updateCargoList() {
        const cargos = [
            "ASPHALT",
            "AUTO PARTS",
            "BEVERAGES",
            "BUILDING MATERIALS",
            "CHEMICALS",
            "COAL / COKE",
            "COMMODITIES, DRY BULK",
            "DRIVEAWAY / TOWAWAY",
            "EMPTY",
            "EMPTY BINS",
            "FRESH PRODUCE",
            "GARBAGE, REFUSE, TRASH",
            "GENERAL FREIGHT",
            "GRAIN, FEED, HAY",
            "HAZARDOUS MATERIALS",
            "HOUSEHOLD GOODS",
            "INTERMODAL",
            "LIQUIDS/GASES IN TANKS",
            "LIVESTOCK",
            "LOGS, POLES, LUMBER",
            "MACHINERY, LARGE OBJECT",
            "MEAT",
            "METAL: SHEETS, COILS",
            "MOBILE HOMES",
            "MOTOR VEHICLES",
            "OILFIELD EQUIPMENT",
            "OTHER",
            "PAPER PRODUCTS",
            "PASSENGER",
            "RACKS",
            "REFRIGERATED FOODS",
            "SALT",
            "SAND/GRAVEL",
            "U.S. MAILER",
        ];
        this.setState({ cargos, filteredCargos: cargos });
    }
}
exports.default = CargoSelector;
