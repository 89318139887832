"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateViolations = exports.validate = void 0;
const InspectionViolationService_1 = require("../../InspectionViolationService");
const DateFormat_1 = require("../../../../services/DateFormat");
exports.validate = (violationDate, otherViolations) => {
    let violationError;
    if (otherViolations.filter(x => x.VioFederalRegCode && x.VioFederalRegCode.startsWith("395.8")).length > 0)
        violationError =
            "A violation of §395.8(a) cannot be cited with any other §395.8 violation.";
    if (otherViolations.filter(x => x.VioFederalRegCode === "395.3A3II" &&
        x.SuppVioDesc &&
        x.SuppVioDesc.indexOf(DateFormat_1.formatDate(violationDate)) >= 0).length > 0)
        violationError =
            "You cannot cite this violation with a §395.3(a)(3)(ii) violation on the same date.";
    return {
        error: violationError,
        prompts: {
            additionalDetails: !violationError,
        },
        evidenceMessage: violationError === undefined
            ? 'A "No Log" violation may not be used to document an HOS violation unless independent evidence exists to cite concurrent hours violation.'
            : undefined,
    };
};
exports.updateViolations = (triggeringViolation, violations, additionalDetails, violationDate) => InspectionViolationService_1.UpdateViolation(violations, triggeringViolation.VioSeqID, Object.assign(Object.assign({}, triggeringViolation), { VioOOSFlag: "Y", SuppVioDesc: additionalDetails, date: violationDate }));
