"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.outputPdf = exports.formatDate = void 0;
const pdfmake_1 = require("pdfmake/build/pdfmake");
const PdfMakeVfsFonts_1 = require("../../services/inspection-rendering/PdfMakeVfsFonts");
exports.formatDate = (d) => {
    const str = d.toISOString().substring(0, 10);
    const parts = str.split("-");
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
};
const hr = (width) => {
    const margin = [0, 1, 0, 1];
    const lineWidth = width || 0.5;
    return {
        margin: margin,
        canvas: [
            {
                type: "line",
                x1: 0,
                y1: 0,
                x2: 535,
                y2: 0,
                lineWidth,
            },
        ],
    };
};
const avg = (total, numSamples) => numSamples === 0 ? "0" : Math.round(total / numSamples).toFixed(0);
const pct = (numerator, denominator) => (denominator === 0
    ? 0
    : Math.round((numerator / denominator) * 10000) / 100) + "%";
const ct = (text) => ({ text, border: [false, false, false, false] });
const cct = (text) => (Object.assign(Object.assign({}, ct(text)), { margin: [0, 0, 20, 0] }));
const cvt = (text) => (Object.assign(Object.assign({}, ct(text)), { alignment: "center" }));
const getDefinition = (report) => {
    const r = report.result.rows[0];
    return {
        pageSize: "letter",
        content: [
            {
                text: "INSPECTION SUMMARY REPORT",
                style: "title",
            },
            hr(2),
            {
                table: {
                    widths: ["*", "*"],
                    body: [
                        [
                            ct(`Time Period: ${exports.formatDate(report.request.start)} - ${exports.formatDate(report.request.end)}`),
                            Object.assign(Object.assign({}, ct(`Total Inspections: ${r["# Inspections"]}`)), { alignment: "right" }),
                        ],
                    ],
                },
            },
            hr(2),
            {
                table: {
                    widths: ["*", "*"],
                    body: [
                        [
                            ct(`Inspector: ${r["name"]}`),
                            Object.assign(Object.assign({}, ct(`Badge #: ${r["badge"]}`)), { alignment: "right" }),
                        ],
                    ],
                },
            },
            hr(),
            {
                text: "Inspection Type Breakdown",
                style: "header",
            },
            {
                table: {
                    headerRows: 0,
                    widths: ["*", "auto", "*", "auto"],
                    body: [
                        [
                            ct("Total HM Inspections:"),
                            cct(r["# HM Bulk"] + r["# HM Combined"] + r["# HM Non-Bulk"]),
                            ct("Inspections Resulting in OOS:"),
                            cct(r["# OOS"]),
                        ],
                        [
                            ct("... # HM Bulk:"),
                            cct(r["# HM Bulk"]),
                            ct("... # Driver/Co-Driver OOS:"),
                            cct(r["# OOS Driver"]),
                        ],
                        [
                            ct("... # HM Non-Bulk:"),
                            ct(r["# HM Non-Bulk"]),
                            ct("... # Vehicle OOS:"),
                            ct(r["# OOS Vehicle"]),
                        ],
                        [
                            ct("... # Both HM Bulk & Non-Bulk:"),
                            cct(r["# HM Combined"]),
                            ct("# & % of U.S. Motor Carriers:"),
                            cct(r["# US"]),
                        ],
                        [
                            ct("Total Single-Unit Trucks:"),
                            cct(r["# Single Truck"]),
                            ct("# & % of Mexican Motor Carriers:"),
                            cct(r["# MX"]),
                        ],
                        [
                            ct("Total PVI Inspections:"),
                            cct(r["# PVI"]),
                            ct("# & % of Canadian Motor Carriers:"),
                            cct(r["# CA"]),
                        ],
                    ],
                },
            },
            hr(),
            {
                text: "Inspection Level Breakdown",
                style: "header",
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*", "auto", "auto", "auto", "auto", "auto", "auto", "auto"],
                    body: [
                        [
                            ct(""),
                            Object.assign(Object.assign({}, ct("Level 1")), { style: "levelHeader" }),
                            Object.assign(Object.assign({}, ct("Level 2")), { style: "levelHeader" }),
                            Object.assign(Object.assign({}, ct("Level 3")), { style: "levelHeader" }),
                            Object.assign(Object.assign({}, ct("Level 4")), { style: "levelHeader" }),
                            Object.assign(Object.assign({}, ct("Level 5")), { style: "levelHeader" }),
                            Object.assign(Object.assign({}, ct("Level 6")), { style: "levelHeader" }),
                            Object.assign(Object.assign({}, ct("Level 7")), { style: "levelHeader" }),
                        ],
                        [
                            ct("Inspections"),
                            cvt(r["lvl 1"]),
                            cvt(r["lvl 2"]),
                            cvt(r["lvl 3"]),
                            cvt(r["lvl 4"]),
                            cvt(r["lvl 5"]),
                            cvt(r["lvl 6"]),
                            cvt(r["lvl 7"]),
                        ],
                        [
                            ct("% of Total"),
                            cvt(pct(r["lvl 1"], r["# Inspections"])),
                            cvt(pct(r["lvl 2"], r["# Inspections"])),
                            cvt(pct(r["lvl 3"], r["# Inspections"])),
                            cvt(pct(r["lvl 4"], r["# Inspections"])),
                            cvt(pct(r["lvl 5"], r["# Inspections"])),
                            cvt(pct(r["lvl 6"], r["# Inspections"])),
                            cvt(pct(r["lvl 7"], r["# Inspections"])),
                        ],
                        [
                            ct("Total Minutes"),
                            cvt(r["lvl 1 Time"]),
                            cvt(r["lvl 2 Time"]),
                            cvt(r["lvl 3 Time"]),
                            cvt(r["lvl 4 Time"]),
                            cvt(r["lvl 5 Time"]),
                            cvt(r["lvl 6 Time"]),
                            cvt(r["lvl 7 Time"]),
                        ],
                        [
                            ct("Average Minutes"),
                            cvt(avg(r["lvl 1 Time"], r["lvl 1"])),
                            cvt(avg(r["lvl 2 Time"], r["lvl 2"])),
                            cvt(avg(r["lvl 3 Time"], r["lvl 3"])),
                            cvt(avg(r["lvl 4 Time"], r["lvl 4"])),
                            cvt(avg(r["lvl 5 Time"], r["lvl 5"])),
                            cvt(avg(r["lvl 6 Time"], r["lvl 6"])),
                            cvt(avg(r["lvl 7 Time"], r["lvl 7"])),
                        ],
                    ],
                },
            },
            hr(),
            {
                text: "Inspection Analysis",
                style: "header",
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*", "auto", "*", "auto"],
                    body: [
                        [
                            ct("Total Violations Cited:"),
                            cct(r["# Violations"]),
                            ct("% of OOS Drivers/Co-Drivers:"),
                            cct(pct(r["# OOS Driver"], r["lvl 1"] + r["lvl 2"] + r["lvl 3"])),
                        ],
                        [
                            ct("Avg. Violations Per Inspection:"),
                            cct(avg(r["# Violations"], r["# Inspections"])),
                            ct("% of OOS Vehicles:"),
                            cct(pct(r["# OOS Vehicle"], r["lvl 1"] + r["lvl 2"])),
                        ],
                        [
                            ct("Total Citations Issued:"),
                            cct(r["# Citations"]),
                            ct("Total OOS Violations:"),
                            cct(r["# OOS Violations"]),
                        ],
                        [
                            ct("Total CVSA Stickers Issued:"),
                            cct(r["# Decals"]),
                            ct("Total OOS Drivers/Co-Drivers:"),
                            cct(r["# OOS Driver"]),
                        ],
                        [
                            ct("Total Cargo Tanks Inspected:"),
                            cct(r["# Cargo Tanks"]),
                            ct("Total OOS Vehicles:"),
                            cct(r["# OOS Vehicle"]),
                        ],
                    ],
                },
            },
            hr(),
            {
                text: "Special Checks",
                style: "header",
            },
            {
                table: {
                    headerRows: 1,
                    widths: ["*", "auto", "*", "auto"],
                    body: [
                        [
                            ct("Size And Weight Enforcement:"),
                            cct(r["# Size/Weight Enf"]),
                            ct("Traffic Enforcement:"),
                            cct(r["# Traffic Enf"]),
                        ],
                        [
                            ct("Alcohol Substance Check:"),
                            cct(r["# Alcohol/Substance"]),
                            ct("Local Enforcement:"),
                            cct(r["# Local Enf"]),
                        ],
                        [
                            ct("Drug Interdiction Search:"),
                            cct(r["# Drug Search"]),
                            ct("Drug Interdiction Arrests:"),
                            cct(r["# Drug Arrest"]),
                        ],
                        [
                            ct("Post Crash:"),
                            cct(r["# Post Crash"]),
                            ct("PBBT Inspections:"),
                            cct(r["# PBBT"]),
                        ],
                        [ct("BEG Inspections:"), cct(r["# Border Grant"]), ct(""), ct("")],
                    ],
                },
            },
        ],
        footer: {
            text: "Page 1 of 1",
            alignment: "center",
        },
        styles: {
            title: {
                bold: true,
                fontSize: 20,
                alignment: "center",
                margin: [0, 0, 0, 15],
            },
            header: {
                decoration: "underline",
                fontSize: 18,
                margin: [0, 5, 0, 5],
            },
            levelHeader: {
                decoration: "underline",
            },
        },
        defaultStyle: {
            font: "Arial",
            fontSize: 14,
        },
    };
};
exports.outputPdf = (report) => {
    return new Promise((resolve, reject) => {
        const definition = getDefinition(report);
        const pdf = pdfmake_1.createPdf(definition, {}, {
            Arial: {
                normal: "arial.ttf",
                bold: "arialbd.ttf",
            },
        }, PdfMakeVfsFonts_1.PdfMakeVfsFonts);
        pdf.getBuffer((buffer) => resolve(buffer));
    });
};
