"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateViolations = exports.validate = void 0;
const InspectionViolationService_1 = require("../../InspectionViolationService");
const DateFormat_1 = require("../../../../services/DateFormat");
const SmartLogicFunctions_1 = require("./SmartLogicFunctions");
exports.validate = (startTime, violationDate, otherViolations, concealment) => {
    let violationDateError;
    let cantCiteError;
    let evidenceMessage;
    let concealmentError;
    let promptConcealment = false;
    let promptAdditionalDetails = false;
    if (otherViolations.filter(x => x.VioFederalRegCode === "395.8E" && DateFormat_1.isSameDate(x.date, violationDate)).length > 0)
        cantCiteError =
            "You cannot cite this violation with a §395.8(e) Violation on the same violation date.";
    if (!violationDateError &&
        otherViolations.filter(x => SmartLogicFunctions_1.is395_8A(x) && DateFormat_1.isSameDate(x.date, violationDate)).length > 0)
        violationDateError =
            "You cannot cite this violation with a §395.8(a) Violation on the same violation date.";
    if (!cantCiteError && !violationDateError) {
        evidenceMessage =
            "A false log may not be used to document an HOS violation. Ensure that independent evidence exists to cite concurrent hours violation.";
        promptConcealment = true;
        promptAdditionalDetails = true;
        if (!concealment)
            concealmentError = "Concealment flag required";
    }
    return {
        errors: {
            concealmentError,
            cantCiteError,
            violationDateError,
        },
        prompts: {
            concealment: promptConcealment,
            additionalDetails: promptAdditionalDetails,
        },
        evidenceMessage,
    };
};
exports.updateViolations = (violationDate, triggeringViolation, violations, additionalDetails, concealment) => InspectionViolationService_1.UpdateViolation(violations, triggeringViolation.VioSeqID, Object.assign(Object.assign({}, triggeringViolation), { SuppVioDesc: additionalDetails, date: violationDate, VioOOSFlag: concealment === "Y" ? "Y" : "N" }));
