"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayPdfFromT0033 = exports.writePdf = exports.inspectionToPdf = exports.t0033ToPdf = void 0;
const T0033Converter_1 = require("./T0033Converter");
// MIGRATE_TODO
// import { shell } from "electron";
// import * as fs from "fs";
// import * as os from "os";
// import * as path from "path";
const ChamberTypes_1 = require("../../components/inspection/brakes/ChamberTypes");
// MIGRATE_TODO
// import { getLogoPath } from "../../pathConfig";
// MIGRATE_TODO
// import { siteTag } from "../../config";
// const pdfService = require("./InspectionToPdfService");
const InspectionToPdfService_1 = require("./InspectionToPdfService");
exports.t0033ToPdf = (t0033, options) => __awaiter(void 0, void 0, void 0, function* () {
    options.brakeOos = options.brakeOos || ChamberTypes_1.measurementIsOutOfRange;
    options.brakeWarning = options.brakeWarning || ChamberTypes_1.measurementIsWarning;
    const content = yield InspectionToPdfService_1.outputPdf(t0033, options);
    return content;
});
exports.inspectionToPdf = (inspection, options) => __awaiter(void 0, void 0, void 0, function* () {
    const t0033 = T0033Converter_1.toT0033(inspection);
    const buffer = yield exports.t0033ToPdf(t0033, options);
    return buffer;
});
exports.writePdf = (t0033, options) => __awaiter(void 0, void 0, void 0, function* () {
    // MIGRATE_TODO
    //options.brakeOos = options.brakeOos || measurementIsOutOfRange;
    // MIGRATE_TODO
    //options.brakeWarning = options.brakeWarning || measurementIsWarning;
    // MIGRATE_TODO
    //options.base64Logo = getLogoPath(siteTag, t0033.INSPECTION_STATE);
    // Normally print to {reportnumber}.pdf, but allow printing to other paths depending on options set
    // We shouldn't really have to do this, but it may be useful to have predictable paths later..
    // MIGRATE_TODO
    const content = yield InspectionToPdfService_1.outputPdf(t0033, options);
    //const content: Buffer = await pdfService.outputPdf(t0033, options);
    // let pathToken = "";
    // if (options.mode === "iep") pathToken = "_iep";
    // const tempName = path.join(
    //   os.tmpdir(),
    //   `${t0033.INSPECTION_RPTNUM}${pathToken}.pdf`,
    // );
    // fs.writeFileSync(tempName, content);
    // return tempName;
    return content;
});
exports.displayPdfFromT0033 = (t0033, options) => __awaiter(void 0, void 0, void 0, function* () {
    throw Error("not implemented");
    // MIGRATE_TODO
    // const pdfPath = await writePdf(t0033, options);
    // shell.openItem(pdfPath);
});
// MIGRATE_TODO
// export const generatePdf = async (
//   inspection: Inspection,
//   options: PdfOptions,
// ) => {
//   const t0033 = toT0033(inspection);
//   const pdfPath = await writePdf(t0033, options);
//   return pdfPath;
// };
// MIGRATE_TODO
// export const displayPdf = async (
//   inspection: Inspection,
//   options: PdfOptions,
// ) => {
//   throw Error("not implemented");
// const t0033 = toT0033(inspection);
// await displayPdfFromT0033(t0033, options);
// };
