"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_flexbox_grid_1 = require("react-flexbox-grid");
const classNames = require("classnames");
const core_1 = require("@blueprintjs/core");
class SpecialStudies extends React.Component {
    constructor() {
        super(...arguments);
        this.studyUpdated = (index, text) => {
            const studies = Object.assign([], this.props.studies);
            studies[index] = studies[index] || { StudyText: "" };
            studies[index].StudyText = text;
            this.props.onStudiesChanged(studies);
        };
    }
    render() {
        const { studies } = this.props;
        return (React.createElement(react_flexbox_grid_1.Grid, { fluid: true }, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => {
            const study = studies[i] || { StudyText: "" };
            return (React.createElement(react_flexbox_grid_1.Row, { key: i },
                React.createElement(react_flexbox_grid_1.Col, { sm: 1 },
                    "#",
                    i + 1,
                    ":"),
                React.createElement(react_flexbox_grid_1.Col, { sm: 11 },
                    React.createElement("input", { type: "text", className: classNames(core_1.Classes.INPUT, core_1.Classes.FILL), value: study.StudyText, onChange: e => this.studyUpdated(i, e.currentTarget.value), maxLength: 40 }))));
        })));
    }
}
exports.default = SpecialStudies;
