"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetPostCrash = exports.UpdateViolation = exports.AddViolation = exports.RemoveViolation = void 0;
exports.RemoveViolation = (seqId, violations) => {
    // Remove violation
    let newViolations = violations.filter(v => v.VioSeqID !== seqId);
    // Decrement higher-numbered violations
    for (let i = 0; i < newViolations.length; ++i) {
        if (newViolations[i].VioSeqID > seqId)
            newViolations[i].VioSeqID--;
    }
    return newViolations;
};
exports.AddViolation = (violations, source, unitNumber, newViolation) => {
    const newSeqId = violations.length + 1;
    return [
        ...violations,
        Object.assign(Object.assign({}, newViolation), { VioSeqID: newSeqId, StateCitation: {
                CitationIssued: "N",
            }, VehicleUnitCode: unitNumber ? unitNumber.toString() : undefined, VehicleDefectActionCode: "N", source }),
    ];
};
exports.UpdateViolation = (violations, seqId, updatedViolation) => {
    let newViolations = [...violations];
    for (var i = 0; i < newViolations.length; ++i)
        if (newViolations[i].VioSeqID === seqId)
            newViolations[i] = updatedViolation;
    return newViolations;
};
exports.ResetPostCrash = (violations) => {
    let newViolations = [...violations];
    for (var i = 0; i < newViolations.length; ++i)
        newViolations[i].VioCrashRelatedFlag = "N";
    return newViolations;
};
