import { RemoteCityService, mapLongCountryToShort } from "@rims/common";
import { Semaphore } from "prex";

const cacheLock = new Semaphore(1);

const cache: { [key: string]: string[] } = {};

export const getCities = async (
  longCountryCode: string,
  state: string,
  filter?: string,
) => {
  const shortCountryCode = mapLongCountryToShort(longCountryCode);

  if (shortCountryCode === longCountryCode)
    console.warn(
      "getCities(): longCountryCode passed by caller is a short country code",
    );

  const key = shortCountryCode + "," + state;

  await cacheLock.wait();
  if (!cache[key]) {
    const cities = await RemoteCityService.listCities(shortCountryCode, state);
    cache[key] = cities;
  }

  cacheLock.release();

  return cache[key].filter((x) => !filter || x.includes(filter));
};
