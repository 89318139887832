"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
let credentials = undefined;
const CdlisCredentialsService = {
    getCredentials: () => credentials,
    setCredentials: (c) => {
        credentials = c;
    },
    clearCredentials: () => {
        credentials = undefined;
    },
    hasCredentials: () => !!credentials,
};
exports.default = CdlisCredentialsService;
