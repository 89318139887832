"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
class GroupList extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement(core_1.HTMLSelect, { defaultValue: "", onChange: e => {
                if (e.currentTarget.value === "")
                    this.props.onGroupSelected(undefined);
                else
                    this.props.onGroupSelected(this.props.groups.filter(x => x.id === parseInt(e.currentTarget.value))[0]);
            } },
            React.createElement("option", { value: "" }, "Choose a group"),
            this.props.groups
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((group, i) => (React.createElement("option", { value: group.id, key: i }, group.name)))));
    }
}
exports.default = GroupList;
