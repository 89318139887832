"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoteInspectionService = void 0;
const CoreService_1 = require("../api/CoreService");
const InspectionSerializer_1 = require("./InspectionSerializer");
const datefns = __importStar(require("date-fns"));
const config_1 = require("../../config");
const Serialization_1 = require("./Serialization");
// MIGRATE_TODO
// import { LocalSnetService } from "../../local-services/LocalSnetService";
const ensureTransmit = () => {
    if (!config_1.allowTransmit)
        throw Error("Transmit disabled");
};
const normalizeSearchInspectionsResponse = (response) => {
    for (const inspection of response.inspections) {
        inspection.localTime = datefns.parse(inspection.localTime);
    }
    return response;
};
const normalizeSaveInspectionsResponse = (response) => {
    response.localTime = datefns.parse(response.localTime);
    return response;
};
const formatResponse = (response) => {
    const inspection = (response.localInspection &&
        Serialization_1.deserializeInspectionJson(response.localInspection)) ||
        undefined;
    const t0033 = response.t0033 ? JSON.parse(response.t0033) : undefined;
    console.log("localInspection", inspection);
    return {
        localInspection: inspection,
        t0018: response.t0018,
        status: response.status,
        localTime: datefns.parse(response.localTime),
        version: response.version,
        t0033: t0033,
        submittedStartTime: response.submittedStartTime,
    };
};
exports.RemoteInspectionService = {
    searchInspections: (params) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.get("inspections", params);
        if (response !== "notFound")
            return normalizeSearchInspectionsResponse(response);
        throw Error("Error query inspections");
    }),
    getInspectionStatuses: (inspectionIds) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.post("find-inspections", undefined, { inspectionIds: inspectionIds, apiversion: 1 });
        if (response !== "notFound")
            return normalizeSearchInspectionsResponse(response);
        throw Error("Error query inspections");
    }),
    saveInspection: (inspection, status, localTime, coInspectors, version) => __awaiter(void 0, void 0, void 0, function* () {
        ensureTransmit();
        const serializedXml = InspectionSerializer_1.serializeXml(inspection, 
        // MIGRATE_TODO
        // LocalSnetService.getLocalFields(),
        [], config_1.localFieldMap);
        // MIGRATE_TODO
        const serializedJson = Serialization_1.serializeInspection(inspection);
        const response = yield CoreService_1.CoreService.post(`inspection/${inspection.id}`, undefined, {
            inspectionStatus: status,
            internalInspection: serializedJson,
            t0018: serializedXml,
            localTime: datefns.format(localTime),
            coInspectors,
            version,
        });
        if (response !== "notFound")
            return normalizeSaveInspectionsResponse(response);
        throw Error("Error saving inspections");
    }),
    getRimsInspection: (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.get(`inspection/${inspectionId}`);
        if (response === "notFound")
            throw Error(`inspection ${inspectionId} not found`);
        return formatResponse(response);
    }),
    getInspection: (inspectionId, inspectionTime) => __awaiter(void 0, void 0, void 0, function* () {
        // Replace period and everything after from timestamp,
        // e.g., 2019-02-19T10:15:00.0000000 becomes 2019-02-19T10:15:00
        // TODO: figure out why we need to do this. If not, server may return 404.
        const normalizedTime = inspectionTime.replace(/\..*/, "");
        const response = yield CoreService_1.CoreService.get(`inspection/${inspectionId}/${normalizedTime}`);
        if (response === "notFound")
            throw Error(`inspection ${inspectionId} not found`);
        return formatResponse(response);
    }),
    uploadAttachment: (inspectionId, attachmentData, attachmentBlob) => __awaiter(void 0, void 0, void 0, function* () {
        ensureTransmit();
        const formData = new FormData();
        formData.append("file", attachmentBlob);
        formData.append("data", JSON.stringify(attachmentData));
        const response = yield CoreService_1.CoreService.post(`inspection/${inspectionId}/attachment`, undefined, formData, {
            "Content-Type": "multipart/form-data",
        });
        if (response === "notFound")
            throw Error("Can't upload attachment. 404");
        return response;
    }),
    listAttachments: (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.get(`inspection/${inspectionId}/attachment`);
        if (response === "notFound")
            throw Error("Can't list attachments. 404");
        return response;
    }),
    deleteInspection: (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
        yield CoreService_1.CoreService.delete(`inspection/${inspectionId}`);
    }),
};
