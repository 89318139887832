"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnitCodes = void 0;
const getUnitCodes = (inspection, includeVehicles = true, includeDrivers = true) => {
    let unitNumbers = [];
    if (includeVehicles)
        unitNumbers = unitNumbers.concat((inspection.Vehicles || []).map(v => ({
            value: v.VehicleUnitNum,
            label: `${v.VehicleUnitTypeCode}  ${v.VehicleMakeCode}`,
        })));
    if (includeDrivers) {
        if (inspection.Drivers.filter(d => !d.IsCoDriver && d.DriverLastName !== undefined).length > 0) {
            unitNumbers = unitNumbers.concat([{ value: "D", label: "DRIVER" }]);
        }
        if (inspection.Drivers.filter(d => d.IsCoDriver && d.DriverLastName !== undefined).length > 0) {
            unitNumbers = unitNumbers.concat([{ value: "C", label: "CO-DRIVER" }]);
        }
    }
    return unitNumbers;
};
exports.getUnitCodes = getUnitCodes;
