import {
  RemoteFederalViolationService,
  FederalViolation,
  GetFederalViolationsOptions,
} from "@rims/common";
import { Semaphore } from "prex";

const cacheLock = new Semaphore(1);

let cache: FederalViolation[] | undefined = undefined;

// localStorage.getItem

export const getFederalViolations = async (
  options?: GetFederalViolationsOptions,
): Promise<FederalViolation[]> => {
  await cacheLock.wait();

  cache = await RemoteFederalViolationService.listFederalViolations();

  cacheLock.release();

  return cache;
};
