"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
class DriverHistory extends React.Component {
    render() {
        const { driver } = this.props;
        return (React.createElement("div", null,
            React.createElement(core_1.Card, null,
                React.createElement("h5", null, "Driver General Information"),
                React.createElement(core_1.HTMLTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "First Name"),
                            React.createElement("th", null, "Middle Name"),
                            React.createElement("th", null, "Last Name"),
                            React.createElement("th", null, "Suffix"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.name.firstName),
                            React.createElement("td", null, driver.name.middleName),
                            React.createElement("td", null, driver.name.lastName),
                            React.createElement("td", null, driver.name.suffix))),
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "SSN"),
                            React.createElement("th", null, "Birth Date"),
                            React.createElement("th", null, "County"),
                            React.createElement("th", null, "State"),
                            React.createElement("th", null, "Zip"),
                            React.createElement("th", null, "Country"),
                            "C")),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.name.ssn),
                            React.createElement("td", null, driver.name.birthDate),
                            React.createElement("td", null, driver.county),
                            React.createElement("td", null, driver.state),
                            React.createElement("td", null, driver.zip),
                            React.createElement("td", null, driver.country))))),
            React.createElement(core_1.Card, null,
                React.createElement("h5", null, "Driver License Details"),
                React.createElement(core_1.HTMLTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Jurisdiction"),
                            React.createElement("th", null, "Driver License"),
                            React.createElement("th", null, "Issue Date"),
                            React.createElement("th", null, "Exp. Date"),
                            React.createElement("th", null, "Commercial Class"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.driverLicense.jurisdiction),
                            React.createElement("td", null, driver.driverLicense.licenseNumber),
                            React.createElement("td", null, driver.driverLicense.issueDate),
                            React.createElement("td", null, driver.driverLicense.expiryDate),
                            React.createElement("td", null, driver.driverLicense.commercialClass))),
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Non Commercial Class"),
                            React.createElement("th", null, "Commercial Status"),
                            React.createElement("th", null, "Non Commercial Status"),
                            React.createElement("th", null, "Withdrawal Action Pending"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.driverLicense.nonCommercialClass),
                            React.createElement("td", null, driver.driverLicense.commercialStatus),
                            React.createElement("td", null, driver.driverLicense.nonCommercialStatus),
                            React.createElement("td", null, driver.driverLicense.withdrawActionPending))))),
            React.createElement(core_1.Card, null,
                React.createElement("h5", null, "Endorsements"),
                React.createElement("ul", { className: core_1.Classes.LIST }, driver.endorsements.map((endorsement, i) => (React.createElement("li", { key: i }, endorsement))))),
            React.createElement(core_1.Card, null,
                React.createElement("h5", null, "Medical Certification"),
                React.createElement(core_1.HTMLTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Issue Date"),
                            React.createElement("th", null, "Expiration Date"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.medicalCertification.issueDate),
                            React.createElement("td", null, driver.medicalCertification.expiryDate))),
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Status Code"),
                            React.createElement("th", null, "Self Certification"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.medicalCertification.statusCode),
                            React.createElement("td", null, driver.medicalCertification.selfCertification))))),
            React.createElement(core_1.Card, null,
                React.createElement("h5", null, "Medical Exiaminer Detail"),
                React.createElement(core_1.HTMLTable, null,
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "First Name"),
                            React.createElement("th", null, "Middle Name"),
                            React.createElement("th", null, "Last Name"),
                            React.createElement("th", null, "Registry Number"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.medicalExaminer.firstName),
                            React.createElement("td", null, driver.medicalExaminer.middleName),
                            React.createElement("td", null, driver.medicalExaminer.lastName),
                            React.createElement("td", null, driver.medicalExaminer.registryNumber))),
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Medical #"),
                            React.createElement("th", null, "Specialty"),
                            React.createElement("th", null, "State"),
                            React.createElement("th", null, "Phone"))),
                    React.createElement("tbody", null,
                        React.createElement("tr", null,
                            React.createElement("td", null, driver.medicalExaminer.medicalNumber),
                            React.createElement("td", null, driver.medicalExaminer.specialty),
                            React.createElement("td", null, driver.medicalExaminer.state),
                            React.createElement("td", null, driver.medicalExaminer.phone)))))));
    }
}
exports.default = DriverHistory;
