"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const CoreService_1 = require("./CoreService");
exports.default = {
    listQueues: () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield CoreService_1.CoreService.get("inspection-queue/queues");
            if (response === "notFound")
                return "error";
            return response.queues;
        }
        catch (error) {
            return "error";
        }
    }),
    listVehicles: (queueName) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield CoreService_1.CoreService.get("inspection-queue/vehicles", { queueName });
            if (response === "notFound")
                return "error";
            return response.vehicles;
        }
        catch (error) {
            return "error";
        }
    }),
    popVehicle: (queueName, vehicleId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const result = yield CoreService_1.CoreService.delete(`inspection-queue/queues/${encodeURI(queueName)}/${encodeURI(vehicleId)}`);
            if (result == "notFound")
                return false;
            return true;
        }
        catch (error) {
            return false;
        }
    }),
};
