"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapLongCountryToShort = void 0;
exports.mapLongCountryToShort = (longAbbr) => {
    const map = {
        USA: "US",
        CAN: "CA",
        MEX: "MX",
    };
    return map[longAbbr] || longAbbr;
};
