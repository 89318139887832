"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const datetime_1 = require("@blueprintjs/datetime");
// MIGRATE_TODO
// import ViolationsService, {
//   FederalViolation,
// } from "../../../../../../rims-electron/ViolationsService";
const DateFormat_1 = require("../../../../services/DateFormat");
const Helper_1 = require("./Helper");
const SmartLogic_Cat50_LogBookAndManner_Logic_1 = require("./SmartLogic_Cat50_LogBookAndManner_Logic");
const moment = require("moment");
const dateFormat = "MM/DD/YYYY";
const copyDate = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate());
class Cat50_LogBookAndManner extends React.Component {
    constructor(props) {
        super(props);
        this.toggleSelectedViolation = (violation) => {
            let violations = Object.assign({}, this.state.selectedViolations);
            if (this.isViolationSelected(violation)) {
                delete violations[SmartLogic_Cat50_LogBookAndManner_Logic_1.violationKey(violation)];
            }
            else {
                violations[SmartLogic_Cat50_LogBookAndManner_Logic_1.violationKey(violation)] = violation;
            }
            const violationsArray = Object.keys(violations).map((k) => violations[k]);
            this.setState({
                selectedViolations: violations,
                additionalDetails: this.createAdditionalDetailsMessage(violationsArray),
                additionalNotes: this.createNotesText(violationsArray),
            });
        };
        this.isViolationSelected = (violation) => !!this.state.selectedViolations[SmartLogic_Cat50_LogBookAndManner_Logic_1.violationKey(violation)];
        this.createAdditionalDetailsMessage = (violations) => {
            const violationCodes = violations
                .map((v) => v.VioRegSectionCode)
                .filter((x) => x);
            if (violationCodes.length > 0)
                return `Violation(s) of ${violationCodes.join(", ")}`;
            return "";
        };
        this.createNotesText = (violations) => {
            const presentViolations = violations.filter((v) => v.VioRegSectionCode !== undefined);
            const preamble = this.props.triggeringViolation.VioFederalRegCode === "395.8"
                ? "Record of Duty Status, Form and Manner Violations"
                : "AOBRD Deficiencies";
            if (presentViolations.length > 0)
                return `Violation(s) of ${preamble} on ${DateFormat_1.formatDate(this.state.violationDate)}:\n${presentViolations
                    .map((violation) => `${violation.VioRegSectionCode} - ${violation.VioDescText}`)
                    .join("\n")}`;
            return "";
        };
        this.violationDateChanged = (violationDate) => this.setState({
            violationDate,
        });
        this.additionalDetailsChanged = (e) => this.setState({ additionalDetails: e.currentTarget.value });
        this.validate = () => {
            const { errors } = SmartLogic_Cat50_LogBookAndManner_Logic_1.validate(this.state.violationDate, this.props.triggeringViolation, this.props.otherViolations, this.state.selectedViolations);
            const citeError = errors.citeError;
            const violationError = errors.violationError;
            this.setState({ citeError, violationError });
            this.props.setError(citeError || violationError);
            if (!citeError && !violationError) {
                const result = SmartLogic_Cat50_LogBookAndManner_Logic_1.updateViolations(this.state.violationDate, this.props.triggeringViolation, this.props.violations, this.state.additionalDetails, this.state.additionalNotes);
                this.props.updateViolations(result.violations, result.additionalNotes);
            }
        };
        this.state = {
            violationDate: copyDate(this.props.inspection.InspMain.InspStartTime.asDate()),
            additionalDetails: "",
            additionalNotes: "",
            violationChoices: [],
            selectedViolations: {},
        };
    }
    render() {
        const today = copyDate(this.props.inspection.InspMain.InspStartTime.asDate());
        const sevenDaysAgo = copyDate(today);
        sevenDaysAgo.setDate(today.getDate() - 7);
        return (React.createElement("div", null,
            React.createElement(core_1.Card, null,
                "When did the violation occur?",
                " ",
                React.createElement(datetime_1.DateInput, { formatDate: (date, locale) => moment(date).format(dateFormat), parseDate: (str, locale) => moment(str, dateFormat).toDate(), onChange: this.violationDateChanged, minDate: sevenDaysAgo, maxDate: today, placeholder: dateFormat })),
            Helper_1.callout(core_1.Intent.DANGER, this.state.citeError),
            !this.state.citeError && (React.createElement("div", null,
                React.createElement(core_1.Card, null,
                    this.state.violationChoices.map((v, i) => (React.createElement(core_1.Checkbox, { key: i, checked: this.isViolationSelected(v), label: `${v.VioFederalRegCode}: ${v.VioDescText}`, onChange: () => this.toggleSelectedViolation(v) }))),
                    Helper_1.callout(core_1.Intent.DANGER, this.state.violationError)),
                React.createElement(core_1.Card, null,
                    "Additional Violation Details:",
                    " ",
                    React.createElement("input", { className: core_1.Classes.INPUT, maxLength: 162, style: { width: "90%" }, value: this.state.additionalDetails, onChange: this.additionalDetailsChanged }))))));
    }
    componentDidMount() {
        const violationGroup = SmartLogic_Cat50_LogBookAndManner_Logic_1.violationGroups.filter((g) => g.group.indexOf(this.props.triggeringViolation.VioFederalRegCode || "") >= 0)[0];
        if (violationGroup) {
            // MIGRATE_TODO
            // const allViolations = ViolationsService.GetFederalViolations({
            //   type: "D",
            // });
            const possibleCodes = violationGroup.group.filter((code) => violationGroup.exclude.indexOf(code) === -1);
            const asViolation = (v) => ({
                source: "driver",
                VioSeqID: 1,
                VehicleUnitCode: "D",
                VehicleDefectActionCode: "N",
                VioOOSLevel6Flag: "N",
                StateCitation: { CitationIssued: "N" },
                VioDescText: v.description,
                VioFederalStateFlag: "F",
                VioCrashRelatedFlag: "N",
                VioRadiologicalFlag: "N",
                VioFederalRegCode: v.code,
                VioRegSectionCode: v.section,
                VioOOSFlag: v.oos,
                VioDescCustomFlag: "S",
            });
            // MIGRATE_TODO
            // const possibleViolations = allViolations
            //   .filter(v => possibleCodes.indexOf(v.code) >= 0)
            //   .map(x => asViolation(x));
            //      this.setState({ violationChoices: possibleViolations });
        }
        this.validate();
    }
    componentDidUpdate(nextProps, nextState) {
        if (this.state.selectedViolations !== nextState.selectedViolations ||
            this.state.violationDate !== nextState.violationDate ||
            this.state.additionalDetails !== nextState.additionalDetails) {
            this.validate();
        }
    }
}
exports.default = Cat50_LogBookAndManner;
