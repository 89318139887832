"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const UserSessionService_1 = require("../../session/UserSessionService");
// MIGRATE_TODO
// import { ipcRenderer } from "electron";
exports.default = {
    triggerSync: () => {
        if (!UserSessionService_1.UserSessionService.isLoggedIn())
            return;
        const username = UserSessionService_1.UserSessionService.getUsername();
        const authToken = UserSessionService_1.UserSessionService.getAuthToken();
        // MIGRATE_TODO
        // if (authToken) ipcRenderer.send("inspection-sync", { username, authToken });
    },
};
