"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_toastify_1 = require("react-toastify");
const NoticeService = {
    success: (msg) => {
        react_toastify_1.toast.success(msg);
        console.log("SUCCESS", msg);
    },
    info: (msg) => {
        react_toastify_1.toast.info(msg);
        console.log("INFO", msg);
    },
    error: (msg) => {
        react_toastify_1.toast.error(msg);
        console.log("ERROR", msg);
    },
};
exports.default = NoticeService;
