import {
  IInspectionManager,
  ListInspectionsRequest,
  InspectionListDetail,
  Inspection,
  InspectionStatus,
  SaveInspectionOptions,
  CreateInspectionOptions,
  GetInspectionResponse,
  IRemoteInspectionService,
} from "@rims/common";
import moment from "moment";

export class RemoteInspectionManager implements IInspectionManager {
  service: IRemoteInspectionService;

  constructor(remoteInspectionService: IRemoteInspectionService) {
    this.service = remoteInspectionService;
  }

  async listInspections(
    request?: ListInspectionsRequest,
  ): Promise<InspectionListDetail[]> {
    const response = await this.service.searchInspections({
      // startDate?: Date;
      // endDate?: Date;
      // // General search
      // text?: string;
      // userId?: number;
      // reportNumber?: string;
      // driverCdl?: string;
      // driverCdlState?: string;
      // driverFirstName?: string;
      // driverLastName?: string;
      // vehicleVin?: string;
      // vehiclePlate?: string;
      // vehicleState?: string;
      // driverOos?: boolean;
      // vehicleOos?: boolean;
      // inspectionLevel?: string;
      startDate: moment().subtract(6, "months").toDate(),
      onlyOwnInspections: true,
    });
    return response.inspections.map((i) => ({
      id: i.inspectionId,
      status: i.status,
      carrierName: i.carrierName || "",
      driverName: `${i.driverFirstName} ${i.driverLastName}`,
      oos: i.isOos,
      reportNumber: i.reportNumber,
      startTimeString: i.startTime,
      startTime: new Date(i.startTime),
      localTime: new Date(i.localTime),
      version: i.version,
    }));
  }

  async saveInspection(
    inspection: Inspection,
    status: InspectionStatus,
    saveInspectionOptions?: SaveInspectionOptions,
  ): Promise<void> {
    return Promise.resolve();
  }

  createInspection(
    options: CreateInspectionOptions | undefined,
  ): Promise<Inspection> {
    throw Error("AHHHHSDFH");
  }

  async getInspection(id: string): Promise<GetInspectionResponse | undefined> {
    const response = await this.service.getRimsInspection(id);

    return response.localInspection
      ? {
          inspection: response.localInspection,
          status: response.status,
          localTime: response.localTime,
          version: response.version,
          hasEverSubmitted: !!response.submittedStartTime,
        }
      : undefined;
  }

  deleteInspection(id: string): void {}
}
