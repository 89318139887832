"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const ChamberTypes_1 = require("./ChamberTypes");
const core_1 = require("@blueprintjs/core");
const BrakeSelectionServices_1 = require("../../../services/BrakeSelectionServices");
class ChamberTypesChooser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.chamberTypeChanged = (e) => {
            const chamberType = ChamberTypes_1.ChamberTypes.filter(x => x.name === e.currentTarget.value)[0];
            this.setState({ chamberType });
            BrakeSelectionServices_1.ChamberTypeSelectionService.selectChamberType(chamberType);
        };
        this.chamberSubtypeChanged = (chamberSubtype) => {
            BrakeSelectionServices_1.ChamberTypeSelectionService.selectChamberSubtype(chamberSubtype);
            // This is just to make the component render again
            this.setState({ selectedSubtype: chamberSubtype });
        };
        this.state = {};
    }
    render() {
        return (React.createElement("div", { style: { display: "flex" } },
            React.createElement(core_1.RadioGroup, { selectedValue: this.state.chamberType && this.state.chamberType.name, onChange: this.chamberTypeChanged }, ChamberTypes_1.ChamberTypes.map((chamberType, i) => (React.createElement(core_1.Radio, { key: i, value: chamberType.name }, chamberType.name)))),
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "Type"),
                        React.createElement("th", null, "Outside"),
                        React.createElement("th", null, "Max"))),
                React.createElement("tbody", null, this.state.chamberType &&
                    this.state.chamberType.subtypes.map((chamberSubtype, i) => {
                        const isSelected = this.state.selectedSubtype === chamberSubtype;
                        const rowStyle = (isSelected && {
                            backgroundColor: "yellow",
                            color: "black",
                        }) ||
                            {};
                        return (React.createElement("tr", { key: i, style: rowStyle },
                            React.createElement("td", null,
                                React.createElement(core_1.Button, { className: core_1.Classes.FILL, text: chamberSubtype.name, style: rowStyle, onClick: () => this.chamberSubtypeChanged(chamberSubtype) })),
                            React.createElement("td", null,
                                React.createElement(core_1.Button, { className: core_1.Classes.FILL, text: chamberSubtype.outside, style: rowStyle, onClick: () => this.chamberSubtypeChanged(chamberSubtype) })),
                            React.createElement("td", null,
                                React.createElement(core_1.Button, { className: core_1.Classes.FILL, text: chamberSubtype.max, style: rowStyle, onClick: () => this.chamberSubtypeChanged(chamberSubtype) }))));
                    })))));
    }
    componentDidMount() {
        this.setState({
            chamberType: BrakeSelectionServices_1.ChamberTypeSelectionService.getSelectedChamberType(),
            selectedSubtype: BrakeSelectionServices_1.ChamberTypeSelectionService.getSelectedChamberSubtype(),
        });
    }
}
exports.default = ChamberTypesChooser;
