"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
class NotesDialog extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            text: this.props.defaultText,
        };
        this.onChange = (e) => this.setState({ text: e.currentTarget.value });
        this.onClose = () => this.props.onChange(this.state.text);
    }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(core_1.Dialog, { isOpen: true, title: this.props.title, onClose: this.onClose, canEscapeKeyClose: true, canOutsideClickClose: true },
                React.createElement(core_1.TextArea, { className: core_1.Classes.FILL, style: { width: 985, height: 130 }, large: true, rows: 5, value: this.state.text, onChange: this.onChange }),
                React.createElement(core_1.Button, { text: "Close", onClick: this.onClose }))));
    }
}
class NotesDialogButton extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            dialogVisible: false,
        };
        this.showDialog = () => this.setState({ dialogVisible: true });
        this.hideDialog = () => this.setState({ dialogVisible: false });
        this.onChange = (text) => {
            this.hideDialog();
            this.props.onChange(text);
        };
    }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(core_1.Button, { text: this.props.label, onClick: this.showDialog, icon: "edit" }),
            this.state.dialogVisible && (React.createElement(NotesDialog, { title: this.props.label, defaultText: this.props.defaultText, onChange: this.onChange }))));
    }
}
exports.default = NotesDialogButton;
