"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFullNotes = exports.getAutogeneratedNotes = exports.serializeXml = exports.diffMinutes = exports.mapStudies = exports.formatPhoneNumber = void 0;
const convert = __importStar(require("xml-js"));
const DateFormat_1 = require("../DateFormat");
const OosTextService_1 = require("../inspection-rendering/OosTextService");
const datefns = __importStar(require("date-fns"));
const LocalFieldsService_1 = require("../LocalFieldsService");
// import { formatCompanyId } from "../vehicle/Iep";
/* tslint:disable:whitespace */
const e = (name, elements, attributes) => ({
    type: "element",
    name,
    attributes,
    elements: elements && elements.filter(c => c),
});
const ez = (name, elements, attributes) => {
    if (!elements || elements.length === 0)
        return undefined;
    return e(name, elements, attributes);
};
// Text element
const t = (name, value) => ({
    type: "element",
    name,
    elements: [
        {
            type: "text",
            text: value.toString().trim(),
        },
    ],
});
// Text element, or remain undefined
const tz = (name, value) => {
    if (value)
        return t(name, value);
    else
        return undefined;
};
// Text element, or undefined if blank string
const tzb = (name, value) => {
    if (!value || value.length === 0)
        return undefined;
    return t(name, value);
};
const toTripText = (location) => {
    if (!location)
        return "";
    return `${location.City}, ${location.State}`.substring(0, 25);
};
// NOTE: default to "USA" because we're getting key of "undefined" for some reason..
const longFormCountry = (country) => ({ US: "USA", CA: "CAN", MX: "MEX" }[country] || "USA");
// Format NNNNNNNNNN phone number as (NNN)NNN-NNNN
exports.formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber)
        return "";
    if (phoneNumber.length === 10) {
        const areaCode = phoneNumber.substring(0, 3);
        const prefix = phoneNumber.substring(3, 6);
        const num = phoneNumber.substring(6);
        return `(${areaCode})${prefix}-${num}`;
    }
    return phoneNumber;
};
exports.mapStudies = (studies) => {
    if (!studies)
        return undefined;
    return studies
        .map((study, i) => ({ studyText: study.StudyText, index: i + 1 }))
        .filter(x => x.studyText)
        .map(study => e("Study", [t("StudyText", study.studyText)], {
        studySeqNum: study.index,
    }));
};
// Return the difference between two timestamps in minutes, ignoring seconds
exports.diffMinutes = (time1, time2) => {
    if (!time1)
        return 0;
    const time1p = new Date(time1);
    const time2p = new Date(time2);
    time1p.setSeconds(0);
    time2p.setSeconds(0);
    return Math.floor(Math.abs(time1p.getTime() - time2p.getTime()) / 1000 / 60);
};
// Serialize inspection to XML
exports.serializeXml = (inspection, localFieldConfig, localFieldMap) => {
    const options = {
        ignoreComment: true,
        spaces: 4,
    };
    const myInspection = toSaferStructure({
        inspection,
        localFieldConfig,
        localFieldMap,
    });
    const xml = convert.js2xml(myInspection, options);
    return xml;
};
const formatSaferDate = (date) => datefns.format(date, "YYYY-MM-DD");
const trimTo = (s, max) => {
    let x;
    if (s.length > max)
        x = s.substring(0, max);
    else
        x = s;
    return x;
};
// Hazmat UN/NA# and proper shipping names
const hmToNoteText = (hm) => hm.unNaNumber
    ? `HM #${hm.HMSeqNum}: ${hm.unNaNumber.trim()} ${hm.properShippingName.trim()}.`
    : "";
exports.getAutogeneratedNotes = (inspection) => (inspection.HazMats || []).map(hmToNoteText).join(" ");
exports.getFullNotes = (inspection) => trimTo([inspection.InspMain.InspNotesText || "", exports.getAutogeneratedNotes(inspection)]
    .filter(x => x.length > 0)
    .join("\n"), 65534);
const toSaferStructure = ({ inspection, localFieldConfig, localFieldMap, }) => {
    const { InspMain, Vehicles, Brakes, PBBTMeasurements, Drivers, Shippers, Violations, HazMats, Radioactives, InspectionOffice, AdminData, Studies, LocalFields, } = inspection;
    const { InspLocation, Carrier, IEPData } = InspMain;
    const oosInfo = OosTextService_1.generateOosInfo(inspection);
    const oosViolations = Violations.filter(v => v.VioOOSFlag === "Y");
    const getDriverOosFlag = () => oosViolations.filter(x => ["D", "C"].indexOf(x.VehicleUnitCode) >= 0)
        .length > 0
        ? "Y"
        : "N";
    const getVehicleOosFlag = () => oosViolations.filter(x => ["1", "2", "3", "4", "5", "6"].indexOf(x.VehicleUnitCode) >= 0).length > 0
        ? "Y"
        : "N";
    const now = new Date();
    // Populate local fields with HM info, if configured
    const localFields = LocalFieldsService_1.populateLocalFieldsWithHM(localFieldConfig, localFieldMap, LocalFields, inspection.HazMats);
    return {
        elements: [
            e("Inspections", [
                e("Inspection", [
                    e("InspMain", [
                        t("InspReportID", InspMain.InspReportID),
                        t("InspStartDate", formatSaferDate(InspMain.InspStartTime.asDate())),
                        t("InspStartTime", DateFormat_1.formatTimeZeroSeconds(InspMain.InspStartTime.asDate())),
                        t("InspEndDate", (InspMain.InspEndTime &&
                            formatSaferDate(InspMain.InspEndTime.asDate())) ||
                            ""),
                        t("InspEndTime", (InspMain.InspEndTime &&
                            DateFormat_1.formatTimeZeroSeconds(InspMain.InspEndTime.asDate())) ||
                            ""),
                        t("InspDuration", exports.diffMinutes((InspMain.InspEndTime && InspMain.InspEndTime.asDate()) ||
                            InspMain.InspStartTime.asDate(), InspMain.InspStartTime.asDate())),
                        t("InspTypeLevelCode", InspMain.InspTypeLevelCode || ""),
                        e("InspLocation", [
                            t("TimeZoneCode", InspLocation.TimeZoneCode),
                            t("InspLocationCode", InspLocation.InspLocationCode || ""),
                            t("InspLocationText", InspLocation.InspLocationText),
                            t("HighwayDescText", InspLocation.HighwayDescText),
                            t("HighwayMilepostNum", InspLocation.HighwayMilepostNum || ""),
                            t("InspFacilityTypeCode", InspLocation.InspFacilityTypeCode || "R"),
                            t("InspStateCode", InspLocation.InspStateCode),
                            t("InspCountyName", InspLocation.InspCountyName),
                            t("FIPSCountyCode", trimTo(InspLocation.FIPSCountyCode, 3)),
                            t("StateCountyCode", ""),
                            t("CountryCode", longFormCountry(InspLocation.CountryCode)),
                        ]),
                        t("InspectorName", InspMain.InspectorName),
                        t("InspectorCode", InspMain.InspectorCode),
                        t("InspAlcSubCheckFlag", InspMain.InspAlcSubCheckFlag || "N"),
                        t("InspDrugSearchFlag", InspMain.InspDrugSearchFlag || "N"),
                        tz("InspDrugArrestCode", (InspMain.InspDrugSearchFlag === "Y" &&
                            InspMain.InspDrugArrestCode) ||
                            undefined),
                        t("InspSizeWeightEnfFlag", InspMain.InspSizeWeightEnfFlag || "N"),
                        t("InspTrafficEnfFlag", InspMain.InspTrafficEnfFlag || "N"),
                        t("InspLocalJurisdictionFlag", InspMain.InspLocalJurisdictionFlag || "N"),
                        t("InspPASACheckFlag", InspMain.InspPASACheckFlag || "N"),
                        t("InspAccidentFlag", InspMain.InspAccidentFlag || "N"),
                        t("InspBorderGrant", InspMain.InspBorderGrant || "N"),
                        t("InspPBBTCheckFlag", InspMain.InspPBBTCheckFlag || "N"),
                        tz("PBBTAxles", InspMain.PBBTAxles),
                        tz("PBBTBrakeForce", InspMain.PBBTBrakeForce),
                        tz("PBBTMinBrakeForce", InspMain.PBBTMinBrakeForce),
                        tz("PBBTVehicleTypeID", InspMain.PBBTVehicleTypeID),
                        t("DisplayBrakeAdvisoryCheck", InspMain.DisplayBrakeAdvisoryCheck || "N"),
                        tz("InspHazmatTypeCode", InspMain.InspHazmatTypeCode),
                        e("Carrier", [
                            t("USDOTNum", Carrier.USDOTNum || ""),
                            t("CarrierStateCensusNum", Carrier.CarrierStateCensusNum || ""),
                            t("CarrierICCNum", Carrier.CarrierICCNum || ""),
                            tzb("CarrierMCMXNum", Carrier.CarrierMCMXNum),
                            t("CarrierInterstateFlag", Carrier.CarrierInterstateFlag || "N"),
                            tzb("CarrierMexicanID", Carrier.CarrierMexicanID),
                            t("CarrierName", Carrier.CarrierName || ""),
                            e("CarrierAddress", [
                                t("AddressStreetText", Carrier.CarrierAddress.AddressStreetText),
                                tz("AddressColoniaText", Carrier.CarrierAddress.AddressColoniaText),
                                t("AddressCityName", Carrier.CarrierAddress.AddressCityName),
                                t("AddressStateCode", 
                                // TODO: Should revisit this one. Really shouldn't have a default
                                Carrier.CarrierAddress.AddressStateCode || "US"),
                                tz("AddressZipCode", Carrier.CarrierAddress.AddressZipCode),
                                t("AddressCountryCode", Carrier.CarrierAddress.AddressCountryCode || "US"),
                            ]),
                            t("CarrierPhoneNum", exports.formatPhoneNumber(Carrier.CarrierPhoneNum || "")),
                            t("CarrierFaxNum", exports.formatPhoneNumber(Carrier.CarrierFaxNum || "")),
                            t("CarrierAspenMatchFlag", Carrier.CarrierAspenMatchFlag || ""),
                            tzb("CountryCode", Carrier.CountryCode),
                        ]),
                        t("ShipperName", InspMain.ShipperName || ""),
                        t("ShipmentNum", InspMain.ShipmentNum || ""),
                        t("TripOriginText", toTripText(InspMain.TripOrigin)),
                        t("TripDestinationText", toTripText(InspMain.TripDestination)),
                        t("CargoTypeText", InspMain.CargoTypeText || ""),
                        // TODO: schema says to default this to "N" but ASPEN does "U"
                        t("CargoHazmatPlacardFlag", InspMain.CargoHazmatPlacardFlag || "U"),
                        t("CargoTankTypeCode", InspMain.CargoTankTypeCode || ""),
                        tz("VehicleGCWeightRating", InspMain.VehicleGCWeightRating),
                        t("VehicleAxlesNum", InspMain.VehicleAxlesNum || "0"),
                        tz("VehicleTruckBusInd", InspMain.VehicleTruckBusInd),
                        (IEPData &&
                            e("IEPData", [
                                t("IEPSpaceProvided", IEPData.IEPSpaceProvided),
                                t("IEPInspectionConducted", IEPData.IEPInspectionConducted),
                            ])) ||
                            undefined,
                        e("InspTotalCounts", [
                            t("InspTotalHazmatNum", HazMats.length),
                            t("InspTotalVehicleNum", Vehicles.length),
                            t("InspTotalVioNum", Violations.length),
                            t("InspTotalOOSVioNum", oosViolations.length),
                            t("InspTotalVehicleOOSVioNum", oosViolations.filter(v => ["1", "2", "3", "4", "5", "6"].indexOf(v.VehicleUnitCode) >= 0).length),
                            t("InspTotalDriverOOSVioNum", Violations.filter(v => v.VioOOSFlag === "Y" &&
                                ["C", "D"].indexOf(v.VehicleUnitCode) >= 0).length),
                        ]),
                        t("InspReportStatusCode", InspMain.InspReportStatusCode || "I"),
                        t("InspNotesText", exports.getFullNotes(inspection)),
                        // t("DriverSignatureImage", InspMain.DriverSignatureImage),
                        // t("InspAspen1Text", InspMain.InspAspen1Text),
                        // t("InspAspen2Text", InspMain.InspAspen2Text),
                        tz("InsuranceVerificationFlag", InspMain.InsuranceVerificationFlag),
                        tz("InsuranceVerificationType", InspMain.InsuranceVerificationType),
                        tz("InsuranceVerificationDescription", InspMain.InsuranceVerificationDescription),
                    ]),
                    ez("Drivers", Drivers.filter(d => d.DriverLastName && d.DriverFirstName).map(d => e("Driver", [
                        t("DriverLastName", d.DriverLastName || ""),
                        t("DriverFirstName", d.DriverFirstName || ""),
                        tz("DriverMiddleInitial", d.DriverMiddleInitial),
                        tz("DriverBirthDate", d.DriverBirthDate && formatSaferDate(d.DriverBirthDate)),
                        tz("DriverLicenseID", d.DriverLicenseID),
                        tz("DriverLicenseStateCode", d.DriverLicenseStateCode),
                    ], {
                        CoDriverFlag: d.IsCoDriver ? "Y" : "N",
                    }))),
                    e("Vehicles", Vehicles.map(v => e("Vehicle", [
                        t("VehicleUnitNum", v.VehicleUnitNum),
                        t("VehicleUnitTypeCode", v.VehicleUnitTypeCode || ""),
                        tz("VehicleMakeCode", v.VehicleMakeCode),
                        tz("VehicleYear", v.VehicleYear),
                        tz("VehicleCompanyID", (v.VehicleUnitTypeCode && v.VehicleCompanyID) || "") || undefined,
                        tz("VehicleLicenseID", v.VehicleLicenseID),
                        t("VehicleLicenseStateCode", v.VehicleLicenseStateCode || ""),
                        tz("VehicleID", v.VehicleID),
                        tz("VehicleGVWRNum", v.VehicleGVWRNum),
                        tz("VehicleCVSADecalFlag", (v.VehicleDecalID && "Y") || "N"),
                        tz("VehicleDecalID", v.VehicleDecalID),
                        tz("VehiclePreviousDecalStatus", v.VehiclePreviousDecalStatus),
                        tz("VehiclePreviousDecalID", v.VehiclePreviousDecalID),
                        tz("VehicleOOSStickerID", v.VehicleOOSStickerID),
                        tz("VehicleRemovedCargoSealID", v.VehicleRemovedCargoSealID),
                        tz("VehicleReplacedCargoSealID", v.VehicleReplacedCargoSealID),
                        ez("IntermodalEquipment", v.IntermodalEquipment && [
                            tz("IEPDOTNum", v.IntermodalEquipment.IEPDOTNum),
                            tz("IEPName", v.IntermodalEquipment.IEPName),
                            tz("IEPChassisPool", v.IntermodalEquipment.IEPChassisPool),
                            tz("IEPSourceCode", v.IntermodalEquipment.IEPSourceCode),
                            (v.IntermodalEquipment.IEPTimestampZ &&
                                t("IEPTimestampZ", v.IntermodalEquipment.IEPTimestampZ.toISOString())) ||
                                undefined,
                        ]),
                    ]))),
                    ez("Brakes", Brakes.map(b => e("Brake", [
                        t("VehicleAxleNum", b.VehicleAxleNum),
                        t("BrakeChamberSubtypeCode", b.BrakeChamberSubtypeCode),
                        tz("LeftBrakeMeasure", b.LeftBrakeMeasure),
                        tz("RightBrakeMeasure", b.RightBrakeMeasure),
                    ]))),
                    ez("PBBTMeasurements", PBBTMeasurements.map(m => e("PBBTMeasurement", [
                        t("PBBTVehicleAxleNum", m.PBBTVehicleAxleNum),
                        t("PBBTLeftBrakeMeasure", m.PBBTLeftBrakeMeasure),
                        t("PBBTRightBrakeMeasure", m.PBBTRightBrakeMeasure),
                    ]))),
                    ez("HazMats", HazMats.map(h => e("HazMat", [
                        t("HMSeqNum", h.HMSeqNum),
                        t("HMTypeCode", h.HMTypeCode),
                        t("HMReportableQuantityFlag", h.HMReportableQuantityFlag || "N"),
                        t("HMWasteFlag", h.HMWasteFlag),
                        tz("HMDescription", h.HMDescription),
                    ]))),
                    ez("Shippers", Shippers.map(s => e("Shipper", [
                        t("ShipperID", s.ShipperID),
                        tz("USDOTNum", s.USDOTNum),
                        tz("ShipperName", s.ShipperName),
                        s.ShipperAddress &&
                            ez("ShipperAddress", [
                                tz("AddressStreetText", s.ShipperAddress.AddressStreetText),
                                tz("AddressColoniaText", s.ShipperAddress.AddressColoniaText),
                                tz("AddressCityName", s.ShipperAddress.AddressCityName),
                                tz("AddressStateCode", s.ShipperAddress.AddressStateCode),
                                tz("AddressZipCode", s.ShipperAddress.AddressZipCode),
                                tz("AddressCountryCode", s.ShipperAddress.AddressCountryCode),
                            ]),
                    ]))),
                    ez("Radioactives", Radioactives && [
                        t("InspSiteCode", Radioactives.InspSiteCode),
                        // These 3 are covered by InspSiteCode
                        // t("InspPointOfOriginFlag", Radioactives.InspPointOfOriginFlag),
                        // t("InspEnRouteFlag", Radioactives.InspEnRouteFlag),
                        // t(
                        //   "InspPointOfDestinationFlag",
                        //   Radioactives.InspPointOfDestinationFlag,
                        // ),
                        t("InspAccidentFlag", Radioactives.InspAccidentFlag),
                        t("InspIncidentFlag", Radioactives.InspIncidentFlag),
                        t("InspHRCQFlag", Radioactives.InspHRCQFlag),
                        t("InspTRUWasteFlag", Radioactives.InspTRUWasteFlag),
                        t("ShipmentDOEFlag", Radioactives.ShipmentDOEFlag),
                        t("ShipmentCobalt60Flag", Radioactives.ShipmentCobalt60Flag),
                        t("ShipmentExclusiveUseFlag", Radioactives.ShipmentExclusiveUseFlag),
                        t("ShipmentProperName", Radioactives.ShipmentProperName),
                        t("ShipmentUNNumber", Radioactives.ShipmentUNNumber),
                        t("ShipmentLevelVIDecal", Radioactives.ShipmentLevelVIDecal),
                        t("InstrumentManufacturer", Radioactives.InstrumentManufacturer),
                        t("InstrumentModel", Radioactives.InstrumentModel),
                        t("InstrumentSerialNum", Radioactives.InstrumentSerialNum),
                        t("ProbeModel", Radioactives.ProbeModel),
                        t("ProbeSerialNumber", Radioactives.ProbeSerialNumber),
                        t("ShipmentPackageTypeName", Radioactives.ShipmentPackageTypeName),
                        (inspection.InspMain.InspTypeLevelCode === "6" &&
                            ez("RamPackages", Radioactives.RamPackages.map(pkg => e("RamPackage", [
                                t("RamPackageSeqID", pkg.RamPackageSeqID),
                                t("RamPackageSerialNum", pkg.RamPackageSerialNum),
                                t("RamTransportIndexNum", pkg.RamTransportIndexNum),
                                t("RamCriticalityIndex", pkg.RamCriticalityIndex),
                            ])))) ||
                            undefined,
                        (inspection.InspMain.InspTypeLevelCode === "6" &&
                            ez("RamReadings", Radioactives.RamReadings.map(r => e("RamReading", [
                                t("RamLocationTypeCode", r.RamLocationTypeCode),
                                t("RamVehicleLocationCode", r.RamVehicleLocationCode),
                                t("RamReadingValue", r.RamReadingValue),
                            ])))) ||
                            undefined,
                    ]),
                    ez("Violations", Violations.map(v => e("Violation", [
                        t("VioSeqID", v.VioSeqID),
                        tz("VioCategory", v.VioCategory),
                        tz("VioFederalRegCode", v.VioFederalRegCode),
                        tz("VioStateRegCode", v.VioStateRegCode),
                        tz("VioRegSectionCode", v.VioRegSectionCode),
                        t("VioDescText", v.VioDescText),
                        t("SuppVioDesc", v.SuppVioDesc || ""),
                        t("VioDescCustomFlag", v.VioDescCustomFlag || "S"),
                        t("VioOOSFlag", v.VioOOSFlag || "N"),
                        t("VioOOSLevel6Flag", v.VioOOSLevel6Flag || "N"),
                        t("VehicleUnitCode", v.VehicleUnitCode),
                        t("VehicleDefectActionCode", v.VehicleDefectActionCode || "N"),
                        ez("StateCitation", v.StateCitation.CitationIssued !== "N"
                            ? [
                                t("CitationIssued", v.StateCitation.CitationIssued),
                                t("CitationNum", v.StateCitation.CitationNum || ""),
                            ]
                            : []),
                        tz("ShipperID", v.ShipperID),
                        t("VioFederalStateFlag", v.VioFederalStateFlag || "F"),
                        t("VioCrashRelatedFlag", v.VioCrashRelatedFlag || "N"),
                        tz("VioRadiologicalFlag", v.VioRadiologicalFlag),
                        tz("VioAttribution", v.VioAttribution),
                    ]))),
                    ez("LocalFields", localFields.map(lf => e("LocalField", [
                        t("InspLocalFieldID", lf.InspLocalFieldID),
                        t("InspLocalFieldLabelText", lf.InspLocalFieldLabelText),
                        t("InspLocalFieldDataValue", lf.InspLocalFieldDataValue),
                        t("RecordPrintDesiredFlag", "N"),
                    ]))),
                    ez("Studies", exports.mapStudies(Studies)),
                    e("OutOfServiceInfo", [
                        t("DriverOOSFlag", getDriverOosFlag()),
                        tz("DriverOOSUntilText", oosInfo.DriverOOSUntilText),
                        tz("DriverOOSText", oosInfo.DriverOOSText),
                        t("VehicleOOSFlag", getVehicleOosFlag()),
                        tz("VehicleOOSText", oosInfo.VehicleOOSText),
                        tz("CarrierCertifyOOSText", oosInfo.CarrierCertifyOOSText),
                        tz("CarrierVerifyOOSText", oosInfo.CarrierVerifyOOSText),
                        tz("InspCertifyCode", oosInfo.InspCertifyCode),
                        tz("InspCertifyDate", oosInfo.InspCertifyDate &&
                            formatSaferDate(oosInfo.InspCertifyDate)) || undefined,
                        tz("InspCertifyUserID", oosInfo.InspCertifyUserID),
                        tz("InspVerifyCode", oosInfo.InspVerifyCode),
                        tz("InspVerifyDate", (oosInfo.InspVerifyDate &&
                            formatSaferDate(oosInfo.InspVerifyDate)) ||
                            undefined),
                        tz("InspVerifyUserID", oosInfo.InspVerifyUserID),
                    ]),
                    e("InspectionOffice", [
                        t("InspOfficeName", InspectionOffice.InspOfficeName || ""),
                        t("InspOfficeAddress1Text", InspectionOffice.InspOfficeAddress1Text || ""),
                        t("InspOfficeAddress2Text", InspectionOffice.InspOfficeAddress2Text || ""),
                        t("InspOfficeAddress3Text", InspectionOffice.InspOfficeAddress3Text || ""),
                        t("InspOfficePhoneNum", InspectionOffice.InspOfficePhoneNum || ""),
                        tz("InspSourceOfficeID", InspectionOffice.InspSourceOfficeID),
                    ]),
                    e("AdminData", [
                        t("InspJurisdictionCode", AdminData.InspJurisdictionCode),
                        t("SourceSystemName", AdminData.SourceSystemName),
                        t("SystemVersionCode", AdminData.SystemVersionCode),
                        tz("CarrierCensusMatchFlag", AdminData.CarrierCensusMatchFlag),
                        tz("UploadSaferRequiredFlag", InspMain.InspTypeLevelCode === "7" ? "N" : "Y"),
                        tz("UploadRequiredFlag", InspMain.InspTypeLevelCode === "7" ? "N" : "Y"),
                        tz("InputUserID", "CVO"),
                        t("UpdateDate", formatSaferDate(now)),
                        t("UpdateTime", DateFormat_1.formatTime(now)),
                        t("AspenUploadDate", formatSaferDate(now)),
                        t("AspenUploadTime", DateFormat_1.formatTime(now)),
                    ]),
                ]),
            ], {
                sourceSystemCode: inspection.AdminData.SourceSystemName,
                schemaVersionCode: "3.02",
            }),
        ],
    };
};
