"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Labeled_1 = require("../core/Labeled");
const core_1 = require("@blueprintjs/core");
// Note we could get these from CountryCodeService.GetCountries() but it includes many more countries
const countries = [
    { name: "USA", value: "US" },
    { name: "CAN", value: "CA" },
    { name: "MEX", value: "MX" },
];
class CountryCodeSelector extends React.PureComponent {
    constructor(props) {
        super(props);
        this.onChange = (e) => this.props.onChange(e.currentTarget.value);
        this.state = {};
    }
    render() {
        const dropdown = (React.createElement(core_1.HTMLSelect, { value: this.props.value, onChange: this.onChange }, countries.map((country, i) => (React.createElement("option", { key: i, value: country.value }, country.name)))));
        if (this.props.label)
            return React.createElement(Labeled_1.Labeled, { label: this.props.label }, dropdown);
        else
            return dropdown;
    }
}
exports.default = CountryCodeSelector;
