"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const CoreService_1 = require("../CoreService");
const UserSettingsService_1 = require("../UserSettingsService");
const NotFound_1 = require("../NotFound");
exports.default = {
    listUsers: () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.get(`cview-user?q=${Math.random()}`, {
            includeSettings: ["inspectorCode"],
        });
        if (response === "notFound")
            throw Error("Error retrieving users: not found");
        return response.users;
    }),
    listGroups: () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.get("cview-group");
        if (response === "notFound")
            throw Error("Error retrieving groups: not found");
        return response.groups;
    }),
    saveUserSettings: (userId, settings) => __awaiter(void 0, void 0, void 0, function* () {
        yield CoreService_1.CoreService.put(`cview-user/${userId}/settings`, undefined, settings);
    }),
    getUserSettings: (userId) => __awaiter(void 0, void 0, void 0, function* () {
        const settingsResponse = yield CoreService_1.CoreService.get(`cview-user/${userId}/settings`);
        if (settingsResponse === NotFound_1.NotFound)
            return NotFound_1.NotFound;
        return (UserSettingsService_1.parseSettingsObject(Object.assign({}, UserSettingsService_1.emptyUserSettings, settingsResponse.settings)));
    }),
};
