"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateInspectionXml = exports.validateInspection = exports.validateDomain = void 0;
const InspectionSerializer_1 = require("../inspection/InspectionSerializer");
// MIGRATE_TODO
// import * as child_process from "child_process";
// MIGRATE_TODO
//import { allFields } from "../LocalFieldsService";
const config_1 = require("../../config");
const ValidationLogic_1 = require("./ValidationLogic");
// MIGRATE_TODO
// import { LocalSnetService } from "../../local-services/LocalSnetService";
const validateLocalFields = (inspection) => {
    return [];
    // MIGRATE_TODO
    // let errors: ValidationError[] = [];
    // for (const requiredField of allFields(
    //   LocalSnetService.getLocalFields(),
    // ).filter(x => x.required)) {
    //   const field = inspection.LocalFields.filter(
    //     x => x.InspLocalFieldID === requiredField.id,
    //   )[0];
    //   if (field === undefined || field.InspLocalFieldDataValue === "") {
    //     errors.push({
    //       elementPaths: [`LocalFields[${requiredField.id}]`],
    //       message: `Required local field ${requiredField.uiName} missing`,
    //     });
    //   }
    // }
    // return errors;
};
exports.validateDomain = (inspection) => {
    let errors = [];
    return (errors
        .concat(ValidationLogic_1.validateStart(inspection))
        .concat(ValidationLogic_1.validateLocation(inspection))
        .concat(ValidationLogic_1.validateCarrier(inspection))
        .concat(ValidationLogic_1.validateHazmat(inspection))
        // .concat(validateIep(inspection))
        .concat(ValidationLogic_1.validateVehicles(inspection))
        .concat(ValidationLogic_1.validateDrivers(inspection))
        .concat(ValidationLogic_1.validateTimes({
        creationTime: inspection.creationTime,
        inspStartTime: inspection.InspMain.InspStartTime,
        inspEndTime: inspection.InspMain.InspEndTime,
    }))
        .concat(ValidationLogic_1.validateBrakes(inspection))
        .concat(ValidationLogic_1.validateTrip(inspection))
        .concat(ValidationLogic_1.validateViolations(inspection))
        .concat(validateLocalFields(inspection)));
};
exports.validateInspection = (inspection) => {
    const domainErrors = exports.validateDomain(inspection);
    if (domainErrors.length > 0)
        return domainErrors;
    const inspectionXml = InspectionSerializer_1.serializeXml(inspection, 
    // MIGRATE_TODO
    //LocalSnetService.getLocalFields(),
    [], config_1.localFieldMap);
    const xmlErrors = exports.validateInspectionXml(inspectionXml).map((err) => ({
        message: `XML error: ${err}`,
    }));
    return xmlErrors;
};
// TODO: assumed that input is formatted, at most one element tag per line
exports.validateInspectionXml = (xml) => {
    // MIGRATE_TODO
    // const result = child_process.spawnSync(
    //     "xmllint.exe",
    //     ["--schema", "T0018V6.xsd", "-"],
    //     {
    //       input: xml,
    //     },
    //   );
    //   // var stdoutStr = result.stdout.toString("utf8");
    //   var stderrStr = result.stderr.toString("utf8") as string;
    //   // -:7: element InspEndDate: Schemas validity error : Element 'InspEndDate': '' is not a valid value of the atomic type 'xs:date'.
    //   // -:8: element InspEndTime: Schemas validity error : Element 'InspEndTime': '' is not a valid value of the atomic type 'xs:time'.
    //   // if (stderrStr.length > 0) alert(stderrStr);
    //   // console.log({ err: stderrStr });
    //   const errors = stderrStr.split("\n");
    //   // "- validates" or "- fails to validate" line
    //   errors.pop();
    //   // blank line
    //   errors.pop();
    //   return errors;
    return [];
};
