"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
class SortButton extends React.Component {
    constructor() {
        super(...arguments);
        this.isSelected = () => this.props.id === this.props.sortBy;
        this.findNextSortDirection = () => {
            if (!this.isSelected())
                return "asc";
            if (this.props.sortDirection === "asc")
                return "desc";
            return "asc";
        };
        this.onClick = () => this.props.onChangeSort(this.props.id, this.findNextSortDirection());
    }
    render() {
        let icon = undefined;
        if (this.isSelected()) {
            if (this.props.sortDirection === "asc")
                icon = "caret-down";
            else
                icon = "caret-up";
        }
        return (React.createElement(core_1.Button, { className: core_1.Classes.FILL, text: this.props.text, onClick: this.onClick, rightIcon: icon }));
    }
}
exports.default = SortButton;
