import {
  RemoteVehicleMakeService,
  VehicleMakeLookup,
} from "@rims/common";
import { Semaphore } from "prex";

// TODO: will need to move this elsewhere to share for electron, etc
// Makes are associated for only ST, BU, TR, so we map the remaining vehicle types
// to one of these.
const mapVehicleType = (vehicleType: string): string => {
  if (["CL", "DC", "FT", "IC", "OT", "PT", "ST"].indexOf(vehicleType) >= 0)
    return "ST";
  else if (["BU", "LM", "MC", "SB", "VN"].indexOf(vehicleType) >= 0)
    return "BU";
  // TR, TT
  else return "TR";
};

// TODO: Do we need to pull down all makes when only one vehicle type needed? Or maybe pull down all and store in localStorage?
const cacheLock = new Semaphore(1);

let cache: VehicleMakeLookup[] | undefined = undefined;

export const getVehicleMakes = async (
  vehicleType?: string,
  ncicCode?: string,
): Promise<VehicleMakeLookup[]> => {
  await cacheLock.wait();

  if (!cache) {
    cache = await RemoteVehicleMakeService.listMakes();
  }

  cacheLock.release();

  let makes = cache;

  if (vehicleType)
    makes = makes.filter((x) => x.vehicleType === mapVehicleType(vehicleType));

  if (ncicCode) makes = makes.filter((x) => x.ncicCode === ncicCode);

  return makes;
};
