"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const CoreService_1 = require("./CoreService");
const moment = require("moment");
exports.default = {
    runCarrierTests: (usdot) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.get(`passfail/carrier?usdot=${usdot}`);
        if (response === "notFound")
            throw Error(`Couldn't run passfail tests for USDOT ${usdot}`);
        response.executionTime = moment().format("MM/DD/YYYY hh:mm A");
        return response;
    }),
};
