"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const CoreService_1 = require("./CoreService");
const NotFound_1 = require("./NotFound");
exports.default = {
    getSnetLocations: () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield CoreService_1.CoreService.get(`snet/location`, undefined, undefined, 20000);
            if (response === NotFound_1.NotFound)
                throw Error();
            return response.locations.map((l) => ({
                TimeZoneCode: l.time_zone_code,
                InspLocationCode: l.insp_location_code,
                InspLocationText: l.insp_location_text,
                HighwayDescText: l.highway_desc_text,
                HighwayMilepostNum: l.highway_milepost_num,
                InspFacilityTypeCode: l.insp_facility_type_code,
                InspStateCode: l.insp_state_code,
                InspCountyName: l.insp_county_name,
                FIPSCountyCode: l.fips_county_code,
                CountryCode: l.country_code,
                StateCountyCode: l.state_county_code,
            }));
        }
        catch (e) {
            console.error("Error querying service for SNET locations", e);
            throw e;
        }
    }),
};
