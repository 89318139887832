"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const Labeled_1 = require("../core/Labeled");
const select_1 = require("@blueprintjs/select");
const core_1 = require("@blueprintjs/core");
const classNames = require("classnames");
const DriverOosUntilSuggest = select_1.Suggest.ofType();
const renderOosUntil = (item, { handleClick, modifiers, query }) => {
    const classes = classNames({
        [core_1.Classes.ACTIVE]: modifiers.active,
        [core_1.Classes.INTENT_PRIMARY]: modifiers.active,
    });
    return (React.createElement(core_1.MenuItem, { className: classes, key: item, onClick: handleClick, text: item }));
};
class DriverOosUntilSelector extends React.Component {
    constructor(props) {
        super(props);
        this.updateOosUntil = (oosUntil) => this.props.onChange(oosUntil);
        this.onChange = (query, e) => this.updateOosUntil(query.toUpperCase());
        this.onSelected = (item, event) => this.updateOosUntil(item || "");
        this.state = {
            oosUntils: [],
        };
    }
    componentDidMount() {
        this.updateOosUntilList();
    }
    render() {
        const dropdown = (React.createElement(DriverOosUntilSuggest, { inputProps: {
                placeholder: "",
                style: { width: 400 },
                maxLength: 40,
            }, onQueryChange: this.onChange, selectedItem: this.props.value || "", itemRenderer: renderOosUntil, inputValueRenderer: val => val, items: this.state.oosUntils, onItemSelect: this.onSelected, openOnKeyDown: false, popoverProps: { popoverClassName: core_1.Classes.MINIMAL } }));
        if (this.props.label)
            return (React.createElement(Labeled_1.Labeled, { label: this.props.label },
                React.createElement("div", null, dropdown)));
        else
            return dropdown;
    }
    updateOosUntilList() {
        const oosUntils = [
            "Driver has Proper CDL Endorsement",
            "Obtains a Proper Medical Waiver",
            "Record of Duty Status Made Current",
        ];
        this.setState({ oosUntils });
    }
}
exports.default = DriverOosUntilSelector;
