"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSameTimeOfDay = exports.isSameDate = exports.formatDateTime = exports.formatTime12HrNoSeconds = exports.formatTimeNoSeconds = exports.formatTimeZeroSeconds = exports.formatTime = exports.formatDate = void 0;
const moment = require("moment");
exports.formatDate = (d) => moment(d).format("MM/DD/YYYY");
exports.formatTime = (d) => moment(d).format("HH:mm:ss");
exports.formatTimeZeroSeconds = (d) => moment(d).format("HH:mm:00");
exports.formatTimeNoSeconds = (d) => moment(d).format("HH:mm");
exports.formatTime12HrNoSeconds = (d) => moment(d).format("hh:mm A");
exports.formatDateTime = (d) => exports.formatDate(d) + " " + exports.formatTime12HrNoSeconds(d);
exports.isSameDate = (d1, d2) => {
    if (!d1 && !d2)
        return true;
    if (!d1 || !d2)
        return false;
    return (d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate());
};
exports.isSameTimeOfDay = (d1, d2) => {
    if (!d1 && !d2)
        return true;
    if (!d1 || !d2)
        return false;
    return (d1.getHours() === d2.getHours() &&
        d1.getMinutes() === d2.getMinutes() &&
        d1.getSeconds() === d2.getSeconds());
};
