"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const RemoteInspectionService_1 = require("../../../../services/inspection/RemoteInspectionService");
const core_1 = require("@blueprintjs/core");
const DateFormat_1 = require("../../../../services/DateFormat");
const moment = require("moment");
const core_2 = require("@blueprintjs/core");
const DateTime_1 = require("../../../../types/DateTime");
const RimsContext_1 = require("../../../../types/RimsContext");
const InspectionPrintService_1 = require("../../../../services/inspection-rendering/InspectionPrintService");
// We replace start and end time with server time because in older versions of RIMS
// the inspection time was stored in UTC time, not local time. That meant that the app didn't know
// how to display correctly. This shouldn't be needed with newer inspections.
const replaceInspectionDatetime = (inspection, startTime, endTime) => {
    inspection.InspMain.InspStartTime = new DateTime_1.DateTime(moment(startTime).toDate());
    inspection.InspMain.InspEndTime = endTime
        ? new DateTime_1.DateTime(moment(endTime).toDate())
        : undefined;
    return inspection;
};
const createIcon = (i) => {
    const make = (label, icon) => (React.createElement("span", { style: { marginLeft: 3 } },
        React.createElement(core_2.Tooltip, { content: label },
            React.createElement(core_1.Icon, { icon: icon, intent: core_1.Intent.PRIMARY }))));
    switch (i.status) {
        case "unsubmitted":
            return make("Not submitted", "warning-sign");
        case "submitting":
            return make("Submitting", "arrow-up");
        case "error":
            return make("Error submitting to SAFER", "error");
    }
    return undefined;
};
let PIQResultList = /** @class */ (() => {
    class PIQResultList extends React.Component {
        constructor(props) {
            super(props);
            // NOTE: we take start time and end time due to PDF printing inconsistent time zone
            this.openInspection = (inspectionId, startTime, endTime) => __awaiter(this, void 0, void 0, function* () {
                const response = yield RemoteInspectionService_1.RemoteInspectionService.getInspection(inspectionId, startTime);
                if (response.localInspection) {
                    const inspection = replaceInspectionDatetime(response.localInspection, startTime, endTime);
                    const buffer = yield InspectionPrintService_1.inspectionToPdf(response.localInspection, {
                        mode: "full",
                    });
                    this.context.displayPdf(response.localInspection.id, buffer);
                }
                else if (response.t0033) {
                    const buffer = yield InspectionPrintService_1.t0033ToPdf(response.t0033, {
                        mode: "full",
                    });
                    this.context.displayPdf("", buffer);
                }
            });
            this.editInspection = (inspectionId, startTime, endTime) => __awaiter(this, void 0, void 0, function* () {
                const response = yield RemoteInspectionService_1.RemoteInspectionService.getInspection(inspectionId, startTime);
                if (response.localInspection) {
                    const inspection = replaceInspectionDatetime(response.localInspection, startTime, endTime);
                    this.props.loadInspection(inspection, "server", response.submittedStartTime !== undefined &&
                        response.submittedStartTime !== null);
                }
                else
                    this.context.showDialogBox({
                        type: "error",
                        title: "Can't edit inspection",
                        message: "Can't edit inspection because it appears to have been created by a different program.",
                    });
            });
        }
        render() {
            const inspections = this.props.inspections.sort((a, b) => a.reportNumber < b.reportNumber ? -1 : 1);
            return (React.createElement("div", null, inspections.length > 0 && (React.createElement(core_1.HTMLTable, { bordered: true, interactive: true, small: true },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", null, "View"),
                        React.createElement("th", null, "Edit"),
                        React.createElement("th", null, "Report#"),
                        React.createElement("th", null, "Date"))),
                React.createElement("tbody", null, inspections.map((inspection, i) => (React.createElement("tr", { key: i },
                    React.createElement("td", null,
                        React.createElement(core_1.Button, { icon: "print", onClick: () => this.openInspection(inspection.inspectionId || inspection.reportNumber, inspection.startTime, inspection.endTime) })),
                    React.createElement("td", null,
                        React.createElement(core_1.Button, { icon: "edit", onClick: () => this.editInspection(inspection.inspectionId, inspection.startTime, inspection.endTime), disabled: !inspection.inspectionId })),
                    React.createElement("td", null,
                        inspection.reportNumber,
                        createIcon(inspection)),
                    React.createElement("td", null, DateFormat_1.formatDateTime(moment(inspection.startTime).toDate()))))))))));
        }
    }
    PIQResultList.contextType = RimsContext_1.RimsContext;
    return PIQResultList;
})();
exports.default = PIQResultList;
