"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateViolations = exports.validate = void 0;
const DateFormat_1 = require("../../../../services/DateFormat");
const InspectionViolationService_1 = require("../../InspectionViolationService");
const SmartLogicFunctions_1 = require("./SmartLogicFunctions");
const isCat6Violation = (vioCode) => vioCode.startsWith("395.3B") || vioCode.startsWith("395.5B");
exports.validate = (inspStartTime, triggeringViolation, otherViolations, violationDate) => {
    let cantCiteError;
    let violationError;
    let violationWarning;
    const yesterday = new Date(inspStartTime);
    yesterday.setDate(yesterday.getDate() - 1);
    if (otherViolations.filter(x => SmartLogicFunctions_1.is395_8A(x)).length > 0)
        cantCiteError = `You cannot cite ${triggeringViolation.VioFederalRegCode} with a 395.8(a) Violation.`;
    if (triggeringViolation.VioFederalRegCode === "395.8K2" &&
        (DateFormat_1.isSameDate(inspStartTime, violationDate) ||
            DateFormat_1.isSameDate(yesterday, violationDate))) {
        violationError =
            "You cannot cite for 395.8(k)(2) on the current or previous date. See exception in 395.13(b)(3). Use 395.8(f)(1).";
    }
    if (triggeringViolation.VioFederalRegCode === "395.8K2" &&
        otherViolations.filter(x => x.VioFederalRegCode === "395.8K2" && DateFormat_1.isSameDate(violationDate, x.date)).length > 0) {
        violationError = "You cannot cite 395.8(k)(2) twice on the same date.";
    }
    if (triggeringViolation.VioFederalRegCode === "395.8F01" &&
        !(DateFormat_1.isSameDate(inspStartTime, violationDate) ||
            DateFormat_1.isSameDate(yesterday, violationDate))) {
        violationError =
            "You cannot cite for 395.8(f)(1). Consider using 395.8(k)(2) instead.";
    }
    if (triggeringViolation.VioFederalRegCode === "395.8K2" &&
        otherViolations.filter(x => x.VioFederalRegCode && isCat6Violation(x.VioFederalRegCode)).length > 0)
        violationWarning =
            "This violation should not be cited with a 60 / 70 Hour rule violation unless independent evidence exists to cite concurrent hours violation.";
    return {
        errors: {
            cantCite: cantCiteError,
            violation: violationError,
        },
        warnings: {
            violation: violationWarning,
        },
    };
};
exports.updateViolations = (triggeringViolation, violations, additionalDetails, violationDate) => InspectionViolationService_1.UpdateViolation(violations, triggeringViolation.VioSeqID, Object.assign(Object.assign({}, triggeringViolation), { VioOOSFlag: triggeringViolation.VioFederalRegCode === "395.8K2" ? "Y" : "N", SuppVioDesc: additionalDetails, date: violationDate }));
