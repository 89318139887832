"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeDriver = exports.replaceDriver = void 0;
exports.replaceDriver = (drivers, driver) => {
    // Replace driver in array. !! is for pre-release inspections where IsCoDriver could be undefined
    return drivers
        .filter(d => !!d.IsCoDriver !== !!driver.IsCoDriver)
        .concat([driver]);
};
exports.removeDriver = (drivers, codriver) => {
    return drivers.filter(d => !!d.IsCoDriver !== !!codriver);
};
