// Until we can cache from API

import { County, mapLongCountryToShort } from "@rims/common";

const counties = [
  ["AB", "000", "DILLINGHAM", "CA"],
  ["AB", "999", "UNKNOWN", "CA"],
  ["AG", "000", "(AGUASCALIENTES)", "MX"],
  ["AG", "999", "UNKNOWN", "MX"],
  ["AK", "013", "ALEUTIANS EAST", "US"],
  ["AK", "016", "ALEUTIANS WEST", "US"],
  ["AK", "020", "ANCHORAGE", "US"],
  ["AK", "030", "ANGOON", "US"],
  ["AK", "040", "BARROW-NORTH", "US"],
  ["AK", "050", "BETHEL", "US"],
  ["AK", "060", "BRISTOL BAY", "US"],
  ["AK", "080", "CORDOVA-MCCARTHY", "US"],
  ["AK", "068", "DENALI", "US"],
  ["AK", "070", "DILLINGHAM", "US"],
  ["AK", "090", "FAIRBANKS NORTH STAR", "US"],
  ["AK", "100", "HAINES", "US"],
  ["AK", "110", "JUNEAU", "US"],
  ["AK", "122", "KENAI PENINSULA", "US"],
  ["AK", "120", "KENAI-COOK", "US"],
  ["AK", "130", "KETCHIKAN GATEWAY", "US"],
  ["AK", "150", "KODIAK ISLAND", "US"],
  ["AK", "164", "LAKE AND PENINSULA", "US"],
  ["AK", "170", "MATANUSKA SUSITNA", "US"],
  ["AK", "180", "NOME", "US"],
  ["AK", "185", "NORTH SLOPE", "US"],
  ["AK", "188", "NORTHWEST ARCTIC", "US"],
  ["AK", "200", "PRINCE", "US"],
  ["AK", "201", "PRINCE OF WALES-OUTERKE", "US"],
  ["AK", "220", "SITKA", "US"],
  ["AK", "232", "SKAGWAY HOONAH ANGOON", "US"],
  ["AK", "240", "SOUTHEAST FAIRBANKS", "US"],
  ["AK", "999", "UNKNOWN", "US"],
  ["AK", "250", "UPPER", "US"],
  ["AK", "261", "VALDEZ CORDOVA", "US"],
  ["AK", "270", "WADE HAMPTON", "US"],
  ["AK", "280", "WRANGELL PETERSBURG", "US"],
  ["AK", "282", "YAKUTAT", "US"],
  ["AK", "290", "YUKON KOYUKUK", "US"],
  ["AL", "001", "AUTAUGA", "US"],
  ["AL", "003", "BALDWIN", "US"],
  ["AL", "005", "BARBOUR", "US"],
  ["AL", "007", "BIBB", "US"],
  ["AL", "009", "BLOUNT", "US"],
  ["AL", "011", "BULLOCK", "US"],
  ["AL", "013", "BUTLER", "US"],
  ["AL", "015", "CALHOUN", "US"],
  ["AL", "017", "CHAMBERS", "US"],
  ["AL", "019", "CHEROKEE", "US"],
  ["AL", "021", "CHILTON", "US"],
  ["AL", "023", "CHOCTAW", "US"],
  ["AL", "025", "CLARKE", "US"],
  ["AL", "027", "CLAY", "US"],
  ["AL", "029", "CLEBURNE", "US"],
  ["AL", "031", "COFFEE", "US"],
  ["AL", "033", "COLBERT", "US"],
  ["AL", "035", "CONECUH", "US"],
  ["AL", "037", "COOSA", "US"],
  ["AL", "039", "COVINGTON", "US"],
  ["AL", "041", "CRENSHAW", "US"],
  ["AL", "043", "CULLMAN", "US"],
  ["AL", "045", "DALE", "US"],
  ["AL", "047", "DALLAS", "US"],
  ["AL", "049", "DE KALB", "US"],
  ["AL", "051", "ELMORE", "US"],
  ["AL", "053", "ESCAMBIA", "US"],
  ["AL", "055", "ETOWAH", "US"],
  ["AL", "057", "FAYETTE", "US"],
  ["AL", "059", "FRANKLIN", "US"],
  ["AL", "061", "GENEVA", "US"],
  ["AL", "063", "GREENE", "US"],
  ["AL", "065", "HALE", "US"],
  ["AL", "067", "HENRY", "US"],
  ["AL", "069", "HOUSTON", "US"],
  ["AL", "071", "JACKSON", "US"],
  ["AL", "073", "JEFFERSON", "US"],
  ["AL", "075", "LAMAR", "US"],
  ["AL", "077", "LAUDERDALE", "US"],
  ["AL", "079", "LAWRENCE", "US"],
  ["AL", "081", "LEE", "US"],
  ["AL", "083", "LIMESTONE", "US"],
  ["AL", "085", "LOWNDES", "US"],
  ["AL", "087", "MACON", "US"],
  ["AL", "089", "MADISON", "US"],
  ["AL", "091", "MARENGO", "US"],
  ["AL", "093", "MARION", "US"],
  ["AL", "095", "MARSHALL", "US"],
  ["AL", "097", "MOBILE", "US"],
  ["AL", "099", "MONROE", "US"],
  ["AL", "101", "MONTGOMERY", "US"],
  ["AL", "103", "MORGAN", "US"],
  ["AL", "105", "PERRY", "US"],
  ["AL", "107", "PICKENS", "US"],
  ["AL", "109", "PIKE", "US"],
  ["AL", "111", "RANDOLPH", "US"],
  ["AL", "113", "RUSSELL", "US"],
  ["AL", "115", "SAINT CLAIR", "US"],
  ["AL", "117", "SHELBY", "US"],
  ["AL", "119", "SUMTER", "US"],
  ["AL", "121", "TALLADEGA", "US"],
  ["AL", "123", "TALLAPOOSA", "US"],
  ["AL", "125", "TUSCALOOSA", "US"],
  ["AL", "999", "UNKNOWN", "US"],
  ["AL", "127", "WALKER", "US"],
  ["AL", "129", "WASHINGTON", "US"],
  ["AL", "131", "WILCOX", "US"],
  ["AL", "133", "WINSTON", "US"],
  ["AR", "001", "ARKANSAS", "US"],
  ["AR", "003", "ASHLEY", "US"],
  ["AR", "005", "BAXTER", "US"],
  ["AR", "007", "BENTON", "US"],
  ["AR", "009", "BOONE", "US"],
  ["AR", "011", "BRADLEY", "US"],
  ["AR", "013", "CALHOUN", "US"],
  ["AR", "015", "CARROLL", "US"],
  ["AR", "017", "CHICOT", "US"],
  ["AR", "019", "CLARK", "US"],
  ["AR", "021", "CLAY", "US"],
  ["AR", "023", "CLEBURNE", "US"],
  ["AR", "025", "CLEVELAND", "US"],
  ["AR", "027", "COLUMBIA", "US"],
  ["AR", "029", "CONWAY", "US"],
  ["AR", "031", "CRAIGHEAD", "US"],
  ["AR", "033", "CRAWFORD", "US"],
  ["AR", "035", "CRITTENDEN", "US"],
  ["AR", "037", "CROSS", "US"],
  ["AR", "039", "DALLAS", "US"],
  ["AR", "041", "DESHA", "US"],
  ["AR", "043", "DREW", "US"],
  ["AR", "045", "FAULKNER", "US"],
  ["AR", "047", "FRANKLIN", "US"],
  ["AR", "049", "FULTON", "US"],
  ["AR", "051", "GARLAND", "US"],
  ["AR", "053", "GRANT", "US"],
  ["AR", "055", "GREENE", "US"],
  ["AR", "057", "HEMPSTEAD", "US"],
  ["AR", "059", "HOT SPRING", "US"],
  ["AR", "061", "HOWARD", "US"],
  ["AR", "063", "INDEPENDENCE", "US"],
  ["AR", "065", "IZARD", "US"],
  ["AR", "067", "JACKSON", "US"],
  ["AR", "069", "JEFFERSON", "US"],
  ["AR", "071", "JOHNSON", "US"],
  ["AR", "073", "LAFAYETTE", "US"],
  ["AR", "075", "LAWRENCE", "US"],
  ["AR", "077", "LEE", "US"],
  ["AR", "079", "LINCOLN", "US"],
  ["AR", "081", "LITTLE RIVER", "US"],
  ["AR", "083", "LOGAN", "US"],
  ["AR", "085", "LONOKE", "US"],
  ["AR", "087", "MADISON", "US"],
  ["AR", "089", "MARION", "US"],
  ["AR", "091", "MILLER", "US"],
  ["AR", "093", "MISSISSIPPI", "US"],
  ["AR", "095", "MONROE", "US"],
  ["AR", "097", "MONTGOMERY", "US"],
  ["AR", "099", "NEVADA", "US"],
  ["AR", "101", "NEWTON", "US"],
  ["AR", "103", "OUACHITA", "US"],
  ["AR", "105", "PERRY", "US"],
  ["AR", "107", "PHILLIPS", "US"],
  ["AR", "109", "PIKE", "US"],
  ["AR", "111", "POINSETT", "US"],
  ["AR", "113", "POLK", "US"],
  ["AR", "115", "POPE", "US"],
  ["AR", "117", "PRAIRIE", "US"],
  ["AR", "119", "PULASKI", "US"],
  ["AR", "121", "RANDOLPH", "US"],
  ["AR", "123", "SAINT FRANCIS", "US"],
  ["AR", "125", "SALINE", "US"],
  ["AR", "127", "SCOTT", "US"],
  ["AR", "129", "SEARCY", "US"],
  ["AR", "131", "SEBASTIAN", "US"],
  ["AR", "133", "SEVIER", "US"],
  ["AR", "135", "SHARP", "US"],
  ["AR", "137", "STONE", "US"],
  ["AR", "139", "UNION", "US"],
  ["AR", "999", "UNKNOWN", "US"],
  ["AR", "141", "VAN BUREN", "US"],
  ["AR", "143", "WASHINGTON", "US"],
  ["AR", "145", "WHITE", "US"],
  ["AR", "147", "WOODRUFF", "US"],
  ["AR", "149", "YELL", "US"],
  ["AS", "030", "Central", "US"],
  ["AS", "010", "EASTERN", "US"],
  ["AS", "020", "MANU'A", "US"],
  ["AS", "040", "SWAINS ISLAND", "US"],
  ["AS", "999", "UNKNOWN", "US"],
  ["AS", "050", "Western", "US"],
  ["AZ", "001", "APACHE", "US"],
  ["AZ", "003", "COCHISE", "US"],
  ["AZ", "005", "COCONINO", "US"],
  ["AZ", "007", "GILA", "US"],
  ["AZ", "009", "GRAHAM", "US"],
  ["AZ", "011", "GREENLEE", "US"],
  ["AZ", "012", "LA PAZ", "US"],
  ["AZ", "013", "MARICOPA", "US"],
  ["AZ", "015", "MOHAVE", "US"],
  ["AZ", "017", "NAVAJO", "US"],
  ["AZ", "019", "PIMA", "US"],
  ["AZ", "021", "PINAL", "US"],
  ["AZ", "023", "SANTA CRUZ", "US"],
  ["AZ", "999", "UNKNOWN", "US"],
  ["AZ", "025", "YAVAPAI", "US"],
  ["AZ", "027", "YUMA", "US"],
  ["BC", "000", "(BRITISH COLUMBIA)", "CA"],
  ["BC", "999", "UNKNOWN", "CA"],
  ["BN", "000", "(BAHA CALIFORNIA NORTE)", "MX"],
  ["BN", "999", "UNKNOWN", "MX"],
  ["BS", "000", "(BAHA CALIFORNIA SUR)", "MX"],
  ["BS", "999", "UNKNOWN", "MX"],
  ["BZ", "000", "(BELIZE)", "O"],
  ["BZ", "999", "UNKNOWN", "O"],
  ["CA", "001", "ALAMEDA", "US"],
  ["CA", "003", "ALPINE", "US"],
  ["CA", "005", "AMADOR", "US"],
  ["CA", "007", "BUTTE", "US"],
  ["CA", "009", "CALAVERAS", "US"],
  ["CA", "011", "COLUSA", "US"],
  ["CA", "013", "CONTRA COSTA", "US"],
  ["CA", "015", "DEL NORTE", "US"],
  ["CA", "017", "EL DORADO", "US"],
  ["CA", "019", "FRESNO", "US"],
  ["CA", "021", "GLENN", "US"],
  ["CA", "023", "HUMBOLDT", "US"],
  ["CA", "025", "IMPERIAL", "US"],
  ["CA", "027", "INYO", "US"],
  ["CA", "029", "KERN", "US"],
  ["CA", "031", "KINGS", "US"],
  ["CA", "033", "LAKE", "US"],
  ["CA", "035", "LASSEN", "US"],
  ["CA", "037", "LOS ANGELES", "US"],
  ["CA", "039", "MADERA", "US"],
  ["CA", "041", "MARIN", "US"],
  ["CA", "043", "MARIPOSA", "US"],
  ["CA", "045", "MENDOCINO", "US"],
  ["CA", "047", "MERCED", "US"],
  ["CA", "049", "MODOC", "US"],
  ["CA", "051", "MONO", "US"],
  ["CA", "053", "MONTEREY", "US"],
  ["CA", "055", "NAPA", "US"],
  ["CA", "057", "NEVADA", "US"],
  ["CA", "059", "ORANGE", "US"],
  ["CA", "061", "PLACER", "US"],
  ["CA", "063", "PLUMAS", "US"],
  ["CA", "065", "RIVERSIDE", "US"],
  ["CA", "067", "SACRAMENTO", "US"],
  ["CA", "069", "SAN BENITO", "US"],
  ["CA", "071", "SAN BERNARDINO", "US"],
  ["CA", "073", "SAN DIEGO", "US"],
  ["CA", "075", "SAN FRANCISCO", "US"],
  ["CA", "077", "SAN JOAQUIN", "US"],
  ["CA", "079", "SAN LUIS OBISPO", "US"],
  ["CA", "081", "SAN MATEO", "US"],
  ["CA", "083", "SANTA BARBARA", "US"],
  ["CA", "085", "SANTA CLARA", "US"],
  ["CA", "087", "SANTA CRUZ", "US"],
  ["CA", "089", "SHASTA", "US"],
  ["CA", "091", "SIERRA", "US"],
  ["CA", "093", "SISKIYOU", "US"],
  ["CA", "095", "SOLANO", "US"],
  ["CA", "097", "SONOMA", "US"],
  ["CA", "099", "STANISLAUS", "US"],
  ["CA", "101", "SUTTER", "US"],
  ["CA", "103", "TEHAMA", "US"],
  ["CA", "105", "TRINITY", "US"],
  ["CA", "107", "TULARE", "US"],
  ["CA", "109", "TUOLUMNE", "US"],
  ["CA", "999", "UNKNOWN", "US"],
  ["CA", "111", "VENTURA", "US"],
  ["CA", "113", "YOLO", "US"],
  ["CA", "115", "YUBA", "US"],
  ["CH", "000", "(COAHUOLIA)", "MX"],
  ["CH", "999", "UNKNOWN", "MX"],
  ["CI", "000", "(COLINA)", "MX"],
  ["CI", "999", "UNKNOWN", "MX"],
  ["CL", "000", "(COLIMA)", "MX"],
  ["CL", "999", "UNKNOWN", "MX"],
  ["CO", "001", "ADAMS", "US"],
  ["CO", "003", "ALAMOSA", "US"],
  ["CO", "005", "ARAPAHOE", "US"],
  ["CO", "007", "ARCHULETA", "US"],
  ["CO", "009", "BACA", "US"],
  ["CO", "011", "BENT", "US"],
  ["CO", "013", "BOULDER", "US"],
  ["CO", "014", "BROOMFIELD", "US"],
  ["CO", "015", "CHAFFEE", "US"],
  ["CO", "017", "CHEYENNE", "US"],
  ["CO", "019", "CLEAR CREEK", "US"],
  ["CO", "021", "CONEJOS", "US"],
  ["CO", "023", "COSTILLA", "US"],
  ["CO", "025", "CROWLEY", "US"],
  ["CO", "027", "CUSTER", "US"],
  ["CO", "029", "DELTA", "US"],
  ["CO", "031", "DENVER", "US"],
  ["CO", "033", "DOLORES", "US"],
  ["CO", "035", "DOUGLAS", "US"],
  ["CO", "037", "EAGLE", "US"],
  ["CO", "041", "EL PASO", "US"],
  ["CO", "039", "ELBERT", "US"],
  ["CO", "043", "FREMONT", "US"],
  ["CO", "045", "GARFIELD", "US"],
  ["CO", "047", "GILPIN", "US"],
  ["CO", "049", "GRAND", "US"],
  ["CO", "051", "GUNNISON", "US"],
  ["CO", "053", "HINSDALE", "US"],
  ["CO", "055", "HUERFANO", "US"],
  ["CO", "057", "JACKSON", "US"],
  ["CO", "059", "JEFFERSON", "US"],
  ["CO", "061", "KIOWA", "US"],
  ["CO", "063", "KIT CARSON", "US"],
  ["CO", "067", "LA PLATA", "US"],
  ["CO", "065", "LAKE", "US"],
  ["CO", "069", "LARIMER", "US"],
  ["CO", "071", "LAS ANIMAS", "US"],
  ["CO", "073", "LINCOLN", "US"],
  ["CO", "075", "LOGAN", "US"],
  ["CO", "077", "MESA", "US"],
  ["CO", "079", "MINERAL", "US"],
  ["CO", "081", "MOFFAT", "US"],
  ["CO", "083", "MONTEZUMA", "US"],
  ["CO", "085", "MONTROSE", "US"],
  ["CO", "087", "MORGAN", "US"],
  ["CO", "089", "OTERO", "US"],
  ["CO", "091", "OURAY", "US"],
  ["CO", "093", "PARK", "US"],
  ["CO", "095", "PHILLIPS", "US"],
  ["CO", "097", "PITKIN", "US"],
  ["CO", "099", "PROWERS", "US"],
  ["CO", "101", "PUEBLO", "US"],
  ["CO", "103", "RIO BLANCO", "US"],
  ["CO", "105", "RIO GRANDE", "US"],
  ["CO", "107", "ROUTT", "US"],
  ["CO", "109", "SAGUACHE", "US"],
  ["CO", "111", "SAN JUAN", "US"],
  ["CO", "113", "SAN MIGUEL", "US"],
  ["CO", "115", "SEDGWICK", "US"],
  ["CO", "117", "SUMMIT", "US"],
  ["CO", "119", "TELLER", "US"],
  ["CO", "999", "UNKNOWN", "US"],
  ["CO", "121", "WASHINGTON", "US"],
  ["CO", "123", "WELD", "US"],
  ["CO", "125", "YUMA", "US"],
  ["CP", "000", "(CAMPECHE)", "MX"],
  ["CP", "999", "UNKNOWN", "MX"],
  ["CR", "000", "(COSTA RICA)", "O"],
  ["CR", "999", "UNKNOWN", "O"],
  ["CS", "000", "(CHIAPAS)", "MX"],
  ["CS", "999", "UNKNOWN", "MX"],
  ["CT", "001", "FAIRFIELD", "US"],
  ["CT", "003", "HARTFORD", "US"],
  ["CT", "005", "LITCHFIELD", "US"],
  ["CT", "007", "MIDDLESEX", "US"],
  ["CT", "009", "NEW HAVEN", "US"],
  ["CT", "011", "NEW LONDON", "US"],
  ["CT", "013", "TOLLAND", "US"],
  ["CT", "999", "UNKNOWN", "US"],
  ["CT", "015", "WINDHAM", "US"],
  ["CZ", "000", "(CANAL ZONE)", "O"],
  ["CZ", "999", "UNKNOWN", "O"],
  ["DC", "001", "DISTRICT OF COLUMBIA", "US"],
  ["DC", "999", "UNKNOWN", "US"],
  ["DE", "001", "KENT", "US"],
  ["DE", "003", "NEW CASTLE", "US"],
  ["DE", "005", "SUSSEX", "US"],
  ["DE", "999", "UNKNOWN", "US"],
  ["DF", "000", "(DISTRITO FEDERAL)", "MX"],
  ["DF", "999", "UNKNOWN", "MX"],
  ["DG", "000", "(DURANGO)", "MX"],
  ["DG", "999", "UNKNOWN", "MX"],
  ["ES", "000", "(EL SALVADOR)", "O"],
  ["ES", "999", "UNKNOWN", "O"],
  ["FL", "001", "ALACHUA", "US"],
  ["FL", "003", "BAKER", "US"],
  ["FL", "005", "BAY", "US"],
  ["FL", "007", "BRADFORD", "US"],
  ["FL", "009", "BREVARD", "US"],
  ["FL", "011", "BROWARD", "US"],
  ["FL", "013", "CALHOUN", "US"],
  ["FL", "015", "CHARLOTTE", "US"],
  ["FL", "017", "CITRUS", "US"],
  ["FL", "019", "CLAY", "US"],
  ["FL", "021", "COLLIER", "US"],
  ["FL", "023", "COLUMBIA", "US"],
  ["FL", "027", "DE SOTO", "US"],
  ["FL", "029", "DIXIE", "US"],
  ["FL", "031", "DUVAL", "US"],
  ["FL", "033", "ESCAMBIA", "US"],
  ["FL", "035", "FLAGLER", "US"],
  ["FL", "037", "FRANKLIN", "US"],
  ["FL", "039", "GADSDEN", "US"],
  ["FL", "041", "GILCHRIST", "US"],
  ["FL", "043", "GLADES", "US"],
  ["FL", "045", "GULF", "US"],
  ["FL", "047", "HAMILTON", "US"],
  ["FL", "049", "HARDEE", "US"],
  ["FL", "051", "HENDRY", "US"],
  ["FL", "053", "HERNANDO", "US"],
  ["FL", "055", "HIGHLANDS", "US"],
  ["FL", "057", "HILLSBOROUGH", "US"],
  ["FL", "059", "HOLMES", "US"],
  ["FL", "061", "INDIAN RIVER", "US"],
  ["FL", "063", "JACKSON", "US"],
  ["FL", "065", "JEFFERSON", "US"],
  ["FL", "067", "LAFAYETTE", "US"],
  ["FL", "069", "LAKE", "US"],
  ["FL", "071", "LEE", "US"],
  ["FL", "073", "LEON", "US"],
  ["FL", "075", "LEVY", "US"],
  ["FL", "077", "LIBERTY", "US"],
  ["FL", "079", "MADISON", "US"],
  ["FL", "081", "MANATEE", "US"],
  ["FL", "083", "MARION", "US"],
  ["FL", "085", "MARTIN", "US"],
  ["FL", "086", "MIAMI-DADE", "US"],
  ["FL", "087", "MONROE", "US"],
  ["FL", "089", "NASSAU", "US"],
  ["FL", "091", "OKALOOSA", "US"],
  ["FL", "093", "OKEECHOBEE", "US"],
  ["FL", "095", "ORANGE", "US"],
  ["FL", "097", "OSCEOLA", "US"],
  ["FL", "099", "PALM BEACH", "US"],
  ["FL", "101", "PASCO", "US"],
  ["FL", "103", "PINELLAS", "US"],
  ["FL", "105", "POLK", "US"],
  ["FL", "107", "PUTNAM", "US"],
  ["FL", "109", "SAINT JOHNS", "US"],
  ["FL", "111", "SAINT LUCIE", "US"],
  ["FL", "113", "SANTA ROSA", "US"],
  ["FL", "115", "SARASOTA", "US"],
  ["FL", "117", "SEMINOLE", "US"],
  ["FL", "119", "SUMTER", "US"],
  ["FL", "121", "SUWANNEE", "US"],
  ["FL", "123", "TAYLOR", "US"],
  ["FL", "125", "UNION", "US"],
  ["FL", "999", "UNKNOWN", "US"],
  ["FL", "127", "VOLUSIA", "US"],
  ["FL", "129", "WAKULLA", "US"],
  ["FL", "131", "WALTON", "US"],
  ["FL", "133", "WASHINGTON", "US"],
  ["GA", "001", "APPLING", "US"],
  ["GA", "003", "ATKINSON", "US"],
  ["GA", "005", "BACON", "US"],
  ["GA", "007", "BAKER", "US"],
  ["GA", "009", "BALDWIN", "US"],
  ["GA", "011", "BANKS", "US"],
  ["GA", "013", "BARROW", "US"],
  ["GA", "015", "BARTOW", "US"],
  ["GA", "017", "BEN HILL", "US"],
  ["GA", "019", "BERRIEN", "US"],
  ["GA", "021", "BIBB", "US"],
  ["GA", "023", "BLECKLEY", "US"],
  ["GA", "025", "BRANTLEY", "US"],
  ["GA", "027", "BROOKS", "US"],
  ["GA", "029", "BRYAN", "US"],
  ["GA", "031", "BULLOCH", "US"],
  ["GA", "033", "BURKE", "US"],
  ["GA", "035", "BUTTS", "US"],
  ["GA", "037", "CALHOUN", "US"],
  ["GA", "039", "CAMDEN", "US"],
  ["GA", "043", "CANDLER", "US"],
  ["GA", "045", "CARROLL", "US"],
  ["GA", "047", "CATOOSA", "US"],
  ["GA", "049", "CHARLTON", "US"],
  ["GA", "051", "CHATHAM", "US"],
  ["GA", "053", "CHATTAHOOCHEE", "US"],
  ["GA", "055", "CHATTOOGA", "US"],
  ["GA", "057", "CHEROKEE", "US"],
  ["GA", "059", "CLARKE", "US"],
  ["GA", "061", "CLAY", "US"],
  ["GA", "063", "CLAYTON", "US"],
  ["GA", "065", "CLINCH", "US"],
  ["GA", "067", "COBB", "US"],
  ["GA", "069", "COFFEE", "US"],
  ["GA", "071", "COLQUITT", "US"],
  ["GA", "073", "COLUMBIA", "US"],
  ["GA", "510", "COLUMBUS", "US"],
  ["GA", "075", "COOK", "US"],
  ["GA", "077", "COWETA", "US"],
  ["GA", "079", "CRAWFORD", "US"],
  ["GA", "081", "CRISP", "US"],
  ["GA", "083", "DADE", "US"],
  ["GA", "085", "DAWSON", "US"],
  ["GA", "087", "DECATUR", "US"],
  ["GA", "089", "DEKALB", "US"],
  ["GA", "091", "DODGE", "US"],
  ["GA", "093", "DOOLY", "US"],
  ["GA", "095", "DOUGHERTY", "US"],
  ["GA", "097", "DOUGLAS", "US"],
  ["GA", "099", "EARLY", "US"],
  ["GA", "101", "ECHOLS", "US"],
  ["GA", "103", "EFFINGHAM", "US"],
  ["GA", "105", "ELBERT", "US"],
  ["GA", "107", "EMANUEL", "US"],
  ["GA", "109", "EVANS", "US"],
  ["GA", "111", "FANNIN", "US"],
  ["GA", "113", "FAYETTE", "US"],
  ["GA", "115", "FLOYD", "US"],
  ["GA", "117", "FORSYTH", "US"],
  ["GA", "119", "FRANKLIN", "US"],
  ["GA", "121", "FULTON", "US"],
  ["GA", "123", "GILMER", "US"],
  ["GA", "125", "GLASCOCK", "US"],
  ["GA", "127", "GLYNN", "US"],
  ["GA", "129", "GORDON", "US"],
  ["GA", "131", "GRADY", "US"],
  ["GA", "133", "GREENE", "US"],
  ["GA", "135", "GWINNETT", "US"],
  ["GA", "137", "HABERSHAM", "US"],
  ["GA", "139", "HALL", "US"],
  ["GA", "141", "HANCOCK", "US"],
  ["GA", "143", "HARALSON", "US"],
  ["GA", "145", "HARRIS", "US"],
  ["GA", "147", "HART", "US"],
  ["GA", "149", "HEARD", "US"],
  ["GA", "151", "HENRY", "US"],
  ["GA", "153", "HOUSTON", "US"],
  ["GA", "155", "IRWIN", "US"],
  ["GA", "157", "JACKSON", "US"],
  ["GA", "159", "JASPER", "US"],
  ["GA", "161", "JEFF DAVIS", "US"],
  ["GA", "163", "JEFFERSON", "US"],
  ["GA", "165", "JENKINS", "US"],
  ["GA", "167", "JOHNSON", "US"],
  ["GA", "169", "JONES", "US"],
  ["GA", "171", "LAMAR", "US"],
  ["GA", "173", "LANIER", "US"],
  ["GA", "175", "LAURENS", "US"],
  ["GA", "177", "LEE", "US"],
  ["GA", "179", "LIBERTY", "US"],
  ["GA", "181", "LINCOLN", "US"],
  ["GA", "183", "LONG", "US"],
  ["GA", "185", "LOWNDES", "US"],
  ["GA", "187", "LUMPKIN", "US"],
  ["GA", "193", "MACON", "US"],
  ["GA", "195", "MADISON", "US"],
  ["GA", "197", "MARION", "US"],
  ["GA", "189", "MCDUFFIE", "US"],
  ["GA", "191", "MCINTOSH", "US"],
  ["GA", "199", "MERIWETHER", "US"],
  ["GA", "201", "MILLER", "US"],
  ["GA", "205", "MITCHELL", "US"],
  ["GA", "207", "MONROE", "US"],
  ["GA", "209", "MONTGOMERY", "US"],
  ["GA", "211", "MORGAN", "US"],
  ["GA", "213", "MURRAY", "US"],
  ["GA", "215", "MUSCOGEE", "US"],
  ["GA", "217", "NEWTON", "US"],
  ["GA", "219", "OCONEE", "US"],
  ["GA", "221", "OGLETHORPE", "US"],
  ["GA", "223", "PAULDING", "US"],
  ["GA", "225", "PEACH", "US"],
  ["GA", "227", "PICKENS", "US"],
  ["GA", "229", "PIERCE", "US"],
  ["GA", "231", "PIKE", "US"],
  ["GA", "233", "POLK", "US"],
  ["GA", "235", "PULASKI", "US"],
  ["GA", "237", "PUTNAM", "US"],
  ["GA", "239", "QUITMAN", "US"],
  ["GA", "241", "RABUN", "US"],
  ["GA", "243", "RANDOLPH", "US"],
  ["GA", "245", "RICHMOND", "US"],
  ["GA", "247", "ROCKDALE", "US"],
  ["GA", "249", "SCHLEY", "US"],
  ["GA", "251", "SCREVEN", "US"],
  ["GA", "253", "SEMINOLE", "US"],
  ["GA", "255", "SPALDING", "US"],
  ["GA", "257", "STEPHENS", "US"],
  ["GA", "259", "STEWART", "US"],
  ["GA", "261", "SUMTER", "US"],
  ["GA", "263", "TALBOT", "US"],
  ["GA", "265", "TALIAFERRO", "US"],
  ["GA", "267", "TATTNALL", "US"],
  ["GA", "269", "TAYLOR", "US"],
  ["GA", "271", "TELFAIR", "US"],
  ["GA", "273", "TERRELL", "US"],
  ["GA", "275", "THOMAS", "US"],
  ["GA", "277", "TIFT", "US"],
  ["GA", "279", "TOOMBS", "US"],
  ["GA", "281", "TOWNS", "US"],
  ["GA", "283", "TREUTLEN", "US"],
  ["GA", "285", "TROUP", "US"],
  ["GA", "287", "TURNER", "US"],
  ["GA", "289", "TWIGGS", "US"],
  ["GA", "291", "UNION", "US"],
  ["GA", "999", "UNKNOWN", "US"],
  ["GA", "293", "UPSON", "US"],
  ["GA", "295", "WALKER", "US"],
  ["GA", "297", "WALTON", "US"],
  ["GA", "299", "WARE", "US"],
  ["GA", "301", "WARREN", "US"],
  ["GA", "303", "WASHINGTON", "US"],
  ["GA", "305", "WAYNE", "US"],
  ["GA", "307", "WEBSTER", "US"],
  ["GA", "309", "WHEELER", "US"],
  ["GA", "311", "WHITE", "US"],
  ["GA", "313", "WHITFIELD", "US"],
  ["GA", "315", "WILCOX", "US"],
  ["GA", "317", "WILKES", "US"],
  ["GA", "319", "WILKINSON", "US"],
  ["GA", "321", "WORTH", "US"],
  ["GE", "000", "(GUERRERO)", "MX"],
  ["GE", "999", "UNKNOWN", "MX"],
  ["GJ", "000", "(GUANAJUATO)", "MX"],
  ["GJ", "999", "UNKNOWN", "MX"],
  ["GT", "000", "(GUATEMALA)", "O"],
  ["GT", "999", "UNKNOWN", "O"],
  ["GU", "010", "GUAM", "US"],
  ["GU", "999", "UNKNOWN", "US"],
  ["HD", "000", "(HIDALGO)", "MX"],
  ["HD", "999", "UNKNOWN", "MX"],
  ["HI", "001", "HAWAII", "US"],
  ["HI", "003", "HONOLULU", "US"],
  ["HI", "005", "KALAWAO", "US"],
  ["HI", "007", "KAUAI", "US"],
  ["HI", "009", "MAUI", "US"],
  ["HI", "999", "UNKNOWN", "US"],
  ["HO", "000", "(HONDURAS)", "O"],
  ["HO", "999", "UNKNOWN", "O"],
  ["IA", "001", "ADAIR", "US"],
  ["IA", "003", "ADAMS", "US"],
  ["IA", "005", "ALLAMAKEE", "US"],
  ["IA", "007", "APPANOOSE", "US"],
  ["IA", "009", "AUDUBON", "US"],
  ["IA", "011", "BENTON", "US"],
  ["IA", "013", "BLACK HAWK", "US"],
  ["IA", "015", "BOONE", "US"],
  ["IA", "017", "BREMER", "US"],
  ["IA", "019", "BUCHANAN", "US"],
  ["IA", "021", "BUENA VISTA", "US"],
  ["IA", "023", "BUTLER", "US"],
  ["IA", "025", "CALHOUN", "US"],
  ["IA", "027", "CARROLL", "US"],
  ["IA", "029", "CASS", "US"],
  ["IA", "031", "CEDAR", "US"],
  ["IA", "033", "CERRO GORDO", "US"],
  ["IA", "035", "CHEROKEE", "US"],
  ["IA", "037", "CHICKASAW", "US"],
  ["IA", "039", "CLARKE", "US"],
  ["IA", "041", "CLAY", "US"],
  ["IA", "043", "CLAYTON", "US"],
  ["IA", "045", "CLINTON", "US"],
  ["IA", "047", "CRAWFORD", "US"],
  ["IA", "049", "DALLAS", "US"],
  ["IA", "051", "DAVIS", "US"],
  ["IA", "053", "DECATUR", "US"],
  ["IA", "055", "DELAWARE", "US"],
  ["IA", "057", "DES MOINES", "US"],
  ["IA", "059", "DICKINSON", "US"],
  ["IA", "061", "DUBUQUE", "US"],
  ["IA", "063", "EMMET", "US"],
  ["IA", "065", "FAYETTE", "US"],
  ["IA", "067", "FLOYD", "US"],
  ["IA", "069", "FRANKLIN", "US"],
  ["IA", "071", "FREMONT", "US"],
  ["IA", "073", "GREENE", "US"],
  ["IA", "075", "GRUNDY", "US"],
  ["IA", "077", "GUTHRIE", "US"],
  ["IA", "079", "HAMILTON", "US"],
  ["IA", "081", "HANCOCK", "US"],
  ["IA", "083", "HARDIN", "US"],
  ["IA", "085", "HARRISON", "US"],
  ["IA", "087", "HENRY", "US"],
  ["IA", "089", "HOWARD", "US"],
  ["IA", "091", "HUMBOLDT", "US"],
  ["IA", "093", "IDA", "US"],
  ["IA", "095", "IOWA", "US"],
  ["IA", "097", "JACKSON", "US"],
  ["IA", "099", "JASPER", "US"],
  ["IA", "101", "JEFFERSON", "US"],
  ["IA", "103", "JOHNSON", "US"],
  ["IA", "105", "JONES", "US"],
  ["IA", "107", "KEOKUK", "US"],
  ["IA", "109", "KOSSUTH", "US"],
  ["IA", "111", "LEE", "US"],
  ["IA", "113", "LINN", "US"],
  ["IA", "115", "LOUISA", "US"],
  ["IA", "117", "LUCAS", "US"],
  ["IA", "119", "LYON", "US"],
  ["IA", "121", "MADISON", "US"],
  ["IA", "123", "MAHASKA", "US"],
  ["IA", "125", "MARION", "US"],
  ["IA", "127", "MARSHALL", "US"],
  ["IA", "129", "MILLS", "US"],
  ["IA", "131", "MITCHELL", "US"],
  ["IA", "133", "MONONA", "US"],
  ["IA", "135", "MONROE", "US"],
  ["IA", "137", "MONTGOMERY", "US"],
  ["IA", "139", "MUSCATINE", "US"],
  ["IA", "141", "OBRIEN", "US"],
  ["IA", "143", "OSCEOLA", "US"],
  ["IA", "145", "PAGE", "US"],
  ["IA", "147", "PALO ALTO", "US"],
  ["IA", "149", "PLYMOUTH", "US"],
  ["IA", "151", "POCAHONTAS", "US"],
  ["IA", "153", "POLK", "US"],
  ["IA", "155", "POTTAWATTAMIE", "US"],
  ["IA", "157", "POWESHIEK", "US"],
  ["IA", "159", "RINGGOLD", "US"],
  ["IA", "161", "SAC", "US"],
  ["IA", "163", "SCOTT", "US"],
  ["IA", "165", "SHELBY", "US"],
  ["IA", "167", "SIOUX", "US"],
  ["IA", "169", "STORY", "US"],
  ["IA", "171", "TAMA", "US"],
  ["IA", "173", "TAYLOR", "US"],
  ["IA", "175", "UNION", "US"],
  ["IA", "999", "UNKNOWN", "US"],
  ["IA", "177", "VAN BUREN", "US"],
  ["IA", "179", "WAPELLO", "US"],
  ["IA", "181", "WARREN", "US"],
  ["IA", "183", "WASHINGTON", "US"],
  ["IA", "185", "WAYNE", "US"],
  ["IA", "187", "WEBSTER", "US"],
  ["IA", "189", "WINNEBAGO", "US"],
  ["IA", "191", "WINNESHIEK", "US"],
  ["IA", "193", "WOODBURY", "US"],
  ["IA", "195", "WORTH", "US"],
  ["IA", "197", "WRIGHT", "US"],
  ["ID", "001", "ADA", "US"],
  ["ID", "003", "ADAMS", "US"],
  ["ID", "005", "BANNOCK", "US"],
  ["ID", "007", "BEAR LAKE", "US"],
  ["ID", "009", "BENEWAH", "US"],
  ["ID", "011", "BINGHAM", "US"],
  ["ID", "013", "BLAINE", "US"],
  ["ID", "015", "BOISE", "US"],
  ["ID", "017", "BONNER", "US"],
  ["ID", "019", "BONNEVILLE", "US"],
  ["ID", "021", "BOUNDARY", "US"],
  ["ID", "023", "BUTTE", "US"],
  ["ID", "025", "CAMAS", "US"],
  ["ID", "027", "CANYON", "US"],
  ["ID", "029", "CARIBOU", "US"],
  ["ID", "031", "CASSIA", "US"],
  ["ID", "033", "CLARK", "US"],
  ["ID", "035", "CLEARWATER", "US"],
  ["ID", "037", "CUSTER", "US"],
  ["ID", "039", "ELMORE", "US"],
  ["ID", "041", "FRANKLIN", "US"],
  ["ID", "043", "FREMONT", "US"],
  ["ID", "045", "GEM", "US"],
  ["ID", "047", "GOODING", "US"],
  ["ID", "049", "IDAHO", "US"],
  ["ID", "051", "JEFFERSON", "US"],
  ["ID", "053", "JEROME", "US"],
  ["ID", "055", "KOOTENAI", "US"],
  ["ID", "057", "LATAH", "US"],
  ["ID", "059", "LEMHI", "US"],
  ["ID", "061", "LEWIS", "US"],
  ["ID", "063", "LINCOLN", "US"],
  ["ID", "065", "MADISON", "US"],
  ["ID", "067", "MINIDOKA", "US"],
  ["ID", "069", "NEZ PERCE", "US"],
  ["ID", "071", "ONEIDA", "US"],
  ["ID", "073", "OWYHEE", "US"],
  ["ID", "075", "PAYETTE", "US"],
  ["ID", "077", "POWER", "US"],
  ["ID", "079", "SHOSHONE", "US"],
  ["ID", "081", "TETON", "US"],
  ["ID", "083", "TWIN FALLS", "US"],
  ["ID", "999", "UNKNOWN", "US"],
  ["ID", "085", "VALLEY", "US"],
  ["ID", "087", "WASHINGTON", "US"],
  ["IL", "001", "ADAMS", "US"],
  ["IL", "003", "ALEXANDER", "US"],
  ["IL", "005", "BOND", "US"],
  ["IL", "007", "BOONE", "US"],
  ["IL", "009", "BROWN", "US"],
  ["IL", "011", "BUREAU", "US"],
  ["IL", "013", "CALHOUN", "US"],
  ["IL", "015", "CARROLL", "US"],
  ["IL", "017", "CASS", "US"],
  ["IL", "019", "CHAMPAIGN", "US"],
  ["IL", "021", "CHRISTIAN", "US"],
  ["IL", "023", "CLARK", "US"],
  ["IL", "025", "CLAY", "US"],
  ["IL", "027", "CLINTON", "US"],
  ["IL", "029", "COLES", "US"],
  ["IL", "031", "COOK", "US"],
  ["IL", "033", "CRAWFORD", "US"],
  ["IL", "035", "CUMBERLAND", "US"],
  ["IL", "037", "DE KALB", "US"],
  ["IL", "039", "DEWITT", "US"],
  ["IL", "041", "DOUGLAS", "US"],
  ["IL", "043", "DU PAGE", "US"],
  ["IL", "045", "EDGAR", "US"],
  ["IL", "047", "EDWARDS", "US"],
  ["IL", "049", "EFFINGHAM", "US"],
  ["IL", "051", "FAYETTE", "US"],
  ["IL", "053", "FORD", "US"],
  ["IL", "055", "FRANKLIN", "US"],
  ["IL", "057", "FULTON", "US"],
  ["IL", "059", "GALLATIN", "US"],
  ["IL", "061", "GREENE", "US"],
  ["IL", "063", "GRUNDY", "US"],
  ["IL", "065", "HAMILTON", "US"],
  ["IL", "067", "HANCOCK", "US"],
  ["IL", "069", "HARDIN", "US"],
  ["IL", "071", "HENDERSON", "US"],
  ["IL", "073", "HENRY", "US"],
  ["IL", "075", "IROQUOIS", "US"],
  ["IL", "077", "JACKSON", "US"],
  ["IL", "079", "JASPER", "US"],
  ["IL", "081", "JEFFERSON", "US"],
  ["IL", "083", "JERSEY", "US"],
  ["IL", "085", "JO DAVIESS", "US"],
  ["IL", "087", "JOHNSON", "US"],
  ["IL", "089", "KANE", "US"],
  ["IL", "091", "KANKAKEE", "US"],
  ["IL", "093", "KENDALL", "US"],
  ["IL", "095", "KNOX", "US"],
  ["IL", "099", "LA SALLE", "US"],
  ["IL", "097", "LAKE", "US"],
  ["IL", "101", "LAWRENCE", "US"],
  ["IL", "103", "LEE", "US"],
  ["IL", "105", "LIVINGSTON", "US"],
  ["IL", "107", "LOGAN", "US"],
  ["IL", "115", "MACON", "US"],
  ["IL", "117", "MACOUPIN", "US"],
  ["IL", "119", "MADISON", "US"],
  ["IL", "121", "MARION", "US"],
  ["IL", "123", "MARSHALL", "US"],
  ["IL", "125", "MASON", "US"],
  ["IL", "127", "MASSAC", "US"],
  ["IL", "109", "MCDONOUGH", "US"],
  ["IL", "111", "MCHENRY", "US"],
  ["IL", "113", "MCLEAN", "US"],
  ["IL", "129", "MENARD", "US"],
  ["IL", "131", "MERCER", "US"],
  ["IL", "133", "MONROE", "US"],
  ["IL", "135", "MONTGOMERY", "US"],
  ["IL", "137", "MORGAN", "US"],
  ["IL", "139", "MOULTRIE", "US"],
  ["IL", "141", "OGLE", "US"],
  ["IL", "143", "PEORIA", "US"],
  ["IL", "145", "PERRY", "US"],
  ["IL", "147", "PIATT", "US"],
  ["IL", "149", "PIKE", "US"],
  ["IL", "151", "POPE", "US"],
  ["IL", "153", "PULASKI", "US"],
  ["IL", "155", "PUTNAM", "US"],
  ["IL", "157", "RANDOLPH", "US"],
  ["IL", "159", "RICHLAND", "US"],
  ["IL", "161", "ROCK ISLAND", "US"],
  ["IL", "163", "SAINT CLAIR", "US"],
  ["IL", "165", "SALINE", "US"],
  ["IL", "167", "SANGAMON", "US"],
  ["IL", "169", "SCHUYLER", "US"],
  ["IL", "171", "SCOTT", "US"],
  ["IL", "173", "SHELBY", "US"],
  ["IL", "175", "STARK", "US"],
  ["IL", "177", "STEPHENSON", "US"],
  ["IL", "179", "TAZEWELL", "US"],
  ["IL", "181", "UNION", "US"],
  ["IL", "999", "UNKNOWN", "US"],
  ["IL", "183", "VERMILION", "US"],
  ["IL", "185", "WABASH", "US"],
  ["IL", "187", "WARREN", "US"],
  ["IL", "189", "WASHINGTON", "US"],
  ["IL", "191", "WAYNE", "US"],
  ["IL", "193", "WHITE", "US"],
  ["IL", "195", "WHITESIDE", "US"],
  ["IL", "197", "WILL", "US"],
  ["IL", "199", "WILLIAMSON", "US"],
  ["IL", "201", "WINNEBAGO", "US"],
  ["IL", "203", "WOODFORD", "US"],
  ["IN", "001", "ADAMS", "US"],
  ["IN", "003", "ALLEN", "US"],
  ["IN", "005", "BARTHOLOMEW", "US"],
  ["IN", "007", "BENTON", "US"],
  ["IN", "009", "BLACKFORD", "US"],
  ["IN", "011", "BOONE", "US"],
  ["IN", "013", "BROWN", "US"],
  ["IN", "015", "CARROLL", "US"],
  ["IN", "017", "CASS", "US"],
  ["IN", "019", "CLARK", "US"],
  ["IN", "021", "CLAY", "US"],
  ["IN", "023", "CLINTON", "US"],
  ["IN", "025", "CRAWFORD", "US"],
  ["IN", "027", "DAVIESS", "US"],
  ["IN", "033", "DE KALB", "US"],
  ["IN", "029", "DEARBORN", "US"],
  ["IN", "031", "DECATUR", "US"],
  ["IN", "035", "DELAWARE", "US"],
  ["IN", "037", "DUBOIS", "US"],
  ["IN", "039", "ELKHART", "US"],
  ["IN", "041", "FAYETTE", "US"],
  ["IN", "043", "FLOYD", "US"],
  ["IN", "045", "FOUNTAIN", "US"],
  ["IN", "047", "FRANKLIN", "US"],
  ["IN", "049", "FULTON", "US"],
  ["IN", "051", "GIBSON", "US"],
  ["IN", "053", "GRANT", "US"],
  ["IN", "055", "GREENE", "US"],
  ["IN", "057", "HAMILTON", "US"],
  ["IN", "059", "HANCOCK", "US"],
  ["IN", "061", "HARRISON", "US"],
  ["IN", "063", "HENDRICKS", "US"],
  ["IN", "065", "HENRY", "US"],
  ["IN", "067", "HOWARD", "US"],
  ["IN", "069", "HUNTINGTON", "US"],
  ["IN", "071", "JACKSON", "US"],
  ["IN", "073", "JASPER", "US"],
  ["IN", "075", "JAY", "US"],
  ["IN", "077", "JEFFERSON", "US"],
  ["IN", "079", "JENNINGS", "US"],
  ["IN", "081", "JOHNSON", "US"],
  ["IN", "083", "KNOX", "US"],
  ["IN", "085", "KOSCIUSKO", "US"],
  ["IN", "091", "LA PORTE", "US"],
  ["IN", "087", "LAGRANGE", "US"],
  ["IN", "089", "LAKE", "US"],
  ["IN", "093", "LAWRENCE", "US"],
  ["IN", "095", "MADISON", "US"],
  ["IN", "097", "MARION", "US"],
  ["IN", "099", "MARSHALL", "US"],
  ["IN", "101", "MARTIN", "US"],
  ["IN", "103", "MIAMI", "US"],
  ["IN", "105", "MONROE", "US"],
  ["IN", "107", "MONTGOMERY", "US"],
  ["IN", "109", "MORGAN", "US"],
  ["IN", "111", "NEWTON", "US"],
  ["IN", "113", "NOBLE", "US"],
  ["IN", "115", "OHIO", "US"],
  ["IN", "117", "ORANGE", "US"],
  ["IN", "119", "OWEN", "US"],
  ["IN", "121", "PARKE", "US"],
  ["IN", "123", "PERRY", "US"],
  ["IN", "125", "PIKE", "US"],
  ["IN", "127", "PORTER", "US"],
  ["IN", "129", "POSEY", "US"],
  ["IN", "131", "PULASKI", "US"],
  ["IN", "133", "PUTNAM", "US"],
  ["IN", "135", "RANDOLPH", "US"],
  ["IN", "137", "RIPLEY", "US"],
  ["IN", "139", "RUSH", "US"],
  ["IN", "143", "SCOTT", "US"],
  ["IN", "145", "SHELBY", "US"],
  ["IN", "147", "SPENCER", "US"],
  ["IN", "141", "ST JOSEPH", "US"],
  ["IN", "149", "STARKE", "US"],
  ["IN", "151", "STEUBEN", "US"],
  ["IN", "153", "SULLIVAN", "US"],
  ["IN", "155", "SWITZERLAND", "US"],
  ["IN", "157", "TIPPECANOE", "US"],
  ["IN", "159", "TIPTON", "US"],
  ["IN", "161", "UNION", "US"],
  ["IN", "999", "UNKNOWN", "US"],
  ["IN", "163", "VANDERBURGH", "US"],
  ["IN", "165", "VERMILLION", "US"],
  ["IN", "167", "VIGO", "US"],
  ["IN", "169", "WABASH", "US"],
  ["IN", "171", "WARREN", "US"],
  ["IN", "173", "WARRICK", "US"],
  ["IN", "175", "WASHINGTON", "US"],
  ["IN", "177", "WAYNE", "US"],
  ["IN", "179", "WELLS", "US"],
  ["IN", "181", "WHITE", "US"],
  ["IN", "183", "WHITLEY", "US"],
  ["JA", "000", "(JALISCO)", "MX"],
  ["JA", "999", "UNKNOWN", "MX"],
  ["KS", "001", "ALLEN", "US"],
  ["KS", "003", "ANDERSON", "US"],
  ["KS", "005", "ATCHISON", "US"],
  ["KS", "007", "BARBER", "US"],
  ["KS", "009", "BARTON", "US"],
  ["KS", "011", "BOURBON", "US"],
  ["KS", "013", "BROWN", "US"],
  ["KS", "015", "BUTLER", "US"],
  ["KS", "017", "CHASE", "US"],
  ["KS", "019", "CHAUTAUQUA", "US"],
  ["KS", "021", "CHEROKEE", "US"],
  ["KS", "023", "CHEYENNE", "US"],
  ["KS", "025", "CLARK", "US"],
  ["KS", "027", "CLAY", "US"],
  ["KS", "029", "CLOUD", "US"],
  ["KS", "031", "COFFEY", "US"],
  ["KS", "033", "COMANCHE", "US"],
  ["KS", "035", "COWLEY", "US"],
  ["KS", "037", "CRAWFORD", "US"],
  ["KS", "039", "DECATUR", "US"],
  ["KS", "041", "DICKINSON", "US"],
  ["KS", "043", "DONIPHAN", "US"],
  ["KS", "045", "DOUGLAS", "US"],
  ["KS", "047", "EDWARDS", "US"],
  ["KS", "049", "ELK", "US"],
  ["KS", "051", "ELLIS", "US"],
  ["KS", "053", "ELLSWORTH", "US"],
  ["KS", "055", "FINNEY", "US"],
  ["KS", "057", "FORD", "US"],
  ["KS", "059", "FRANKLIN", "US"],
  ["KS", "061", "GEARY", "US"],
  ["KS", "063", "GOVE", "US"],
  ["KS", "065", "GRAHAM", "US"],
  ["KS", "067", "GRANT", "US"],
  ["KS", "069", "GRAY", "US"],
  ["KS", "071", "GREELEY", "US"],
  ["KS", "073", "GREENWOOD", "US"],
  ["KS", "075", "HAMILTON", "US"],
  ["KS", "077", "HARPER", "US"],
  ["KS", "079", "HARVEY", "US"],
  ["KS", "081", "HASKELL", "US"],
  ["KS", "083", "HODGEMAN", "US"],
  ["KS", "085", "JACKSON", "US"],
  ["KS", "087", "JEFFERSON", "US"],
  ["KS", "089", "JEWELL", "US"],
  ["KS", "091", "JOHNSON", "US"],
  ["KS", "093", "KEARNY", "US"],
  ["KS", "095", "KINGMAN", "US"],
  ["KS", "097", "KIOWA", "US"],
  ["KS", "099", "LABETTE", "US"],
  ["KS", "101", "LANE", "US"],
  ["KS", "103", "LEAVENWORTH", "US"],
  ["KS", "105", "LINCOLN", "US"],
  ["KS", "107", "LINN", "US"],
  ["KS", "109", "LOGAN", "US"],
  ["KS", "111", "LYON", "US"],
  ["KS", "115", "MARION", "US"],
  ["KS", "117", "MARSHALL", "US"],
  ["KS", "113", "MCPHERSON", "US"],
  ["KS", "119", "MEADE", "US"],
  ["KS", "121", "MIAMI", "US"],
  ["KS", "123", "MITCHELL", "US"],
  ["KS", "125", "MONTGOMERY", "US"],
  ["KS", "127", "MORRIS", "US"],
  ["KS", "129", "MORTON", "US"],
  ["KS", "131", "NEMAHA", "US"],
  ["KS", "133", "NEOSHO", "US"],
  ["KS", "135", "NESS", "US"],
  ["KS", "137", "NORTON", "US"],
  ["KS", "139", "OSAGE", "US"],
  ["KS", "141", "OSBORNE", "US"],
  ["KS", "143", "OTTAWA", "US"],
  ["KS", "145", "PAWNEE", "US"],
  ["KS", "147", "PHILLIPS", "US"],
  ["KS", "149", "POTTAWATOMIE", "US"],
  ["KS", "151", "PRATT", "US"],
  ["KS", "153", "RAWLINS", "US"],
  ["KS", "155", "RENO", "US"],
  ["KS", "157", "REPUBLIC", "US"],
  ["KS", "159", "RICE", "US"],
  ["KS", "161", "RILEY", "US"],
  ["KS", "163", "ROOKS", "US"],
  ["KS", "165", "RUSH", "US"],
  ["KS", "167", "RUSSELL", "US"],
  ["KS", "169", "SALINE", "US"],
  ["KS", "171", "SCOTT", "US"],
  ["KS", "173", "SEDGWICK", "US"],
  ["KS", "175", "SEWARD", "US"],
  ["KS", "177", "SHAWNEE", "US"],
  ["KS", "179", "SHERIDAN", "US"],
  ["KS", "181", "SHERMAN", "US"],
  ["KS", "183", "SMITH", "US"],
  ["KS", "185", "STAFFORD", "US"],
  ["KS", "187", "STANTON", "US"],
  ["KS", "189", "STEVENS", "US"],
  ["KS", "191", "SUMNER", "US"],
  ["KS", "193", "THOMAS", "US"],
  ["KS", "195", "TREGO", "US"],
  ["KS", "999", "UNKNOWN", "US"],
  ["KS", "197", "WABAUNSEE", "US"],
  ["KS", "199", "WALLACE", "US"],
  ["KS", "201", "WASHINGTON", "US"],
  ["KS", "203", "WICHITA", "US"],
  ["KS", "205", "WILSON", "US"],
  ["KS", "207", "WOODSON", "US"],
  ["KS", "209", "WYANDOTTE", "US"],
  ["KY", "001", "ADAIR", "US"],
  ["KY", "003", "ALLEN", "US"],
  ["KY", "005", "ANDERSON", "US"],
  ["KY", "007", "BALLARD", "US"],
  ["KY", "009", "BARREN", "US"],
  ["KY", "011", "BATH", "US"],
  ["KY", "013", "BELL", "US"],
  ["KY", "015", "BOONE", "US"],
  ["KY", "017", "BOURBON", "US"],
  ["KY", "019", "BOYD", "US"],
  ["KY", "021", "BOYLE", "US"],
  ["KY", "023", "BRACKEN", "US"],
  ["KY", "025", "BREATHITT", "US"],
  ["KY", "027", "BRECKINRIDGE", "US"],
  ["KY", "029", "BULLITT", "US"],
  ["KY", "031", "BUTLER", "US"],
  ["KY", "033", "CALDWELL", "US"],
  ["KY", "035", "CALLOWAY", "US"],
  ["KY", "037", "CAMPBELL", "US"],
  ["KY", "039", "CARLISLE", "US"],
  ["KY", "041", "CARROLL", "US"],
  ["KY", "043", "CARTER", "US"],
  ["KY", "045", "CASEY", "US"],
  ["KY", "047", "CHRISTIAN", "US"],
  ["KY", "049", "CLARK", "US"],
  ["KY", "051", "CLAY", "US"],
  ["KY", "053", "CLINTON", "US"],
  ["KY", "055", "CRITTENDEN", "US"],
  ["KY", "057", "CUMBERLAND", "US"],
  ["KY", "059", "DAVIESS", "US"],
  ["KY", "061", "EDMONSON", "US"],
  ["KY", "063", "ELLIOTT", "US"],
  ["KY", "065", "ESTILL", "US"],
  ["KY", "067", "FAYETTE", "US"],
  ["KY", "069", "FLEMING", "US"],
  ["KY", "071", "FLOYD", "US"],
  ["KY", "073", "FRANKLIN", "US"],
  ["KY", "075", "FULTON", "US"],
  ["KY", "077", "GALLATIN", "US"],
  ["KY", "079", "GARRARD", "US"],
  ["KY", "081", "GRANT", "US"],
  ["KY", "083", "GRAVES", "US"],
  ["KY", "085", "GRAYSON", "US"],
  ["KY", "087", "GREEN", "US"],
  ["KY", "089", "GREENUP", "US"],
  ["KY", "091", "HANCOCK", "US"],
  ["KY", "093", "HARDIN", "US"],
  ["KY", "095", "HARLAN", "US"],
  ["KY", "097", "HARRISON", "US"],
  ["KY", "099", "HART", "US"],
  ["KY", "101", "HENDERSON", "US"],
  ["KY", "103", "HENRY", "US"],
  ["KY", "105", "HICKMAN", "US"],
  ["KY", "107", "HOPKINS", "US"],
  ["KY", "109", "JACKSON", "US"],
  ["KY", "111", "JEFFERSON", "US"],
  ["KY", "113", "JESSAMINE", "US"],
  ["KY", "115", "JOHNSON", "US"],
  ["KY", "117", "KENTON", "US"],
  ["KY", "119", "KNOTT", "US"],
  ["KY", "121", "KNOX", "US"],
  ["KY", "123", "LARUE", "US"],
  ["KY", "125", "LAUREL", "US"],
  ["KY", "127", "LAWRENCE", "US"],
  ["KY", "129", "LEE", "US"],
  ["KY", "131", "LESLIE", "US"],
  ["KY", "133", "LETCHER", "US"],
  ["KY", "135", "LEWIS", "US"],
  ["KY", "137", "LINCOLN", "US"],
  ["KY", "139", "LIVINGSTON", "US"],
  ["KY", "141", "LOGAN", "US"],
  ["KY", "143", "LYON", "US"],
  ["KY", "151", "MADISON", "US"],
  ["KY", "153", "MAGOFFIN", "US"],
  ["KY", "155", "MARION", "US"],
  ["KY", "157", "MARSHALL", "US"],
  ["KY", "159", "MARTIN", "US"],
  ["KY", "161", "MASON", "US"],
  ["KY", "145", "MCCRACKEN", "US"],
  ["KY", "147", "MCCREARY", "US"],
  ["KY", "149", "MCLEAN", "US"],
  ["KY", "163", "MEADE", "US"],
  ["KY", "165", "MENIFEE", "US"],
  ["KY", "167", "MERCER", "US"],
  ["KY", "169", "METCALFE", "US"],
  ["KY", "171", "MONROE", "US"],
  ["KY", "173", "MONTGOMERY", "US"],
  ["KY", "175", "MORGAN", "US"],
  ["KY", "177", "MUHLENBERG", "US"],
  ["KY", "179", "NELSON", "US"],
  ["KY", "181", "NICHOLAS", "US"],
  ["KY", "183", "OHIO", "US"],
  ["KY", "185", "OLDHAM", "US"],
  ["KY", "187", "OWEN", "US"],
  ["KY", "189", "OWSLEY", "US"],
  ["KY", "191", "PENDLETON", "US"],
  ["KY", "193", "PERRY", "US"],
  ["KY", "195", "PIKE", "US"],
  ["KY", "197", "POWELL", "US"],
  ["KY", "199", "PULASKI", "US"],
  ["KY", "201", "ROBERTSON", "US"],
  ["KY", "203", "ROCKCASTLE", "US"],
  ["KY", "205", "ROWAN", "US"],
  ["KY", "207", "RUSSELL", "US"],
  ["KY", "209", "SCOTT", "US"],
  ["KY", "211", "SHELBY", "US"],
  ["KY", "213", "SIMPSON", "US"],
  ["KY", "215", "SPENCER", "US"],
  ["KY", "217", "TAYLOR", "US"],
  ["KY", "219", "TODD", "US"],
  ["KY", "221", "TRIGG", "US"],
  ["KY", "223", "TRIMBLE", "US"],
  ["KY", "225", "UNION", "US"],
  ["KY", "999", "UNKNOWN", "US"],
  ["KY", "227", "WARREN", "US"],
  ["KY", "229", "WASHINGTON", "US"],
  ["KY", "231", "WAYNE", "US"],
  ["KY", "233", "WEBSTER", "US"],
  ["KY", "235", "WHITLEY", "US"],
  ["KY", "237", "WOLFE", "US"],
  ["KY", "239", "WOODFORD", "US"],
  ["LA", "001", "ACADIA", "US"],
  ["LA", "003", "ALLEN", "US"],
  ["LA", "005", "ASCENSION", "US"],
  ["LA", "007", "ASSUMPTION", "US"],
  ["LA", "009", "AVOYELLES", "US"],
  ["LA", "011", "BEAUREGARD", "US"],
  ["LA", "013", "BIENVILLE", "US"],
  ["LA", "015", "BOSSIER", "US"],
  ["LA", "017", "CADDO", "US"],
  ["LA", "019", "CALCASIEU", "US"],
  ["LA", "021", "CALDWELL", "US"],
  ["LA", "023", "CAMERON", "US"],
  ["LA", "025", "CATAHOULA", "US"],
  ["LA", "027", "CLAIBORNE", "US"],
  ["LA", "029", "CONCORDIA", "US"],
  ["LA", "031", "DE SOTO", "US"],
  ["LA", "033", "EAST BATON ROUGE", "US"],
  ["LA", "035", "EAST CARROLL", "US"],
  ["LA", "037", "EAST FELICIANA", "US"],
  ["LA", "039", "EVANGELINE", "US"],
  ["LA", "041", "FRANKLIN", "US"],
  ["LA", "043", "GRANT", "US"],
  ["LA", "045", "IBERIA", "US"],
  ["LA", "047", "IBERVILLE", "US"],
  ["LA", "049", "JACKSON", "US"],
  ["LA", "051", "JEFFERSON", "US"],
  ["LA", "053", "JEFFERSON DAVIS", "US"],
  ["LA", "059", "LA SALLE", "US"],
  ["LA", "055", "LAFAYETTE", "US"],
  ["LA", "057", "LAFOURCHE", "US"],
  ["LA", "061", "LINCOLN", "US"],
  ["LA", "063", "LIVINGSTON", "US"],
  ["LA", "065", "MADISON", "US"],
  ["LA", "067", "MOREHOUSE", "US"],
  ["LA", "069", "NATCHITOCHES", "US"],
  ["LA", "071", "ORLEANS", "US"],
  ["LA", "073", "OUACHITA", "US"],
  ["LA", "075", "PLAQUEMINES", "US"],
  ["LA", "077", "POINTE COUPEE", "US"],
  ["LA", "079", "RAPIDES", "US"],
  ["LA", "081", "RED RIVER", "US"],
  ["LA", "083", "RICHLAND", "US"],
  ["LA", "085", "SABINE", "US"],
  ["LA", "087", "SAINT BERNARD", "US"],
  ["LA", "089", "SAINT CHARLES", "US"],
  ["LA", "091", "SAINT HELENA", "US"],
  ["LA", "093", "SAINT JAMES", "US"],
  ["LA", "097", "SAINT LANDRY", "US"],
  ["LA", "099", "SAINT MARTIN", "US"],
  ["LA", "101", "SAINT MARY", "US"],
  ["LA", "103", "SAINT TAMMANY", "US"],
  ["LA", "095", "ST JOHN THE BAPTIST", "US"],
  ["LA", "105", "TANGIPAHOA", "US"],
  ["LA", "107", "TENSAS", "US"],
  ["LA", "109", "TERREBONNE", "US"],
  ["LA", "111", "UNION", "US"],
  ["LA", "999", "UNKNOWN", "US"],
  ["LA", "113", "VERMILION", "US"],
  ["LA", "115", "VERNON", "US"],
  ["LA", "117", "WASHINGTON", "US"],
  ["LA", "119", "WEBSTER", "US"],
  ["LA", "121", "WEST BATON ROUGE", "US"],
  ["LA", "123", "WEST CARROLL", "US"],
  ["LA", "125", "WEST FELICIANA", "US"],
  ["LA", "127", "WINN", "US"],
  ["MA", "001", "BARNSTABLE", "US"],
  ["MA", "003", "BERKSHIRE", "US"],
  ["MA", "005", "BRISTOL", "US"],
  ["MA", "007", "DUKES", "US"],
  ["MA", "009", "ESSEX", "US"],
  ["MA", "011", "FRANKLIN", "US"],
  ["MA", "013", "HAMPDEN", "US"],
  ["MA", "015", "HAMPSHIRE", "US"],
  ["MA", "017", "MIDDLESEX", "US"],
  ["MA", "019", "NANTUCKET", "US"],
  ["MA", "021", "NORFOLK", "US"],
  ["MA", "023", "PLYMOUTH", "US"],
  ["MA", "025", "SUFFOLK", "US"],
  ["MA", "999", "UNKNOWN", "US"],
  ["MA", "027", "WORCESTER", "US"],
  ["MB", "000", "(MANITOBA)", "CA"],
  ["MB", "999", "UNKNOWN", "CA"],
  ["MC", "000", "(MICHOACAN)", "MX"],
  ["MC", "999", "UNKNOWN", "MX"],
  ["MD", "001", "ALLEGANY", "US"],
  ["MD", "003", "ANNE ARUNDEL", "US"],
  ["MD", "005", "BALTIMORE", "US"],
  ["MD", "510", "BALTIMORE CITY", "US"],
  ["MD", "009", "CALVERT", "US"],
  ["MD", "011", "CAROLINE", "US"],
  ["MD", "013", "CARROLL", "US"],
  ["MD", "015", "CECIL", "US"],
  ["MD", "017", "CHARLES", "US"],
  ["MD", "019", "DORCHESTER", "US"],
  ["MD", "021", "FREDERICK", "US"],
  ["MD", "023", "GARRETT", "US"],
  ["MD", "025", "HARFORD", "US"],
  ["MD", "027", "HOWARD", "US"],
  ["MD", "500", "INDEPENDENT CITY/S", "US"],
  ["MD", "029", "KENT", "US"],
  ["MD", "031", "MONTGOMERY", "US"],
  ["MD", "033", "PRINCE GEORGES", "US"],
  ["MD", "035", "QUEEN ANNES", "US"],
  ["MD", "037", "SAINT MARYS", "US"],
  ["MD", "039", "SOMERSET", "US"],
  ["MD", "041", "TALBOT", "US"],
  ["MD", "999", "UNKNOWN", "US"],
  ["MD", "043", "WASHINGTON", "US"],
  ["MD", "045", "WICOMICO", "US"],
  ["MD", "047", "WORCESTER", "US"],
  ["ME", "001", "ANDROSCOGGIN", "US"],
  ["ME", "003", "AROOSTOOK", "US"],
  ["ME", "005", "CUMBERLAND", "US"],
  ["ME", "007", "FRANKLIN", "US"],
  ["ME", "009", "HANCOCK", "US"],
  ["ME", "011", "KENNEBEC", "US"],
  ["ME", "013", "KNOX", "US"],
  ["ME", "015", "LINCOLN", "US"],
  ["ME", "017", "OXFORD", "US"],
  ["ME", "019", "PENOBSCOT", "US"],
  ["ME", "021", "PISCATAQUIS", "US"],
  ["ME", "023", "SAGADAHOC", "US"],
  ["ME", "025", "SOMERSET", "US"],
  ["ME", "999", "UNKNOWN", "US"],
  ["ME", "027", "WALDO", "US"],
  ["ME", "029", "WASHINGTON", "US"],
  ["ME", "031", "YORK", "US"],
  ["MI", "001", "ALCONA", "US"],
  ["MI", "003", "ALGER", "US"],
  ["MI", "005", "ALLEGAN", "US"],
  ["MI", "007", "ALPENA", "US"],
  ["MI", "009", "ANTRIM", "US"],
  ["MI", "011", "ARENAC", "US"],
  ["MI", "013", "BARAGA", "US"],
  ["MI", "015", "BARRY", "US"],
  ["MI", "017", "BAY", "US"],
  ["MI", "019", "BENZIE", "US"],
  ["MI", "021", "BERRIEN", "US"],
  ["MI", "023", "BRANCH", "US"],
  ["MI", "025", "CALHOUN", "US"],
  ["MI", "027", "CASS", "US"],
  ["MI", "029", "CHARLEVOIX", "US"],
  ["MI", "031", "CHEBOYGAN", "US"],
  ["MI", "033", "CHIPPEWA", "US"],
  ["MI", "035", "CLARE", "US"],
  ["MI", "037", "CLINTON", "US"],
  ["MI", "039", "CRAWFORD", "US"],
  ["MI", "041", "DELTA", "US"],
  ["MI", "043", "DICKINSON", "US"],
  ["MI", "045", "EATON", "US"],
  ["MI", "047", "EMMET", "US"],
  ["MI", "049", "GENESEE", "US"],
  ["MI", "051", "GLADWIN", "US"],
  ["MI", "053", "GOGEBIC", "US"],
  ["MI", "055", "GRAND TRAVERSE", "US"],
  ["MI", "057", "GRATIOT", "US"],
  ["MI", "059", "HILLSDALE", "US"],
  ["MI", "061", "HOUGHTON", "US"],
  ["MI", "063", "HURON", "US"],
  ["MI", "065", "INGHAM", "US"],
  ["MI", "067", "IONIA", "US"],
  ["MI", "069", "IOSCO", "US"],
  ["MI", "071", "IRON", "US"],
  ["MI", "073", "ISABELLA", "US"],
  ["MI", "075", "JACKSON", "US"],
  ["MI", "077", "KALAMAZOO", "US"],
  ["MI", "079", "KALKASKA", "US"],
  ["MI", "081", "KENT", "US"],
  ["MI", "083", "KEWEENAW", "US"],
  ["MI", "085", "LAKE", "US"],
  ["MI", "087", "LAPEER", "US"],
  ["MI", "089", "LEELANAU", "US"],
  ["MI", "091", "LENAWEE", "US"],
  ["MI", "093", "LIVINGSTON", "US"],
  ["MI", "095", "LUCE", "US"],
  ["MI", "097", "MACKINAC", "US"],
  ["MI", "099", "MACOMB", "US"],
  ["MI", "101", "MANISTEE", "US"],
  ["MI", "103", "MARQUETTE", "US"],
  ["MI", "105", "MASON", "US"],
  ["MI", "107", "MECOSTA", "US"],
  ["MI", "109", "MENOMINEE", "US"],
  ["MI", "111", "MIDLAND", "US"],
  ["MI", "113", "MISSAUKEE", "US"],
  ["MI", "115", "MONROE", "US"],
  ["MI", "117", "MONTCALM", "US"],
  ["MI", "119", "MONTMORENCY", "US"],
  ["MI", "121", "MUSKEGON", "US"],
  ["MI", "123", "NEWAYGO", "US"],
  ["MI", "125", "OAKLAND", "US"],
  ["MI", "127", "OCEANA", "US"],
  ["MI", "129", "OGEMAW", "US"],
  ["MI", "131", "ONTONAGON", "US"],
  ["MI", "133", "OSCEOLA", "US"],
  ["MI", "135", "OSCODA", "US"],
  ["MI", "137", "OTSEGO", "US"],
  ["MI", "139", "OTTAWA", "US"],
  ["MI", "141", "PRESQUE ISLE", "US"],
  ["MI", "143", "ROSCOMMON", "US"],
  ["MI", "145", "SAGINAW", "US"],
  ["MI", "147", "SAINT CLAIR", "US"],
  ["MI", "149", "SAINT JOSEPH", "US"],
  ["MI", "151", "SANILAC", "US"],
  ["MI", "153", "SCHOOLCRAFT", "US"],
  ["MI", "155", "SHIAWASSEE", "US"],
  ["MI", "157", "TUSCOLA", "US"],
  ["MI", "999", "UNKNOWN", "US"],
  ["MI", "159", "VAN BUREN", "US"],
  ["MI", "161", "WASHTENAW", "US"],
  ["MI", "163", "WAYNE", "US"],
  ["MI", "165", "WEXFORD", "US"],
  ["MN", "001", "AITKIN", "US"],
  ["MN", "003", "ANOKA", "US"],
  ["MN", "005", "BECKER", "US"],
  ["MN", "007", "BELTRAMI", "US"],
  ["MN", "009", "BENTON", "US"],
  ["MN", "011", "BIG STONE", "US"],
  ["MN", "013", "BLUE EARTH", "US"],
  ["MN", "015", "BROWN", "US"],
  ["MN", "017", "CARLTON", "US"],
  ["MN", "019", "CARVER", "US"],
  ["MN", "021", "CASS", "US"],
  ["MN", "023", "CHIPPEWA", "US"],
  ["MN", "025", "CHISAGO", "US"],
  ["MN", "027", "CLAY", "US"],
  ["MN", "029", "CLEARWATER", "US"],
  ["MN", "031", "COOK", "US"],
  ["MN", "033", "COTTONWOOD", "US"],
  ["MN", "035", "CROW WING", "US"],
  ["MN", "037", "DAKOTA", "US"],
  ["MN", "039", "DODGE", "US"],
  ["MN", "041", "DOUGLAS", "US"],
  ["MN", "043", "FARIBAULT", "US"],
  ["MN", "045", "FILLMORE", "US"],
  ["MN", "047", "FREEBORN", "US"],
  ["MN", "049", "GOODHUE", "US"],
  ["MN", "051", "GRANT", "US"],
  ["MN", "053", "HENNEPIN", "US"],
  ["MN", "055", "HOUSTON", "US"],
  ["MN", "057", "HUBBARD", "US"],
  ["MN", "059", "ISANTI", "US"],
  ["MN", "061", "ITASCA", "US"],
  ["MN", "063", "JACKSON", "US"],
  ["MN", "065", "KANABEC", "US"],
  ["MN", "067", "KANDIYOHI", "US"],
  ["MN", "069", "KITTSON", "US"],
  ["MN", "071", "KOOCHICHING", "US"],
  ["MN", "073", "LAC QUI PARLE", "US"],
  ["MN", "075", "LAKE", "US"],
  ["MN", "077", "LAKE OF THE WOODS", "US"],
  ["MN", "079", "LE SUEUR", "US"],
  ["MN", "081", "LINCOLN", "US"],
  ["MN", "083", "LYON", "US"],
  ["MN", "087", "MAHNOMEN", "US"],
  ["MN", "089", "MARSHALL", "US"],
  ["MN", "091", "MARTIN", "US"],
  ["MN", "085", "MCLEOD", "US"],
  ["MN", "093", "MEEKER", "US"],
  ["MN", "095", "MILLE LACS", "US"],
  ["MN", "097", "MORRISON", "US"],
  ["MN", "099", "MOWER", "US"],
  ["MN", "101", "MURRAY", "US"],
  ["MN", "103", "NICOLLET", "US"],
  ["MN", "105", "NOBLES", "US"],
  ["MN", "107", "NORMAN", "US"],
  ["MN", "109", "OLMSTED", "US"],
  ["MN", "111", "OTTER TAIL", "US"],
  ["MN", "113", "PENNINGTON", "US"],
  ["MN", "115", "PINE", "US"],
  ["MN", "117", "PIPESTONE", "US"],
  ["MN", "119", "POLK", "US"],
  ["MN", "121", "POPE", "US"],
  ["MN", "123", "RAMSEY", "US"],
  ["MN", "125", "RED LAKE", "US"],
  ["MN", "127", "REDWOOD", "US"],
  ["MN", "129", "RENVILLE", "US"],
  ["MN", "131", "RICE", "US"],
  ["MN", "133", "ROCK", "US"],
  ["MN", "135", "ROSEAU", "US"],
  ["MN", "137", "SAINT LOUIS", "US"],
  ["MN", "139", "SCOTT", "US"],
  ["MN", "141", "SHERBURNE", "US"],
  ["MN", "143", "SIBLEY", "US"],
  ["MN", "145", "STEARNS", "US"],
  ["MN", "147", "STEELE", "US"],
  ["MN", "149", "STEVENS", "US"],
  ["MN", "151", "SWIFT", "US"],
  ["MN", "153", "TODD", "US"],
  ["MN", "155", "TRAVERSE", "US"],
  ["MN", "999", "UNKNOWN", "US"],
  ["MN", "157", "WABASHA", "US"],
  ["MN", "159", "WADENA", "US"],
  ["MN", "161", "WASECA", "US"],
  ["MN", "163", "WASHINGTON", "US"],
  ["MN", "165", "WATONWAN", "US"],
  ["MN", "167", "WILKIN", "US"],
  ["MN", "169", "WINONA", "US"],
  ["MN", "171", "WRIGHT", "US"],
  ["MN", "173", "YELLOW MEDICINE", "US"],
  ["MO", "001", "ADAIR", "US"],
  ["MO", "003", "ANDREW", "US"],
  ["MO", "005", "ATCHISON", "US"],
  ["MO", "007", "AUDRAIN", "US"],
  ["MO", "009", "BARRY", "US"],
  ["MO", "011", "BARTON", "US"],
  ["MO", "013", "BATES", "US"],
  ["MO", "015", "BENTON", "US"],
  ["MO", "017", "BOLLINGER", "US"],
  ["MO", "019", "BOONE", "US"],
  ["MO", "021", "BUCHANAN", "US"],
  ["MO", "023", "BUTLER", "US"],
  ["MO", "025", "CALDWELL", "US"],
  ["MO", "027", "CALLAWAY", "US"],
  ["MO", "029", "CAMDEN", "US"],
  ["MO", "031", "CAPE GIRARDEAU", "US"],
  ["MO", "033", "CARROLL", "US"],
  ["MO", "035", "CARTER", "US"],
  ["MO", "037", "CASS", "US"],
  ["MO", "039", "CEDAR", "US"],
  ["MO", "041", "CHARITON", "US"],
  ["MO", "043", "CHRISTIAN", "US"],
  ["MO", "045", "CLARK", "US"],
  ["MO", "047", "CLAY", "US"],
  ["MO", "049", "CLINTON", "US"],
  ["MO", "051", "COLE", "US"],
  ["MO", "053", "COOPER", "US"],
  ["MO", "055", "CRAWFORD", "US"],
  ["MO", "057", "DADE", "US"],
  ["MO", "059", "DALLAS", "US"],
  ["MO", "061", "DAVIESS", "US"],
  ["MO", "063", "DEKALB", "US"],
  ["MO", "065", "DENT", "US"],
  ["MO", "067", "DOUGLAS", "US"],
  ["MO", "069", "DUNKLIN", "US"],
  ["MO", "071", "FRANKLIN", "US"],
  ["MO", "073", "GASCONADE", "US"],
  ["MO", "075", "GENTRY", "US"],
  ["MO", "077", "GREENE", "US"],
  ["MO", "079", "GRUNDY", "US"],
  ["MO", "081", "HARRISON", "US"],
  ["MO", "083", "HENRY", "US"],
  ["MO", "085", "HICKORY", "US"],
  ["MO", "087", "HOLT", "US"],
  ["MO", "089", "HOWARD", "US"],
  ["MO", "091", "HOWELL", "US"],
  ["MO", "500", "INDEPENDENT CITY/S", "US"],
  ["MO", "093", "IRON", "US"],
  ["MO", "095", "JACKSON", "US"],
  ["MO", "097", "JASPER", "US"],
  ["MO", "099", "JEFFERSON", "US"],
  ["MO", "101", "JOHNSON", "US"],
  ["MO", "103", "KNOX", "US"],
  ["MO", "105", "LACLEDE", "US"],
  ["MO", "107", "LAFAYETTE", "US"],
  ["MO", "109", "LAWRENCE", "US"],
  ["MO", "111", "LEWIS", "US"],
  ["MO", "113", "LINCOLN", "US"],
  ["MO", "115", "LINN", "US"],
  ["MO", "117", "LIVINGSTON", "US"],
  ["MO", "121", "MACON", "US"],
  ["MO", "123", "MADISON", "US"],
  ["MO", "125", "MARIES", "US"],
  ["MO", "127", "MARION", "US"],
  ["MO", "119", "MCDONALD", "US"],
  ["MO", "129", "MERCER", "US"],
  ["MO", "131", "MILLER", "US"],
  ["MO", "133", "MISSISSIPPI", "US"],
  ["MO", "135", "MONITEAU", "US"],
  ["MO", "137", "MONROE", "US"],
  ["MO", "139", "MONTGOMERY", "US"],
  ["MO", "141", "MORGAN", "US"],
  ["MO", "143", "NEW MADRID", "US"],
  ["MO", "145", "NEWTON", "US"],
  ["MO", "147", "NODAWAY", "US"],
  ["MO", "149", "OREGON", "US"],
  ["MO", "151", "OSAGE", "US"],
  ["MO", "153", "OZARK", "US"],
  ["MO", "155", "PEMISCOT", "US"],
  ["MO", "157", "PERRY", "US"],
  ["MO", "159", "PETTIS", "US"],
  ["MO", "161", "PHELPS", "US"],
  ["MO", "163", "PIKE", "US"],
  ["MO", "165", "PLATTE", "US"],
  ["MO", "167", "POLK", "US"],
  ["MO", "169", "PULASKI", "US"],
  ["MO", "171", "PUTNAM", "US"],
  ["MO", "173", "RALLS", "US"],
  ["MO", "175", "RANDOLPH", "US"],
  ["MO", "177", "RAY", "US"],
  ["MO", "179", "REYNOLDS", "US"],
  ["MO", "181", "RIPLEY", "US"],
  ["MO", "183", "SAINT CHARLES", "US"],
  ["MO", "185", "SAINT CLAIR", "US"],
  ["MO", "187", "SAINT FRANCOIS", "US"],
  ["MO", "189", "SAINT LOUIS", "US"],
  ["MO", "510", "SAINT LOUIS CITY", "US"],
  ["MO", "186", "SAINTE GENEVIEVE", "US"],
  ["MO", "195", "SALINE", "US"],
  ["MO", "197", "SCHUYLER", "US"],
  ["MO", "199", "SCOTLAND", "US"],
  ["MO", "201", "SCOTT", "US"],
  ["MO", "203", "SHANNON", "US"],
  ["MO", "205", "SHELBY", "US"],
  ["MO", "207", "STODDARD", "US"],
  ["MO", "209", "STONE", "US"],
  ["MO", "211", "SULLIVAN", "US"],
  ["MO", "213", "TANEY", "US"],
  ["MO", "215", "TEXAS", "US"],
  ["MO", "999", "UNKNOWN", "US"],
  ["MO", "217", "VERNON", "US"],
  ["MO", "219", "WARREN", "US"],
  ["MO", "221", "WASHINGTON", "US"],
  ["MO", "223", "WAYNE", "US"],
  ["MO", "225", "WEBSTER", "US"],
  ["MO", "227", "WORTH", "US"],
  ["MO", "229", "WRIGHT", "US"],
  ["MP", "085", "NORTHERN ISLANDS", "US"],
  ["MP", "010", "NORTHERN MARIANA ISLAND", "US"],
  ["MP", "100", "ROTA", "US"],
  ["MP", "110", "SAIPAN", "US"],
  ["MP", "120", "TINIAN", "US"],
  ["MP", "999", "UNKNOWN", "US"],
  ["MR", "000", "(MORELOS)", "MX"],
  ["MR", "999", "UNKNOWN", "MX"],
  ["MS", "001", "ADAMS", "US"],
  ["MS", "003", "ALCORN", "US"],
  ["MS", "005", "AMITE", "US"],
  ["MS", "007", "ATTALA", "US"],
  ["MS", "009", "BENTON", "US"],
  ["MS", "011", "BOLIVAR", "US"],
  ["MS", "013", "CALHOUN", "US"],
  ["MS", "015", "CARROLL", "US"],
  ["MS", "017", "CHICKASAW", "US"],
  ["MS", "019", "CHOCTAW", "US"],
  ["MS", "021", "CLAIBORNE", "US"],
  ["MS", "023", "CLARKE", "US"],
  ["MS", "025", "CLAY", "US"],
  ["MS", "027", "COAHOMA", "US"],
  ["MS", "029", "COPIAH", "US"],
  ["MS", "031", "COVINGTON", "US"],
  ["MS", "033", "DE SOTO", "US"],
  ["MS", "035", "FORREST", "US"],
  ["MS", "037", "FRANKLIN", "US"],
  ["MS", "039", "GEORGE", "US"],
  ["MS", "041", "GREENE", "US"],
  ["MS", "043", "GRENADA", "US"],
  ["MS", "045", "HANCOCK", "US"],
  ["MS", "047", "HARRISON", "US"],
  ["MS", "049", "HINDS", "US"],
  ["MS", "051", "HOLMES", "US"],
  ["MS", "053", "HUMPHREYS", "US"],
  ["MS", "055", "ISSAQUENA", "US"],
  ["MS", "057", "ITAWAMBA", "US"],
  ["MS", "059", "JACKSON", "US"],
  ["MS", "061", "JASPER", "US"],
  ["MS", "063", "JEFFERSON", "US"],
  ["MS", "065", "JEFFERSON DAVIS", "US"],
  ["MS", "067", "JONES", "US"],
  ["MS", "069", "KEMPER", "US"],
  ["MS", "071", "LAFAYETTE", "US"],
  ["MS", "073", "LAMAR", "US"],
  ["MS", "075", "LAUDERDALE", "US"],
  ["MS", "077", "LAWRENCE", "US"],
  ["MS", "079", "LEAKE", "US"],
  ["MS", "081", "LEE", "US"],
  ["MS", "083", "LEFLORE", "US"],
  ["MS", "085", "LINCOLN", "US"],
  ["MS", "087", "LOWNDES", "US"],
  ["MS", "089", "MADISON", "US"],
  ["MS", "091", "MARION", "US"],
  ["MS", "093", "MARSHALL", "US"],
  ["MS", "095", "MONROE", "US"],
  ["MS", "097", "MONTGOMERY", "US"],
  ["MS", "099", "NESHOBA", "US"],
  ["MS", "101", "NEWTON", "US"],
  ["MS", "103", "NOXUBEE", "US"],
  ["MS", "105", "OKTIBBEHA", "US"],
  ["MS", "107", "PANOLA", "US"],
  ["MS", "109", "PEARL RIVER", "US"],
  ["MS", "111", "PERRY", "US"],
  ["MS", "113", "PIKE", "US"],
  ["MS", "115", "PONTOTOC", "US"],
  ["MS", "117", "PRENTISS", "US"],
  ["MS", "119", "QUITMAN", "US"],
  ["MS", "121", "RANKIN", "US"],
  ["MS", "123", "SCOTT", "US"],
  ["MS", "125", "SHARKEY", "US"],
  ["MS", "127", "SIMPSON", "US"],
  ["MS", "129", "SMITH", "US"],
  ["MS", "131", "STONE", "US"],
  ["MS", "133", "SUNFLOWER", "US"],
  ["MS", "135", "TALLAHATCHIE", "US"],
  ["MS", "137", "TATE", "US"],
  ["MS", "139", "TIPPAH", "US"],
  ["MS", "141", "TISHOMINGO", "US"],
  ["MS", "143", "TUNICA", "US"],
  ["MS", "145", "UNION", "US"],
  ["MS", "999", "UNKNOWN", "US"],
  ["MS", "147", "WALTHALL", "US"],
  ["MS", "149", "WARREN", "US"],
  ["MS", "151", "WASHINGTON", "US"],
  ["MS", "153", "WAYNE", "US"],
  ["MS", "155", "WEBSTER", "US"],
  ["MS", "157", "WILKINSON", "US"],
  ["MS", "159", "WINSTON", "US"],
  ["MS", "161", "YALOBUSHA", "US"],
  ["MS", "163", "YAZOO", "US"],
  ["MT", "001", "BEAVERHEAD", "US"],
  ["MT", "003", "BIG HORN", "US"],
  ["MT", "005", "BLAINE", "US"],
  ["MT", "007", "BROADWATER", "US"],
  ["MT", "009", "CARBON", "US"],
  ["MT", "011", "CARTER", "US"],
  ["MT", "013", "CASCADE", "US"],
  ["MT", "015", "CHOUTEAU", "US"],
  ["MT", "017", "CUSTER", "US"],
  ["MT", "019", "DANIELS", "US"],
  ["MT", "021", "DAWSON", "US"],
  ["MT", "023", "DEER LODGE", "US"],
  ["MT", "025", "FALLON", "US"],
  ["MT", "027", "FERGUS", "US"],
  ["MT", "029", "FLATHEAD", "US"],
  ["MT", "031", "GALLATIN", "US"],
  ["MT", "033", "GARFIELD", "US"],
  ["MT", "035", "GLACIER", "US"],
  ["MT", "037", "GOLDEN VALLEY", "US"],
  ["MT", "039", "GRANITE", "US"],
  ["MT", "041", "HILL", "US"],
  ["MT", "043", "JEFFERSON", "US"],
  ["MT", "045", "JUDITH BASIN", "US"],
  ["MT", "047", "LAKE", "US"],
  ["MT", "049", "LEWIS AND CLARK", "US"],
  ["MT", "051", "LIBERTY", "US"],
  ["MT", "053", "LINCOLN", "US"],
  ["MT", "057", "MADISON", "US"],
  ["MT", "055", "MCCONE", "US"],
  ["MT", "059", "MEAGHER", "US"],
  ["MT", "061", "MINERAL", "US"],
  ["MT", "063", "MISSOULA", "US"],
  ["MT", "065", "MUSSELSHELL", "US"],
  ["MT", "067", "PARK", "US"],
  ["MT", "069", "PETROLEUM", "US"],
  ["MT", "071", "PHILLIPS", "US"],
  ["MT", "073", "PONDERA", "US"],
  ["MT", "075", "POWDER RIVER", "US"],
  ["MT", "077", "POWELL", "US"],
  ["MT", "079", "PRAIRIE", "US"],
  ["MT", "081", "RAVALLI", "US"],
  ["MT", "083", "RICHLAND", "US"],
  ["MT", "085", "ROOSEVELT", "US"],
  ["MT", "087", "ROSEBUD", "US"],
  ["MT", "089", "SANDERS", "US"],
  ["MT", "091", "SHERIDAN", "US"],
  ["MT", "093", "SILVER BOW", "US"],
  ["MT", "095", "STILLWATER", "US"],
  ["MT", "097", "SWEET GRASS", "US"],
  ["MT", "099", "TETON", "US"],
  ["MT", "101", "TOOLE", "US"],
  ["MT", "103", "TREASURE", "US"],
  ["MT", "999", "UNKNOWN", "US"],
  ["MT", "105", "VALLEY", "US"],
  ["MT", "107", "WHEATLAND", "US"],
  ["MT", "109", "WIBAUX", "US"],
  ["MT", "111", "YELLOWSTONE", "US"],
  ["MX", "000", "(MEXICO)", "MX"],
  ["MX", "999", "UNKNOWN", "MX"],
  ["NA", "000", "(NAYARIT)", "MX"],
  ["NA", "999", "UNKNOWN", "MX"],
  ["NB", "000", "(NEW BRUNSWICK)", "CA"],
  ["NB", "999", "UNKNOWN", "CA"],
  ["NC", "001", "ALAMANCE", "US"],
  ["NC", "003", "ALEXANDER", "US"],
  ["NC", "005", "ALLEGHANY", "US"],
  ["NC", "007", "ANSON", "US"],
  ["NC", "009", "ASHE", "US"],
  ["NC", "011", "AVERY", "US"],
  ["NC", "013", "BEAUFORT", "US"],
  ["NC", "015", "BERTIE", "US"],
  ["NC", "017", "BLADEN", "US"],
  ["NC", "019", "BRUNSWICK", "US"],
  ["NC", "021", "BUNCOMBE", "US"],
  ["NC", "023", "BURKE", "US"],
  ["NC", "025", "CABARRUS", "US"],
  ["NC", "027", "CALDWELL", "US"],
  ["NC", "029", "CAMDEN", "US"],
  ["NC", "031", "CARTERET", "US"],
  ["NC", "033", "CASWELL", "US"],
  ["NC", "035", "CATAWBA", "US"],
  ["NC", "037", "CHATHAM", "US"],
  ["NC", "039", "CHEROKEE", "US"],
  ["NC", "041", "CHOWAN", "US"],
  ["NC", "043", "CLAY", "US"],
  ["NC", "045", "CLEVELAND", "US"],
  ["NC", "047", "COLUMBUS", "US"],
  ["NC", "049", "CRAVEN", "US"],
  ["NC", "051", "CUMBERLAND", "US"],
  ["NC", "053", "CURRITUCK", "US"],
  ["NC", "055", "DARE", "US"],
  ["NC", "057", "DAVIDSON", "US"],
  ["NC", "059", "DAVIE", "US"],
  ["NC", "061", "DUPLIN", "US"],
  ["NC", "063", "DURHAM", "US"],
  ["NC", "065", "EDGECOMBE", "US"],
  ["NC", "067", "FORSYTH", "US"],
  ["NC", "069", "FRANKLIN", "US"],
  ["NC", "071", "GASTON", "US"],
  ["NC", "073", "GATES", "US"],
  ["NC", "075", "GRAHAM", "US"],
  ["NC", "077", "GRANVILLE", "US"],
  ["NC", "079", "GREENE", "US"],
  ["NC", "081", "GUILFORD", "US"],
  ["NC", "083", "HALIFAX", "US"],
  ["NC", "085", "HARNETT", "US"],
  ["NC", "087", "HAYWOOD", "US"],
  ["NC", "089", "HENDERSON", "US"],
  ["NC", "091", "HERTFORD", "US"],
  ["NC", "093", "HOKE", "US"],
  ["NC", "095", "HYDE", "US"],
  ["NC", "097", "IREDELL", "US"],
  ["NC", "099", "JACKSON", "US"],
  ["NC", "101", "JOHNSTON", "US"],
  ["NC", "103", "JONES", "US"],
  ["NC", "105", "LEE", "US"],
  ["NC", "107", "LENOIR", "US"],
  ["NC", "109", "LINCOLN", "US"],
  ["NC", "113", "MACON", "US"],
  ["NC", "115", "MADISON", "US"],
  ["NC", "117", "MARTIN", "US"],
  ["NC", "111", "MCDOWELL", "US"],
  ["NC", "119", "MECKLENBURG", "US"],
  ["NC", "121", "MITCHELL", "US"],
  ["NC", "123", "MONTGOMERY", "US"],
  ["NC", "125", "MOORE", "US"],
  ["NC", "127", "NASH", "US"],
  ["NC", "129", "NEW HANOVER", "US"],
  ["NC", "131", "NORTHAMPTON", "US"],
  ["NC", "133", "ONSLOW", "US"],
  ["NC", "135", "ORANGE", "US"],
  ["NC", "137", "PAMLICO", "US"],
  ["NC", "139", "PASQUOTANK", "US"],
  ["NC", "141", "PENDER", "US"],
  ["NC", "143", "PERQUIMANS", "US"],
  ["NC", "145", "PERSON", "US"],
  ["NC", "147", "PITT", "US"],
  ["NC", "149", "POLK", "US"],
  ["NC", "151", "RANDOLPH", "US"],
  ["NC", "153", "RICHMOND", "US"],
  ["NC", "155", "ROBESON", "US"],
  ["NC", "157", "ROCKINGHAM", "US"],
  ["NC", "159", "ROWAN", "US"],
  ["NC", "161", "RUTHERFORD", "US"],
  ["NC", "163", "SAMPSON", "US"],
  ["NC", "165", "SCOTLAND", "US"],
  ["NC", "167", "STANLY", "US"],
  ["NC", "169", "STOKES", "US"],
  ["NC", "171", "SURRY", "US"],
  ["NC", "173", "SWAIN", "US"],
  ["NC", "175", "TRANSYLVANIA", "US"],
  ["NC", "177", "TYRRELL", "US"],
  ["NC", "179", "UNION", "US"],
  ["NC", "999", "UNKNOWN", "US"],
  ["NC", "181", "VANCE", "US"],
  ["NC", "183", "WAKE", "US"],
  ["NC", "185", "WARREN", "US"],
  ["NC", "187", "WASHINGTON", "US"],
  ["NC", "189", "WATAUGA", "US"],
  ["NC", "191", "WAYNE", "US"],
  ["NC", "193", "WILKES", "US"],
  ["NC", "195", "WILSON", "US"],
  ["NC", "197", "YADKIN", "US"],
  ["NC", "199", "YANCEY", "US"],
  ["ND", "001", "ADAMS", "US"],
  ["ND", "003", "BARNES", "US"],
  ["ND", "005", "BENSON", "US"],
  ["ND", "007", "BILLINGS", "US"],
  ["ND", "009", "BOTTINEAU", "US"],
  ["ND", "011", "BOWMAN", "US"],
  ["ND", "013", "BURKE", "US"],
  ["ND", "015", "BURLEIGH", "US"],
  ["ND", "017", "CASS", "US"],
  ["ND", "019", "CAVALIER", "US"],
  ["ND", "021", "DICKEY", "US"],
  ["ND", "023", "DIVIDE", "US"],
  ["ND", "025", "DUNN", "US"],
  ["ND", "027", "EDDY", "US"],
  ["ND", "029", "EMMONS", "US"],
  ["ND", "031", "FOSTER", "US"],
  ["ND", "033", "GOLDEN VALLEY", "US"],
  ["ND", "035", "GRAND FORKS", "US"],
  ["ND", "037", "GRANT", "US"],
  ["ND", "039", "GRIGGS", "US"],
  ["ND", "041", "HETTINGER", "US"],
  ["ND", "043", "KIDDER", "US"],
  ["ND", "045", "LAMOURE", "US"],
  ["ND", "047", "LOGAN", "US"],
  ["ND", "049", "MCHENRY", "US"],
  ["ND", "051", "MCINTOSH", "US"],
  ["ND", "053", "MCKENZIE", "US"],
  ["ND", "055", "MCLEAN", "US"],
  ["ND", "057", "MERCER", "US"],
  ["ND", "059", "MORTON", "US"],
  ["ND", "061", "MOUNTRAIL", "US"],
  ["ND", "063", "NELSON", "US"],
  ["ND", "065", "OLIVER", "US"],
  ["ND", "067", "PEMBINA", "US"],
  ["ND", "069", "PIERCE", "US"],
  ["ND", "071", "RAMSEY", "US"],
  ["ND", "073", "RANSOM", "US"],
  ["ND", "075", "RENVILLE", "US"],
  ["ND", "077", "RICHLAND", "US"],
  ["ND", "079", "ROLETTE", "US"],
  ["ND", "081", "SARGENT", "US"],
  ["ND", "083", "SHERIDAN", "US"],
  ["ND", "085", "SIOUX", "US"],
  ["ND", "087", "SLOPE", "US"],
  ["ND", "089", "STARK", "US"],
  ["ND", "091", "STEELE", "US"],
  ["ND", "093", "STUTSMAN", "US"],
  ["ND", "095", "TOWNER", "US"],
  ["ND", "097", "TRAILL", "US"],
  ["ND", "999", "UNKNOWN", "US"],
  ["ND", "099", "WALSH", "US"],
  ["ND", "101", "WARD", "US"],
  ["ND", "103", "WELLS", "US"],
  ["ND", "105", "WILLIAMS", "US"],
  ["NE", "001", "ADAMS", "US"],
  ["NE", "003", "ANTELOPE", "US"],
  ["NE", "005", "ARTHUR", "US"],
  ["NE", "007", "BANNER", "US"],
  ["NE", "009", "BLAINE", "US"],
  ["NE", "011", "BOONE", "US"],
  ["NE", "013", "BOX BUTTE", "US"],
  ["NE", "015", "BOYD", "US"],
  ["NE", "017", "BROWN", "US"],
  ["NE", "019", "BUFFALO", "US"],
  ["NE", "021", "BURT", "US"],
  ["NE", "023", "BUTLER", "US"],
  ["NE", "025", "CASS", "US"],
  ["NE", "027", "CEDAR", "US"],
  ["NE", "029", "CHASE", "US"],
  ["NE", "031", "CHERRY", "US"],
  ["NE", "033", "CHEYENNE", "US"],
  ["NE", "035", "CLAY", "US"],
  ["NE", "037", "COLFAX", "US"],
  ["NE", "039", "CUMING", "US"],
  ["NE", "041", "CUSTER", "US"],
  ["NE", "043", "DAKOTA", "US"],
  ["NE", "045", "DAWES", "US"],
  ["NE", "047", "DAWSON", "US"],
  ["NE", "049", "DEUEL", "US"],
  ["NE", "051", "DIXON", "US"],
  ["NE", "053", "DODGE", "US"],
  ["NE", "055", "DOUGLAS", "US"],
  ["NE", "057", "DUNDY", "US"],
  ["NE", "059", "FILLMORE", "US"],
  ["NE", "061", "FRANKLIN", "US"],
  ["NE", "063", "FRONTIER", "US"],
  ["NE", "065", "FURNAS", "US"],
  ["NE", "067", "GAGE", "US"],
  ["NE", "069", "GARDEN", "US"],
  ["NE", "071", "GARFIELD", "US"],
  ["NE", "073", "GOSPER", "US"],
  ["NE", "075", "GRANT", "US"],
  ["NE", "077", "GREELEY", "US"],
  ["NE", "079", "HALL", "US"],
  ["NE", "081", "HAMILTON", "US"],
  ["NE", "083", "HARLAN", "US"],
  ["NE", "085", "HAYES", "US"],
  ["NE", "087", "HITCHCOCK", "US"],
  ["NE", "089", "HOLT", "US"],
  ["NE", "091", "HOOKER", "US"],
  ["NE", "093", "HOWARD", "US"],
  ["NE", "095", "JEFFERSON", "US"],
  ["NE", "097", "JOHNSON", "US"],
  ["NE", "099", "KEARNEY", "US"],
  ["NE", "101", "KEITH", "US"],
  ["NE", "103", "KEYA PAHA", "US"],
  ["NE", "105", "KIMBALL", "US"],
  ["NE", "107", "KNOX", "US"],
  ["NE", "109", "LANCASTER", "US"],
  ["NE", "111", "LINCOLN", "US"],
  ["NE", "113", "LOGAN", "US"],
  ["NE", "115", "LOUP", "US"],
  ["NE", "119", "MADISON", "US"],
  ["NE", "117", "MCPHERSON", "US"],
  ["NE", "121", "MERRICK", "US"],
  ["NE", "123", "MORRILL", "US"],
  ["NE", "125", "NANCE", "US"],
  ["NE", "127", "NEMAHA", "US"],
  ["NE", "129", "NUCKOLLS", "US"],
  ["NE", "131", "OTOE", "US"],
  ["NE", "133", "PAWNEE", "US"],
  ["NE", "135", "PERKINS", "US"],
  ["NE", "137", "PHELPS", "US"],
  ["NE", "139", "PIERCE", "US"],
  ["NE", "141", "PLATTE", "US"],
  ["NE", "143", "POLK", "US"],
  ["NE", "145", "RED WILLOW", "US"],
  ["NE", "147", "RICHARDSON", "US"],
  ["NE", "149", "ROCK", "US"],
  ["NE", "151", "SALINE", "US"],
  ["NE", "153", "SARPY", "US"],
  ["NE", "155", "SAUNDERS", "US"],
  ["NE", "157", "SCOTTS BLUFF", "US"],
  ["NE", "159", "SEWARD", "US"],
  ["NE", "161", "SHERIDAN", "US"],
  ["NE", "163", "SHERMAN", "US"],
  ["NE", "165", "SIOUX", "US"],
  ["NE", "167", "STANTON", "US"],
  ["NE", "169", "THAYER", "US"],
  ["NE", "171", "THOMAS", "US"],
  ["NE", "173", "THURSTON", "US"],
  ["NE", "999", "UNKNOWN", "US"],
  ["NE", "175", "VALLEY", "US"],
  ["NE", "177", "WASHINGTON", "US"],
  ["NE", "179", "WAYNE", "US"],
  ["NE", "181", "WEBSTER", "US"],
  ["NE", "183", "WHEELER", "US"],
  ["NE", "185", "YORK", "US"],
  ["NF", "000", "(NEWFOUNDLAND)", "CA"],
  ["NF", "999", "UNKNOWN", "CA"],
  ["NH", "001", "BELKNAP", "US"],
  ["NH", "003", "CARROLL", "US"],
  ["NH", "005", "CHESHIRE", "US"],
  ["NH", "007", "COOS", "US"],
  ["NH", "009", "GRAFTON", "US"],
  ["NH", "011", "HILLSBOROUGH", "US"],
  ["NH", "013", "MERRIMACK", "US"],
  ["NH", "015", "ROCKINGHAM", "US"],
  ["NH", "017", "STRAFFORD", "US"],
  ["NH", "019", "SULLIVAN", "US"],
  ["NH", "999", "UNKNOWN", "US"],
  ["NI", "000", "(NICARAGUA)", "O"],
  ["NI", "999", "UNKNOWN", "O"],
  ["NJ", "001", "ATLANTIC", "US"],
  ["NJ", "003", "BERGEN", "US"],
  ["NJ", "005", "BURLINGTON", "US"],
  ["NJ", "007", "CAMDEN", "US"],
  ["NJ", "009", "CAPE MAY", "US"],
  ["NJ", "011", "CUMBERLAND", "US"],
  ["NJ", "013", "ESSEX", "US"],
  ["NJ", "015", "GLOUCESTER", "US"],
  ["NJ", "017", "HUDSON", "US"],
  ["NJ", "019", "HUNTERDON", "US"],
  ["NJ", "021", "MERCER", "US"],
  ["NJ", "023", "MIDDLESEX", "US"],
  ["NJ", "025", "MONMOUTH", "US"],
  ["NJ", "027", "MORRIS", "US"],
  ["NJ", "029", "OCEAN", "US"],
  ["NJ", "031", "PASSAIC", "US"],
  ["NJ", "033", "SALEM", "US"],
  ["NJ", "035", "SOMERSET", "US"],
  ["NJ", "037", "SUSSEX", "US"],
  ["NJ", "039", "UNION", "US"],
  ["NJ", "999", "UNKNOWN", "US"],
  ["NJ", "041", "WARREN", "US"],
  ["NL", "000", "(NUEVO LEON)", "MX"],
  ["NL", "999", "UNKNOWN", "MX"],
  ["NM", "001", "BERNALILLO", "US"],
  ["NM", "003", "CATRON", "US"],
  ["NM", "005", "CHAVES", "US"],
  ["NM", "006", "CIBOLA", "US"],
  ["NM", "007", "COLFAX", "US"],
  ["NM", "009", "CURRY", "US"],
  ["NM", "011", "DE BACA", "US"],
  ["NM", "013", "DONA ANA", "US"],
  ["NM", "015", "EDDY", "US"],
  ["NM", "017", "GRANT", "US"],
  ["NM", "019", "GUADALUPE", "US"],
  ["NM", "021", "HARDING", "US"],
  ["NM", "023", "HIDALGO", "US"],
  ["NM", "025", "LEA", "US"],
  ["NM", "027", "LINCOLN", "US"],
  ["NM", "028", "LOS ALAMOS", "US"],
  ["NM", "029", "LUNA", "US"],
  ["NM", "031", "MCKINLEY", "US"],
  ["NM", "033", "MORA", "US"],
  ["NM", "035", "OTERO", "US"],
  ["NM", "037", "QUAY", "US"],
  ["NM", "039", "RIO ARRIBA", "US"],
  ["NM", "041", "ROOSEVELT", "US"],
  ["NM", "045", "SAN JUAN", "US"],
  ["NM", "047", "SAN MIGUEL", "US"],
  ["NM", "043", "SANDOVAL", "US"],
  ["NM", "049", "SANTA FE", "US"],
  ["NM", "051", "SIERRA", "US"],
  ["NM", "053", "SOCORRO", "US"],
  ["NM", "055", "TAOS", "US"],
  ["NM", "057", "TORRANCE", "US"],
  ["NM", "059", "UNION", "US"],
  ["NM", "999", "UNKNOWN", "US"],
  ["NM", "061", "VALENCIA", "US"],
  ["NS", "000", "(NOVA SCOTIA)", "CA"],
  ["NS", "999", "UNKNOWN", "CA"],
  ["NT", "000", "(NEW TERRITORIES)", "CA"],
  ["NT", "999", "UNKNOWN", "CA"],
  ["NU", "000", "(NUNAVUT)", "CA"],
  ["NU", "999", "UNKNOWN", "CA"],
  ["NV", "510", "CARSON CITY", "US"],
  ["NV", "001", "CHURCHILL", "US"],
  ["NV", "003", "CLARK", "US"],
  ["NV", "005", "DOUGLAS", "US"],
  ["NV", "007", "ELKO", "US"],
  ["NV", "009", "ESMERALDA", "US"],
  ["NV", "011", "EUREKA", "US"],
  ["NV", "013", "HUMBOLDT", "US"],
  ["NV", "500", "INDEPENDENT CITY/S", "US"],
  ["NV", "015", "LANDER", "US"],
  ["NV", "017", "LINCOLN", "US"],
  ["NV", "019", "LYON", "US"],
  ["NV", "021", "MINERAL", "US"],
  ["NV", "023", "NYE", "US"],
  ["NV", "027", "PERSHING", "US"],
  ["NV", "029", "STOREY", "US"],
  ["NV", "999", "UNKNOWN", "US"],
  ["NV", "031", "WASHOE", "US"],
  ["NV", "033", "WHITE PINE", "US"],
  ["NY", "001", "ALBANY", "US"],
  ["NY", "003", "ALLEGANY", "US"],
  ["NY", "005", "BRONX", "US"],
  ["NY", "007", "BROOME", "US"],
  ["NY", "009", "CATTARAUGUS", "US"],
  ["NY", "011", "CAYUGA", "US"],
  ["NY", "013", "CHAUTAUQUA", "US"],
  ["NY", "015", "CHEMUNG", "US"],
  ["NY", "017", "CHENANGO", "US"],
  ["NY", "019", "CLINTON", "US"],
  ["NY", "021", "COLUMBIA", "US"],
  ["NY", "023", "CORTLAND", "US"],
  ["NY", "025", "DELAWARE", "US"],
  ["NY", "027", "DUTCHESS", "US"],
  ["NY", "029", "ERIE", "US"],
  ["NY", "031", "ESSEX", "US"],
  ["NY", "033", "FRANKLIN", "US"],
  ["NY", "035", "FULTON", "US"],
  ["NY", "037", "GENESEE", "US"],
  ["NY", "039", "GREENE", "US"],
  ["NY", "041", "HAMILTON", "US"],
  ["NY", "043", "HERKIMER", "US"],
  ["NY", "045", "JEFFERSON", "US"],
  ["NY", "047", "KINGS", "US"],
  ["NY", "049", "LEWIS", "US"],
  ["NY", "051", "LIVINGSTON", "US"],
  ["NY", "053", "MADISON", "US"],
  ["NY", "055", "MONROE", "US"],
  ["NY", "057", "MONTGOMERY", "US"],
  ["NY", "059", "NASSAU", "US"],
  ["NY", "061", "NEW YORK", "US"],
  ["NY", "063", "NIAGARA", "US"],
  ["NY", "065", "ONEIDA", "US"],
  ["NY", "067", "ONONDAGA", "US"],
  ["NY", "069", "ONTARIO", "US"],
  ["NY", "071", "ORANGE", "US"],
  ["NY", "073", "ORLEANS", "US"],
  ["NY", "075", "OSWEGO", "US"],
  ["NY", "077", "OTSEGO", "US"],
  ["NY", "079", "PUTNAM", "US"],
  ["NY", "081", "QUEENS", "US"],
  ["NY", "083", "RENSSELAER", "US"],
  ["NY", "085", "RICHMOND", "US"],
  ["NY", "087", "ROCKLAND", "US"],
  ["NY", "089", "SAINT LAWRENCE", "US"],
  ["NY", "091", "SARATOGA", "US"],
  ["NY", "093", "SCHENECTADY", "US"],
  ["NY", "095", "SCHOHARIE", "US"],
  ["NY", "097", "SCHUYLER", "US"],
  ["NY", "099", "SENECA", "US"],
  ["NY", "101", "STEUBEN", "US"],
  ["NY", "103", "SUFFOLK", "US"],
  ["NY", "105", "SULLIVAN", "US"],
  ["NY", "107", "TIOGA", "US"],
  ["NY", "109", "TOMPKINS", "US"],
  ["NY", "111", "ULSTER", "US"],
  ["NY", "999", "UNKNOWN", "US"],
  ["NY", "113", "WARREN", "US"],
  ["NY", "115", "WASHINGTON", "US"],
  ["NY", "117", "WAYNE", "US"],
  ["NY", "119", "WESTCHESTER", "US"],
  ["NY", "121", "WYOMING", "US"],
  ["NY", "123", "YATES", "US"],
  ["OA", "000", "(OAXACE)", "MX"],
  ["OA", "999", "UNKNOWN", "MX"],
  ["OH", "001", "ADAMS", "US"],
  ["OH", "003", "ALLEN", "US"],
  ["OH", "005", "ASHLAND", "US"],
  ["OH", "007", "ASHTABULA", "US"],
  ["OH", "009", "ATHENS", "US"],
  ["OH", "011", "AUGLAIZE", "US"],
  ["OH", "013", "BELMONT", "US"],
  ["OH", "015", "BROWN", "US"],
  ["OH", "017", "BUTLER", "US"],
  ["OH", "019", "CARROLL", "US"],
  ["OH", "021", "CHAMPAIGN", "US"],
  ["OH", "023", "CLARK", "US"],
  ["OH", "025", "CLERMONT", "US"],
  ["OH", "027", "CLINTON", "US"],
  ["OH", "029", "COLUMBIANA", "US"],
  ["OH", "031", "COSHOCTON", "US"],
  ["OH", "033", "CRAWFORD", "US"],
  ["OH", "035", "CUYAHOGA", "US"],
  ["OH", "037", "DARKE", "US"],
  ["OH", "039", "DEFIANCE", "US"],
  ["OH", "041", "DELAWARE", "US"],
  ["OH", "043", "ERIE", "US"],
  ["OH", "045", "FAIRFIELD", "US"],
  ["OH", "047", "FAYETTE", "US"],
  ["OH", "049", "FRANKLIN", "US"],
  ["OH", "051", "FULTON", "US"],
  ["OH", "053", "GALLIA", "US"],
  ["OH", "055", "GEAUGA", "US"],
  ["OH", "057", "GREENE", "US"],
  ["OH", "059", "GUERNSEY", "US"],
  ["OH", "061", "HAMILTON", "US"],
  ["OH", "063", "HANCOCK", "US"],
  ["OH", "065", "HARDIN", "US"],
  ["OH", "067", "HARRISON", "US"],
  ["OH", "069", "HENRY", "US"],
  ["OH", "071", "HIGHLAND", "US"],
  ["OH", "073", "HOCKING", "US"],
  ["OH", "075", "HOLMES", "US"],
  ["OH", "077", "HURON", "US"],
  ["OH", "079", "JACKSON", "US"],
  ["OH", "081", "JEFFERSON", "US"],
  ["OH", "083", "KNOX", "US"],
  ["OH", "085", "LAKE", "US"],
  ["OH", "087", "LAWRENCE", "US"],
  ["OH", "089", "LICKING", "US"],
  ["OH", "091", "LOGAN", "US"],
  ["OH", "093", "LORAIN", "US"],
  ["OH", "095", "LUCAS", "US"],
  ["OH", "097", "MADISON", "US"],
  ["OH", "099", "MAHONING", "US"],
  ["OH", "101", "MARION", "US"],
  ["OH", "103", "MEDINA", "US"],
  ["OH", "105", "MEIGS", "US"],
  ["OH", "107", "MERCER", "US"],
  ["OH", "109", "MIAMI", "US"],
  ["OH", "111", "MONROE", "US"],
  ["OH", "113", "MONTGOMERY", "US"],
  ["OH", "115", "MORGAN", "US"],
  ["OH", "117", "MORROW", "US"],
  ["OH", "119", "MUSKINGUM", "US"],
  ["OH", "121", "NOBLE", "US"],
  ["OH", "123", "OTTAWA", "US"],
  ["OH", "125", "PAULDING", "US"],
  ["OH", "127", "PERRY", "US"],
  ["OH", "129", "PICKAWAY", "US"],
  ["OH", "131", "PIKE", "US"],
  ["OH", "133", "PORTAGE", "US"],
  ["OH", "135", "PREBLE", "US"],
  ["OH", "137", "PUTNAM", "US"],
  ["OH", "139", "RICHLAND", "US"],
  ["OH", "141", "ROSS", "US"],
  ["OH", "143", "SANDUSKY", "US"],
  ["OH", "145", "SCIOTO", "US"],
  ["OH", "147", "SENECA", "US"],
  ["OH", "149", "SHELBY", "US"],
  ["OH", "151", "STARK", "US"],
  ["OH", "153", "SUMMIT", "US"],
  ["OH", "155", "TRUMBULL", "US"],
  ["OH", "157", "TUSCARAWAS", "US"],
  ["OH", "159", "UNION", "US"],
  ["OH", "999", "UNKNOWN", "US"],
  ["OH", "161", "VAN WERT", "US"],
  ["OH", "163", "VINTON", "US"],
  ["OH", "165", "WARREN", "US"],
  ["OH", "167", "WASHINGTON", "US"],
  ["OH", "169", "WAYNE", "US"],
  ["OH", "171", "WILLIAMS", "US"],
  ["OH", "173", "WOOD", "US"],
  ["OH", "175", "WYANDOT", "US"],
  ["OK", "001", "ADAIR", "US"],
  ["OK", "003", "ALFALFA", "US"],
  ["OK", "005", "ATOKA", "US"],
  ["OK", "007", "BEAVER", "US"],
  ["OK", "009", "BECKHAM", "US"],
  ["OK", "011", "BLAINE", "US"],
  ["OK", "013", "BRYAN", "US"],
  ["OK", "015", "CADDO", "US"],
  ["OK", "017", "CANADIAN", "US"],
  ["OK", "019", "CARTER", "US"],
  ["OK", "021", "CHEROKEE", "US"],
  ["OK", "023", "CHOCTAW", "US"],
  ["OK", "025", "CIMARRON", "US"],
  ["OK", "027", "CLEVELAND", "US"],
  ["OK", "029", "COAL", "US"],
  ["OK", "031", "COMANCHE", "US"],
  ["OK", "033", "COTTON", "US"],
  ["OK", "035", "CRAIG", "US"],
  ["OK", "037", "CREEK", "US"],
  ["OK", "039", "CUSTER", "US"],
  ["OK", "041", "DELAWARE", "US"],
  ["OK", "043", "DEWEY", "US"],
  ["OK", "045", "ELLIS", "US"],
  ["OK", "047", "GARFIELD", "US"],
  ["OK", "049", "GARVIN", "US"],
  ["OK", "051", "GRADY", "US"],
  ["OK", "053", "GRANT", "US"],
  ["OK", "055", "GREER", "US"],
  ["OK", "057", "HARMON", "US"],
  ["OK", "059", "HARPER", "US"],
  ["OK", "061", "HASKELL", "US"],
  ["OK", "063", "HUGHES", "US"],
  ["OK", "065", "JACKSON", "US"],
  ["OK", "067", "JEFFERSON", "US"],
  ["OK", "069", "JOHNSTON", "US"],
  ["OK", "071", "KAY", "US"],
  ["OK", "073", "KINGFISHER", "US"],
  ["OK", "075", "KIOWA", "US"],
  ["OK", "077", "LATIMER", "US"],
  ["OK", "079", "LE FLORE", "US"],
  ["OK", "081", "LINCOLN", "US"],
  ["OK", "083", "LOGAN", "US"],
  ["OK", "085", "LOVE", "US"],
  ["OK", "093", "MAJOR", "US"],
  ["OK", "095", "MARSHALL", "US"],
  ["OK", "097", "MAYES", "US"],
  ["OK", "087", "MCCLAIN", "US"],
  ["OK", "089", "MCCURTAIN", "US"],
  ["OK", "091", "MCINTOSH", "US"],
  ["OK", "099", "MURRAY", "US"],
  ["OK", "101", "MUSKOGEE", "US"],
  ["OK", "103", "NOBLE", "US"],
  ["OK", "105", "NOWATA", "US"],
  ["OK", "107", "OKFUSKEE", "US"],
  ["OK", "109", "OKLAHOMA", "US"],
  ["OK", "111", "OKMULGEE", "US"],
  ["OK", "113", "OSAGE", "US"],
  ["OK", "115", "OTTAWA", "US"],
  ["OK", "117", "PAWNEE", "US"],
  ["OK", "119", "PAYNE", "US"],
  ["OK", "121", "PITTSBURG", "US"],
  ["OK", "123", "PONTOTOC", "US"],
  ["OK", "125", "POTTAWATOMIE", "US"],
  ["OK", "127", "PUSHMATAHA", "US"],
  ["OK", "129", "ROGER MILLS", "US"],
  ["OK", "131", "ROGERS", "US"],
  ["OK", "133", "SEMINOLE", "US"],
  ["OK", "135", "SEQUOYAH", "US"],
  ["OK", "137", "STEPHENS", "US"],
  ["OK", "139", "TEXAS", "US"],
  ["OK", "141", "TILLMAN", "US"],
  ["OK", "143", "TULSA", "US"],
  ["OK", "999", "UNKNOWN", "US"],
  ["OK", "145", "WAGONER", "US"],
  ["OK", "147", "WASHINGTON", "US"],
  ["OK", "149", "WASHITA", "US"],
  ["OK", "151", "WOODS", "US"],
  ["OK", "153", "WOODWARD", "US"],
  ["ON", "000", "(ONTARIO)", "CA"],
  ["ON", "999", "UNKNOWN", "CA"],
  ["OR", "001", "BAKER", "US"],
  ["OR", "003", "BENTON", "US"],
  ["OR", "005", "CLACKAMAS", "US"],
  ["OR", "007", "CLATSOP", "US"],
  ["OR", "009", "COLUMBIA", "US"],
  ["OR", "011", "COOS", "US"],
  ["OR", "013", "CROOK", "US"],
  ["OR", "015", "CURRY", "US"],
  ["OR", "017", "DESCHUTES", "US"],
  ["OR", "019", "DOUGLAS", "US"],
  ["OR", "021", "GILLIAM", "US"],
  ["OR", "023", "GRANT", "US"],
  ["OR", "025", "HARNEY", "US"],
  ["OR", "027", "HOOD RIVER", "US"],
  ["OR", "029", "JACKSON", "US"],
  ["OR", "031", "JEFFERSON", "US"],
  ["OR", "033", "JOSEPHINE", "US"],
  ["OR", "035", "KLAMATH", "US"],
  ["OR", "037", "LAKE", "US"],
  ["OR", "039", "LANE", "US"],
  ["OR", "041", "LINCOLN", "US"],
  ["OR", "043", "LINN", "US"],
  ["OR", "045", "MALHEUR", "US"],
  ["OR", "047", "MARION", "US"],
  ["OR", "049", "MORROW", "US"],
  ["OR", "051", "MULTNOMAH", "US"],
  ["OR", "053", "POLK", "US"],
  ["OR", "055", "SHERMAN", "US"],
  ["OR", "057", "TILLAMOOK", "US"],
  ["OR", "059", "UMATILLA", "US"],
  ["OR", "061", "UNION", "US"],
  ["OR", "999", "UNKNOWN", "US"],
  ["OR", "063", "WALLOWA", "US"],
  ["OR", "065", "WASCO", "US"],
  ["OR", "067", "WASHINGTON", "US"],
  ["OR", "069", "WHEELER", "US"],
  ["OR", "071", "YAMHILL", "US"],
  ["OT", "000", "(OTHER)", "O"],
  ["OT", "999", "UNKNOWN", "O"],
  ["PA", "001", "ADAMS", "US"],
  ["PA", "003", "ALLEGHENY", "US"],
  ["PA", "005", "ARMSTRONG", "US"],
  ["PA", "007", "BEAVER", "US"],
  ["PA", "009", "BEDFORD", "US"],
  ["PA", "011", "BERKS", "US"],
  ["PA", "013", "BLAIR", "US"],
  ["PA", "015", "BRADFORD", "US"],
  ["PA", "017", "BUCKS", "US"],
  ["PA", "019", "BUTLER", "US"],
  ["PA", "021", "CAMBRIA", "US"],
  ["PA", "023", "CAMERON", "US"],
  ["PA", "025", "CARBON", "US"],
  ["PA", "027", "CENTRE", "US"],
  ["PA", "029", "CHESTER", "US"],
  ["PA", "031", "CLARION", "US"],
  ["PA", "033", "CLEARFIELD", "US"],
  ["PA", "035", "CLINTON", "US"],
  ["PA", "037", "COLUMBIA", "US"],
  ["PA", "039", "CRAWFORD", "US"],
  ["PA", "041", "CUMBERLAND", "US"],
  ["PA", "043", "DAUPHIN", "US"],
  ["PA", "045", "DELAWARE", "US"],
  ["PA", "047", "ELK", "US"],
  ["PA", "049", "ERIE", "US"],
  ["PA", "051", "FAYETTE", "US"],
  ["PA", "053", "FOREST", "US"],
  ["PA", "055", "FRANKLIN", "US"],
  ["PA", "057", "FULTON", "US"],
  ["PA", "059", "GREENE", "US"],
  ["PA", "061", "HUNTINGDON", "US"],
  ["PA", "063", "INDIANA", "US"],
  ["PA", "065", "JEFFERSON", "US"],
  ["PA", "067", "JUNIATA", "US"],
  ["PA", "069", "LACKAWANNA", "US"],
  ["PA", "071", "LANCASTER", "US"],
  ["PA", "073", "LAWRENCE", "US"],
  ["PA", "075", "LEBANON", "US"],
  ["PA", "077", "LEHIGH", "US"],
  ["PA", "079", "LUZERNE", "US"],
  ["PA", "081", "LYCOMING", "US"],
  ["PA", "083", "MCKEAN", "US"],
  ["PA", "085", "MERCER", "US"],
  ["PA", "087", "MIFFLIN", "US"],
  ["PA", "089", "MONROE", "US"],
  ["PA", "091", "MONTGOMERY", "US"],
  ["PA", "093", "MONTOUR", "US"],
  ["PA", "095", "NORTHAMPTON", "US"],
  ["PA", "097", "NORTHUMBERLAND", "US"],
  ["PA", "099", "PERRY", "US"],
  ["PA", "101", "PHILADELPHIA", "US"],
  ["PA", "103", "PIKE", "US"],
  ["PA", "105", "POTTER", "US"],
  ["PA", "107", "SCHUYLKILL", "US"],
  ["PA", "109", "SNYDER", "US"],
  ["PA", "111", "SOMERSET", "US"],
  ["PA", "113", "SULLIVAN", "US"],
  ["PA", "115", "SUSQUEHANNA", "US"],
  ["PA", "117", "TIOGA", "US"],
  ["PA", "119", "UNION", "US"],
  ["PA", "999", "UNKNOWN", "US"],
  ["PA", "121", "VENANGO", "US"],
  ["PA", "123", "WARREN", "US"],
  ["PA", "125", "WASHINGTON", "US"],
  ["PA", "127", "WAYNE", "US"],
  ["PA", "129", "WESTMORELAND", "US"],
  ["PA", "131", "WYOMING", "US"],
  ["PA", "133", "YORK", "US"],
  ["PE", "000", "(PRINCE EDWARD ISLAND)", "CA"],
  ["PE", "999", "UNKNOWN", "CA"],
  ["PN", "000", "(PANAMA)", "O"],
  ["PN", "999", "UNKNOWN", "O"],
  ["QC", "000", "(QUEBEC)", "CA"],
  ["QC", "011", "BEAUCE", "CA"],
  ["QC", "003", "EASTERN TOWNSHIPS", "CA"],
  ["QC", "023", "FAR NORTH", "CA"],
  ["QC", "025", "FAR WEST", "CA"],
  ["QC", "021", "GASPE & ISLANDS", "CA"],
  ["QC", "015", "KAMOURASKA", "CA"],
  ["QC", "019", "LAC-ST-JEAN", "CA"],
  ["QC", "017", "LAURENTIANS", "CA"],
  ["QC", "001", "MONTREAL", "CA"],
  ["QC", "013", "NORTH SHORE EAST", "CA"],
  ["QC", "009", "NORTH SHORE WEST", "CA"],
  ["QC", "027", "RICHELIEU", "CA"],
  ["QC", "007", "SOUTH SHORE", "CA"],
  ["QC", "005", "SOUTHERN TOWNSHIPS", "CA"],
  ["QC", "999", "UNKNOWN", "CA"],
  ["PR", "000", "(PUERTO RICO)", "US"],
  ["PR", "001", "ADJUNTAS", "US"],
  ["PR", "003", "AGUADA", "US"],
  ["PR", "005", "AGUADILLA", "US"],
  ["PR", "007", "AGUAS BUENAS", "US"],
  ["PR", "009", "AIBONITO", "US"],
  ["PR", "011", "ANASCO", "US"],
  ["PR", "013", "ARECIBO", "US"],
  ["PR", "015", "ARROYO", "US"],
  ["PR", "017", "BARCELONETA", "US"],
  ["PR", "019", "BARRANQUITAS", "US"],
  ["PR", "021", "BAYAMON", "US"],
  ["PR", "023", "CABO ROJO", "US"],
  ["PR", "025", "CAGUAS", "US"],
  ["PR", "027", "CAMUY", "US"],
  ["PR", "029", "CANOVANAS", "US"],
  ["PR", "031", "CAROLINA", "US"],
  ["PR", "033", "CATANO", "US"],
  ["PR", "035", "CAYEY", "US"],
  ["PR", "037", "CEIBA", "US"],
  ["PR", "039", "CIALES", "US"],
  ["PR", "041", "CIDRA", "US"],
  ["PR", "043", "COAMO", "US"],
  ["PR", "045", "COMERIO", "US"],
  ["PR", "047", "COROZAL", "US"],
  ["PR", "049", "CULEBRA", "US"],
  ["PR", "051", "DORADO", "US"],
  ["PR", "053", "FAJARDO", "US"],
  ["PR", "054", "FLORIDA", "US"],
  ["PR", "055", "GUANICA", "US"],
  ["PR", "057", "GUAYAMA", "US"],
  ["PR", "059", "GUAYANILLA", "US"],
  ["PR", "061", "GUAYNABO", "US"],
  ["PR", "063", "GURABO", "US"],
  ["PR", "065", "HATILLO", "US"],
  ["PR", "067", "HORMIGUEROS", "US"],
  ["PR", "069", "HUMACAO", "US"],
  ["PR", "071", "ISABELA", "US"],
  ["PR", "073", "JAYUYA", "US"],
  ["PR", "075", "JUANA DIAZ", "US"],
  ["PR", "077", "JUNCOS", "US"],
  ["PR", "079", "LAJAS", "US"],
  ["PR", "081", "LARES", "US"],
  ["PR", "083", "LAS MARIAS", "US"],
  ["PR", "085", "LAS PIEDRAS", "US"],
  ["PR", "087", "LOIZA", "US"],
  ["PR", "089", "LUQUILLO", "US"],
  ["PR", "091", "MANATI", "US"],
  ["PR", "093", "MARICAO", "US"],
  ["PR", "095", "MAUNABO", "US"],
  ["PR", "097", "MAYAGUEZ", "US"],
  ["PR", "099", "MOCA", "US"],
  ["PR", "101", "MOROVIS", "US"],
  ["PR", "103", "NAGUABO", "US"],
  ["PR", "105", "NARANJITO", "US"],
  ["PR", "107", "OROCOVIS", "US"],
  ["PR", "109", "PATILLAS", "US"],
  ["PR", "111", "PENUELAS", "US"],
  ["PR", "113", "PONCE", "US"],
  ["PR", "115", "QUEBRADILLAS", "US"],
  ["PR", "117", "RINCON", "US"],
  ["PR", "119", "RIO GRANDE", "US"],
  ["PR", "121", "SABANA GRANDE", "US"],
  ["PR", "123", "SALINAS", "US"],
  ["PR", "125", "SAN GERMAN", "US"],
  ["PR", "127", "SAN JUAN", "US"],
  ["PR", "129", "SAN LORENZO", "US"],
  ["PR", "131", "SAN SEBASTIAN", "US"],
  ["PR", "133", "SANTA ISABEL", "US"],
  ["PR", "135", "TOA ALTA", "US"],
  ["PR", "137", "TOA BAJA", "US"],
  ["PR", "139", "TRUJILLO ALTO", "US"],
  ["PR", "999", "UNKNOWN", "US"],
  ["PR", "141", "UTUADO", "US"],
  ["PR", "143", "VEGA ALTA", "US"],
  ["PR", "145", "VEGA BAJA", "US"],
  ["PR", "147", "VIEQUES", "US"],
  ["PR", "149", "VILLALBA", "US"],
  ["PR", "151", "YABUCOA", "US"],
  ["PR", "153", "YAUCO", "US"],
  ["PU", "000", "(PUEBLA)", "MX"],
  ["PU", "999", "UNKNOWN", "MX"],
  ["QE", "000", "(QUERETARO)", "MX"],
  ["QE", "999", "UNKNOWN", "MX"],
  ["QI", "000", "(QUINTANA ROO)", "MX"],
  ["QI", "999", "UNKNOWN", "MX"],
  ["RI", "001", "BRISTOL", "US"],
  ["RI", "003", "KENT", "US"],
  ["RI", "005", "NEWPORT", "US"],
  ["RI", "007", "PROVIDENCE", "US"],
  ["RI", "999", "UNKNOWN", "US"],
  ["RI", "009", "WASHINGTON", "US"],
  ["SC", "001", "ABBEVILLE", "US"],
  ["SC", "003", "AIKEN", "US"],
  ["SC", "005", "ALLENDALE", "US"],
  ["SC", "007", "ANDERSON", "US"],
  ["SC", "009", "BAMBERG", "US"],
  ["SC", "011", "BARNWELL", "US"],
  ["SC", "013", "BEAUFORT", "US"],
  ["SC", "015", "BERKELEY", "US"],
  ["SC", "017", "CALHOUN", "US"],
  ["SC", "019", "CHARLESTON", "US"],
  ["SC", "021", "CHEROKEE", "US"],
  ["SC", "023", "CHESTER", "US"],
  ["SC", "025", "CHESTERFIELD", "US"],
  ["SC", "027", "CLARENDON", "US"],
  ["SC", "029", "COLLETON", "US"],
  ["SC", "031", "DARLINGTON", "US"],
  ["SC", "033", "DILLON", "US"],
  ["SC", "035", "DORCHESTER", "US"],
  ["SC", "037", "EDGEFIELD", "US"],
  ["SC", "039", "FAIRFIELD", "US"],
  ["SC", "041", "FLORENCE", "US"],
  ["SC", "043", "GEORGETOWN", "US"],
  ["SC", "045", "GREENVILLE", "US"],
  ["SC", "047", "GREENWOOD", "US"],
  ["SC", "049", "HAMPTON", "US"],
  ["SC", "051", "HORRY", "US"],
  ["SC", "053", "JASPER", "US"],
  ["SC", "055", "KERSHAW", "US"],
  ["SC", "057", "LANCASTER", "US"],
  ["SC", "059", "LAURENS", "US"],
  ["SC", "061", "LEE", "US"],
  ["SC", "063", "LEXINGTON", "US"],
  ["SC", "067", "MARION", "US"],
  ["SC", "069", "MARLBORO", "US"],
  ["SC", "065", "MCCORMICK", "US"],
  ["SC", "071", "NEWBERRY", "US"],
  ["SC", "073", "OCONEE", "US"],
  ["SC", "075", "ORANGEBURG", "US"],
  ["SC", "077", "PICKENS", "US"],
  ["SC", "079", "RICHLAND", "US"],
  ["SC", "081", "SALUDA", "US"],
  ["SC", "083", "SPARTANBURG", "US"],
  ["SC", "085", "SUMTER", "US"],
  ["SC", "087", "UNION", "US"],
  ["SC", "999", "UNKNOWN", "US"],
  ["SC", "089", "WILLIAMSBURG", "US"],
  ["SC", "091", "YORK", "US"],
  ["SD", "003", "AURORA", "US"],
  ["SD", "005", "BEADLE", "US"],
  ["SD", "007", "BENNETT", "US"],
  ["SD", "009", "BON HOMME", "US"],
  ["SD", "011", "BROOKINGS", "US"],
  ["SD", "013", "BROWN", "US"],
  ["SD", "015", "BRULE", "US"],
  ["SD", "017", "BUFFALO", "US"],
  ["SD", "019", "BUTTE", "US"],
  ["SD", "021", "CAMPBELL", "US"],
  ["SD", "023", "CHARLES MIX", "US"],
  ["SD", "025", "CLARK", "US"],
  ["SD", "027", "CLAY", "US"],
  ["SD", "029", "CODINGTON", "US"],
  ["SD", "031", "CORSON", "US"],
  ["SD", "033", "CUSTER", "US"],
  ["SD", "035", "DAVISON", "US"],
  ["SD", "037", "DAY", "US"],
  ["SD", "039", "DEUEL", "US"],
  ["SD", "041", "DEWEY", "US"],
  ["SD", "043", "DOUGLAS", "US"],
  ["SD", "045", "EDMUNDS", "US"],
  ["SD", "047", "FALL RIVER", "US"],
  ["SD", "049", "FAULK", "US"],
  ["SD", "051", "GRANT", "US"],
  ["SD", "053", "GREGORY", "US"],
  ["SD", "055", "HAAKON", "US"],
  ["SD", "057", "HAMLIN", "US"],
  ["SD", "059", "HAND", "US"],
  ["SD", "061", "HANSON", "US"],
  ["SD", "063", "HARDING", "US"],
  ["SD", "065", "HUGHES", "US"],
  ["SD", "067", "HUTCHINSON", "US"],
  ["SD", "069", "HYDE", "US"],
  ["SD", "071", "JACKSON", "US"],
  ["SD", "073", "JERAULD", "US"],
  ["SD", "075", "JONES", "US"],
  ["SD", "077", "KINGSBURY", "US"],
  ["SD", "079", "LAKE", "US"],
  ["SD", "081", "LAWRENCE", "US"],
  ["SD", "083", "LINCOLN", "US"],
  ["SD", "085", "LYMAN", "US"],
  ["SD", "091", "MARSHALL", "US"],
  ["SD", "087", "MCCOOK", "US"],
  ["SD", "089", "MCPHERSON", "US"],
  ["SD", "093", "MEADE", "US"],
  ["SD", "095", "MELLETTE", "US"],
  ["SD", "097", "MINER", "US"],
  ["SD", "099", "MINNEHAHA", "US"],
  ["SD", "101", "MOODY", "US"],
  ["SD", "103", "PENNINGTON", "US"],
  ["SD", "105", "PERKINS", "US"],
  ["SD", "107", "POTTER", "US"],
  ["SD", "109", "ROBERTS", "US"],
  ["SD", "111", "SANBORN", "US"],
  ["SD", "113", "SHANNON", "US"],
  ["SD", "115", "SPINK", "US"],
  ["SD", "117", "STANLEY", "US"],
  ["SD", "119", "SULLY", "US"],
  ["SD", "121", "TODD", "US"],
  ["SD", "123", "TRIPP", "US"],
  ["SD", "125", "TURNER", "US"],
  ["SD", "127", "UNION", "US"],
  ["SD", "999", "UNKNOWN", "US"],
  ["SD", "129", "WALWORTH", "US"],
  ["SD", "131", "WASHABAUGH", "US"],
  ["SD", "135", "YANKTON", "US"],
  ["SD", "137", "ZIEBACH", "US"],
  ["SI", "000", "(SINALOA)", "MX"],
  ["SI", "999", "UNKNOWN", "MX"],
  ["SK", "000", "(SASKATCHEWAN)", "CA"],
  ["SK", "999", "UNKNOWN", "CA"],
  ["SL", "000", "(SAN LUIS POTOSI)", "MX"],
  ["SL", "999", "UNKNOWN", "MX"],
  ["SO", "000", "(SALTILLO)", "MX"],
  ["SO", "999", "UNKNOWN", "MX"],
  ["TA", "000", "(TAMAULIBAS)", "MX"],
  ["TA", "999", "UNKNOWN", "MX"],
  ["TB", "000", "(TABASCO)", "MX"],
  ["TB", "999", "UNKNOWN", "MX"],
  ["TL", "000", "(TLAXCALA)", "MX"],
  ["TL", "999", "UNKNOWN", "MX"],
  ["TN", "001", "ANDERSON", "US"],
  ["TN", "003", "BEDFORD", "US"],
  ["TN", "005", "BENTON", "US"],
  ["TN", "007", "BLEDSOE", "US"],
  ["TN", "009", "BLOUNT", "US"],
  ["TN", "011", "BRADLEY", "US"],
  ["TN", "013", "CAMPBELL", "US"],
  ["TN", "015", "CANNON", "US"],
  ["TN", "017", "CARROLL", "US"],
  ["TN", "019", "CARTER", "US"],
  ["TN", "021", "CHEATHAM", "US"],
  ["TN", "023", "CHESTER", "US"],
  ["TN", "025", "CLAIBORNE", "US"],
  ["TN", "027", "CLAY", "US"],
  ["TN", "029", "COCKE", "US"],
  ["TN", "031", "COFFEE", "US"],
  ["TN", "033", "CROCKETT", "US"],
  ["TN", "035", "CUMBERLAND", "US"],
  ["TN", "037", "DAVIDSON", "US"],
  ["TN", "039", "DECATUR", "US"],
  ["TN", "041", "DEKALB", "US"],
  ["TN", "043", "DICKSON", "US"],
  ["TN", "045", "DYER", "US"],
  ["TN", "047", "FAYETTE", "US"],
  ["TN", "049", "FENTRESS", "US"],
  ["TN", "051", "FRANKLIN", "US"],
  ["TN", "053", "GIBSON", "US"],
  ["TN", "055", "GILES", "US"],
  ["TN", "057", "GRAINGER", "US"],
  ["TN", "059", "GREENE", "US"],
  ["TN", "061", "GRUNDY", "US"],
  ["TN", "063", "HAMBLEN", "US"],
  ["TN", "065", "HAMILTON", "US"],
  ["TN", "067", "HANCOCK", "US"],
  ["TN", "069", "HARDEMAN", "US"],
  ["TN", "071", "HARDIN", "US"],
  ["TN", "073", "HAWKINS", "US"],
  ["TN", "075", "HAYWOOD", "US"],
  ["TN", "077", "HENDERSON", "US"],
  ["TN", "079", "HENRY", "US"],
  ["TN", "081", "HICKMAN", "US"],
  ["TN", "083", "HOUSTON", "US"],
  ["TN", "085", "HUMPHREYS", "US"],
  ["TN", "087", "JACKSON", "US"],
  ["TN", "089", "JEFFERSON", "US"],
  ["TN", "091", "JOHNSON", "US"],
  ["TN", "093", "KNOX", "US"],
  ["TN", "095", "LAKE", "US"],
  ["TN", "097", "LAUDERDALE", "US"],
  ["TN", "099", "LAWRENCE", "US"],
  ["TN", "101", "LEWIS", "US"],
  ["TN", "103", "LINCOLN", "US"],
  ["TN", "105", "LOUDON", "US"],
  ["TN", "111", "MACON", "US"],
  ["TN", "113", "MADISON", "US"],
  ["TN", "115", "MARION", "US"],
  ["TN", "117", "MARSHALL", "US"],
  ["TN", "119", "MAURY", "US"],
  ["TN", "107", "MCMINN", "US"],
  ["TN", "109", "MCNAIRY", "US"],
  ["TN", "121", "MEIGS", "US"],
  ["TN", "123", "MONROE", "US"],
  ["TN", "125", "MONTGOMERY", "US"],
  ["TN", "127", "MOORE", "US"],
  ["TN", "129", "MORGAN", "US"],
  ["TN", "131", "OBION", "US"],
  ["TN", "133", "OVERTON", "US"],
  ["TN", "135", "PERRY", "US"],
  ["TN", "137", "PICKETT", "US"],
  ["TN", "139", "POLK", "US"],
  ["TN", "141", "PUTNAM", "US"],
  ["TN", "143", "RHEA", "US"],
  ["TN", "145", "ROANE", "US"],
  ["TN", "147", "ROBERTSON", "US"],
  ["TN", "149", "RUTHERFORD", "US"],
  ["TN", "151", "SCOTT", "US"],
  ["TN", "153", "SEQUATCHIE", "US"],
  ["TN", "155", "SEVIER", "US"],
  ["TN", "157", "SHELBY", "US"],
  ["TN", "159", "SMITH", "US"],
  ["TN", "161", "STEWART", "US"],
  ["TN", "163", "SULLIVAN", "US"],
  ["TN", "165", "SUMNER", "US"],
  ["TN", "167", "TIPTON", "US"],
  ["TN", "169", "TROUSDALE", "US"],
  ["TN", "171", "UNICOI", "US"],
  ["TN", "173", "UNION", "US"],
  ["TN", "999", "UNKNOWN", "US"],
  ["TN", "175", "VAN BUREN", "US"],
  ["TN", "177", "WARREN", "US"],
  ["TN", "179", "WASHINGTON", "US"],
  ["TN", "181", "WAYNE", "US"],
  ["TN", "183", "WEAKLEY", "US"],
  ["TN", "185", "WHITE", "US"],
  ["TN", "187", "WILLIAMSON", "US"],
  ["TN", "189", "WILSON", "US"],
  ["TX", "001", "ANDERSON", "US"],
  ["TX", "003", "ANDREWS", "US"],
  ["TX", "005", "ANGELINA", "US"],
  ["TX", "007", "ARANSAS", "US"],
  ["TX", "009", "ARCHER", "US"],
  ["TX", "011", "ARMSTRONG", "US"],
  ["TX", "013", "ATASCOSA", "US"],
  ["TX", "015", "AUSTIN", "US"],
  ["TX", "017", "BAILEY", "US"],
  ["TX", "019", "BANDERA", "US"],
  ["TX", "021", "BASTROP", "US"],
  ["TX", "023", "BAYLOR", "US"],
  ["TX", "025", "BEE", "US"],
  ["TX", "027", "BELL", "US"],
  ["TX", "029", "BEXAR", "US"],
  ["TX", "031", "BLANCO", "US"],
  ["TX", "033", "BORDEN", "US"],
  ["TX", "035", "BOSQUE", "US"],
  ["TX", "037", "BOWIE", "US"],
  ["TX", "039", "BRAZORIA", "US"],
  ["TX", "041", "BRAZOS", "US"],
  ["TX", "043", "BREWSTER", "US"],
  ["TX", "045", "BRISCOE", "US"],
  ["TX", "047", "BROOKS", "US"],
  ["TX", "049", "BROWN", "US"],
  ["TX", "051", "BURLESON", "US"],
  ["TX", "053", "BURNET", "US"],
  ["TX", "055", "CALDWELL", "US"],
  ["TX", "057", "CALHOUN", "US"],
  ["TX", "059", "CALLAHAN", "US"],
  ["TX", "061", "CAMERON", "US"],
  ["TX", "063", "CAMP", "US"],
  ["TX", "065", "CARSON", "US"],
  ["TX", "067", "CASS", "US"],
  ["TX", "069", "CASTRO", "US"],
  ["TX", "071", "CHAMBERS", "US"],
  ["TX", "073", "CHEROKEE", "US"],
  ["TX", "075", "CHILDRESS", "US"],
  ["TX", "077", "CLAY", "US"],
  ["TX", "079", "COCHRAN", "US"],
  ["TX", "081", "COKE", "US"],
  ["TX", "083", "COLEMAN", "US"],
  ["TX", "085", "COLLIN", "US"],
  ["TX", "087", "COLLINGSWORTH", "US"],
  ["TX", "089", "COLORADO", "US"],
  ["TX", "091", "COMAL", "US"],
  ["TX", "093", "COMANCHE", "US"],
  ["TX", "095", "CONCHO", "US"],
  ["TX", "097", "COOKE", "US"],
  ["TX", "099", "CORYELL", "US"],
  ["TX", "101", "COTTLE", "US"],
  ["TX", "103", "CRANE", "US"],
  ["TX", "105", "CROCKETT", "US"],
  ["TX", "107", "CROSBY", "US"],
  ["TX", "109", "CULBERSON", "US"],
  ["TX", "111", "DALLAM", "US"],
  ["TX", "113", "DALLAS", "US"],
  ["TX", "115", "DAWSON", "US"],
  ["TX", "123", "DE WITT", "US"],
  ["TX", "117", "DEAF SMITH", "US"],
  ["TX", "119", "DELTA", "US"],
  ["TX", "121", "DENTON", "US"],
  ["TX", "125", "DICKENS", "US"],
  ["TX", "127", "DIMMIT", "US"],
  ["TX", "129", "DONLEY", "US"],
  ["TX", "131", "DUVAL", "US"],
  ["TX", "133", "EASTLAND", "US"],
  ["TX", "135", "ECTOR", "US"],
  ["TX", "137", "EDWARDS", "US"],
  ["TX", "141", "EL PASO", "US"],
  ["TX", "139", "ELLIS", "US"],
  ["TX", "143", "ERATH", "US"],
  ["TX", "145", "FALLS", "US"],
  ["TX", "147", "FANNIN", "US"],
  ["TX", "149", "FAYETTE", "US"],
  ["TX", "151", "FISHER", "US"],
  ["TX", "153", "FLOYD", "US"],
  ["TX", "155", "FOARD", "US"],
  ["TX", "157", "FORT BEND", "US"],
  ["TX", "159", "FRANKLIN", "US"],
  ["TX", "161", "FREESTONE", "US"],
  ["TX", "163", "FRIO", "US"],
  ["TX", "165", "GAINES", "US"],
  ["TX", "167", "GALVESTON", "US"],
  ["TX", "169", "GARZA", "US"],
  ["TX", "171", "GILLESPIE", "US"],
  ["TX", "173", "GLASSCOCK", "US"],
  ["TX", "175", "GOLIAD", "US"],
  ["TX", "177", "GONZALES", "US"],
  ["TX", "179", "GRAY", "US"],
  ["TX", "181", "GRAYSON", "US"],
  ["TX", "183", "GREGG", "US"],
  ["TX", "185", "GRIMES", "US"],
  ["TX", "187", "GUADALUPE", "US"],
  ["TX", "189", "HALE", "US"],
  ["TX", "191", "HALL", "US"],
  ["TX", "193", "HAMILTON", "US"],
  ["TX", "195", "HANSFORD", "US"],
  ["TX", "197", "HARDEMAN", "US"],
  ["TX", "199", "HARDIN", "US"],
  ["TX", "201", "HARRIS", "US"],
  ["TX", "203", "HARRISON", "US"],
  ["TX", "205", "HARTLEY", "US"],
  ["TX", "207", "HASKELL", "US"],
  ["TX", "209", "HAYS", "US"],
  ["TX", "211", "HEMPHILL", "US"],
  ["TX", "213", "HENDERSON", "US"],
  ["TX", "215", "HIDALGO", "US"],
  ["TX", "217", "HILL", "US"],
  ["TX", "219", "HOCKLEY", "US"],
  ["TX", "221", "HOOD", "US"],
  ["TX", "223", "HOPKINS", "US"],
  ["TX", "225", "HOUSTON", "US"],
  ["TX", "227", "HOWARD", "US"],
  ["TX", "229", "HUDSPETH", "US"],
  ["TX", "231", "HUNT", "US"],
  ["TX", "233", "HUTCHINSON", "US"],
  ["TX", "235", "IRION", "US"],
  ["TX", "237", "JACK", "US"],
  ["TX", "239", "JACKSON", "US"],
  ["TX", "241", "JASPER", "US"],
  ["TX", "243", "JEFF DAVIS", "US"],
  ["TX", "245", "JEFFERSON", "US"],
  ["TX", "247", "JIM HOGG", "US"],
  ["TX", "249", "JIM WELLS", "US"],
  ["TX", "251", "JOHNSON", "US"],
  ["TX", "253", "JONES", "US"],
  ["TX", "255", "KARNES", "US"],
  ["TX", "257", "KAUFMAN", "US"],
  ["TX", "259", "KENDALL", "US"],
  ["TX", "261", "KENEDY", "US"],
  ["TX", "263", "KENT", "US"],
  ["TX", "265", "KERR", "US"],
  ["TX", "267", "KIMBLE", "US"],
  ["TX", "269", "KING", "US"],
  ["TX", "271", "KINNEY", "US"],
  ["TX", "273", "KLEBERG", "US"],
  ["TX", "275", "KNOX", "US"],
  ["TX", "283", "LA SALLE", "US"],
  ["TX", "277", "LAMAR", "US"],
  ["TX", "279", "LAMB", "US"],
  ["TX", "281", "LAMPASAS", "US"],
  ["TX", "285", "LAVACA", "US"],
  ["TX", "287", "LEE", "US"],
  ["TX", "289", "LEON", "US"],
  ["TX", "291", "LIBERTY", "US"],
  ["TX", "293", "LIMESTONE", "US"],
  ["TX", "295", "LIPSCOMB", "US"],
  ["TX", "297", "LIVE OAK", "US"],
  ["TX", "299", "LLANO", "US"],
  ["TX", "301", "LOVING", "US"],
  ["TX", "303", "LUBBOCK", "US"],
  ["TX", "305", "LYNN", "US"],
  ["TX", "313", "MADISON", "US"],
  ["TX", "315", "MARION", "US"],
  ["TX", "317", "MARTIN", "US"],
  ["TX", "319", "MASON", "US"],
  ["TX", "321", "MATAGORDA", "US"],
  ["TX", "323", "MAVERICK", "US"],
  ["TX", "307", "MCCULLOCH", "US"],
  ["TX", "309", "MCLENNAN", "US"],
  ["TX", "311", "MCMULLEN", "US"],
  ["TX", "325", "MEDINA", "US"],
  ["TX", "327", "MENARD", "US"],
  ["TX", "329", "MIDLAND", "US"],
  ["TX", "331", "MILAM", "US"],
  ["TX", "333", "MILLS", "US"],
  ["TX", "335", "MITCHELL", "US"],
  ["TX", "337", "MONTAGUE", "US"],
  ["TX", "339", "MONTGOMERY", "US"],
  ["TX", "341", "MOORE", "US"],
  ["TX", "343", "MORRIS", "US"],
  ["TX", "345", "MOTLEY", "US"],
  ["TX", "347", "NACOGDOCHES", "US"],
  ["TX", "349", "NAVARRO", "US"],
  ["TX", "351", "NEWTON", "US"],
  ["TX", "353", "NOLAN", "US"],
  ["TX", "355", "NUECES", "US"],
  ["TX", "357", "OCHILTREE", "US"],
  ["TX", "359", "OLDHAM", "US"],
  ["TX", "361", "ORANGE", "US"],
  ["TX", "363", "PALO PINTO", "US"],
  ["TX", "365", "PANOLA", "US"],
  ["TX", "367", "PARKER", "US"],
  ["TX", "369", "PARMER", "US"],
  ["TX", "371", "PECOS", "US"],
  ["TX", "373", "POLK", "US"],
  ["TX", "375", "POTTER", "US"],
  ["TX", "377", "PRESIDIO", "US"],
  ["TX", "379", "RAINS", "US"],
  ["TX", "381", "RANDALL", "US"],
  ["TX", "383", "REAGAN", "US"],
  ["TX", "385", "REAL", "US"],
  ["TX", "387", "RED RIVER", "US"],
  ["TX", "389", "REEVES", "US"],
  ["TX", "391", "REFUGIO", "US"],
  ["TX", "393", "ROBERTS", "US"],
  ["TX", "395", "ROBERTSON", "US"],
  ["TX", "397", "ROCKWALL", "US"],
  ["TX", "399", "RUNNELS", "US"],
  ["TX", "401", "RUSK", "US"],
  ["TX", "403", "SABINE", "US"],
  ["TX", "405", "SAN AUGUSTINE", "US"],
  ["TX", "407", "SAN JACINTO", "US"],
  ["TX", "409", "SAN PATRICIO", "US"],
  ["TX", "411", "SAN SABA", "US"],
  ["TX", "413", "SCHLEICHER", "US"],
  ["TX", "415", "SCURRY", "US"],
  ["TX", "417", "SHACKELFORD", "US"],
  ["TX", "419", "SHELBY", "US"],
  ["TX", "421", "SHERMAN", "US"],
  ["TX", "423", "SMITH", "US"],
  ["TX", "425", "SOMERVELL", "US"],
  ["TX", "427", "STARR", "US"],
  ["TX", "429", "STEPHENS", "US"],
  ["TX", "431", "STERLING", "US"],
  ["TX", "433", "STONEWALL", "US"],
  ["TX", "435", "SUTTON", "US"],
  ["TX", "437", "SWISHER", "US"],
  ["TX", "439", "TARRANT", "US"],
  ["TX", "441", "TAYLOR", "US"],
  ["TX", "443", "TERRELL", "US"],
  ["TX", "445", "TERRY", "US"],
  ["TX", "447", "THROCKMORTON", "US"],
  ["TX", "449", "TITUS", "US"],
  ["TX", "451", "TOM GREEN", "US"],
  ["TX", "453", "TRAVIS", "US"],
  ["TX", "455", "TRINITY", "US"],
  ["TX", "457", "TYLER", "US"],
  ["TX", "999", "UNKNOWN", "US"],
  ["TX", "459", "UPSHUR", "US"],
  ["TX", "461", "UPTON", "US"],
  ["TX", "463", "UVALDE", "US"],
  ["TX", "465", "VAL VERDE", "US"],
  ["TX", "467", "VAN ZANDT", "US"],
  ["TX", "469", "VICTORIA", "US"],
  ["TX", "471", "WALKER", "US"],
  ["TX", "473", "WALLER", "US"],
  ["TX", "475", "WARD", "US"],
  ["TX", "477", "WASHINGTON", "US"],
  ["TX", "479", "WEBB", "US"],
  ["TX", "481", "WHARTON", "US"],
  ["TX", "483", "WHEELER", "US"],
  ["TX", "485", "WICHITA", "US"],
  ["TX", "487", "WILBARGER", "US"],
  ["TX", "489", "WILLACY", "US"],
  ["TX", "491", "WILLIAMSON", "US"],
  ["TX", "493", "WILSON", "US"],
  ["TX", "495", "WINKLER", "US"],
  ["TX", "497", "WISE", "US"],
  ["TX", "499", "WOOD", "US"],
  ["TX", "501", "YOAKUM", "US"],
  ["TX", "503", "YOUNG", "US"],
  ["TX", "505", "ZAPATA", "US"],
  ["TX", "507", "ZAVALA", "US"],
  ["UK", "000", "(UNKNOWN)", "O"],
  ["UK", "999", "UNKNOWN", "O"],
  ["UT", "001", "BEAVER", "US"],
  ["UT", "003", "BOX ELDER", "US"],
  ["UT", "005", "CACHE", "US"],
  ["UT", "007", "CARBON", "US"],
  ["UT", "009", "DAGGETT", "US"],
  ["UT", "011", "DAVIS", "US"],
  ["UT", "013", "DUCHESNE", "US"],
  ["UT", "015", "EMERY", "US"],
  ["UT", "017", "GARFIELD", "US"],
  ["UT", "019", "GRAND", "US"],
  ["UT", "021", "IRON", "US"],
  ["UT", "023", "JUAB", "US"],
  ["UT", "025", "KANE", "US"],
  ["UT", "027", "MILLARD", "US"],
  ["UT", "029", "MORGAN", "US"],
  ["UT", "031", "PIUTE", "US"],
  ["UT", "033", "RICH", "US"],
  ["UT", "035", "SALT LAKE", "US"],
  ["UT", "037", "SAN JUAN", "US"],
  ["UT", "039", "SANPETE", "US"],
  ["UT", "041", "SEVIER", "US"],
  ["UT", "043", "SUMMIT", "US"],
  ["UT", "045", "TOOELE", "US"],
  ["UT", "047", "UINTAH", "US"],
  ["UT", "999", "UNKNOWN", "US"],
  ["UT", "049", "UTAH", "US"],
  ["UT", "051", "WASATCH", "US"],
  ["UT", "053", "WASHINGTON", "US"],
  ["UT", "055", "WAYNE", "US"],
  ["UT", "057", "WEBER", "US"],
  ["VA", "001", "ACCOMACK", "US"],
  ["VA", "003", "ALBEMARLE", "US"],
  ["VA", "510", "ALEXANDRIA CITY", "US"],
  ["VA", "005", "ALLEGHANY", "US"],
  ["VA", "007", "AMELIA", "US"],
  ["VA", "009", "AMHERST", "US"],
  ["VA", "011", "APPOMATTOX", "US"],
  ["VA", "013", "ARLINGTON", "US"],
  ["VA", "015", "AUGUSTA", "US"],
  ["VA", "017", "BATH", "US"],
  ["VA", "019", "BEDFORD", "US"],
  ["VA", "515", "BEDFORD CITY", "US"],
  ["VA", "021", "BLAND", "US"],
  ["VA", "023", "BOTETOURT", "US"],
  ["VA", "520", "BRISTOL", "US"],
  ["VA", "025", "BRUNSWICK", "US"],
  ["VA", "027", "BUCHANAN", "US"],
  ["VA", "029", "BUCKINGHAM", "US"],
  ["VA", "530", "BUENA VISTA CITY", "US"],
  ["VA", "031", "CAMPBELL", "US"],
  ["VA", "033", "CAROLINE", "US"],
  ["VA", "035", "CARROLL", "US"],
  ["VA", "036", "CHARLES CITY", "US"],
  ["VA", "037", "CHARLOTTE", "US"],
  ["VA", "540", "CHARLOTTESVILLE CITY", "US"],
  ["VA", "550", "CHESAPEAKE CITY", "US"],
  ["VA", "041", "CHESTERFIELD", "US"],
  ["VA", "043", "CLARKE", "US"],
  ["VA", "570", "COLONIAL HEIGHTS CITY", "US"],
  ["VA", "580", "COVINGTON CITY", "US"],
  ["VA", "045", "CRAIG", "US"],
  ["VA", "047", "CULPEPER", "US"],
  ["VA", "049", "CUMBERLAND", "US"],
  ["VA", "590", "DANVILLE CITY", "US"],
  ["VA", "051", "DICKENSON", "US"],
  ["VA", "053", "DINWIDDIE", "US"],
  ["VA", "595", "EMPORIA CITY", "US"],
  ["VA", "057", "ESSEX", "US"],
  ["VA", "059", "FAIRFAX", "US"],
  ["VA", "600", "FAIRFAX CITY", "US"],
  ["VA", "610", "FALLS CHURCH CITY", "US"],
  ["VA", "061", "FAUQUIER", "US"],
  ["VA", "063", "FLOYD", "US"],
  ["VA", "065", "FLUVANNA", "US"],
  ["VA", "067", "FRANKLIN", "US"],
  ["VA", "620", "FRANKLIN CITY", "US"],
  ["VA", "069", "FREDERICK", "US"],
  ["VA", "630", "FREDERICKSBURG CITY", "US"],
  ["VA", "640", "GALAX CITY", "US"],
  ["VA", "071", "GILES", "US"],
  ["VA", "073", "GLOUCESTER", "US"],
  ["VA", "075", "GOOCHLAND", "US"],
  ["VA", "077", "GRAYSON", "US"],
  ["VA", "079", "GREENE", "US"],
  ["VA", "081", "GREENSVILLE", "US"],
  ["VA", "083", "HALIFAX", "US"],
  ["VA", "650", "HAMPTON CITY", "US"],
  ["VA", "085", "HANOVER", "US"],
  ["VA", "660", "HARRISONBURG CITY", "US"],
  ["VA", "087", "HENRICO", "US"],
  ["VA", "089", "HENRY", "US"],
  ["VA", "091", "HIGHLAND", "US"],
  ["VA", "670", "HOPEWELL CITY", "US"],
  ["VA", "500", "INDEPENDENT CITY/S", "US"],
  ["VA", "093", "ISLE OF WIGHT", "US"],
  ["VA", "095", "JAMES CITY", "US"],
  ["VA", "097", "KING AND QUEEN", "US"],
  ["VA", "099", "KING GEORGE", "US"],
  ["VA", "101", "KING WILLIAM", "US"],
  ["VA", "103", "LANCASTER", "US"],
  ["VA", "105", "LEE", "US"],
  ["VA", "678", "LEXINGTON CITY", "US"],
  ["VA", "107", "LOUDOUN", "US"],
  ["VA", "109", "LOUISA", "US"],
  ["VA", "111", "LUNENBURG", "US"],
  ["VA", "680", "LYNCHBURG CITY", "US"],
  ["VA", "113", "MADISON", "US"],
  ["VA", "683", "MANASSAS", "US"],
  ["VA", "685", "MANASSAS PARK CITY", "US"],
  ["VA", "690", "MARTINSVILLE CITY", "US"],
  ["VA", "115", "MATHEWS", "US"],
  ["VA", "117", "MECKLENBURG", "US"],
  ["VA", "119", "MIDDLESEX", "US"],
  ["VA", "121", "MONTGOMERY", "US"],
  ["VA", "695", "NANSEMOND", "US"],
  ["VA", "125", "NELSON", "US"],
  ["VA", "127", "NEW KENT", "US"],
  ["VA", "700", "NEWPORT NEWS CITY", "US"],
  ["VA", "710", "NORFOLK CITY", "US"],
  ["VA", "131", "NORTHAMPTON", "US"],
  ["VA", "133", "NORTHUMBERLAND", "US"],
  ["VA", "720", "NORTON CITY", "US"],
  ["VA", "135", "NOTTOWAY", "US"],
  ["VA", "137", "ORANGE", "US"],
  ["VA", "139", "PAGE", "US"],
  ["VA", "141", "PATRICK", "US"],
  ["VA", "730", "PETERSBURG CITY", "US"],
  ["VA", "143", "PITTSYLVANIA", "US"],
  ["VA", "735", "POQUOSON CITY", "US"],
  ["VA", "740", "PORTSMOUTH CITY", "US"],
  ["VA", "145", "POWHATAN", "US"],
  ["VA", "147", "PRINCE EDWARD", "US"],
  ["VA", "149", "PRINCE GEORGE", "US"],
  ["VA", "153", "PRINCE WILLIAM", "US"],
  ["VA", "155", "PULASKI", "US"],
  ["VA", "750", "RADFORD", "US"],
  ["VA", "157", "RAPPAHANNOCK", "US"],
  ["VA", "159", "RICHMOND", "US"],
  ["VA", "760", "RICHMOND CITY", "US"],
  ["VA", "161", "ROANOKE", "US"],
  ["VA", "770", "ROANOKE CITY", "US"],
  ["VA", "163", "ROCKBRIDGE", "US"],
  ["VA", "165", "ROCKINGHAM", "US"],
  ["VA", "167", "RUSSELL", "US"],
  ["VA", "775", "SALEM", "US"],
  ["VA", "169", "SCOTT", "US"],
  ["VA", "171", "SHENANDOAH", "US"],
  ["VA", "173", "SMYTH", "US"],
  ["VA", "175", "SOUTHAMPTON", "US"],
  ["VA", "177", "SPOTSYLVANIA", "US"],
  ["VA", "179", "STAFFORD", "US"],
  ["VA", "790", "STAUNTON CITY", "US"],
  ["VA", "800", "SUFFOLK CITY", "US"],
  ["VA", "181", "SURRY", "US"],
  ["VA", "183", "SUSSEX", "US"],
  ["VA", "185", "TAZEWELL", "US"],
  ["VA", "999", "UNKNOWN", "US"],
  ["VA", "810", "VIRGINIA BEACH CITY", "US"],
  ["VA", "187", "WARREN", "US"],
  ["VA", "191", "WASHINGTON", "US"],
  ["VA", "820", "WAYNESBORO CITY", "US"],
  ["VA", "193", "WESTMORELAND", "US"],
  ["VA", "830", "WILLIAMSBURG CITY", "US"],
  ["VA", "840", "WINCHESTER CITY", "US"],
  ["VA", "195", "WISE", "US"],
  ["VA", "197", "WYTHE", "US"],
  ["VA", "199", "YORK", "US"],
  ["VC", "000", "(VERACRUZ)", "MX"],
  ["VC", "999", "UNKNOWN", "MX"],
  ["VI", "000", "(VIRGIN ISLANDS)", "US"],
  ["VI", "010", "SAINT CROIX", "US"],
  ["VI", "020", "SAINT JOHN", "US"],
  ["VI", "030", "SAINT THOMAS", "US"],
  ["VI", "999", "UNKNOWN", "US"],
  ["VT", "001", "ADDISON", "US"],
  ["VT", "003", "BENNINGTON", "US"],
  ["VT", "005", "CALEDONIA", "US"],
  ["VT", "007", "CHITTENDEN", "US"],
  ["VT", "009", "ESSEX", "US"],
  ["VT", "011", "FRANKLIN", "US"],
  ["VT", "013", "GRAND ISLE", "US"],
  ["VT", "015", "LAMOILLE", "US"],
  ["VT", "017", "ORANGE", "US"],
  ["VT", "019", "ORLEANS", "US"],
  ["VT", "021", "RUTLAND", "US"],
  ["VT", "999", "UNKNOWN", "US"],
  ["VT", "023", "WASHINGTON", "US"],
  ["VT", "025", "WINDHAM", "US"],
  ["VT", "027", "WINDSOR", "US"],
  ["WA", "001", "ADAMS", "US"],
  ["WA", "003", "ASOTIN", "US"],
  ["WA", "005", "BENTON", "US"],
  ["WA", "007", "CHELAN", "US"],
  ["WA", "009", "CLALLAM", "US"],
  ["WA", "011", "CLARK", "US"],
  ["WA", "013", "COLUMBIA", "US"],
  ["WA", "015", "COWLITZ", "US"],
  ["WA", "017", "DOUGLAS", "US"],
  ["WA", "019", "FERRY", "US"],
  ["WA", "021", "FRANKLIN", "US"],
  ["WA", "023", "GARFIELD", "US"],
  ["WA", "025", "GRANT", "US"],
  ["WA", "027", "GRAYS HARBOR", "US"],
  ["WA", "029", "ISLAND", "US"],
  ["WA", "031", "JEFFERSON", "US"],
  ["WA", "033", "KING", "US"],
  ["WA", "035", "KITSAP", "US"],
  ["WA", "037", "KITTITAS", "US"],
  ["WA", "039", "KLICKITAT", "US"],
  ["WA", "041", "LEWIS", "US"],
  ["WA", "043", "LINCOLN", "US"],
  ["WA", "045", "MASON", "US"],
  ["WA", "047", "OKANOGAN", "US"],
  ["WA", "049", "PACIFIC", "US"],
  ["WA", "051", "PEND OREILLE", "US"],
  ["WA", "053", "PIERCE", "US"],
  ["WA", "055", "SAN JUAN", "US"],
  ["WA", "057", "SKAGIT", "US"],
  ["WA", "059", "SKAMANIA", "US"],
  ["WA", "061", "SNOHOMISH", "US"],
  ["WA", "063", "SPOKANE", "US"],
  ["WA", "065", "STEVENS", "US"],
  ["WA", "067", "THURSTON", "US"],
  ["WA", "999", "UNKNOWN", "US"],
  ["WA", "069", "WAHKIAKUM", "US"],
  ["WA", "071", "WALLA WALLA", "US"],
  ["WA", "073", "WHATCOM", "US"],
  ["WA", "075", "WHITMAN", "US"],
  ["WA", "077", "YAKIMA", "US"],
  ["WI", "001", "ADAMS", "US"],
  ["WI", "003", "ASHLAND", "US"],
  ["WI", "005", "BARRON", "US"],
  ["WI", "007", "BAYFIELD", "US"],
  ["WI", "009", "BROWN", "US"],
  ["WI", "011", "BUFFALO", "US"],
  ["WI", "013", "BURNETT", "US"],
  ["WI", "015", "CALUMET", "US"],
  ["WI", "017", "CHIPPEWA", "US"],
  ["WI", "019", "CLARK", "US"],
  ["WI", "021", "COLUMBIA", "US"],
  ["WI", "023", "CRAWFORD", "US"],
  ["WI", "025", "DANE", "US"],
  ["WI", "027", "DODGE", "US"],
  ["WI", "029", "DOOR", "US"],
  ["WI", "031", "DOUGLAS", "US"],
  ["WI", "033", "DUNN", "US"],
  ["WI", "035", "EAU CLAIRE", "US"],
  ["WI", "037", "FLORENCE", "US"],
  ["WI", "039", "FOND DU LAC", "US"],
  ["WI", "041", "FOREST", "US"],
  ["WI", "043", "GRANT", "US"],
  ["WI", "045", "GREEN", "US"],
  ["WI", "047", "GREEN LAKE", "US"],
  ["WI", "049", "IOWA", "US"],
  ["WI", "051", "IRON", "US"],
  ["WI", "053", "JACKSON", "US"],
  ["WI", "055", "JEFFERSON", "US"],
  ["WI", "057", "JUNEAU", "US"],
  ["WI", "059", "KENOSHA", "US"],
  ["WI", "061", "KEWAUNEE", "US"],
  ["WI", "063", "LA CROSSE", "US"],
  ["WI", "065", "LAFAYETTE", "US"],
  ["WI", "067", "LANGLADE", "US"],
  ["WI", "069", "LINCOLN", "US"],
  ["WI", "071", "MANITOWOC", "US"],
  ["WI", "073", "MARATHON", "US"],
  ["WI", "075", "MARINETTE", "US"],
  ["WI", "077", "MARQUETTE", "US"],
  ["WI", "078", "MENOMINEE", "US"],
  ["WI", "079", "MILWAUKEE", "US"],
  ["WI", "081", "MONROE", "US"],
  ["WI", "083", "OCONTO", "US"],
  ["WI", "085", "ONEIDA", "US"],
  ["WI", "087", "OUTAGAMIE", "US"],
  ["WI", "089", "OZAUKEE", "US"],
  ["WI", "091", "PEPIN", "US"],
  ["WI", "093", "PIERCE", "US"],
  ["WI", "095", "POLK", "US"],
  ["WI", "097", "PORTAGE", "US"],
  ["WI", "099", "PRICE", "US"],
  ["WI", "101", "RACINE", "US"],
  ["WI", "103", "RICHLAND", "US"],
  ["WI", "105", "ROCK", "US"],
  ["WI", "107", "RUSK", "US"],
  ["WI", "109", "SAINT CROIX", "US"],
  ["WI", "111", "SAUK", "US"],
  ["WI", "113", "SAWYER", "US"],
  ["WI", "115", "SHAWANO", "US"],
  ["WI", "117", "SHEBOYGAN", "US"],
  ["WI", "119", "TAYLOR", "US"],
  ["WI", "121", "TREMPEALEAU", "US"],
  ["WI", "999", "UNKNOWN", "US"],
  ["WI", "123", "VERNON", "US"],
  ["WI", "125", "VILAS", "US"],
  ["WI", "127", "WALWORTH", "US"],
  ["WI", "129", "WASHBURN", "US"],
  ["WI", "131", "WASHINGTON", "US"],
  ["WI", "133", "WAUKESHA", "US"],
  ["WI", "135", "WAUPACA", "US"],
  ["WI", "137", "WAUSHARA", "US"],
  ["WI", "139", "WINNEBAGO", "US"],
  ["WI", "141", "WOOD", "US"],
  ["WV", "001", "BARBOUR", "US"],
  ["WV", "003", "BERKELEY", "US"],
  ["WV", "005", "BOONE", "US"],
  ["WV", "007", "BRAXTON", "US"],
  ["WV", "009", "BROOKE", "US"],
  ["WV", "011", "CABELL", "US"],
  ["WV", "013", "CALHOUN", "US"],
  ["WV", "015", "CLAY", "US"],
  ["WV", "017", "DODDRIDGE", "US"],
  ["WV", "019", "FAYETTE", "US"],
  ["WV", "021", "GILMER", "US"],
  ["WV", "023", "GRANT", "US"],
  ["WV", "025", "GREENBRIER", "US"],
  ["WV", "027", "HAMPSHIRE", "US"],
  ["WV", "029", "HANCOCK", "US"],
  ["WV", "031", "HARDY", "US"],
  ["WV", "033", "HARRISON", "US"],
  ["WV", "035", "JACKSON", "US"],
  ["WV", "037", "JEFFERSON", "US"],
  ["WV", "039", "KANAWHA", "US"],
  ["WV", "041", "LEWIS", "US"],
  ["WV", "043", "LINCOLN", "US"],
  ["WV", "045", "LOGAN", "US"],
  ["WV", "049", "MARION", "US"],
  ["WV", "051", "MARSHALL", "US"],
  ["WV", "053", "MASON", "US"],
  ["WV", "047", "MCDOWELL", "US"],
  ["WV", "055", "MERCER", "US"],
  ["WV", "057", "MINERAL", "US"],
  ["WV", "059", "MINGO", "US"],
  ["WV", "061", "MONONGALIA", "US"],
  ["WV", "063", "MONROE", "US"],
  ["WV", "065", "MORGAN", "US"],
  ["WV", "067", "NICHOLAS", "US"],
  ["WV", "069", "OHIO", "US"],
  ["WV", "071", "PENDLETON", "US"],
  ["WV", "073", "PLEASANTS", "US"],
  ["WV", "075", "POCAHONTAS", "US"],
  ["WV", "077", "PRESTON", "US"],
  ["WV", "079", "PUTNAM", "US"],
  ["WV", "081", "RALEIGH", "US"],
  ["WV", "083", "RANDOLPH", "US"],
  ["WV", "085", "RITCHIE", "US"],
  ["WV", "087", "ROANE", "US"],
  ["WV", "089", "SUMMERS", "US"],
  ["WV", "091", "TAYLOR", "US"],
  ["WV", "093", "TUCKER", "US"],
  ["WV", "095", "TYLER", "US"],
  ["WV", "999", "UNKNOWN", "US"],
  ["WV", "097", "UPSHUR", "US"],
  ["WV", "099", "WAYNE", "US"],
  ["WV", "101", "WEBSTER", "US"],
  ["WV", "103", "WETZEL", "US"],
  ["WV", "105", "WIRT", "US"],
  ["WV", "107", "WOOD", "US"],
  ["WV", "109", "WYOMING", "US"],
  ["WY", "001", "ALBANY", "US"],
  ["WY", "003", "BIG HORN", "US"],
  ["WY", "005", "CAMPBELL", "US"],
  ["WY", "007", "CARBON", "US"],
  ["WY", "009", "CONVERSE", "US"],
  ["WY", "011", "CROOK", "US"],
  ["WY", "013", "FREMONT", "US"],
  ["WY", "015", "GOSHEN", "US"],
  ["WY", "017", "HOT SPRINGS", "US"],
  ["WY", "019", "JOHNSON", "US"],
  ["WY", "021", "LARAMIE", "US"],
  ["WY", "023", "LINCOLN", "US"],
  ["WY", "025", "NATRONA", "US"],
  ["WY", "027", "NIOBRARA", "US"],
  ["WY", "029", "PARK", "US"],
  ["WY", "031", "PLATTE", "US"],
  ["WY", "033", "SHERIDAN", "US"],
  ["WY", "035", "SUBLETTE", "US"],
  ["WY", "037", "SWEETWATER", "US"],
  ["WY", "039", "TETON", "US"],
  ["WY", "041", "UINTA", "US"],
  ["WY", "999", "UNKNOWN", "US"],
  ["WY", "043", "WASHAKIE", "US"],
  ["WY", "045", "WESTON", "US"],
  ["YT", "000", "(YUKON TERRITORY)", "CA"],
  ["YT", "999", "UNKNOWN", "CA"],
  ["YU", "000", "(YUCATAN)", "MX"],
  ["YU", "999", "UNKNOWN", "MX"],
  ["ZA", "000", "(ZACATECAS)", "MX"],
  ["ZA", "999", "UNKNOWN", "MX"],
];

export const getCounties = (longCountryCode: string, state: string): County[] =>
  counties
    .filter((x) => x[0] === state && x[3] === mapLongCountryToShort(longCountryCode))
    .map((x) => ({ name: x[2], fipsCode: x[1] }));
