"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const CoreService_1 = require("../CoreService");
const DateUtil_1 = require("../../DateUtil");
const datefns = __importStar(require("date-fns"));
const DateFormat_1 = require("../../DateFormat");
exports.default = {
    inspectionSummaryReport: (request) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.post("inspection-report/summary", undefined, Object.assign(Object.assign({}, request), { start: DateUtil_1.toDateString(request.start), end: DateUtil_1.toDateString(request.end) }));
        if (response === "notFound") {
            throw Error("Error executing report: not found");
        }
        response.request = {
            start: request.start,
            end: request.end,
        };
        return response;
    }),
    versionNumberReport: () => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield CoreService_1.CoreService.get("version-report", undefined, {});
        if (response === "notFound") {
            throw Error("Error executing report: not found");
        }
        return {
            request: {},
            result: {
                rows: response.logins.map(x => (Object.assign(Object.assign({}, x), { inspectorName: x.inspectorName, inspectorCode: x.inspectorCode, email: x.email, appName: x.appName, appVersion: x.appVersion, deviceName: x.deviceName, authTime: DateFormat_1.formatDateTime(datefns.parse(x.authTime)) }))),
            },
        };
    }),
};
