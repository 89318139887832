"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.t0028ToSaferVehicle = exports.localVehicleToSaferVehicle = exports.t0031ToSaferCarrier = exports.guardIccNumber = void 0;
const T0031V4_1 = require("../../services/api/T0031V4");
const Levenshtein_1 = require("../Levenshtein");
exports.guardIccNumber = (iccNum) => {
    if (iccNum === undefined)
        return undefined;
    if (iccNum.length > 6)
        return undefined;
    return iccNum;
};
exports.t0031ToSaferCarrier = (t0031) => ({
    USDOTNum: t0031.CARRIER_ID_NUMBER,
    CarrierName: t0031.CARRIER_NAME,
    CarrierEmail: t0031.EMAIL_ADDRESS,
    CarrierStateCensusNum: t0031.STATE_CARRIER_ID || "",
    CarrierAddress: {
        AddressStreetText: t0031.STREET,
        // AddressColoniaText: ???;
        AddressCityName: t0031.CITY,
        AddressStateCode: t0031.STATE,
        AddressZipCode: t0031.ZIP_CODE,
        AddressCountryCode: T0031V4_1.lookupCountry(t0031.COUNTRY).shortName,
    },
    CarrierPhoneNum: t0031.TELEPHONE_NUMBER,
    CarrierFaxNum: t0031.FAX_NUMBER,
    CarrierICCNum: exports.guardIccNumber(t0031.ICC_NUMBER_1),
    CarrierInterstateFlag: (t0031.CARRIER_INTERSTATE === "1" && "Y") || "N",
    PAUndeliverableCode: t0031.PA_UNDELIVERABLE_CODE,
    MAUndeliverableCode: t0031.MA_UNDELIVERABLE_CODE,
});
const mapToUnitTypeCode = (typeCode) => {
    if (!typeCode)
        return "TR";
    if ([
        "BU",
        "CL",
        "DC",
        "FT",
        "IC",
        "LM",
        "MC",
        "OT",
        "PT",
        "SB",
        "ST",
        "TR",
        "TT",
        "VN",
    ].indexOf(typeCode) >= 0)
        return typeCode;
    return "TR";
};
const mapToMakeName = (makeCode) => {
    // MIGRATE_TODO
    //const makes = VehicleMakeService.GetVehicleMakes({ makeCode });
    const makes = [];
    if (makes.length === 0)
        return makeCode;
    // Make our best guess at which name belongs to which code. NCIC database has duplicates per code,
    // for instance "FORD" for both "FORD" and "COURIER"
    return makes.sort((a, b) => {
        const aLev = Levenshtein_1.levenshtein(makeCode, a.name.toUpperCase());
        const bLev = Levenshtein_1.levenshtein(makeCode, b.name.toUpperCase());
        return aLev < bLev ? -1 : 1;
    })[0].name;
};
exports.localVehicleToSaferVehicle = (
// MIGRATE_TODO (copied from LocalVehicle)
vehicle, vehicleIndex) => {
    const makeCode = (vehicle.make || "").toUpperCase();
    const makeName = mapToMakeName(makeCode);
    return {
        VehicleID: vehicle.vin,
        VehicleUnitNum: (vehicleIndex + 1).toString(),
        VehicleUnitTypeCode: mapToUnitTypeCode(vehicle.type),
        VehicleMakeCode: makeCode,
        VehicleMakeName: makeName,
        VehicleYear: vehicle.year ? vehicle.year.toString() : "",
        VehicleLicenseID: vehicle.plate,
        VehicleLicenseStateCode: vehicle.state,
        VehicleGVWRNum: vehicle.gvw ? vehicle.gvw.toString() : "",
    };
};
exports.t0028ToSaferVehicle = (t0028, vehicleIndex) => {
    const makeCode = (t0028.MAKE || "").toUpperCase();
    const makeName = mapToMakeName(makeCode);
    return {
        VehicleID: t0028.VIN,
        VehicleUnitNum: (vehicleIndex + 1).toString(),
        VehicleUnitTypeCode: mapToUnitTypeCode(t0028.TYPE),
        VehicleMakeCode: makeCode,
        VehicleMakeName: makeName,
        VehicleYear: t0028.MODEL_YEAR,
        VehicleLicenseID: t0028.LICENSE_PLATE_NUMBER,
        VehicleLicenseStateCode: t0028.IRP_BASE_STATE,
        VehicleGVWRNum: t0028.GVW,
    };
};
