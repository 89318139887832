"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_1 = require("react");
const Labeled_1 = require("./Labeled");
const core_1 = require("@blueprintjs/core");
const classNames = require("classnames");
const latinAsciiMap = {
    Á: "A",
    á: "a",
    É: "E",
    é: "e",
    Í: "I",
    í: "i",
    Ñ: "N",
    ñ: "n",
    Ó: "O",
    ó: "o",
    Ú: "U",
    ú: "u",
};
const substituteAscii = (input) => input
    .split("")
    .map((char) => latinAsciiMap[char] || char)
    .join("");
class Content extends React.PureComponent {
    constructor(props) {
        super(props);
        this.input = react_1.createRef();
        this.onChange = (e) => {
            if (this.props.onChange) {
                let value = e.currentTarget.value;
                // Reject if only numeric input allowed and non-numeric input received
                if (value.length > 0 &&
                    this.props.restrict === "number" &&
                    !/^[0-9\b]+$/.test(value))
                    return;
                // Replace "á" with "a" etc
                if (this.props.allowAsciiSubstitution)
                    value = substituteAscii(value);
                // Reject if only ASCII input allowed and non-ASCII input received
                if (value.length > 0 &&
                    this.props.restrict === "ascii" &&
                    !/^[ -~]+$/.test(value))
                    return;
                if (this.props.uppercase)
                    value = value.toUpperCase();
                this.selectionStart = e.target.selectionStart;
                this.props.onChange(value);
            }
        };
        this.selectionStart = null;
    }
    render() {
        const { maxLength, disabled, hint, style, value } = this.props;
        return (React.createElement("input", Object.assign({ ref: this.input, spellCheck: false, className: classNames(core_1.Classes.INPUT, core_1.Classes.FILL), value: value, maxLength: maxLength, onChange: this.onChange, disabled: disabled, type: "text", placeholder: hint, style: style, dir: "auto", autoComplete: "off" }, this.props.inputProps)));
    }
    componentDidUpdate() {
        if (this.input.current) {
            this.input.current.selectionStart = this.input.current.selectionEnd = this.selectionStart;
        }
    }
}
let TextField = /** @class */ (() => {
    class TextField extends React.PureComponent {
        render() {
            const { label } = this.props;
            const content = React.createElement(Content, Object.assign({}, this.props));
            return ((label && (React.createElement(Labeled_1.Labeled, { label: label, style: this.props.labelStyle }, content))) ||
                content);
        }
    }
    TextField.defaultProps = {
        uppercase: true,
        disabled: false,
    };
    return TextField;
})();
exports.default = TextField;
