"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSettingsObject = exports.emptyUserSettings = exports.lastOfflineNumber = exports.lastOnlineNumber = exports.poolReportNumberEnd = exports.poolReportNumberBegin = exports.reportNumberUniqueCode = exports.reportNumberStateCode = void 0;
const CoreService_1 = require("./CoreService");
const NotFound_1 = require("./NotFound");
exports.reportNumberStateCode = "rptnum.stateCode";
exports.reportNumberUniqueCode = "rptnum.uniqueCode";
exports.poolReportNumberBegin = "rptnum.pool.beginNumber";
exports.poolReportNumberEnd = "rptnum.pool.endNumber";
exports.lastOnlineNumber = "rptnum.pool.lastOnlineNumber";
exports.lastOfflineNumber = "rptnum.pool.lastOfflineNumber";
exports.emptyUserSettings = {
    [exports.reportNumberStateCode]: "",
    [exports.reportNumberUniqueCode]: "",
    [exports.poolReportNumberBegin]: "",
    [exports.poolReportNumberEnd]: "",
    [exports.lastOnlineNumber]: "",
    [exports.lastOfflineNumber]: "",
    inspectorName: "",
    inspectorCode: "",
    timezone: "ET",
    canPerformLevel6: false,
};
exports.parseSettingsObject = (obj) => {
    const newObj = Object.assign({}, obj);
    if (obj.canPerformLevel6 === "true" || obj.canPerformLevel6 === true)
        newObj.canPerformLevel6 = true;
    else
        newObj.canPerformLevel6 = false;
    return newObj;
};
const UserSettingsService = {
    saveSettings: (settings) => __awaiter(void 0, void 0, void 0, function* () {
        yield CoreService_1.CoreService.put("user/settings", undefined, settings);
    }),
    getSettings: () => __awaiter(void 0, void 0, void 0, function* () {
        const settingsResponse = yield CoreService_1.CoreService.get("user/settings");
        if (settingsResponse === NotFound_1.NotFound)
            return NotFound_1.NotFound;
        return (exports.parseSettingsObject(Object.assign({}, exports.emptyUserSettings, settingsResponse.settings)));
    }),
};
exports.default = UserSettingsService;
