"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapInspection = void 0;
const mapInspection = (insp) => ({
    id: insp.inspectionId,
    status: insp.status,
    carrierName: insp.carrierName || "n/a",
    driverName: (insp.driverFirstName || "") + " " + (insp.driverLastName || ""),
    oos: insp.isOos,
    reportNumber: insp.reportNumber,
    startTimeString: insp.startTime,
    startTime: new Date(insp.startTime),
    localTime: insp.localTime,
    version: insp.version,
});
exports.mapInspection = mapInspection;
