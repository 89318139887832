"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lookupCountry = void 0;
exports.lookupCountry = (country) => {
    const lookup = {
        A: { shortName: "US", longName: "United States" },
        C: { shortName: "CA", longName: "Canada" },
        M: { shortName: "MX", longName: "Mexico" },
        P: { shortName: "PR", longName: "Puerto Rico" },
        // What's the deal with "S"? Example cities: San Salvador, CA; Belmopan, Belize
        S: { shortName: "US", longName: "United States" },
    };
    // TODO: Ok to default to US?
    return lookup[country] || lookup["A"];
};
