"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deserializeInspectionJson = exports.withDefaults = exports.withSerializationFixes = exports.serializeInspection = exports.inspectionReplacer = void 0;
const moment = require("moment");
const DateTime_1 = require("../../types/DateTime");
exports.inspectionReplacer = (key, value) => {
    // Save dates without timestamp
    const dateKeys = ["DriverBirthDate"];
    if (dateKeys.indexOf(key) >= 0 && value instanceof Date) {
        return moment(value).format("YYYY-MM-DD");
    }
    // Save inspection start/end times in local time (no tz)
    const datetimeKeys = ["creationTime", "InspStartTime", "InspEndTime"];
    if (datetimeKeys.indexOf(key) >= 0 && value instanceof DateTime_1.DateTime) {
        return moment(value.asDate()).format("YYYY-MM-DDTHH:mm:ss");
    }
    // Default date case, store local time (no tz)
    if (value instanceof Date) {
        return moment(value).format("YYYY-MM-DDTHH:mm:ss");
    }
    return value;
};
exports.serializeInspection = (inspection) => {
    // Idea from https://stackoverflow.com/a/21035361/344448
    // JSON.stringify calls toJSON _before_ calling replacer function!
    // So we copy the function from Date, delete it, serialize, and replace
    const storeToJSON = Date.prototype.toJSON;
    // MIGRATE_TODO
    //   delete Date.prototype.toJSON;
    // MIGRATE_TODO
    Date.prototype.toJSON = (key) => "";
    const json = JSON.stringify(inspection, exports.inspectionReplacer);
    Date.prototype.toJSON = storeToJSON;
    return json;
};
// Deserialization
exports.withSerializationFixes = (inspection) => {
    const i = Object.assign({}, inspection);
    for (const violation of i.Violations) {
        if (violation.date)
            violation.date = moment(violation.date).toDate();
    }
    // Make sure unit numbers are strings
    for (const vehicle of i.Vehicles)
        vehicle.VehicleUnitNum = vehicle.VehicleUnitNum.toString();
    // TODO: shouldn't have to do default unit code here but we can get undefined
    for (const violation of i.Violations) {
        violation.VehicleUnitCode = (violation.VehicleUnitCode || "").toString();
        violation.StateCitation =
            violation.StateCitation || { CitationIssued: "N" };
    }
    // Custom CVO fields added later. Make sure default strings exist
    if (i.HazMats) {
        for (const hm of i.HazMats) {
            hm.unNaNumber = hm.unNaNumber || "";
            hm.properShippingName = hm.properShippingName || "";
        }
    }
    return i;
};
const inspectionReviver = (key, value) => {
    const datetimeKeys = ["creationTime", "InspStartTime", "InspEndTime"];
    if (datetimeKeys.indexOf(key) >= 0 && /^\d\d\d\d-\d\d-\d\d/.test(value))
        return new DateTime_1.DateTime(moment(value).toDate());
    // We use key names instead of detecting based on value to guard against a date string
    // in a string property.
    const timestampKeys = [
        "DriverBirthDate",
        "UpdateTime",
        "AspenUploadTime",
        "IEPTimestampZ",
    ];
    if (timestampKeys.indexOf(key) >= 0 && /^\d\d\d\d-\d\d-\d\d/.test(value))
        return moment(value).toDate();
    if (key === "t0034" && value instanceof Array) {
        // used to store UCRs as array before UCR response included "liveResponse"
        return {
            liveResponse: false,
            registrations: value,
        };
    }
    return value;
};
exports.withDefaults = (inspection) => {
    const i = Object.assign({}, inspection);
    // This is a guess, but if an inspection was created before implementing report number source,
    // it would be rare that report number was given in offline mode
    if (i.reportNumberFromBack === undefined)
        i.reportNumberFromBack = false;
    if (!i.InspMain.TripOrigin)
        i.InspMain.TripOrigin = { Country: "USA" };
    if (!i.InspMain.TripDestination)
        i.InspMain.TripDestination = { Country: "USA" };
    if (!i.Drivers)
        i.Drivers = [];
    if (!i.Shippers)
        i.Shippers = [];
    if (!i.Brakes)
        i.Brakes = [];
    if (!i.PBBTMeasurements)
        i.PBBTMeasurements = [];
    if (!i.Violations)
        i.Violations = [];
    if (!i.Studies)
        i.Studies = [];
    if (!i.LocalFields)
        i.LocalFields = [];
    if (i.Violations)
        for (const violation of i.Violations) {
            violation.VioOOSLevel6Flag = violation.VioOOSLevel6Flag || "N";
            violation.VehicleDefectActionCode =
                violation.VehicleDefectActionCode || "N";
        }
    if (!i.coInspectors)
        i.coInspectors = [];
    if (!i.attachments)
        i.attachments = [];
    // Wipe out old inspection status, which is now stored in another column
    i.status = undefined;
    return i;
};
exports.deserializeInspectionJson = (json) => {
    return exports.withSerializationFixes(exports.withDefaults(JSON.parse(json, inspectionReviver)));
};
