"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateViolations = exports.getViolationPrompt = exports.validateTimes = exports.validateDrivers = exports.validateVehicles = exports.validateVehicle = exports.validateHazmat = exports.validateCarrier = exports.validateLocation = exports.validateBrakes = exports.validateTrip = exports.validateStart = void 0;
const VinValidator_1 = require("../VinValidator");
const moment = require("moment");
const _ = __importStar(require("lodash"));
const Iep_1 = require("../vehicle/Iep");
exports.validateStart = (inspection) => {
    const errors = [];
    if (!inspection.InspMain.InspEScreenFlag)
        errors.push({
            elementPaths: ["InspMain.InspEScreenFlag"],
            message: 'eScreen special check must be either "Yes" or "No"',
        });
    // MIGRATE_TODO
    // if (cviewState === "KY" && !inspection.InspMain.InspDueToBasic)
    //   errors.push({
    //     elementPaths: ["InspMain.InspDueToBasic"],
    //     message: 'BASIC special check must be either "Yes" or "No"',
    //   });
    if (!inspection.InspMain.InspTypeLevelCode)
        errors.push({
            elementPaths: ["InspMain.InspTypeLevelCode"],
            message: "Missing inspection level",
        });
    return errors;
};
exports.validateTrip = (inspection) => {
    const errors = [];
    if (inspection.InspMain.InspTypeLevelCode !== "5") {
        const { TripOrigin, TripDestination, CargoTypeText } = inspection.InspMain;
        if ((TripOrigin.City || "").length < 2) {
            errors.push({
                elementPaths: ["InspMain.TripOrigin.City"],
                message: "Trip origin must be at least 2 characters",
            });
        }
        if ((TripDestination.City || "").length < 2) {
            errors.push({
                elementPaths: ["InspMain.TripDestination.City"],
                message: "Trip destination must be at least 2 characters",
            });
        }
        if (CargoTypeText === undefined || CargoTypeText.length === 0) {
            errors.push({
                elementPaths: ["InspMain.CargoTypeText"],
                message: "Cargo type not entered",
            });
        }
    }
    return errors;
};
exports.validateBrakes = (inspection) => {
    const errors = [];
    if (inspection.InspMain.InspTypeLevelCode === "1" &&
        !inspection.InspMain.VehicleAxlesNum &&
        !inspection.InspMain.PBBTAxles) {
        errors.push({
            elementPaths: [
                "InspMain.VehicleAxlesNum",
                "InspMain.PBBTAxles",
                "Brakes",
            ],
            message: "Level I requires either brake or PBBT brake measurements",
        });
    }
    const hasBrakeMeasurements = (inspection.InspMain.VehicleAxlesNum || 0) > 0 ||
        inspection.Brakes.length > 0;
    const hasPbbtMeasurements = (inspection.InspMain.PBBTAxles || 0) > 0 ||
        inspection.PBBTMeasurements.length > 0;
    // Disallow brakes on level 3
    if (inspection.InspMain.InspTypeLevelCode === "3" &&
        (hasBrakeMeasurements || hasPbbtMeasurements)) {
        errors.push({
            elementPaths: [
                "InspMain.VehicleAxlesNum",
                "InspMain.PBBTAxles",
                "Brakes",
            ],
            message: "Level III inspections may not have brake measurements",
        });
    }
    // Brake validation
    const validBrake = (brake) => brake.VehicleAxleNum &&
        brake.BrakeChamberSubtypeCode &&
        brake.LeftBrakeMeasure &&
        brake.RightBrakeMeasure;
    const numValidBrakeMeasurements = inspection.Brakes.filter((x) => validBrake(x)).length;
    if ((inspection.InspMain.VehicleAxlesNum || 0) !== numValidBrakeMeasurements) {
        errors.push({
            elementPaths: ["InspMain.VehicleAxlesNum", "Brakes"],
            message: `Axle count ${inspection.InspMain.VehicleAxlesNum || 0} doesn't match brake measurement count ${numValidBrakeMeasurements}`,
        });
    }
    // PBBT brake validation
    const validPbbtMeasurement = (measurement) => measurement.PBBTLeftBrakeMeasure &&
        measurement.PBBTVehicleAxleNum &&
        measurement.PBBTRightBrakeMeasure;
    const numValidPbbtMeasurements = inspection.PBBTMeasurements.filter((x) => validPbbtMeasurement(x)).length;
    if (inspection.InspMain.InspPBBTCheckFlag === "Y" &&
        numValidPbbtMeasurements !== inspection.InspMain.VehicleAxlesNum)
        errors.push({
            elementPaths: ["PBBTMeasurements", "InspMain.VehicleAxlesNum"],
            message: `Axle count ${inspection.InspMain.VehicleAxlesNum || 0} doesn't match PBBT measurement count ${numValidPbbtMeasurements}`,
        });
    return errors;
};
exports.validateLocation = (inspection) => {
    const errors = [];
    const { InspLocation } = inspection.InspMain;
    let { InspLocationText, HighwayDescText, InspFacilityTypeCode, FIPSCountyCode, CountryCode, } = InspLocation;
    // NOTE: didn't default in destructure due to null seemingly not being defaulted
    InspLocationText = (InspLocationText || "").trim();
    HighwayDescText = (HighwayDescText || "").trim();
    FIPSCountyCode = (FIPSCountyCode || "").trim();
    CountryCode = CountryCode || "";
    if (InspLocationText.length === 0)
        errors.push({
            elementPaths: ["InspMain.InspLocation.InspLocationText"],
            message: "Missing required location description",
        });
    if (HighwayDescText.length > 25)
        errors.push({
            elementPaths: ["InspMain.InspLocation.HighwayDescText"],
            message: "Road name max length 25",
        });
    if (HighwayDescText.length === 0)
        errors.push({
            elementPaths: ["InspMain.InspLocation.HighwayDescText"],
            message: "Missing location info",
        });
    if (!InspFacilityTypeCode || InspFacilityTypeCode.length === 0)
        errors.push({
            elementPaths: ["InspMain.InspLocation.InspFacilityTypeCode"],
            message: "Missing required facility type (fixed/roadside)",
        });
    if (FIPSCountyCode.length === 0)
        errors.push({
            elementPaths: ["InspMain.InspLocation.FIPSCountyCode"],
            message: "Missing FIPS county code",
        });
    if (CountryCode.length > 3)
        errors.push({
            elementPaths: ["InspMain.InspLocation.CountryCode"],
            message: "Invalid country code",
        });
    return errors;
};
exports.validateCarrier = (inspection) => {
    const errors = [];
    const carrier = inspection.InspMain.Carrier;
    if (carrier.CarrierName.length === 0)
        errors.push({
            elementPaths: ["InspMain.Carrier.CarrierName"],
            message: "Missing carrier name",
        });
    if (carrier.CarrierAddress.AddressCityName.length === 0)
        errors.push({
            elementPaths: ["InspMain.Carrier.CarrierAddress.AddressCityName"],
            message: "Missing carrier city",
        });
    const phoneLength = (carrier.CarrierPhoneNum || "").length;
    const faxLength = (carrier.CarrierFaxNum || "").length;
    if (phoneLength > 0 && phoneLength !== 10)
        errors.push({
            elementPaths: ["InspMain.Carrier.CarrierPhoneNum"],
            message: "Carrier phone number length not 10 digits",
            isWarning: true,
        });
    if (faxLength > 0 && faxLength !== 10)
        errors.push({
            elementPaths: ["InspMain.Carrier.CarrierFaxNum"],
            message: "Carrier fax number length not 10 digits",
            isWarning: true,
        });
    return errors;
};
exports.validateHazmat = (inspection) => {
    const errors = [];
    const isHazmatInspection = (inspection.InspMain.InspHazmatTypeCode || "") != "";
    if (isHazmatInspection) {
        // Require "yes" or "no" on placards
        if (["Y", "N"].indexOf(inspection.InspMain.CargoHazmatPlacardFlag || "") ===
            -1)
            errors.push({
                elementPaths: ["InspMain.CargoHazmatPlacardFlag"],
                message: "Placards required must be Yes or No on hazmat inspection",
            });
    }
    // Hazmat inspections can't have hazmat info
    else {
        if ((inspection.InspMain.CargoHazmatPlacardFlag || "") !== "")
            errors.push({
                elementPaths: ["InspMain.CargoHazmatPlacardFlag"],
                message: "Placards required must be None on non-hazmat inspection",
            });
        if (inspection.HazMats.length > 0)
            errors.push({
                elementPaths: ["InspMain.HazMats"],
                message: "Hazmats must not be present on non-hazmat inspection",
            });
        if ((inspection.InspMain.CargoTankTypeCode || "") !== "")
            errors.push({
                elementPaths: ["InspMain.CargoTankTypeCode"],
                message: "Cargo Tank Type not applicable on non-hazmat inspection",
            });
    }
    for (const hm of inspection.HazMats || []) {
        if (!hm.HMTypeCode) {
            errors.push({
                elementPaths: [`InspMain.HazMats[${hm.HMSeqNum}]`],
                message: `Hazmat #${hm.HMSeqNum} missing hazmat type`,
            });
        }
    }
    return errors;
};
exports.validateVehicle = (inspectionDate, vehicle, vehiclePath, inspectionContainsIepDetail) => {
    const inspectionYear = inspectionDate.asDate().getFullYear();
    const errors = [];
    const vehicleId = !vehicle.VehicleID || vehicle.VehicleID.length === 0
        ? "(blank)"
        : vehicle.VehicleID;
    if (vehicle.VehicleID.length === 0) {
        errors.push({
            elementPaths: [vehiclePath],
            message: `Vehicle ${vehicleId} missing VIN`,
        });
    }
    else {
        const vinValidationResult = VinValidator_1.validateVin(vehicle.VehicleID || "");
        if (!vinValidationResult.isValid)
            errors.push({
                elementPaths: [vehiclePath],
                message: `Vehicle ${vehicleId} VIN: ${vinValidationResult.invalidReason}`,
                isWarning: true,
            });
    }
    if ((vehicle.VehicleUnitTypeCode || "").length === 0)
        errors.push({
            elementPaths: [vehiclePath],
            message: `Vehicle ${vehicleId} missing unit code`,
        });
    if (!vehicle.VehicleMakeCode ||
        !vehicle.VehicleMakeName ||
        vehicle.VehicleMakeCode.trim().length === 0 ||
        vehicle.VehicleMakeName.trim().length === 0) {
        errors.push({
            elementPaths: [vehiclePath],
            message: `Vehicle ${vehicleId} missing make`,
        });
    }
    const gvwr = vehicle.VehicleGVWRNum ? parseInt(vehicle.VehicleGVWRNum) : 0;
    if (gvwr < 0 || isNaN(gvwr))
        errors.push({
            elementPaths: [vehiclePath],
            message: `Vehicle ${vehicleId} missing GVWR; please provide value of at least 0`,
        });
    if (!isNaN(gvwr) && gvwr > 80000)
        errors.push({
            elementPaths: [vehiclePath],
            message: `Vehicle ${vehicleId} GVWR is over 80,000. Please double-check weight.`,
            isWarning: true,
        });
    const vehicleYear = vehicle.VehicleYear
        ? parseInt(vehicle.VehicleYear)
        : undefined;
    if (!vehicleYear || vehicleYear < 1900 || vehicleYear > inspectionYear + 1) {
        errors.push({
            elementPaths: [vehiclePath],
            message: `Vehicle ${vehicleId} requires valid year`,
        });
    }
    if (!vehicle.VehicleLicenseStateCode) {
        errors.push({
            elementPaths: [vehiclePath],
            message: `Vehicle ${vehicleId} missing state`,
        });
    }
    if (vehicle.VehicleUnitTypeCode === "IC") {
        const vehicleIepPath = `${vehiclePath}.IntermodalEquipment`;
        const equipmentId = Iep_1.parseIepEquipmentId(vehicle.VehicleCompanyID);
        switch (equipmentId.status) {
            case "error":
                errors.push({
                    elementPaths: [vehicleIepPath],
                    message: `IC vehicle ${vehicleId} error: ${equipmentId.reason}`,
                });
                break;
        }
        if (!vehicle.IntermodalEquipment) {
            errors.push({
                elementPaths: [vehicleIepPath],
                message: `IC vehicle ${vehicleId} missing intermodal detail`,
            });
        }
        else {
            if (!vehicle.IntermodalEquipment.IEPDOTNum ||
                vehicle.IntermodalEquipment.IEPDOTNum.length === 0)
                errors.push({
                    elementPaths: [vehicleIepPath],
                    message: `IC vehicle ${vehicleId} missing IEP USDOT`,
                });
            if (!vehicle.IntermodalEquipment.IEPName ||
                vehicle.IntermodalEquipment.IEPName.length === 0)
                errors.push({
                    elementPaths: [vehicleIepPath],
                    message: `IC vehicle ${vehicleId} missing IEP Name`,
                });
            if (!vehicle.IntermodalEquipment.IEPSourceCode ||
                vehicle.IntermodalEquipment.IEPSourceCode.length === 0)
                errors.push({
                    elementPaths: [vehicleIepPath],
                    message: `IC vehicle ${vehicleId} missing source of identification`,
                });
            if (!inspectionContainsIepDetail)
                errors.push({
                    elementPaths: [vehicleIepPath],
                    message: `IC vehicle ${vehicleId} missing pre-trip inspection detail`,
                });
        }
    }
    return errors;
};
exports.validateVehicles = (inspection) => {
    let errors = [];
    if (inspection.InspMain.InspTypeLevelCode !== "3" &&
        inspection.Vehicles.length === 0)
        errors.push({
            elementPaths: ["Vehicles"],
            message: "Inspection contains no vehicles",
        });
    for (let i = 0; i < inspection.Vehicles.length; ++i) {
        const vehicle = inspection.Vehicles[i];
        const vehiclePath = `Vehicles[${i}].IntermodalEquipment`;
        const inspectionContainsIepDetail = !!inspection.InspMain.IEPData;
        errors = errors.concat(exports.validateVehicle(inspection.InspMain.InspStartTime, vehicle, vehiclePath, inspectionContainsIepDetail));
    }
    return errors;
};
// const validateIep = (inspection: Inspection): ValidationError[] => {
//   if (inspection.Vehicles.filter(x=>x.VehicleUnitTypeCode === "IC").length === 0
//   )
//   return [];
//   let errors: ValidationError[] = [];
//   // If no chassis owned/leased by carrier, require space provided and pre-trip inspection flags to be provided
//   if (inspection.Vehicles.filter(x=>x.IntermodalEquipment && x.IntermodalEquipment.chassisOwnedLeaseByMC).length === 0){
//     if (!inspection.InspMain.IEPData || inspection.InspMain.IEPData.IEPInspectionConducted === "U")
//     errors.push({
//       elementPaths: [`Vehicles[${i}]`],
//       message: "Inspection contains no vehicles",
//     });
//   }
//   return errors;
// };
exports.validateDrivers = (inspection) => {
    const errors = [];
    const driver = inspection.Drivers.filter((x) => !x.IsCoDriver)[0];
    const codriver = inspection.Drivers.filter((x) => x.IsCoDriver)[0];
    // No driver validation for level 5 or 7
    if (inspection.InspMain.InspTypeLevelCode === "5" ||
        inspection.InspMain.InspTypeLevelCode === "7")
        return [];
    const validateDriver = (driver, driverLabel) => {
        if ((driver.DriverFirstName || "").length === 0)
            errors.push({
                elementPaths: ["Driver"],
                message: `${driverLabel} missing first name`,
            });
        else if ((driver.DriverLastName || "").length === 0)
            errors.push({
                elementPaths: ["Driver"],
                message: `${driverLabel} missing last name`,
            });
        else if ((driver.DriverLicenseStateCode || "").length === 0 ||
            driver.DriverLicenseStateCode === "US")
            errors.push({
                elementPaths: ["Driver"],
                message: `${driverLabel} missing CDL state`,
            });
        else if (!driver.DriverBirthDate)
            errors.push({
                elementPaths: ["Driver"],
                message: `${driverLabel} missing date of birth`,
            });
        else {
            const age = moment().diff(driver.DriverBirthDate, "years");
            if (age < 18)
                errors.push({
                    elementPaths: ["Driver"],
                    message: `${driverLabel} less than 18 years old (${Math.floor(age)})`,
                });
            else if (age < 21)
                errors.push({
                    elementPaths: ["Driver"],
                    message: `${driverLabel} less than 21 years old (${Math.floor(age)})`,
                    isWarning: true,
                });
            else if (age > 80)
                errors.push({
                    elementPaths: ["Driver"],
                    message: `${driverLabel} over 80 years old (${Math.floor(age)})`,
                    isWarning: true,
                });
        }
    };
    if (driver) {
        validateDriver(driver, "Driver");
    }
    else {
        errors.push({
            elementPaths: ["Driver"],
            message: `Missing driver info`,
        });
    }
    if (codriver) {
        validateDriver(codriver, "Co-driver");
    }
    return errors;
};
exports.validateTimes = (args) => {
    const { creationTime: creationTimeParam, inspStartTime, inspEndTime } = args;
    const creationTime = creationTimeParam
        ? moment(creationTimeParam.asDate())
        : undefined;
    const startTime = moment(inspStartTime.asDate());
    const endTime = (inspEndTime && moment(inspEndTime.asDate())) || undefined;
    if (creationTime &&
        creationTime.isAfter(startTime) &&
        creationTime.diff(startTime, "minutes") > 10) {
        const createdToday = creationTime.format("MM/DD/YYYY") == startTime.format("MM/DD/YYYY");
        return [
            {
                elementPaths: ["InspMain.InspStartTime"],
                message: `Inspection start time too far back! Inspection created at ${createdToday
                    ? creationTime.format("H:mm")
                    : creationTime.format("MM/DD/YYYY H:mm")}`,
            },
        ];
    }
    if (!endTime)
        return [
            {
                elementPaths: ["InspMain.InspEndTime"],
                message: "Must specify end time",
            },
        ];
    if (startTime.isAfter(endTime))
        return [
            {
                elementPaths: ["InspMain.InspStartTime", "InspMain.InspEndTime"],
                message: "Inspection start time must not be after end time",
            },
        ];
    const minutesElapsed = endTime.diff(startTime, "minutes");
    if (minutesElapsed < 1)
        return [
            {
                elementPaths: ["InspMain.InspStartTime", "InspMain.InspEndTime"],
                message: "Inspection duration < 1 minute",
            },
        ];
    if (minutesElapsed > 48 * 60)
        return [
            {
                elementPaths: ["InspMain.InspStartTime", "InspMain.InspEndTime"],
                message: "Inspection duration > 48 hours",
            },
        ];
    const tenMinutesFromNow = moment(new Date())
        .add("10", "minutes")
        .endOf("minute");
    if (endTime.endOf("minute").isAfter(tenMinutesFromNow))
        return [
            {
                elementPaths: ["InspMain.InspEndTime"],
                message: "Inspection end time is in future",
            },
        ];
    // Only warnings below
    if (minutesElapsed < 5)
        return [
            {
                elementPaths: ["InspMain.InspStartTime", "InspMain.InspEndTime"],
                message: "Inspection duration < 5 minutes",
                isWarning: true,
            },
        ];
    return [];
};
exports.getViolationPrompt = (changedViolation, otherViolations) => {
    if (changedViolation.VioFederalRegCode === "396.3A1BOS" &&
        otherViolations.filter((x) => x.VioFederalRegCode === "396.3A1BOS").length >
            0)
        return {
            message: "Only a single 396.3(a)(1) violation for BRAKES OUT-OF-SERVICE allowed per inspection",
            level: "warn",
        };
    return undefined;
};
exports.validateViolations = (inspection) => {
    var _a, _b;
    const { Violations } = inspection;
    const errors = [];
    for (const violation of Violations) {
        const path = `# ${violation.VioSeqID}`;
        if (((_b = (_a = violation.SuppVioDesc) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 162)
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} supplemental text too long`,
            });
        // Missing violation detail
        if (!violation.VioFederalRegCode ||
            violation.VioFederalRegCode.length === 0)
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} missing federal violation code`,
            });
        if (!violation.VioRegSectionCode ||
            violation.VioRegSectionCode.length === 0)
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} missing federal section code`,
            });
        if (!violation.VioDescText || violation.VioDescText.length === 0)
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} missing description`,
            });
        if (!violation.VehicleUnitCode || violation.VehicleUnitCode.length === 0)
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} missing unit code`,
            });
        // Require verification flag
        if (!violation.VehicleDefectActionCode) {
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} missing verification code`,
            });
        }
        // OOS violation can be anything but "N"
        else if (violation.VioOOSFlag === "Y" &&
            violation.VehicleDefectActionCode === "N") {
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} verification marked "Not Applicable," but flag is required for OOS violation`,
            });
        }
        // Non-OOS violation must be "N"
        else if (violation.VioOOSFlag !== "Y" &&
            violation.VehicleDefectActionCode !== "N")
            errors.push({
                elementPaths: ["Violations"],
                message: `Violation ${path} verification must be "Not Applicable" unless OOS`,
            });
    }
    // Any driver OOS violations
    if (Violations.filter((x) => x.VehicleUnitCode === "D" && x.VioOOSFlag === "Y")
        .length > 0) {
        if (!inspection.OutOfServiceInfo ||
            !inspection.OutOfServiceInfo.DriverOOSUntilText ||
            inspection.OutOfServiceInfo.DriverOOSUntilText.trim().length === 0)
            errors.push({
                elementPaths: ["OutOfServiceInfo.DriverOOSUntilText"],
                message: "Driver OOS violation present, but missing Driver OOS Until text",
            });
    }
    // Non-driver violations on a driver inspection are allowed, but this encourages the inspector to review their work
    if (inspection.InspMain.InspTypeLevelCode == "3" &&
        Violations.filter((x) => x.VehicleUnitCode !== "D" && x.VehicleUnitCode !== "C").length > 0) {
        errors.push({
            elementPaths: ["Violations"],
            message: "Level 3 inspection should only contain Driver/Co-Driver violations",
            isWarning: true,
        });
    }
    // Violations not allowed to be OOS
    // NOTE: these seem to be arbitrary
    errors.push(...Violations.filter((violation) => [
        "393.47E",
        "393.47F",
        "393.53A",
        "393.53B",
        "393.53C",
        "396.3A1BA",
    ].filter((code) => code === violation.VioFederalRegCode).length > 0 &&
        violation.VioOOSFlag === "Y").map((violation) => ({
        elementPaths: ["Violations"],
        message: `${violation.VioFederalRegCode} may not be marked OOS`,
    })));
    // Violations that must always be OOS
    // NOTE: these seem to be arbitrary
    errors.push(...Violations.filter((violation) => ["396.3A1BOS", "391.41A-FPC"].filter((code) => code === violation.VioFederalRegCode).length > 0 && violation.VioOOSFlag !== "Y").map((violation) => ({
        elementPaths: ["Violations"],
        message: `${violation.VioFederalRegCode} must be marked OOS`,
    })));
    // Only one allowed per inspection
    if (Violations.filter((x) => x.VioFederalRegCode === "396.3A1BOS").length > 1)
        errors.push({
            elementPaths: ["Violations"],
            message: "May not cite 396.3A1BOS more than once per inspection",
        });
    // Only one 393.53B or 393.53BMAN allowed per vehicle
    const _393_53b_counts = _.countBy(Violations.filter((v) => ["393.53B", "393.53BMAN"].some((code) => code === v.VioFederalRegCode)), (v) => v.VehicleUnitCode);
    for (const k of Object.keys(_393_53b_counts)) {
        if (_393_53b_counts[k] > 1)
            errors.push({
                elementPaths: ["Violations"],
                message: "May not cite 393.53B more than once per unit",
            });
    }
    return errors;
};
