"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const core_1 = require("@blueprintjs/core");
const BrakeSelectionServices_1 = require("../../../services/BrakeSelectionServices");
class ChamberBox extends React.PureComponent {
    constructor(props) {
        super(props);
        this.selectChamberSubtype = () => this.props.onChamberSubTypeChanged(this.props.axleNum, BrakeSelectionServices_1.ChamberTypeSelectionService.getSelectedChamberSubtype());
        this.state = {
            chamberType: undefined,
        };
    }
    render() {
        return (React.createElement("div", null,
            React.createElement(core_1.Button, { style: this.props.style, className: core_1.Classes.FILL, text: this.props.chamberSubType && this.props.chamberSubType.name, onClick: this.selectChamberSubtype })));
    }
}
exports.default = ChamberBox;
