"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getVinYear = exports.validateVin = void 0;
const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2];
const transValues = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: 9,
    0: 0,
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    F: 6,
    G: 7,
    H: 8,
    J: 1,
    K: 2,
    L: 3,
    M: 4,
    N: 5,
    P: 7,
    R: 9,
    S: 2,
    T: 3,
    U: 4,
    V: 5,
    W: 6,
    X: 7,
    Y: 8,
    Z: 9,
};
exports.validateVin = (vin) => {
    if (vin.length !== 17)
        return {
            isValid: false,
            invalidReason: "Expected VIN length of 17 characters",
        };
    let sum = 0;
    for (let i = 0; i < vin.length; ++i) {
        const char = vin[i];
        if (!(char in transValues))
            return {
                isValid: false,
                invalidReason: `Invalid character: ${char}`,
            };
        const value = transValues[char];
        const weight = weights[i];
        sum = sum + value * weight;
    }
    const checkResult = sum % 11;
    const expectedCheckDigit = checkResult === 10 ? "X" : checkResult.toString();
    const checkDigit = vin[8];
    if (expectedCheckDigit !== checkDigit)
        return {
            isValid: false,
            invalidReason: `Check digit "${checkDigit}" doesn't match calculated "${expectedCheckDigit}"`,
        };
    return {
        isValid: true,
    };
};
exports.getVinYear = (vin) => {
    // Only operate on 17-digit VINs
    if (vin.length < 17)
        return [];
    const yearDigit = vin[9];
    return ({
        "1": [2001, 2031],
        "2": [2002, 2032],
        "3": [2003, 2033],
        "4": [2004, 2034],
        "5": [2005, 2035],
        "6": [2006, 2036],
        "7": [2007, 2037],
        "8": [2008, 2038],
        "9": [2009, 2039],
        A: [1980, 2010],
        B: [1981, 2011],
        C: [1982, 2012],
        D: [1983, 2013],
        E: [1984, 2014],
        F: [1985, 2015],
        G: [1986, 2016],
        H: [1987, 2017],
        J: [1988, 2018],
        K: [1989, 2019],
        L: [1990, 2020],
        M: [1991, 2021],
        N: [1992, 2022],
        P: [1993, 2023],
        R: [1994, 2024],
        S: [1995, 2025],
        T: [1996, 2026],
        V: [1997, 2027],
        W: [1998, 2028],
        X: [1999, 2029],
        Y: [2000, 2030],
    }[yearDigit] || []);
};
