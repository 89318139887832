"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findMeasurement = exports.Measurements = exports.NAMeasurement = void 0;
exports.NAMeasurement = { label: "N/A", measurement: 0 };
exports.Measurements = [
    { label: "Clear", measurement: undefined },
    { label: "1/16", measurement: 0.0625 },
    { label: "3/16", measurement: 0.1875 },
    { label: "5/16", measurement: 0.3125 },
    { label: "7/16", measurement: 0.4375 },
    exports.NAMeasurement,
    { label: "INOP", measurement: 0 },
    { label: "N/M", measurement: 0 },
    { label: "0", measurement: 0 },
    { label: "1/8", measurement: 0.125 },
    { label: "1/4", measurement: 0.25 },
    { label: "3/8", measurement: 0.375 },
    { label: "1/2", measurement: 0.5 },
    { label: "5/8", measurement: 0.625 },
    { label: "3/4", measurement: 0.75 },
    { label: "7/8", measurement: 0.875 },
    { label: "1", measurement: 1 },
    { label: "1 1/8", measurement: 1.125 },
    { label: "1 1/4", measurement: 1.25 },
    { label: "1 3/8", measurement: 1.375 },
    { label: "1 1/2", measurement: 1.5 },
    { label: "1 5/8", measurement: 1.625 },
    { label: "1 3/4", measurement: 1.75 },
    { label: "1 7/8", measurement: 1.875 },
    { label: "2", measurement: 2 },
    { label: "2 1/8", measurement: 2.125 },
    { label: "2 1/4", measurement: 2.25 },
    { label: "2 3/8", measurement: 2.375 },
    { label: "2 1/2", measurement: 2.5 },
    { label: "2 5/8", measurement: 2.625 },
    { label: "2 3/4", measurement: 2.75 },
    { label: "2 7/8", measurement: 2.875 },
    { label: "3", measurement: 3 },
    { label: "3 1/8", measurement: 3.125 },
    { label: "3 1/4", measurement: 3.25 },
    { label: "3 3/8", measurement: 3.375 },
    { label: "3 1/2", measurement: 3.5 },
    { label: "3 5/8", measurement: 3.625 },
    { label: "3 3/4", measurement: 3.75 },
    { label: "3 7/8", measurement: 3.875 },
    { label: "4", measurement: 4 },
    { label: "4 1/8", measurement: 4.125 },
    { label: "4 1/4", measurement: 4.25 },
    { label: "4 3/8", measurement: 4.375 },
    { label: "4 1/2", measurement: 4.5 },
    { label: "4 3/4", measurement: 4.75 },
    { label: "4 5/8", measurement: 4.625 },
    { label: "4 7/8", measurement: 4.875 },
    { label: "5", measurement: 5 },
    { label: "5 1/8", measurement: 5.125 },
    { label: "5 1/4", measurement: 5.25 },
    { label: "5 3/8", measurement: 5.375 },
    { label: "5 1/2", measurement: 5.5 },
    { label: "5 3/4", measurement: 5.75 },
    { label: "5 5/8", measurement: 5.625 },
    { label: "5 7/8", measurement: 5.875 },
];
function findMeasurement(label) {
    const found = exports.Measurements.filter(m => m.label === label)[0];
    return found;
}
exports.findMeasurement = findMeasurement;
