"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timezoneOffsets = exports.timezones = void 0;
exports.timezones = [
    ["AD", "Alaska Daylight"],
    ["AS", "Alaska Standard"],
    ["AT", "Alaska"],
    ["CD", "Central Daylight"],
    ["CS", "Central Standard"],
    ["CT", "Central"],
    ["ED", "Eastern Daylight"],
    ["ES", "Eastern Standard"],
    ["ET", "Eastern"],
    ["HD", "Hawaii-Aleutian Daylight"],
    ["HS", "Hawaii-Aleutian Standard"],
    ["HT", "Hawaii-Aleutian"],
    ["MD", "Mountain Daylight"],
    ["MS", "Mountain Standard"],
    ["MT", "Mountain"],
    ["ND", "Newfoundland Daylight"],
    ["NS", "Newfoundland Standard"],
    ["NT", "Newfoundland"],
    ["PD", "Pacific Daylight"],
    ["PS", "Pacific Standard"],
    ["PT", "Pacific"],
    ["TD", "Atlantic Daylight"],
    ["TS", "Atlantic Standard"],
    ["TT", "Atlantic"],
    ["10", "Guam, N. Mariana Is."],
    ["12", "Wake Is."],
    ["14", "Am. Samoa, Midway Is."],
];
exports.timezoneOffsets = {
    AD: -8,
    AS: -9,
    AT: -9,
    CD: -5,
    CS: -6,
    CT: -6,
    ED: -4,
    ES: -5,
    ET: -5,
    HD: -9,
    HS: -10,
    HT: -10,
    MD: -6,
    MS: -7,
    MT: -7,
    ND: -2.5,
    NS: -3.5,
    NT: -3.5,
    PD: -7,
    PS: -8,
    PT: -8,
    TD: -3,
    TS: -4,
    TT: -4,
    "10": 10,
    "12": 12,
    "14": 14,
};
